import React from 'react'

const InternalActivity = ({ user, stage, ...props }) => (
  <div className="InternalActivity h-full">
    <div className='h-full overflow-y-auto'>
      <div className='prose mx-auto px-6 py-12' dangerouslySetInnerHTML={ { __html: stage.internalText }} />
    </div>
  </div>
)

export default InternalActivity