import React from "react";
import { Modal } from "components/shared";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import { useHistory } from "react-router-dom";
import { useQuery } from "hooks";
import AddCohort from 'components/admin/AddCohort'

const AddCohortModal = ({ ...props }) => {
  const { watchQuery, deleteQuery } = useQuery();
  const history = useHistory();

  const open = watchQuery('addCohort')
  const onClose = () => {
    deleteQuery('addCohort')
    if(props.onChange) props.onChange()
  }

  return (
    <Modal
      size='sm'
      dismissible
      open={!!open}
      onClose={onClose}
      Icon={UserGroupIcon}
      title="Add a cohort"
    >
      <AddCohort onClose={onClose} />
    </Modal>
  );
};

export default AddCohortModal;
