import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Loading } from "components/shared";
import { H3 } from "components/typography";
import LiveSessionDetails from "components/live-sessions/LiveSessionDetails";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import moment from "moment";
import { cn } from "utils";

const LiveSessionSignup = ({ id, ...props }) => {
  const { API } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [liveSession, setLiveSession] = useState(null);

  const fetchLiveSession = async () => {
    const response = await API.get(`/live_sessions/${id}`);

    if (response) {
      setLiveSession(response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLiveSession();
  }, [id]);

  if (loading || !liveSession)
    return (
      <div className="relative min-h-[240px]">
        <Loading message="Loading session details" />
      </div>
    );

  return (
    <div className={cn(props.className)}>
      <H3>✅ You're signed up</H3>
      <LiveSessionDetails className="pt-8 pb-4" liveSession={liveSession} />
      <div className="flex justify-center">
        <AddToCalendarButton
          name={liveSession.title}
          options={[
            "Apple",
            "Google",
            "iCal",
            "Microsoft365",
            "MicrosoftTeams",
            "Outlook.com",
            "Yahoo",
          ]}
          description={liveSession.shortDescription}
          location={liveSession.location}
          startDate={moment(liveSession.startsAt).format("YYYY-MM-DD")}
          endDate={moment(liveSession.endsAt).format("YYYY-MM-DD")}
          startTime={moment(liveSession.startsAt).format("HH:mm")}
          endTime={moment(liveSession.endsAt).format("HH:mm")}
          organizer={`${liveSession.user.name}|${liveSession.user.email}`}
          timeZone="currentBrowser"
          listStyle="modal"
        />
      </div>
    </div>
  );
};

export default LiveSessionSignup;
