import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { pure } from "recompose";
import ArticleEditor from "components/editor/Editor";
import { Loading } from "components/shared";

const ArticleActivity = ({ stage, ...props }) => {
  const { editorMode } = useContext(AppContext);

  return (
    <div className="h-full overflow-auto overflow-x-hidden w-full pb-12">
      <ArticleEditor
        className="pt-8"
        initialContent={stage.blocks}
        editable={editorMode}
        updatePath={`/activity_stages/${stage.slug}`}
        transform={(blocks) => ({
          activity_stage: { blocks },
        })}
        editorClassName="prose mx-auto"
        onStartUpdate={props.onStartUpdate}
        onUpdate={props.onFinishUpdate}
        onErrorUpdate={() => props.onErrorUpdate({ message: 'Unspecified error' })}
        stage={stage}
      />
    </div>
  );
};

export default pure(ArticleActivity);
