import React from "react";
import ReactContentEditable from "react-contenteditable";
import { cn } from "utils";

const ContentEditable = React.forwardRef(
  (
    {
      as = 'div',
      onChange,
      onInput,
      onBlur,
      onFocus,
      onKeyPress,
      onKeyDown,
      selectOnFocus,
      interactive,
      ...props
    },
    ref,
  ) => {
    const onChangeRef = React.useRef(onChange);
    const onInputRef = React.useRef(onInput);
    const onFocusRef = React.useRef(onFocus);
    const onBlurRef = React.useRef(onBlur);
    const onKeyPressRef = React.useRef(onKeyPress);
    const onKeyDownRef = React.useRef(onKeyDown);

    React.useEffect(() => {
      onChangeRef.current = onChange;
    }, [onChange]);
    React.useEffect(() => {
      onInputRef.current = onInput;
    }, [onInput]);
    React.useEffect(() => {
      onFocusRef.current = onFocus;
    }, [onFocus]);
    React.useEffect(() => {
      onBlurRef.current = onBlur;
    }, [onBlur]);
    React.useEffect(() => {
      onKeyPressRef.current = onKeyPress;
    }, [onKeyPress]);
    React.useEffect(() => {
      onKeyDownRef.current = onKeyDown;
    }, [onKeyDown]);

    return (
      <ReactContentEditable
        {...props}
        tagName={as}
        className={cn(
          `focus:outline-none`,
          interactive && "hover:bg-gray-100 cursor-pointer underline decoration-dashed decoration-1 underline-offset-2 decoration-gray-400 hover:decoration-gray-600 focus:bg-gray-100",
          props.className,
        )}
        ref={ref}
        onChange={
          onChange
            ? (...args) => {
                if (onChangeRef.current) {
                  onChangeRef.current(...args);
                }
              }
            : undefined
        }
        onInput={
          onInput
            ? (...args) => {
                if (onInputRef.current) {
                  onInputRef.current(...args);
                }
              }
            : undefined
        }
        onFocus={(...args) => {
          if (onFocus) {
            if (onFocusRef.current) {
              onFocusRef.current(...args);
            }
          }

          if (selectOnFocus) {
            const target = args[0].target;
            const range = document.createRange();
            range.selectNodeContents(target);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
          }
        }}
        onBlur={
          onBlur
            ? (...args) => {
                if (onBlurRef.current) {
                  onBlurRef.current(...args);
                }
              }
            : undefined
        }
        onKeyPress={
          onKeyPress
            ? (...args) => {
                if (onKeyPressRef.current) {
                  onKeyPressRef.current(...args);
                }
              }
            : undefined
        }
        onKeyDown={
          onKeyDown
            ? (...args) => {
                if (onKeyDownRef.current) {
                  onKeyDownRef.current(...args);
                }
              }
            : undefined
        }
      />
    );
  },
);

export default ContentEditable;
