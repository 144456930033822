import React from "react";
import Option from "components/bloom/Option";
import { motion } from "framer-motion";

const Options = ({ title, options, attachments, ...props }) => {
  return (
    <div className="shrink w-96 flex flex-col h-full overflow-y-auto">
      {options.length > 0 && (
        <motion.div
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            duration: 0.65,
            ease: [0.075, 0.82, 0.165, 1],
          }}
          className="pt-4 px-8 text-gray-400 text-sm font-semibold"
        >
          {title}
        </motion.div>
      )}

      <div className="px-4 pt-4 pb-6 space-y-4">
        {options.map((option, index) => (
          <Option
            key={option.id}
            {...option}
            index={index}
            attached={attachments.includes(option)}
            onAttach={() => props.onAttach(option)}
            onUnattach={() => props.onUnattach(option)}
          />
        ))}
      </div>
    </div>
  );
};

export default Options;
