import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import { useQuery, usePut, useFetch, useGeolocatedPrice } from "hooks";
import { Link } from "react-router-dom";
import { Modal, Button, PlaceholderText } from "components/shared";
import { CheckIcon } from "@heroicons/react/20/solid";
import LicenseInput from "components/users/LicenseInput";
import Logo from "images/techmap-logo.png";

const UpfrontPaymentModal = ({ ...props }) => {
  const { graphUrl, currentUser, setCurrentUser } = useContext(AppContext);
  const { watchQuery } = useQuery();

  const open = !!watchQuery("getStarted");

  const [loadingPayment, setLoadingPayment] = useState(false);
  const [course, setCourse] = useState(null);

  useFetch(`courses/${currentUser.courseId}`, setCourse, [currentUser, open]);
  const price = useGeolocatedPrice(course?.prices);

  const referral = () => {
    if (
      typeof Rewardful !== "undefined" &&
      Rewardful &&
      Rewardful.referral &&
      Rewardful.referral.length > 0
    ) {
      return `&referral=${Rewardful.referral}`;
    }

    return "";
  };

  const { put, data } = usePut(async (user) => {
    await setCurrentUser(user);
    props.onClose();
  });

  const buyLicenseLink = `${graphUrl}/stripe/create-checkout-session?payment_point=onboarding${referral()}`;

  const startFreeTrial = (e) => {
    e.preventDefault();

    const body = {
      user: {
        selected_plan_type: "free_trial",
      },
    };

    put(`users/${currentUser.id}`, body);
  };

  return (
    <Modal size={"5xl"} open={open} onClose={() => {}}>
      <div className="mx-auto space-y-8 mb-4">
        <LicenseInput
          className="absolute right-14 top-6"
          onClose={props.onClose}
        />
        <div className="bg-white py-8">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl sm:text-center">
              <img src={Logo} className="mx-auto mb-4 h-12 w-12" />
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Pick a plan
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                TechMap is the industry standard course that teaches you
                everything you need to communicate with technical stakeholders.
              </p>
            </div>
            <div className="mx-auto mt-8 max-w-2xl rounded-3xl ring-1 ring-gray-200 mx-0 flex-col max-w-none">
              <div className="flex">
                <div className="px-8 py-6 flex-grow">
                  <h3 className="text-2xl font-bold tracking-tight text-gray-900 text-left">
                    Lifetime membership
                  </h3>
                  <div className="mt-4 flex items-center gap-x-4">
                    <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                      What’s included
                    </h4>
                    <div className="h-px flex-auto bg-gray-100" />
                  </div>
                  <ul
                    role="list"
                    className="mt-4 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      The full 15+ hour course
                    </li>
                    <li className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      One-to-one coaching
                    </li>
                    <li className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      LinkedIn/CV Certification
                    </li>
                    <li className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      All new features we release
                    </li>
                  </ul>
                </div>
                <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                  <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center">
                    <div className="mx-auto max-w-xs px-8">
                      <p className="text-base font-semibold text-gray-600">
                        Pay once, own it forever
                      </p>
                      {price ? (
                        <p className="mt-6 flex items-baseline justify-center gap-x-2">
                          <span className="text-5xl font-bold tracking-tight text-gray-900">
                            {price.currencySymbol}
                            {price.amount}
                          </span>
                          <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                            {price.isoCode}
                          </span>
                        </p>
                      ) : (
                        <div className="mt-6 flex items-baseline justify-center gap-x-2">
                          <PlaceholderText size="5xl" />
                          <PlaceholderText size="sm" />
                        </div>
                      )}
                      <Button
                        id="buyButton"
                        type="link"
                        href={buyLicenseLink}
                        disabled={loadingPayment}
                        onClick={() => setLoadingPayment(true)}
                        size="lg"
                        className="mt-6"
                      >
                        Get access
                      </Button>
                      <p className="mt-6 text-xs leading-5 text-gray-600">
                        Invoices and receipts available for easy company
                        reimbursement
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="px-8 py-6 flex-grow">
                  <h3 className="text-2xl font-bold tracking-tight text-gray-900 text-left">
                    Free trial
                  </h3>
                  <div className="mt-4 flex items-center gap-x-4">
                    <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                      What’s included
                    </h4>
                    <div className="h-px flex-auto bg-gray-100" />
                  </div>
                  <ul
                    role="list"
                    className="mt-4 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      3 hours of learning
                    </li>
                    <li className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      AI coaching
                    </li>
                    <li className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      Learn the basic models
                    </li>
                  </ul>
                </div>
                <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                  <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center">
                    <div className="mx-auto max-w-xs px-8">
                      <p className="text-sm font-semibold text-gray-600">
                        Try three activities
                      </p>
                      <Button
                        id="freeTrialLink"
                        variant="secondary"
                        onClick={startFreeTrial}
                        size="lg"
                        className="mt-4"
                      >
                        Try it out
                      </Button>
                      <p className="mt-4 text-xs leading-5 text-gray-600">
                        You won't be charged, and you can upgrade later.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpfrontPaymentModal;
