import React from 'react'
import { Hint, ProgressLine, Avatar } from 'components/shared'
import moment from 'moment'

const MergePullRequest = (props) => {
  return(
    <ProgressLine.Widget>
      <ProgressLine.Widget.Indicator
       filled={props.attempt?.pullRequestMerged}
       active={props.attempt?.pullRequestApproved && !props.attempt?.pullRequestMerged} />

      <ProgressLine.Widget.Details>
        <ProgressLine.Widget.Details.Header>
          <div className="WidgetTitle">
            Pull Request merged
            {
              props.attempt?.pullRequestMerged
              && <Hint className="ml-1">
                   { moment(props.attempt?.pullRequest.mergedAt).fromNow() }
                 </Hint>
            }
          </div>
          <Hint>
            {
              props.attempt?.pullRequestMerged
              && <a href={props.attempt.pullRequest.url} target="_blank" className="mr-1">
                   { props.attempt.pullRequest.title }
                 </a>
            }
          </Hint>
        </ProgressLine.Widget.Details.Header>
      </ProgressLine.Widget.Details>
    </ProgressLine.Widget>
  )
}

export default MergePullRequest