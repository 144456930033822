import React, { Fragment, useState, useContext, useMemo } from "react";
import AppContext from "contexts/AppContext";
import { Pill } from "components/shared";
import { Link } from "react-router-dom";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import Discussion from "components/coaching/Discussion";
import moment from "moment";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { cn } from "utils";

const ActivityAttemptTranscript = React.forwardRef(
  ({ attempt, user, ...props }, ref) => {
    const { currentUser } = useContext(AppContext);

    const unviewedDiscussions = attempt.discussions.filter(
      (d) => !d.viewers.includes(currentUser.id),
    );

    const completedAt = useMemo(() => {
      if (!attempt.completedAt) return "now";

      if (
        moment(attempt.createdAt).format("Do MMMM") ===
        moment(attempt.completedAt).format("Do MMMM")
      )
        return moment(attempt.completedAt).format("HH:mm");

      return moment(attempt.completedAt).format("Do MMMM HH:mm");
    }, [attempt]);

    if (attempt.discussions.length === 0) return null;

    return (
      <div
        className={cn("mb-8 border-b border-gray-200", props.className)}
        ref={ref}
      >
        <Pill className="ml-8 mb-2 opacity-80">
          {moment(attempt.createdAt).format("Do MMMM HH:mm")} &#8212;{" "}
          {completedAt}
        </Pill>
        <h3 className="flex items-center space-x-2 px-8 font-medium text-xl text-gray-800 mb-2">
          {attempt.activity.title}
          <Link to={`/activities/${attempt.activity.slug}`}>
            <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-2 text-blue-400 hover:text-blue-500 cursor-pointer" />
          </Link>
        </h3>

        {props.children}
      </div>
    );
  },
);

export default ActivityAttemptTranscript;
