import React from "react";
import {
  Hint,
  Alert,
  PlaceholderText,
  ProgressBar,
  Button,
} from "components/shared";
import {
  XCircleIcon,
  ArrowPathRoundedSquareIcon,
} from "@heroicons/react/24/outline";

const TranscriptionStatus = ({ transcribeJob, ...props }) => {
  const PROCESSING_STATUSES = ["working", "retrying", "queued"];
  const isProcessing = PROCESSING_STATUSES.includes(transcribeJob.status);

  if (transcribeJob.exception)
    return (
      <div>
        <Alert
          type="alert"
          action={
            transcribeJob.status === "failed" ? (
              <Button
                type="pill"
                Icon={ArrowPathRoundedSquareIcon}
                onClick={props.onRetryTranscribeJob}
              >
                Retry
              </Button>
            ) : (
              <Button
                type="pill"
                Icon={XCircleIcon}
                onClick={props.onCancelTranscribeJob}
              >
                Cancel
              </Button>
            )
          }
        >
          Transcriber {transcribeJob.status}: {transcribeJob.exception}
        </Alert>
      </div>
    );

  return (
    <div className="py-5 px-6 space-y-4">
      <div className="flex items-center justify-between">
        <Hint>
          {transcribeJob.activity
            ? transcribeJob.activity
            : `Transcriber ${transcribeJob.status || "working"}${
                isProcessing && "..."
              }`}
        </Hint>

        <Button
          type="pill"
          Icon={XCircleIcon}
          onClick={props.onCancelTranscribeJob}
        >
          Cancel
        </Button>
      </div>

      {isProcessing && <ProgressBar progress={transcribeJob.at || 0} />}

      <PlaceholderText lines={12} size="md" />
    </div>
  );
};

export default TranscriptionStatus;
