import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { ArrowTopRightOnSquareIcon, PlusIcon } from "@heroicons/react/20/solid";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/24/outline";
import { Hint } from "components/typography";
import {
  Badge,
  Loading,
  RemoteSelectInput,
  Tooltip,
  SelectInput,
  Label,
  Spinner,
  Form,
  Checkbox,
  Button,
} from "components/shared";
import { Link } from "react-router-dom";
import { useFilters, usePagination, useFlash } from "hooks";
import { cn, niceDate } from "utils";

const StageFilters = ({ filters, onChange, ...props }) => {
  const { stageTypes, AVAILABLE_STAGE_TYPES } = useContext(AppContext);

  return (
    <div className="py-2 mt-4 flex items-center space-x-4">
      <div className="w-full text-left">
        <Label small className="mb-1 ml-1">
          Course
        </Label>
        <RemoteSelectInput
          size="sm"
          className="w-full"
          selectClassName="w-full"
          title="Course"
          defaultOption="Select a course"
          defaultValue={filters.course_id}
          disableDefaultOption
          fetchPath="courses"
          optionValue={"id"}
          optionLabel={"title"}
          onChange={(e) =>
            onChange(
              { name: "course_id", value: e.target.value },
              { resetOtherFilters: true },
            )
          }
        />
      </div>

      <div className="w-full text-left">
        <Label small className="mb-1 ml-1">
          Activity
        </Label>
        <RemoteSelectInput
          size="sm"
          className="w-full"
          selectClassName="w-full"
          title="Activity"
          defaultOption="Select an activity"
          defaultValue={filters.activity_id}
          disableDefaultOption
          fetchPath={`activities?course_id=${filters.course_id}`}
          optionValue={"id"}
          optionLabel={"title"}
          optionPrelabel={"stage_count"}
          onChange={(e) =>
            onChange({ name: "activity_id", value: e.target.value })
          }
        />
      </div>

      <div className="w-full text-left">
        <Label small className="mb-1 ml-1">
          Stage type
        </Label>
        <SelectInput
          size="sm"
          defaultValue={filters.type}
          wrapperClassName="w-full"
          onChange={(e) => onChange({ name: "type", value: e.target.value })}
        >
          <option>All</option>
          {Object.entries(stageTypes)
            .filter(([type, data]) => AVAILABLE_STAGE_TYPES.includes(type))
            .map(([type, { name }], index) => (
              <option value={type} key={index}>
                {name}
              </option>
            ))}
        </SelectInput>
      </div>
    </div>
  );
};

const StageInList = ({ stage, ...props }) => {
  const { stageMetadata } = useContext(AppContext);

  const Icon = stageMetadata(stage.type).Icon;

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!props.isDuplicating) props.onClick(stage);
  };

  const handleDuplicate = (formData) => {
    if (props.isConfiguring) props.onDuplicate(stage.slug, formData);
  };

  const name = stage.title ? stage.title : stageMetadata(stage.type).name;

  return (
    <div
      className={cn(
        "px-4 py-4 flex items-center justify-between space-x-4",
        !!props.isDuplicating &&
          props.isDuplicating !== stage.slug &&
          "opacity-40 animate-pulse",
      )}
      key={stage.slug}
    >
      <div className="truncate w-full text-left">
        <div className="flex items-baseline space-x-1 text-sm text-gray-700">
          {stage.isArchived ? (
            <>
              <ArchiveBoxXMarkIcon className="h-5 w-5 inline relative top-[5px] flex-shrink-0" />
              <span>Archived {name}</span>
            </>
          ) : (
            <>
              <Icon className="h-5 w-5 inline relative top-[5px] flex-shrink-0" />
              <span className="">{name}</span>
            </>
          )}
          <span className="text-gray-500 text-xs">in</span>
          {stage.activity && (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/activities/${stage.activity.slug}/stages/${stage.slug}`}
              className="text-blue-400 hover:text-blue-500 text-xs"
            >
              {stage.activity.title}{" "}
              <ArrowTopRightOnSquareIcon className="h-3 w-3 inline relative" />
            </Link>
          )}
        </div>
        <Hint tiny inline>
          Created {niceDate(stage.createdAt)}
        </Hint>
        {new Date(stage.updatedAt) - new Date(stage.createdAt) > 20_000 &&
          niceDate(stage.createdAt) !== niceDate(stage.updatedAt) &&
          !stage.isArchived && (
            <Hint tiny inline>
              . Updated {niceDate(stage.updatedAt)}
            </Hint>
          )}
        {stage.archivedAt && (
          <Hint tiny inline>
            . Archived {niceDate(stage.archivedAt)}
          </Hint>
        )}
      </div>
      {props.isDuplicating ? (
        props.isDuplicating === stage.slug ? (
          <Spinner className="text-gray-400" />
        ) : (
          <div className="flex-shrink-0 text-gray-400 cursor-wait">
            <PlusIcon className="h-6 w-6" />
          </div>
        )
      ) : props.isConfiguring ? (
        <Form
          className="flex-shrink-0 flex-col space-y-1"
          onSubmit={handleDuplicate}
        >
          <div className="flex items-center space-x-1">
            <Checkbox name="include_questions" size="small" />
            <Label small>Questions</Label>
          </div>
          <div className="flex items-center space-x-1">
            <Checkbox name="include_discussions" size="small" />
            <Label small>Discussions</Label>
          </div>
          <div>
            <Button type="submit" size="xs" Icon={PlusIcon}>
              Duplicate
            </Button>
          </div>
        </Form>
      ) : (
        <div
          className="flex-shrink-0 text-gray-400 hover:text-gray-700 cursor-pointer"
          onClick={handleClick}
        >
          <Tooltip text="Duplicate this stage to the current activity">
            <PlusIcon className="h-6 w-6" />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const AddStageFromLibrary = ({ activity, ...props }) => {
  const { API, currentUser } = useContext(AppContext);
  const [stages, setStages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isConfiguring, setIsConfiguring] = useState(null);
  const [isDuplicating, setIsDuplicating] = useState(null);

  const { successFlash, errorFlash } = useFlash();

  const { filters, handleFilterChange } = useFilters({
    course_id: currentUser.courseId,
  });

  const { page, Pagination } = usePagination(stages, { perPage: 6 });

  const fetchStages = async () => {
    setIsLoading(true);
    const response = await API.get("/activity_stages", {
      params: filters,
    });
    if (response) {
      setStages(response);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStages();
  }, [filters]);

  const handleDuplicateStage = async (slug, formData) => {
    setIsConfiguring(null);
    setIsDuplicating(slug);

    const body = {
      activity_stage: {
        graph_activity_id: activity.id,
        slug: slug,
        ...formData,
      },
    };

    const stage = await API.post(`/activity_stages/duplicate`, body, {
      onError: errorFlash,
    });

    if (stage) {
      setIsDuplicating(false);
      successFlash("Stage duplicated successfully.");
      fetchStages();
      if (props.onAddStage)
        props.onAddStage(stage, {
          redirectPath: `/activities/${activity.slug}/stages/${stage.slug}?addStageFromLibrary=1`,
        });
    }
  };

  if (isLoading)
    return (
      <div className={cn(props.className)}>
        <StageFilters filters={filters} onChange={handleFilterChange} />
        <div className="relative min-h-[240px]">
          <Loading />
        </div>
      </div>
    );

  return (
    <div className={cn(props.className)}>
      <StageFilters filters={filters} onChange={handleFilterChange} />
      <div className="divide-y divide-gray-200">
        {page.map((stage) => (
          <StageInList
            stage={stage}
            isConfiguring={isConfiguring === stage.slug}
            isDuplicating={isDuplicating}
            key={stage.slug}
            onClick={(stage) => setIsConfiguring(stage.slug)}
            onDuplicate={handleDuplicateStage}
          />
        ))}
      </div>

      <Pagination small />
    </div>
  );
};

export default AddStageFromLibrary;
