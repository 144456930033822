import React, { Fragment, useState, useContext } from "react";
import AppContext from "contexts/AppContext";
import {
  Detail,
  RemoteSelectInput,
  RemoteTextInput,
  RemoteCombobox,
  Card,
  Button,
  Badge,
  Hint,
} from "components/shared";
import { TrashIcon, PlusIcon } from "@heroicons/react/24/solid";
import UserDetails from "components/users/UserDetails";
import ChangePasswordModal from "components/users/ChangePasswordModal";
import UserRoles from "components/admin/UserRoles";
import { useQuery } from "hooks";
import { Link } from "react-router-dom";
import { titleize } from "utils";

const UserProfile = ({ user, cohort, ...props }) => {
  const { currentUser } = useContext(AppContext);

  const { pathWithQuery } = useQuery();

  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  return (
    <div>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8">
        {user.bio && (
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Bio</dt>
            <dd
              className="mt-1 max-w-prose space-y-5 text-sm text-gray-900"
              dangerouslySetInnerHTML={{ __html: user.bio }}
            />
          </div>
        )}

        <Detail key="course" default="No course" title="Course">
          {currentUser.isAdmin ? (
            <RemoteSelectInput
              title="Course"
              defaultOption="Select a course"
              fetchPath="courses"
              updatePath={`/users/${user.id}`}
              updateTransform={(e) => ({ user: { course_id: e.target.value } })}
              defaultValue={user.course?.id}
              optionValue={"id"}
              optionLabel={"title"}
              onSubmit={props.onChange}
            />
          ) : (
            user.course?.title
          )}
        </Detail>

        {user.cohort?.title && (
          <Detail key="cohort" defaultValue="No cohort" title="Cohort">
            {currentUser.isAdmin ? (
              <div className="flex items-center space-x-2">
                <RemoteSelectInput
                  title="Cohort"
                  defaultOption="Select a cohort"
                  fetchPath="cohorts"
                  updatePath={`/users/${user.id}`}
                  updateTransform={(e) => ({
                    user: { cohort_id: e.target.value },
                  })}
                  defaultValue={user.cohort.id}
                  optionValue={"id"}
                  optionLabel={"title"}
                  onSubmit={props.onChange}
                />
                <Button
                  size="sm"
                  variant="secondary"
                  type="routeLink"
                  to={pathWithQuery(null, { addCohort: true })}
                  Icon={PlusIcon}
                />
              </div>
            ) : (
              user.cohort?.title
            )}
          </Detail>
        )}

        {(currentUser.isAdmin ||
          currentUser.isTrainer ||
          currentUser.isManager ||
          currentUser.id === user.id) && (
          <Detail
            title="Email"
            extra={
              user.unconfirmedEmail ? (
                <Badge size="sm" color="yellow" className="ml-1">
                  Unconfirmed
                </Badge>
              ) : (
                <Badge size="sm" color="green" className="ml-1">
                  Confirmed
                </Badge>
              )
            }
          >
            <RemoteTextInput
              title="Email"
              name="email"
              placeholder="No email given"
              defaultValue={
                user.unconfirmedEmail ? user.unconfirmedEmail : user.email
              }
              method="put"
              path={`users/${user.id}`}
              transform={(text) => ({ user: { email: text } })}
              onSubmit={props.onChange}
              messageOnSuccess={
                user.unconfirmedEmail &&
                "Please check your email inbox to confirm the email change."
              }
            />
            {user.unconfirmedEmail && (
              <Hint tiny className="mt-1 pl-px max-w-sm">
                Currently using {user.email}. Check your inbox to confirm new
                email.
              </Hint>
            )}
          </Detail>
        )}

        {(currentUser.isAdmin || currentUser.id === user.id) && (
          <div className="flex items-center pt-6">
            <Button
              type="pill"
              onClick={() => setChangePasswordModalOpen(true)}
            >
              Change Password
            </Button>
            <ChangePasswordModal
              user={user}
              open={changePasswordModalOpen}
              onClose={() => setChangePasswordModalOpen(false)}
            />
          </div>
        )}

        <Detail title="Organization" defaultValue="No organization given">
          {currentUser.isAdmin ? (
            <div className="flex items-center space-x-2">
              <RemoteSelectInput
                title="Organization"
                defaultOption="Select an organization"
                fetchPath="organizations"
                updatePath={`/users/${user.id}`}
                updateTransform={(e) => ({
                  user: { organization_id: e.target.value },
                })}
                defaultValue={user.organization?.id}
                optionValue={"id"}
                optionLabel={"name"}
                onSubmit={props.onChange}
              />
              <Button
                size="sm"
                variant="secondary"
                type="routeLink"
                to={pathWithQuery(null, { addOrganization: true })}
                Icon={PlusIcon}
              />
            </div>
          ) : (
            user.organization?.name
          )}
        </Detail>

        {(currentUser.isAdmin || user.managers?.length > 0) && (
          <Detail title="Managers" defaultValue="No managers selected">
            {currentUser.isAdmin ? (
              <div className="flex items-center space-x-2">
                <RemoteCombobox
                  title="Managers"
                  placeholder="Select managers"
                  defaultOptions={user.managers}
                  fetchPath="managers"
                  updatePath={`/managers`}
                  updateTransform={(managers) => ({
                    user: { manager_ids: managers.map(m => m.id) },
                    user_id: user.id,
                  })}
                  successMessage={`Managers updated for ${user.firstName}.`}
                  defaultValue={user.managers}
                  optionValue={"id"}
                  optionLabel={"name"}
                />
              </div>
            ) : (
              <>
                {user.managers.map((manager, index) => (
                  <>
                    <Link
                      className="font-semibold text-indigo-600 hover:text-indigo-800"
                      to={pathWithQuery(null, { user: manager.id })}
                    >
                      {manager.name}
                    </Link>
                    {user.managers.length > 1 &&
                      index !== user.managers.length - 1 && (
                        <span className="text-gray-600">, </span>
                      )}
                  </>
                ))}
              </>
            )}
          </Detail>
        )}

        <Detail title="Title" defaultValue="No title given">
          {currentUser.isAdmin || currentUser.id === user.id ? (
            <RemoteTextInput
              title="Job title"
              name="title"
              placeholder="No title given"
              defaultValue={user.title}
              method="put"
              path={`users/${user.id}`}
              transform={(text) => ({ user: { job_title: text } })}
              onSubmit={props.onChange}
            />
          ) : (
            user.title
          )}
        </Detail>

        <Detail title="Location" defaultValue="No location given">
          {currentUser.isAdmin || currentUser.id === user.id ? (
            <RemoteTextInput
              user={user}
              title="Location"
              name="location"
              placeholder="No location given"
              defaultValue={user.location}
              method="put"
              path={`users/${user.id}`}
              transform={(text) => ({ user: { location: text } })}
              onSubmit={props.onChange}
            />
          ) : (
            user.location
          )}
        </Detail>

        {(currentUser.isAdmin ||
          currentUser.isManager ||
          currentUser.id === user.id) && (
          <Detail title="License" defaultValue="No license given">
            <RemoteTextInput
              title="License key"
              name="license"
              placeholder="No License"
              defaultValue={user.license}
              method="put"
              path={`users/${user.id}`}
              transform={(code) => ({ user: { license: code } })}
              disabled={!(currentUser.isAdmin || currentUser.isManager)}
              onSubmit={props.onChange}
            />
          </Detail>
        )}

        {currentUser.isAdmin && (
          <Detail title="Roles" defaultValue="No roles given">
            <UserRoles user={user} remote={true} />
          </Detail>
        )}

        {currentUser.isAdmin && (
          <Detail>
            <Button
              type="routeLink"
              variant="danger"
              to={pathWithQuery(null, { destroyUserId: user.id })}
              Icon={TrashIcon}
            >
              Delete user
            </Button>
          </Detail>
        )}
      </dl>

      {user.cohort?.id && user.cohort.title !== "No cohort" && (
        <div className="mt-8 pb-12">
          <h2 className="text-sm font-medium text-gray-500">
            Also in {user.cohort.title}
          </h2>
          <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {cohort?.map((user) => (
              <Link key={user.id} to={pathWithQuery(null, { user: user.id })}>
                <Card>
                  <UserDetails user={user} onChange={props.onChange} />
                </Card>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
