import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useFetch } from 'hooks'
import moment from 'moment'
import { ChevronDownIcon,
         ChevronUpIcon } from '@heroicons/react/20/solid'
import { FormattedEvent } from 'components/shared'
import { classNames, isInViewport } from 'utils'

const TimelineItem = React.forwardRef(({ event, previousEvent, isLast, isHighlighted, isShort, ...props }, ref) => {
  const diff = (previousEvent, event) => {
    if(!previousEvent) return null

    const secondsDiff = moment(event.createdAt).diff(moment(previousEvent.createdAt), 'seconds')

    if(secondsDiff < 60) return `${secondsDiff}s`

    const minutesDiff = moment(event.createdAt).diff(moment(previousEvent.createdAt), 'minutes')

    if(minutesDiff < 60) return `${minutesDiff}m`

    const hoursDiff = moment(event.createdAt).diff(moment(previousEvent.createdAt), 'hours')

    if(hoursDiff < 24) return `${hoursDiff}h`

    const daysDiff = moment(event.createdAt).diff(moment(previousEvent.createdAt), 'days')

    if(daysDiff < 30) return `${daysDiff}d`

    const monthsDiff = moment(event.createdAt).diff(moment(previousEvent.createdAt), 'months')

    if(monthsDiff < 12) return `${monthsDiff}mo`

    const yearsDiff = moment(event.createdAt).diff(moment(previousEvent.createdAt), 'years')

    return `${yearsDiff}y`
  }

  return(
    <li ref={ref} className="relative">
      <div
       className={classNames(
        "block relative",
        !isLast && 'pb-10'
       )}>
        {
          !isLast &&
          <div aria-hidden="true" className="absolute top-4 left-2 w-4 h-full flex flex-col items-center">
            <div className="-ml-px h-full w-0.5 bg-gray-200" />
            <div className="text-xs bg-gray-50 rounded-full text-gray-400 px-2 py-1 absolute bottom-1/3">
              { diff(previousEvent, event) }
            </div>
          </div>
        }
        <FormattedEvent event={event} isHighlighted={isHighlighted} isShort={isShort} />
      </div>
    </li>
  )
})

const Timeline = ({ title, user, selectedDiscussion, highlightedResponseId, reverse }) => {
  const ITEMS_PER_PAGE = 9
  const [events, setEvents] = useState([])
  const [page, setPage] = useState([])
  const [pageIndex, setPageIndex] = useState(0)

  const currentItemRef = useRef(null)

  useEffect(() => {
    if(pageIndex === 0) {
      setPage(events.slice(0, ITEMS_PER_PAGE))
    } else {
      setPage(
        events.slice(
          pageIndex * ITEMS_PER_PAGE,
          (pageIndex * ITEMS_PER_PAGE) + ITEMS_PER_PAGE)
        )
    }
  }, [events, pageIndex])

  useEffect(() => {
    if(highlightedResponseId) {
      const highlightedResponseIndex = events
                                        .map(({ response }) => response?.id)
                                        .indexOf(highlightedResponseId) 
      setPageIndex(Math.floor(highlightedResponseIndex / ITEMS_PER_PAGE))

      const element = currentItemRef.current
      if(element && !isInViewport(element)) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest', offset: '20' })
      }
    }
  }, [events, highlightedResponseId])

  const nextPage = () => setPageIndex(pageIndex + 1)
  const previousPage = () => setPageIndex(pageIndex - 1)

  const canPreviousPage = pageIndex > 0
  const canNextPage = events.length > ITEMS_PER_PAGE && events.length > (pageIndex + 1) * ITEMS_PER_PAGE

  const { loadingOrError } = useFetch(`events?user_id=${user.id}`, setEvents, [user])

  if(loadingOrError) return loadingOrError

  return (
    <>
      {
        title &&
        <div className="font-semibold text-gray-900 mb-2 text-md">
          { title }
        </div>
      }

      <div className="flex items-center space-x-3 mb-4 pl-1">
      <ChevronUpIcon
       className={classNames(
        "h-6 w-6",
        canPreviousPage ? 'text-gray-400 hover:text-gray-600 cursor-pointer' : 'text-gray-300'
       )}
       onClick={ canPreviousPage ? previousPage : null } />
      <span className="text-xs text-gray-400">More recent</span>
      </div>

      <div>
        <ul className='list-none' role="list">
          {page.map((event, index) => (
            <TimelineItem
             key={event.id}
             ref={highlightedResponseId && highlightedResponseId === event.response?.id ? currentItemRef : null}
             event={event}
             previousEvent={events[events.indexOf(event) + 1]}
             isLast={index === page.length - 1}
             isHighlighted={highlightedResponseId && highlightedResponseId === event.response?.id} />
          ))}
        </ul>
      </div>

      <div className="flex items-center space-x-3 mt-4 pl-1">
      <ChevronDownIcon
       className={classNames(
        "h-6 w-6",
        canNextPage ? 'text-gray-400 hover:text-gray-600 cursor-pointer' : 'text-gray-300'
       )}
       onClick={ canNextPage ? nextPage : null } />
      <span className="text-xs text-gray-400">Older</span>
      </div>
    </>
  )
}

export default Timeline
