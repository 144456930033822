import React, { useMemo } from 'react'
import { useQuery } from 'hooks'
import { Modal } from 'components/shared'
import DestroyUser from 'components/admin/DestroyUser'

const DestroyUserModal = ({ ...props }) => {
  const { watchQuery, deleteQuery } = useQuery()

  const id = watchQuery('destroyUserId')
  const onClose = () => deleteQuery('destroyUserId')

  return(
    <Modal
     size={'prose'}
     open={!!id}
     onClose={onClose}>
       <DestroyUser id={id} onClose={onClose} />
    </Modal>
  )
}

export default DestroyUserModal