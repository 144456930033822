import React, { Fragment, useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import { Hint } from "components/typography";
import { Checkbox, Label } from "components/shared";
import Question from "components/questions/Question";
import AddQuestion from "components/design/AddQuestion";
import Discussions from "components/coaching/Discussions";
import { useFlash } from "hooks";

const QuestionActivity = ({ user, extraResponseParams, stage, ...props }) => {
  const { currentUser, editorMode, setEditorMode, API } =
    useContext(AppContext);
  const { errorFlash } = useFlash();

  const selectedQuestion = stage.questions[0];

  const [discussionsLocked, setDiscussionsLocked] = useState(
    !selectedQuestion?.userHasAnswered,
  );

  const [showDiscussions, setShowDiscussions] = useState(stage.showDiscussions);

  const handleAdvance = (response) => {
    setDiscussionsLocked(false);
  };

  const updateStage = async (properties) => {
    if (props.onStartUpdate) props.onStartUpdate();
    const body = {
      activity_stage: {
        show_discussions: properties.showDiscussions,
      },
    };

    const response = await API.put(`/activity_stages/${stage.slug}`, body, {
      onError: (message, header) => {
        if (props.onErrorUpdate) props.onErrorUpdate({ message });
        errorFlash(message, header);
      },
    });

    if (response) {
      if (props.onChange) props.onChange();
      if (props.onFinishUpdate) props.onFinishUpdate();
    }
  };

  const handleChangeShowDiscussions = async () => {
    const newShowDiscussions = !showDiscussions;

    setShowDiscussions(newShowDiscussions);
    updateStage({ showDiscussions: newShowDiscussions });
  };

  if (!selectedQuestion)
    return (
      <div className="h-full z-0 overflow-hidden">
        {editorMode && (currentUser.isAdmin || currentUser.isCourseDesigner) ? (
          <AddQuestion stage={stage} onChange={props.onChange} />
        ) : (
          <Hint center className="py-6 w-full flex items-center justify-center">
            This activity has no questions.{" "}
            {currentUser.isAdmin || currentUser.isCourseDesigner ? (
              <span>
                <span
                  onClick={() => setEditorMode(true)}
                  className="text-blue-400 cursor-pointer mx-1"
                >
                  Turn on editor mode
                </span>
                to add a question.
              </span>
            ) : (
              <span>Please contact support.</span>
            )}
          </Hint>
        )}
      </div>
    );

  return (
    <div className="h-full z-0 flex flex-1 overflow-hidden">
      <main className="@container relative z-0 flex-1 overflow-y-auto focus:outline-none overflow-x-hidden">
        <div className="mx-auto max-w-prose py-4">
          <Question
            includeAiCoach
            user={user}
            onAdvance={handleAdvance}
            constraints={selectedQuestion?.constraints}
            extraResponseParams={extraResponseParams}
            onStartUpdate={props.onStartUpdate}
            onUpdate={props.onFinishUpdate}
            onErrorUpdate={() =>
              props.onErrorUpdate({ message: "Unspecified error" })
            }
            stage={stage}
            {...selectedQuestion}
          />
        </div>
      </main>
      {editorMode ? (
        <aside className="@container relative w-1/3 flex-shrink-0 overflow-y-auto border-l border-gray-200 flex flex-col">
          <div className="py-2 px-4 flex items-center justify-center border-b border-gray-200">
            <Label className="flex items-center h-8 justify-center space-x-2 text-gray-500">
              <Checkbox
                readOnly
                checked={showDiscussions}
                onClick={handleChangeShowDiscussions}
              />
              <span>Show discussions?</span>
            </Label>
          </div>
          {showDiscussions && (
            <Discussions
              locked={discussionsLocked}
              omitCurrentUser
              omitActivity
              omitLastName
              question={{ id: selectedQuestion?.id }}
            />
          )}
        </aside>
      ) : (
        showDiscussions && (
          <aside className="@container relative w-1/3 flex-shrink-0 overflow-y-auto overflow-x-hidden border-l border-gray-200 flex flex-col">
            <Discussions
              locked={discussionsLocked}
              omitCurrentUser
              omitActivity
              omitLastName
              question={{ id: selectedQuestion?.id }}
            />
          </aside>
        )
      )}
    </div>
  );
};

export default QuestionActivity;
