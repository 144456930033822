import React, { useState, useRef, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { Feed } from "components/shared";
import NotificationInFeed from "components/notifications/NotificationInFeed";

const NotificationsMenu = ({
  notifications,
  setNotificationRead,
  ...props
}) => {
  const [menuStyle, setMenuStyle] = useState({});

  const menu = useRef();

  useLayoutEffect(() => {
    if (menu.current) {
      const height = Array.from(
        menu.current.children,
        (child) => child.offsetHeight,
      ).reduce((acc, height) => (acc += height), 0);

      setMenuStyle({ height });
    }
  }, [notifications]);

  const filteredNotifications = notifications.slice(0, 3);

  return (
    <div
      ref={menu}
      className="w-full max-w-sm rounded-md focus:outline-none max-h-80 overflow-y-auto"
      style={menuStyle}
    >
      <Feed dark>
        {filteredNotifications.map((notification, index) => (
          <NotificationInFeed
            preview
            key={notification.id}
            notification={notification}
            onMouseOver={() => {
              if (notification.status === "unread")
                setNotificationRead(notification.id);
            }}
          />
        ))}
      </Feed>
      <div className="h-10 text-center border-t border-slate-800 -mb-4 cursor-pointer hover:bg-slate-800 relative">
        <Link
          className="absolute block inset-0 flex items-center justify-center text-slate-300 text-xs"
          to={`/notifications`}
        >
          See all notifications
        </Link>
      </div>
    </div>
  );
};

export default NotificationsMenu;
