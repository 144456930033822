import React, { useState, useEffect, useContext } from "react";
import Category from "components/questions/Category";

import { get } from "api";
import AppContext from "contexts/AppContext";

const Categories = ({
  question,
  categories,
  checkedCategories,
  allowChange,
  onCheck,
  label,
  showPercentage,
  showTitle,
  advanceButton,
}) => {
  const { API } = useContext(AppContext);
  const [allocations, setAllocations] = useState([]);

  const fetchAllocations = async () => {
    const data = await API.get(`/allocations`, {
      params: { question_id: question.id },
    });
    setAllocations(data);
  };

  useEffect(() => {
    if (showPercentage) fetchAllocations();
  }, [showPercentage]);

  return (
    <div className="px-6 py-4 @lg:pl-12">
      {label && (
        <label className="block text-sm text-gray-400 mb-4">{label}</label>
      )}

      <div className="flex flex-wrap align-start">
        {categories.map((category, index) => (
          <Category
            key={category.id}
            {...category}
            showPercentage={showPercentage}
            allocations={allocations}
            checked={checkedCategories.map((c) => c.id).includes(category.id)}
            allowChange={allowChange}
            onCheck={onCheck}
            index={index}
          />
        ))}
      </div>

      {advanceButton}
    </div>
  );
};

Categories.defaultProps = {
  allowChange: false,
  showPercentage: false,
  checkedCategories: [],
  showTitle: true,
};

export default Categories;
