import React, { useState } from 'react'
import { SparklesIcon } from '@heroicons/react/24/solid'
import { Spinner } from 'components/shared'
import { useAiCoach } from 'hooks'

const AiCoachTrigger = ({ question, answer, user, onResponse, className }) => {
  const { generate, loading } = useAiCoach()

  const handleClick = async (e) => {
    e.preventDefault()
    const response = await generate(question, answer, user)
    onResponse(response)
  }

  return(
    <div className={className}>
      {
        loading
        ? <Spinner className="text-yellow-500" />
        : <SparklesIcon
           className="h-5 w-5 text-yellow-300 hover:text-yellow-400 cursor-pointer"
           onClick={handleClick} />
      }
    </div>
  )
}

export default AiCoachTrigger
