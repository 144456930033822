import React, { useEffect, useState } from 'react'

const useModal = ({ showIf, afterHide }) => {
  const [show, setShow] = useState(!!showIf)

  const hide = () => {
    setShow(false)
    if (afterHide) setTimeout(afterHide, 300)
  }

  useEffect(() => {
    setShow(!!showIf)
  }, [showIf])

  return { show, hide }
}

export default useModal
