import React, { useState } from 'react'
import { Button, Hint, ProgressLine } from 'components/shared'
import { Link, useRouteMatch } from 'react-router-dom'

const CompareSolutions = (props) => {
  const { url } = useRouteMatch()

  return(
    <ProgressLine.Widget isLast>
      <ProgressLine.Widget.Indicator
       filled={false}
       active={props.attempt?.completed} />

      <ProgressLine.Widget.Details>
        <ProgressLine.Widget.Details.Header>
          <div className="WidgetTitle">
            Compare solutions
          </div>
        </ProgressLine.Widget.Details.Header>

        <ProgressLine.Widget.Details.Action>
          {
            props.attempt?.completed
            && <Link
                to={`${ url }/solutions`}
                className="btn btn-primary">
                Compare
              </Link>
          }
        </ProgressLine.Widget.Details.Action>
      </ProgressLine.Widget.Details>
    </ProgressLine.Widget>
  )
}

export default CompareSolutions