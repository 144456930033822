import React from "react";
import { Label, Tooltip, Hint } from "components/shared";
import { cn } from "utils";

const NumberInput = ({ ...props }) => {
  return (
    <div className="flex items-center justify-between">
      {props.label && (
        <Label
          className={cn("ml-1", props.labelClassName)}
          htmlFor={props.name}
        >
          {props.label}
          {props.required && (
            <Tooltip text="This field is required." className="inline">
              <span className="superscript text-red-500">*</span>
            </Tooltip>
          )}
        </Label>
      )}
      {props.hint && <Hint small>{props.hint}</Hint>}
      <input
        type="number"
        className={cn(
          "w-16 text-sm border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50",
          props.className,
        )}
        defaultValue={props.defaultValue}
        min={props.min}
        max={props.max}
        value={props.value}
        name={props.name}
        onChange={props.onChange}
      />
    </div>
  );
};

export default NumberInput;
