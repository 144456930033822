import React, { useRef, useContext, useState, useEffect } from 'react'
import AppContext from 'contexts/AppContext'
import { Hint, SelectInput, Loading } from 'components/shared'
import { get, put } from 'api'

const ChangePullRequest = (props) => {
  const { apiUrl, formToken } = useContext(AppContext)
  const [possiblePullRequests, setPossiblePullRequests] = useState([])
  const [loadingPrs, setLoadingPrs] = useState(false)

  const fetchPrs = () => {
    get(
      `${ apiUrl }/issues/${props.issue.slug}/issue_attempts/${props.attempt.id}/pull_requests?repo_slug=${ props.learnerRepo.slug }&tracking_branch=${ props.attempt.trackingBranch }`,
      (pullRequests) => {
        setPossiblePullRequests(pullRequests)
        props.setShow(true)
        setLoadingPrs(false)
      }
    )
  }

  useEffect(() => {
    setLoadingPrs(true)
    fetchPrs()
  }, [props.show, props.attempt])

  const selectRef = useRef(null)
  const updatePullRequest = (e) => {
    e.preventDefault()

    const pullRequest = possiblePullRequests
                        .find(({ id }) => String(id) === String(selectRef.current.value))

    const body = {
      pull_request_id: pullRequest.id
    }

    put(`${apiUrl}/issues/${props.issue.slug}/issue_attempts/${props.attempt.id}/update_pull_request`,
        { formToken, body },
        afterUpdatePr)
  }

  const afterUpdatePr = (data) => {
    props.setShow(false)
    props.afterChange(data)
  }

  if(loadingPrs) return <Loading
                         inline
                         size="sm"
                         message="Fetching PRs..." />

  return(
    <Hint>
      {
        possiblePullRequests?.length > 0
        ? <>
            Open PRs for { props.attempt?.trackingBranch || 'this branch' }
            <SelectInput
             ref={selectRef}
             size="sm"
             button
             onSubmit={updatePullRequest}
             buttonText='Update'>
               {
                 possiblePullRequests
                 .map((pr, index) => <option
                                      key={index}
                                      value={pr.id}>
                                      {pr.title}
                                     </option>)
               }
            </SelectInput>
          </>
        : `There are no open PRs for ${ props.attempt?.trackingBranch
                                   ? `the branch ${ props.attempt.trackingBranch }`
                                   : 'this branch' }`
      }
    </Hint>
  )
}

export default ChangePullRequest