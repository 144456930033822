import React from 'react'
import { Page } from 'components/shared'
import Manage from 'components/manage/Manage'
import { pure } from 'recompose'

const ManagePage = () => {
  return(
    <Page className='bg-[#FAFAFA]'>
      <Manage />
    </Page>
  )
}

export default pure(ManagePage)