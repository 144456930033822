import React, { useContext, useState, useEffect, useMemo } from "react";
import AppContext from "contexts/AppContext";
import { TextInput, Button, Form } from "components/shared";
import { useHistory } from 'react-router-dom';
import { useFlash } from "hooks";
import { RadioGroup } from "@headlessui/react";
import { H5, Hint } from "components/typography";
import moment from 'moment';
import { cn } from "utils";

const SelectCourse = ({ ...props }) => {
  const { API, currentUser, setCurrentUser } = useContext(AppContext);
  const { successFlash, errorFlash } = useFlash();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(
    currentUser.courseId,
  );
  const selectedCourse = useMemo(
    () => courses.find((c) => c.id === selectedCourseId),
    [courses, selectedCourseId],
  );

  const fetchCourses = async () => {
    setIsLoading(true);
    const response = await API.get("/courses");
    if (response) {
      setCourses(response.sort((a, b) => a.title.localeCompare(b.title)));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const handleChange = async (course) => {
    setSelectedCourseId(course.id)

    const properties = {
      course_id: course.id
    }

    const response = await API.put(
      `/users/${currentUser.id}`,
      { user: properties },
      {
        onError: errorFlash,
      },
    );

    if (response) {
      successFlash(`Successfully switched course.`);
      if (props.onChange) props.onChange();
      history.go(0)
    }
  }

  return (
    <div className="pt-4 space-y-4">
      <RadioGroup
        value={selectedCourse}
        onChange={handleChange}
        className='space-y-4'
      >
        <RadioGroup.Label>Available courses</RadioGroup.Label>
        {courses.map((course) => (
          <RadioGroup.Option value={course} key={course.id}>
            {({ checked }) => (
              <div
                className={cn(
                  "px-4 py-2 flex items-baseline justify-between cursor-pointer border border-gray-200 rounded-md shadow-sm hover:shadow-md",
                  checked && "bg-blue-200",
                )}
              >
                <H5 className='text-sm'>{course.title ?? "Untitled course"}</H5>
                <Hint sm>{moment(course.createdAt).fromNow()}</Hint>
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </div>
  );
};

export default SelectCourse;
