import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { DotBadge } from "components/shared";
import { useLocation } from "react-router-dom";
import { cn } from "utils";

const RemoteDotBadge = ({ path, ...props }) => {
  const { API } = useContext(AppContext);
  const location = useLocation();

  const [value, setValue] = useState(0);

  const fetchPath = async () => {
    const response = await API.get(path);

    if (response) {
      setValue(response.length);
    }
  };

  useEffect(() => {
    fetchPath();
  }, [location]);

  if (value === 0 || !value) return null;

  return (
    <DotBadge
      className={cn(props.className)}
      value={value}
    />
  );
};

export default RemoteDotBadge;
