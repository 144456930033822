import React, { useState } from "react";
import Joyride, { STATUS } from "react-joyride";
import { TourPanel } from "components/shared";
import { useFetch } from "hooks";

const Tour = ({ slug, run, ...props }) => {
  const [tour, setTour] = useState(null);
  useFetch(`tours/${slug}`, setTour, [slug]);

  if (!tour?.steps) return null;

  const joyrideCallback = (data) => {
    const { action, index, status, type } = data;
    // Left in for debugging
    // console.log(slug, action, status, type)
  };

  return (
    <span id={`joyride-${slug}`}>
      <Joyride
        steps={tour.steps}
        run={run}
        tooltipComponent={(props) => (
          <TourPanel {...props} slug={slug} steps={tour.steps} />
        )}
        continuous={tour.steps.length > 1}
        callback={joyrideCallback}
      />
    </span>
  );
};

export default Tour;
