import React, { useState } from 'react'
import Categoriser from 'components/questions/questionTypes/Categoriser'
import { classNames } from 'utils'

const CategoriserQuestion = (props) => {
  return(
    <div className={classNames(props.className)}>
      <Categoriser id={props.categoriserId} onComplete={props.onComplete} />
    </div>
  )
}

export default CategoriserQuestion
