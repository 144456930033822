import React from 'react'
import { RangeSlider } from 'components/shared'

const ConfidenceSlider = ({ initialValue, onChangeComplete, className, disabled }) => {
  const labels = {
    1: 'Nope',
    3: 'Kinda',
    5: 'Yes'
  }

  return(
    <div className={`ConfidenceSlider ${className}`}>
      <RangeSlider
       min={1}
       max={5}
       initialValue={initialValue}
       disabled={disabled}
       onChangeComplete={onChangeComplete}
       labels={labels} />
    </div>
  )
}

ConfidenceSlider.defaultProps = {
  className: '',
  initialValue: 0,
  disabled: false
}

export default ConfidenceSlider
