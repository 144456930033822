import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory, useRouteMatch } from 'react-router-dom'
import { Loading, Avatar, RatingAlert, Form, TextArea, Modal, Button } from 'components/shared'

import { put } from 'api'

import AppContext from 'contexts/AppContext'

const IssueSummary = (props) => {
  const { apiUrl, formToken } = useContext(AppContext)
  const [show, setShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = (formData) => {
    const body = {
                  issue_attempt: formData,
                  just_completed: !props.attempt.completed 
                 }

    put(`${ apiUrl }/issue_attempts/${ props.attempt.id }`, { formToken, body }, (completedIssue) => {
      hide()
      props.onComplete(completedIssue)
    })
  }

  const hide = () => {
    setShow(false)

    setTimeout(() => {
      props.onHide()
    }, 300)
  }

  useEffect(() => {
    setShow(true)
  }, [])

    return (
      <Modal
       size={"md"}
       show={show}
       onHide={hide}
       className="IssueSummary">
        <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            { props.attempt?.complete
                      ? `Review "${props.issue?.title}"`
                      : `Complete "${props.issue?.title}"` }
            { props.attempt?.complete
              && <Button
                  disabled={submitting}
                  onClick={handleSubmit}>
                  { submitting
                    ? 'Submitting'
                    : 'Complete Activity' }
                 </Button> }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RatingAlert issueAttempt={props.attempt} />
          <div className="f-row f-row-v-baseline mt2">
            <label className="mb0 mr1">Feedback</label>
            <p className="hint text-small mb0">Optional</p>
          </div>
          <TextArea
           name="feedback"
           placeholder="e.g. I thought it was great when...but I would have preferred..."
           defaultValue={props.attempt?.feedback} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Back
          </Button>
          <input
           className="btn btn-primary"
           type="submit"
           disabled={submitting}
           value={ submitting
                   ? 'Submitting'
                   : 'Complete Issue' } />
        </Modal.Footer>
        </Form>
      </Modal>
    );
}

export default IssueSummary