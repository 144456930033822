import React, { useContext } from "react";
import AppContext from "contexts/AppContext";
import { Hint } from "components/shared";
import { PlusIcon } from "@heroicons/react/24/outline";

const NoActivityStages = ({ ...props }) => {
  const { currentUser, editorMode, setEditorMode } = useContext(AppContext);
  return (
    <div className="flex items-center justify-center h-full">
      <Hint center>
        This Activity has no stages yet.
        {(currentUser.isAdmin || currentUser.isDesigner) &&
          (editorMode ? (
            <span>
              {" "}
              Click the
              <PlusIcon
                className="inline h-4 w-4 text-gray-500 group-hover:text-gray-700 relative -top-px ml-0.5 mr-0.5"
                aria-hidden="true"
              />
              above to add stages.
            </span>
          ) : (
            <span>
              {" "}
              <span
                onClick={() => setEditorMode(true)}
                className="text-blue-400 cursor-pointer hover:text-blue-500"
              >
                Turn on editor mode
              </span>{" "}
              to add stages.
            </span>
          ))}
      </Hint>
    </div>
  );
};

export default NoActivityStages;
