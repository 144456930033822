import React, { Fragment, useState, useEffect, useRef } from 'react'
import Question from 'components/questions/Question'
import Discussions from 'components/coaching/Discussions'

const IntroActivity = ({ user, stage, ...props }) => {
  const selectedQuestion = stage.questions[0]

  const [discussionsLocked, setDiscussionsLocked] = useState(!selectedQuestion.userHasAnswered)

  const handleAdvance = (response) => setDiscussionsLocked(false)

  return(
    <>
      <div className="h-full z-0 flex flex-1 overflow-hidden">
        <main className="@container relative z-0 flex-1 overflow-y-auto focus:outline-none">
          <div>
            <Question
             includeAiCoach
             user={user}
             onAdvance={handleAdvance}
             extraResponseParams={
              {
                is_user_goal: true,
                ...stage.extraResponseParams
              }
            }
             { ...selectedQuestion } />
          </div>
        </main>
        <aside className="@container relative w-1/3 flex-shrink-0 overflow-y-auto border-l border-gray-200 flex flex-col">
          <Discussions
           locked={discussionsLocked}
           omitCurrentUser
           omitActivity
           omitLastName
           question={ { id: selectedQuestion.id } } />
        </aside>
      </div>
    </>
  )
}

export default IntroActivity
