import React, { useState, useContext } from "react";
import AppContext from "contexts/AppContext";
import LearnerRepoStatus from "components/github/actions/LearnerRepoStatus";
import LearnerIssue from "components/github/LearnerIssue";
import IssueStatus from "components/github/IssueStatus";
import {
  Switch,
  Route,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { useFetch } from "hooks";
import { titleize, humanize } from "utils";
import IssueSummary from "components/github/IssueSummary";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import IssueSolutions from "components/github/CompareSolutions";
import { Hint, Tabs } from "components/shared";
import { H2, H3 } from "components/typography";

const Issue = (props) => {
  const { currentUser } = useContext(AppContext);
  const { issueSlug } = useParams();
  let history = useHistory();
  const { path, url } = useRouteMatch();

  const [issue, setIssue] = useState({ slug: issueSlug });
  const [attempt, setAttempt] = useState(null);
  const [isReflecting, setIsReflecting] = useState(false);

  const { loadingOrError: loadingIssue } = useFetch(
    `issues/${issueSlug}`,
    setIssue,
    [issueSlug],
  );

  const { fetch: fetchAttempt, loadingOrError: loadingAttempt } = useFetch(
    `issues/${issueSlug}/issue_attempts?user_id=${currentUser.id}`,
    (attempts) => setAttempt(attempts[0]),
    [issueSlug],
  );

  const startReflection = () => {
    setIsReflecting(true);
    history.push(`${url}/summary`);
  };

  const stopReflection = () => {
    setIsReflecting(false);
    history.push(url);
  };

  const completeIssue = (completedIssue) => {
    fetchAttempt();
  };

  if (loadingIssue()) return loadingIssue();
  if (loadingAttempt() && !attempt) return loadingAttempt();

  const descriptionWithoutTitle = issue?.description?.replace(/^\#\s+(.+)/, "");

  return (
    <div className="h-screen -mt-16 pt-16 flex overflow-hidden">
      <div className="px-10 py-8 flex-1 overflow-auto">
        <div className="flex items-center mb-2">
          <H3 className="mr-2 text-gray-300 font-normal mt-px">
            #{issue.number}
          </H3>
          <H2>{issue.title}</H2>
        </div>

        {currentUser.githubUsername && currentUser.githubAccessToken && (
          <div className="flex">
            <LearnerRepoStatus
              {...props.project}
              slug={`${currentUser.githubUsername}/${props.project.defaultRepoSlug}`}
              onSuccess={props.onChangeRepo}
            />
          </div>
        )}

        <ReactMarkdown
          className="prose GithubMarkdown"
          remarkPlugins={[remarkGfm]}
        >
          {descriptionWithoutTitle}
        </ReactMarkdown>
      </div>

      <aside className="@container relative flex-shrink-0 w-[32rem] border-l border-gray-200 overflow-y-auto flex flex-col">
        <Tabs.Header>Progress</Tabs.Header>
        <div className="px-6 py-8">
          <IssueStatus
            issue={issue}
            onStartReflection={startReflection}
            isReflecting={isReflecting}
            attempt={attempt}
            learnerRepo={props.learnerRepo}
            onChangeStatus={fetchAttempt}
          />
        </div>
      </aside>

      <Switch>
        <Route path={`${path}/summary`}>
          <IssueSummary
            issue={issue}
            attempt={attempt}
            onComplete={completeIssue}
            onHide={stopReflection}
          />
        </Route>

        <Route path={`${path}/solutions`}>
          <IssueSolutions
            issue={issue}
            attempt={attempt}
            learnerRepo={props.learnerRepo}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default Issue;
