import React, { useContext } from 'react'
import { TwoColumn } from 'components/shared'
import AppContext from 'contexts/AppContext'
import { useDiscussions } from 'hooks'
import DiscussionsFeed from 'components/coaching/DiscussionsFeed'
import Question from 'components/questions/Question'

const TwoUpDiscussions = (props) => {
  const { currentUser } = useContext(AppContext)

  const {
    discussions,
    selectedDiscussion,
    selectedDiscussionId,
    setSelectedDiscussionId,
    loadingDiscussions,
    loadingSelectedDiscussion,
    highlightedResponse,
    websocketConnected
  } = useDiscussions({
    order: 'latest',
    user_id: props.user.id
  })

  if(loadingDiscussions) return <TwoColumn>
                                  <div>
                                    {loadingDiscussions}
                                  </div>
                                  <div>
                                    {loadingDiscussions}
                                  </div>
                                </TwoColumn>

  return(
    <TwoColumn>
      <DiscussionsFeed
       { ...{ discussions, selectedDiscussionId, setSelectedDiscussionId, websocketConnected } } />

      {
        selectedDiscussion?.question &&
        <Question
         includeAiCoach={props.includeAiCoach}
         includeOtherAnswers={props.includeOtherAnswers}
         user={props.user}
         highlightedResponse={highlightedResponse}
         { ...selectedDiscussion.question } />
      }
    </TwoColumn>
  )
}

export default TwoUpDiscussions
