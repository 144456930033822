import React, { useState } from "react";
import { usePagination } from "hooks";
import { classNames } from "utils";

const Table = ({ headers, children, ...props }) => {
  const { page, setPageIndex, Pagination } = usePagination(children, {
    perPage: 5,
  });

  return (
    <table
      className={classNames(
        "w-full divide-y divide-gray-300 table-fixed",
        props.className,
      )}
    >
      <thead className="bg-gray-50">
        <tr>
          {headers.map((header) => (
            <th
              key={header.key}
              scope="col"
              className={classNames(
                "px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
                header.className,
              )}
            >
              {header.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">{page}</tbody>
      <tfoot>
        <tr>
          <td colSpan={headers.length} className="p-0">
            <Pagination className="w-full border-none" />
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

Table.Row = ({ columns, ...props }) => {
  return (
    <tr className={classNames(props.className)}>
      {columns.map((column) => (
        <td
          key={column.key}
          className={classNames(
            "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
            column.className,
          )}
        >
          {column.children}
        </td>
      ))}
    </tr>
  );
};

export default Table;
