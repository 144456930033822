import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { FlushList, Hint, Indicator } from 'components/shared'
import moment from 'moment'

const IssuePreview = (props) => {
  const { url } = useRouteMatch()

  return(
    <FlushList.Item hoverable>
      <Link to={`${ url }/issues/${props.slug}`}>
        <IssuePreview.Header { ...props } />
        <IssuePreview.Body { ...props } />
      </Link>
    </FlushList.Item>
  )
}

IssuePreview.Header = (props) => {
  const statusInWords = () => {
    if(!props.userAttempt) return null;
    
    if(props.userAttempt.completed) return `Completed ${ moment(props.userAttempt.completedAt).fromNow() }`
    if(props.userAttempt.started) return `Started ${ moment(props.userAttempt.startedAt).fromNow() }`
  }

  return(
    <div className="flex w-full items-center">
      <Indicator
       Positive={props.userAttempt?.completed}
       Intermediary={props.userAttempt && !props.userAttempt.completed}
       className="mr-1" />

      <div>
        <Hint className="mr-1 inline">#{ props.number }</Hint>

        <span className="font-semibold text-gray-700 text-sm">
          { props.title }
        </span>
      </div>

      { 
        props.userAttempt
        && <Hint className="ml-1">
            { statusInWords() }
          </Hint> 
      }
    </div>
  )
}

IssuePreview.Body = (props) => {
  if(!props.userAttempt) return(
    <Hint small className="pl-5">Clone the issue to start this challenge.</Hint>
  )

  if(props.userAttempt.tracked) return(
    <Hint small className="pl-5">{ props.userAttempt.trackingBranch }</Hint>
  )

  return null
}

export default IssuePreview