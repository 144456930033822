import React from 'react'
import { ProgressLine } from 'components/shared'
import StartIssue from 'components/github/issues/StartIssue'
import TrackIssue from 'components/github/issues/TrackIssue'
import SubmitPullRequest from 'components/github/issues/SubmitPullRequest'
import ApprovePullRequest from 'components/github/issues/ApprovePullRequest'
import MergePullRequest from 'components/github/issues/MergePullRequest'
import CompleteChallenge from 'components/github/issues/CompleteChallenge'
import CompareSolutions from 'components/github/issues/CompareSolutions'

import { pure } from 'recompose'

const IssueStatus = (props) => {
  return(
    <ProgressLine>
      <StartIssue { ...props } />
      <TrackIssue { ...props } />
      <SubmitPullRequest { ...props } />
      <ApprovePullRequest { ...props } />
      <MergePullRequest { ...props } />
      <CompleteChallenge { ...props } />
      <CompareSolutions { ...props } />
    </ProgressLine>
  )
}

export default pure(IssueStatus)