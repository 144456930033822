import React, { useState, useEffect, useContext } from "react";
import AppContext from "contexts/AppContext";
import { Link } from "react-router-dom";
import { Loading, Table, Button, RemoteSelectInput, NumberInput } from "components/shared";
import { useQuery, useFlash } from "hooks";
import { pluralize } from "utils";

const ManageLicenses = ({ ...props }) => {
  const { API } = useContext(AppContext);
  const { pathWithQuery } = useQuery();
  const { errorFlash, successFlash } = useFlash();

  const [licenses, setLicenses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchLicenses = async () => {
    setIsLoading(true);
    const response = await API.get(`/licenses`, {
      onError: errorFlash,
    });

    if (response) {
      setLicenses(response);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLicenses();
  }, []);

  const [newLicenseCount, setNewLicenseCount] = useState(1);
  const [newLicenseOrganizationId, setNewLicenseOrganizationId] =
    useState(null);
  const [newLicenseCohortId, setNewLicenseCohortId] = useState(null);

  const generateLicenses = async (e) => {
    e.preventDefault();

    const body = {
      licenses: {
        organization_id: newLicenseOrganizationId,
        cohort_id: newLicenseCohortId,
      },
      count: newLicenseCount,
    };

    const response = await API.post(`/licenses/bulk`, body, {
      onError: errorFlash,
    });

    if (response) {
      const codes = response.map((license) => license.code).join(", ");
      navigator.clipboard.writeText(codes)
      successFlash(`${ newLicenseCount } licenses have been copied to your clipboard.`)
      fetchLicenses();
    }
  };

  const headers = [
    {
      key: "code",
      title: "Code",
      className: "w-3/12 pl-4 text-xs text-center",
    },
    {
      key: "user",
      title: "User",
      className: "w-3/12 text-xs text-center",
    },
    {
      key: "organization",
      title: "Organization",
      className: "w-2/12 text-xs text-center",
    },
    {
      key: "cohort",
      title: "Cohort",
      className: "w-2/12 text-xs text-center",
    },
    {
      key: "course",
      title: "Course",
      className: "w-2/12 text-xs text-center",
    },
  ];

  const columns = (license) => {
    const basicColumns = [
      {
        key: "code",
        children: license.code,
        className: "font-medium text-gray-900 px-4 text-xs",
      },
      {
        key: "user",
        children: license.user ? (
          <Link
            className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
            to={pathWithQuery(null, { user: license.user.id })}
          >
            {license.user.name && license.user.name.length > 0
              ? license.user.name
              : license.user.email}
          </Link>
        ) : (
          "Unclaimed"
        ),
        className: "text-xs truncate",
      },
      {
        key: "organization",
        children: license.organization?.name || "No organization",
        className: "text-xs truncate",
      },
      {
        key: "cohort",
        children: license.cohort?.title || "No cohort",
        className: "text-xs truncate",
      },
      {
        key: "course",
        children: license.course?.title || "No course",
        className: "pr-4 text-xs truncate",
      },
    ];

    return basicColumns;
  };

  return (
    <div className="mt-4 min-h-[320px]">
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <Table
            className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
            headers={headers}
          >
            {licenses.map((license) => (
              <Table.Row
                className="h-[69px]"
                key={license.id}
                columns={columns(license)}
              />
            ))}
          </Table>
          <div className="mt-4 flex items-center justify-center space-x-3 text-xs">
            <div className="text-gray-600">Add</div>
            <div>
              <NumberInput
               value={newLicenseCount}
               onChange={(e) => setNewLicenseCount(e.target.value)} />
            </div>
            <div className="text-gray-600">
              {pluralize(newLicenseCount, "license", false)} for
            </div>
            <div>
              <RemoteSelectInput
                selectClassName="w-32"
                title="Organization"
                defaultOption="All organizations"
                fetchPath="organizations"
                optionValue={"id"}
                optionLabel={"name"}
                onChange={(e) => setNewLicenseOrganizationId(e.target.value)}
              />
            </div>
            <div className="text-gray-600">in</div>
            <div>
              <RemoteSelectInput
                selectClassName="w-32"
                title="Cohort"
                defaultOption="All cohorts"
                fetchPath="cohorts"
                optionValue={"id"}
                optionLabel={"title"}
                onChange={(e) => setNewLicenseCohortId(e.target.value)}
              />
            </div>
            <Button size="xs" onClick={generateLicenses}>
              Generate
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageLicenses;
