import React, { useContext } from "react";
import AppContext from "contexts/AppContext";
import ManualCertificationToggle from "components/certification/ManualCertificationToggle";
import CertificationControls from "components/certification/CertificationControls";
import UserTranscriptDiscussions from "components/transcripts/UserTranscriptDiscussions";
import AiCertificationHelper from "components/transcripts/AiCertificationHelper";
import { pure } from "recompose";

const UserTranscript = ({ user, ...props }) => {
  const { currentUser } = useContext(AppContext);
  return (
    <div className="flex w-full">
      <div className="flex-grow overflow-y-auto w-full relative">
        {(currentUser.isTrainer || currentUser.isAdmin) &&
          user.certificationRequested && (
            <div className="px-8 pb-0 pt-8 -mb-4">
              <CertificationControls
                user={user}
                onChangeCertification={props.onChangeCertification}
              />
            </div>
          )}
        {(currentUser.isTrainer || currentUser.isAdmin) && (
          <div className="px-8 py-4 pt-8">
            <AiCertificationHelper
              initialText={user.certificationSummary}
              user={user}
            />
          </div>
        )}

        <UserTranscriptDiscussions
          user={user}
          showNewDiscussionsLink={props.showNewDiscussionsLink}
          showSidebar={props.showSidebar}
        />
      </div>
    </div>
  );
};

export default pure(UserTranscript);
