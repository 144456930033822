import React, { useContext, useState, useEffect, useMemo } from "react";
import AppContext from "contexts/AppContext";
import { Hint } from "components/typography";
import { Button, Loading, Form, TextInput } from "components/shared";
import { TrashIcon } from "@heroicons/react/24/outline";
import InteractiveVideoPlayer from "components/activities/InteractiveVideo/InteractiveVideoPlayer";
import Transcript from "components/activities/InteractiveVideo/Transcript";
import VideoUploader from "components/design/VideoUploader";
import TranscriptHeader from "components/activities/InteractiveVideo/TranscriptHeader";
import Questions from "components/questions/Questions";
import { useConfirm, useFlash, useWatchJob } from "hooks";
import parseSRT from "parse-srt";

const InteractiveVideo = ({ user, extraResponseParams, stage, ...props }) => {
  const { currentUser, editorMode, setEditorMode, API } =
    useContext(AppContext);
  const [ConfirmModal, confirmRemoveVideo] = useConfirm(
    "Remove video",
    `Are you sure you want to remove this video? You will have to upload it again to re-attach it.`,
  );
  const { notificationFlash, errorFlash } = useFlash();
  const {
    watchJob,
    data: transcribeData,
    job: transcribeJob,
  } = useWatchJob(props.onChange);

  const captions = useMemo(() => {
    if (!stage.video?.captions) return null;

    return parseSRT(stage.video.captions).map(({ id, start, end, text }) => ({
      id,
      start,
      end,
      text,
      timestamp: start,
      transcriptBlockType: "caption",
    }));
  }, [stage]);

  const [videoUrl, setVideoUrl] = useState(stage.video?.url);

  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [played, setPlayed] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(null);
  const [cachedQuestions, setCachedQuestions] = useState(stage.questions);
  const [showTranscript, setShowTranscript] = useState(
    captions && captions.length > 0,
  );

  useEffect(() => {
    if (stage.video?.transcriptionJid) watchJob(stage.video.transcriptionJid);
  }, [stage.video?.transcriptionJid]);

  useEffect(() => {
    if (editorMode && transcribeData?.isLoading) {
      setShowTranscript(true);
    }
  }, [editorMode, transcribeData?.isLoading]);

  const handleReady = () => setPlayedSeconds(0);

  const handleCompleteQuestion = (question) => {
    const indexOfQuestion = cachedQuestions
      .map(({ id }) => id)
      .indexOf(question.id);

    const newQuestions = [
      ...cachedQuestions.slice(0, indexOfQuestion),
      { ...question, userHasAnswered: true },
      ...cachedQuestions.slice(indexOfQuestion + 1),
    ];

    setCachedQuestions(newQuestions);
    // if currentQuestion is null, the video player plays and no question is viewed
    setCurrentQuestion(null);
  };

  const handleClickQuestionHeader = (question) => setCurrentQuestion(question);

  const handleUploadVideo = (response) => {
    watchJob(response.video.transcriptionJid);
    setVideoUrl(response.video.url);
  };

  const handleRemoveVideo = async () => {
    const isConfirmed = await confirmRemoveVideo();

    if (isConfirmed) {
      const response = await API.destroy(
        `/activity_stages/${stage.slug}/videos`,
      );

      if (response) {
        notificationFlash("Video removed.");
        props.onChange();
      }
    }
  };

  const handleGenerateTranscript = async () => {
    const response = await API.post(
      `/activity_stages/${stage.slug}/transcribe`,
    );

    if (response) {
      watchJob(response.video.transcriptionJid);
    }
  };

  const handleCancelTranscribeJob = async () => {
    const response = await API.destroy(
      `/activity_stages/${stage.slug}/transcribe`,
    );

    if (response) {
      notificationFlash("Transcription cancelled.");
      props.onChange();
    }
  };

  const handleSetVideoUrl = async ({ videoUrl }) => {
    setIsLoadingMeta(true);

    const body = {
      video: {
        canonical_url: videoUrl,
      },
    };

    const response = await API.post(
      `/activity_stages/${stage.slug}/videos`,
      body,
      {
        onError: errorFlash,
      },
    );

    if (response) {
      notificationFlash("Video updated.");
      handleUploadVideo(response);
      setIsLoadingMeta(false);
    }
  };
  
  return (
    <>
      <div className="h-full z-0 flex flex-1 overflow-hidden">
        <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none">
          {videoUrl ? (
            <>
              <InteractiveVideoPlayer
                onRemoveVideo={props.onChange}
                src={videoUrl}
                questions={cachedQuestions}
                onPauseForQuestion={setCurrentQuestion}
                {...{ played, setPlayed, setPlayedSeconds, handleReady }}
              />
              {editorMode && (
                <Button
                  className="absolute top-4 right-4"
                  variant="danger"
                  size="xs"
                  Icon={TrashIcon}
                  onClick={handleRemoveVideo}
                >
                  Remove video
                </Button>
              )}
            </>
          ) : editorMode ? (
            transcribeData.isLoading ? (
              <Loading message="Processing video..." />
            ) : (
              <div className="h-full flex flex-col space-y-4 items-center justify-center">
                <VideoUploader
                  path={`/activity_stages/${stage.slug}/videos`}
                  onSuccess={handleUploadVideo}
                />
                <Hint className="text-gray-400">or</Hint>
                <Form onSubmit={handleSetVideoUrl}>
                  <TextInput
                    inputClassName="w-72"
                    label="Enter a URL"
                    name="videoUrl"
                    button
                    buttonText="Submit"
                  />
                </Form>
              </div>
            )
          ) : (
            <Hint center className="h-full flex items-center justify-center">
              No video url specified.
              {(currentUser.isAdmin || currentUser.isCourseDesigner) && (
                <span>
                  <span
                    className="ml-1 text-blue-400 cursor-pointer"
                    onClick={() => setEditorMode(true)}
                  >
                    Switch on Editor Mode
                  </span>{" "}
                  to upload a video.
                </span>
              )}
            </Hint>
          )}
        </main>

        <aside className="@container relative hidden w-96 flex-shrink-0 overflow-y-auto overflow-x-hidden border-l border-gray-200 xl:flex xl:flex-col">
          <TranscriptHeader
            onToggleTranscript={() => setShowTranscript(!showTranscript)}
            title={stage.title}
            author={stage.author}
            updatedAt={stage.updatedAt}
            showTranscript={showTranscript}
            isLoading={transcribeData.isLoading}
            beforeChange={props.onStartUpdate}
            onChange={props.onFinishUpdate}
            stage={stage}
            captions={captions}
            videoUrl={videoUrl}
          />

          {showTranscript ? (
            <Transcript
              {...{ captions, currentQuestion, playedSeconds }}
              isLoading={transcribeData.isLoading}
              transcribeJob={transcribeJob}
              user={user}
              onCompleteQuestion={handleCompleteQuestion}
              questions={cachedQuestions}
              extraResponseParams={extraResponseParams}
              onStartUpdate={props.onStartUpdate}
              onUpdate={props.onFinishUpdate}
              onUpdateTimestamp={props.onChange}
              onDelete={props.onChange}
              onErrorUpdate={() =>
                props.onErrorUpdate({ message: "Unspecified error" })
              }
              stage={stage}
              onGenerateTranscript={handleGenerateTranscript}
              onCancelTranscribeJob={handleCancelTranscribeJob}
              onAddQuestion={props.onChange}
            />
          ) : (
            <Questions
              small
              allowDeleteQuestion
              className="pt-0"
              includeAiCoach
              includeOtherAnswers
              user={user}
              questions={cachedQuestions}
              onComplete={handleCompleteQuestion}
              currentQuestion={currentQuestion}
              onClick={handleClickQuestionHeader}
              extraResponseParams={extraResponseParams}
              onStartUpdate={props.onStartUpdate}
              onUpdate={props.onFinishUpdate}
              onUpdateTimestamp={props.onChange}
              onDelete={props.onChange}
              onErrorUpdate={() =>
                props.onErrorUpdate({ message: "Unspecified error" })
              }
              stage={stage}
              onCompleteText="Continue video"
            />
          )}
        </aside>
      </div>
      <ConfirmModal />
    </>
  );
};

export default InteractiveVideo;
