import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import {
  PlayIcon,
  PauseIcon,
  ArrowUturnLeftIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import { duration as toDuration, classNames } from "utils";

const InteractiveVideoPlayer = ({
  src,
  questions,
  played,
  setPlayed,
  handleReady,
  currentQuestion,
  setPlayedSeconds,
  onPauseForQuestion,
  ...props
}) => {
  if (!src) return null;

  const MINIMUM_GAP_BETWEEN_QUESTIONS = 1;

  const player = useRef(null);

  const [started, setStarted] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [seeking, setSeeking] = useState(false);
  const [duration, setDuration] = useState(1);
  const [playing, setPlaying] = useState(false);

  const [timeOfLastQuestion, setTimeOfLastQuestion] = useState(null);

  useEffect(() => {
    setPlaying(started && !currentQuestion);
  }, [currentQuestion]);

  const maybePauseForQuestion = ({ playedSeconds }) => {
    if (playedSeconds < timeOfLastQuestion + MINIMUM_GAP_BETWEEN_QUESTIONS)
      return;

    const question = questions.find(
      ({ timestamp }) => timestamp === Math.ceil(playedSeconds),
    );
    if (question && question !== undefined) {
      setPlaying(false);
      setTimeOfLastQuestion(playedSeconds);
      onPauseForQuestion(question);
    }
  };

  const handleStart = () => {
    setStarted(true);
    setPlaying(true);
  };
  const handlePlayPause = () => setPlaying(!playing);
  const handleSetPlaybackRate = (e) =>
    setPlaybackRate(parseFloat(e.target.value));
  const handleSeekMouseDown = (e) => setSeeking(true);
  const handleSeekChange = (e) => setPlayed(parseFloat(e.target.value));
  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    setTimeOfLastQuestion(null);
    player.current.seekTo(parseFloat(e.target.value));
  };

  const handleProgress = (state) => {
    setPlayedSeconds(state.playedSeconds);

    if (!seeking) {
      setPlayed(state.played);
      maybePauseForQuestion(state);
    }
  };
  const handleReplay10 = () => {
    // allow the questions to freeze the video again
    setTimeOfLastQuestion(null);

    const newPlayed = played - 10 / duration;

    setPlayed(Math.max(0, newPlayed));
    player.current.seekTo(newPlayed);
    setPlaying(true);
  };

  return (
    <div id={props.id} className="h-full flex flex-col justify-center">
      {src && (
        <div style={{ aspectRatio: "16 / 9" }}>
          <ReactPlayer
            ref={player}
            width="100%"
            height="100%"
            className="-ml-px"
            url={src}
            onReady={handleReady}
            onStart={handleStart}
            onProgress={handleProgress}
            progressInterval={200}
            onDuration={setDuration}
            {...{ playing, playbackRate }}
          />
        </div>
      )}

      <div className="flex px-4 space-x-4 justify-between items-center">
        <div className="grow-0 flex items-center h-12 space-x-4">
          <button onClick={handlePlayPause}>
            {playing ? (
              <PauseIcon className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer" />
            ) : (
              <PlayIcon className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer" />
            )}
          </button>

          <div className="text-sm text-gray-400 ml-1 w-20">
            {toDuration(played * duration)} / {toDuration(duration)}
          </div>
        </div>
        <div className="grow flex items-center h-12 relative">
          <input
            className="w-full bg-gray-300"
            type="range"
            min={0}
            max={0.999999}
            step="any"
            value={played}
            onMouseDown={handleSeekMouseDown}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
          />

          {questions.map((question) => (
            <div
              key={`question-mark-${question.id}`}
              className="absolute"
              style={{ left: `${(question.timestamp * 100) / duration}%` }}
            >
              <QuestionMarkCircleIcon className="h-5 w-5 -ml-2 text-indigo-500" />
            </div>
          ))}
        </div>
        <div className="grow-0 flex items-center h-12 space-x-2">
          <button className="mr-2" onClick={handleReplay10}>
            <ArrowUturnLeftIcon className="h-5 w-5 text-gray-300 hover:text-gray-600 cursor-pointer" />
          </button>

          <button
            className={classNames(
              "text-sm text-gray-300 font-semibold hover:text-gray-600 cursor-pointer",
              playbackRate === 1 && "text-indigo-600",
            )}
            onClick={handleSetPlaybackRate}
            value={1}
          >
            1x
          </button>
          <button
            className={classNames(
              "text-sm text-gray-300 font-semibold hover:text-gray-600 cursor-pointer",
              playbackRate === 1.5 && "text-indigo-600",
            )}
            onClick={handleSetPlaybackRate}
            value={1.5}
          >
            1.5x
          </button>
          <button
            className={classNames(
              "text-sm text-gray-300 font-semibold hover:text-gray-600 cursor-pointer",
              playbackRate === 2 && "text-indigo-600",
            )}
            onClick={handleSetPlaybackRate}
            value={2}
          >
            2x
          </button>
        </div>
      </div>
    </div>
  );
};

InteractiveVideoPlayer.defaultProps = {
  src: null,
  playing: false,
  setPlaying: () => {},
  played: 0,
  setPlayed: () => {},
};

export default InteractiveVideoPlayer;
