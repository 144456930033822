import React, { useState, useRef, useEffect, useContext } from "react";
import AppContext from "contexts/AppContext";
import { Chat, Loading } from "components/shared";
import { useFetch, usePost } from "hooks";
import { classNames } from "utils";

const Conversation = ({ id, ...props }) => {
  const { currentUser } = useContext(AppContext);
  const [user, setUser] = useState(null);
  const [conversation, setConversation] = useState(null);

  useFetch(`conversations/${id}`, setConversation, [id]);

  const [attachments, setAttachments] = useState([]);

  const unattach = (option) => {
    setAttachments([
      ...attachments.filter((attachment) => attachment.id !== option.id),
    ]);
  };

  const inputRef = useRef(null);
  const panelRef = useRef(null);
  const ref = { inputRef, panelRef };

  const afterSubmit = (message) => {
    setConversation({
      ...conversation,
      messages: [...conversation.messages, message],
    });
  };

  const { post, data } = usePost(afterSubmit);

  const handleSubmit = (text) => {
    const body = {
      text,
      user_id: currentUser.id,
    };
    post(`conversations/${id}/messages`, body);
  };

  const canSubmit = true;
  const isRespondingToUser = false;

  if (!conversation) return <Loading />;

  return (
    <div className={classNames(props.className)}>
      <Chat
        ref={ref}
        onSubmit={handleSubmit}
        messages={conversation.messages}
        attachments={attachments}
        onUnattach={unattach}
        canSubmit={canSubmit}
        isRespondingToUser={isRespondingToUser}
        users={conversation.users}
      />
    </div>
  );
};

export default Conversation;
