import React from 'react'
import { Page } from 'components/shared'
import LiveSessionsIndex from 'components/live-sessions/LiveSessionsIndex'

const LiveSessionsPage = () => {
  return (
    <Page>
      <LiveSessionsIndex />
    </Page>
  )
}

export default LiveSessionsPage