import React from 'react'
import { Indicator, Loading } from 'components/shared'
import { classNames } from 'utils'

const LearnerRepo = (props) => {
  return(
    <div className={classNames("flex items-center", props.className)}>
      <Indicator
       Positive={!props.loading}
       Intermediary={props.loading}
       className="mr-2 -mt-px" />
      <span className="mr-1 text-xs">
        Repo
      </span>
      {
        props.loading
        ? <Loading inline thin message="" />
        : <a
           href={ props.url }
           className="text-xs text-blue-500"
           target="_blank">
            { props.slug }
           </a>
      }
    </div>
  )
}

export default LearnerRepo
