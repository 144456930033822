import React, { useState, useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'

const usePanel = (showPath, hidePath) => {
  let { path } = useRouteMatch()
  const history = useHistory()

  const [show, setShow] = useState(false)

  useEffect(() => {
    if(path === showPath) setShow(true)
  }, [path])

  const hide = (path = hidePath) => {
    setShow(false)

    if(path === -1) {
      setTimeout(() => history.goBack(), 500)
    } else {
      setTimeout(() => history.push(path), 500)
    }
  }

  return { show, hide }
}

export default usePanel