import React, { useState, useContext } from "react";
import AppContext from "contexts/AppContext";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { Badge } from "components/shared";
import { useFetch, usePut, useFlash } from "hooks";
import { cn } from "utils";

const RemoteCombobox = ({
  title,
  placeholder,
  defaultValue,
  fetchPath,
  updatePath,
  updateTransform = (options) => options,
  createPath,
  createTransform = (option) => option,
  optionValue,
  optionLabel,
  defaultOptions = [],
  allowCreate,
  ...props
}) => {
  const { API } = useContext(AppContext);
  const [query, setQuery] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(defaultValue);
  const [options, setOptions] = useState(defaultOptions);

  const { successFlash } = useFlash();

  useFetch(fetchPath, setOptions, []);

  const { put, data } = usePut(() => {
    if (props.successMessage) successFlash(props.successMessage);
    if (props.onSubmit) props.onSubmit();
  });

  const filteredOptions =
    query === "" || !query
      ? options
      : options.filter((option) => {
          return option[optionLabel]
            .toLowerCase()
            .includes(query.toLowerCase());
        });

  const handleSetOptions = async (newOptions) => {
    let modifiedNewOptions = newOptions;

    if (allowCreate) {
      const newOption = newOptions.find((option) => option[optionValue] === 0);

      if (newOption && createPath) {
        const response = await API.post(createPath, createTransform(newOption));
        if (response) {
          modifiedNewOptions = [
            ...newOptions.filter((option) => option[optionValue] !== 0),
            response,
          ];
        }
      }
    }

    setSelectedOptions(modifiedNewOptions);
    setQuery("");
    if (props.onChange) props.onChange(modifiedNewOptions);
    if (updatePath) put(updatePath, updateTransform(modifiedNewOptions));
  };

  const handleRemoveOption = (value) => {
    const newOptions = selectedOptions.filter(
      (option) => option[optionValue] !== value,
    );
    setSelectedOptions(newOptions);
    if (props.onChange) props.onChange(newOptions);
    if (updatePath) put(updatePath, updateTransform(newOptions));
  };

  return (
    <Combobox
      as="div"
      value={selectedOptions}
      by={optionValue}
      onChange={handleSetOptions}
      nullable
      multiple
      className={cn("w-full relative", props.className)}
    >
      {selectedOptions.length > 0 && (
        <span className={cn("space-x-1 space-y-1 block mb-2")}>
          {selectedOptions.map((option) => (
            <Badge
              key={option[optionValue]}
              dismissible={true}
              onClick={() => handleRemoveOption(option[optionValue])}
            >
              {option[optionLabel]}
            </Badge>
          ))}
        </span>
      )}

      <Combobox.Button as="div">
        <Combobox.Input
          className={cn(
            "w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm",
            props.small && "text-xs sm:text-xs",
            props.selectClassName,
          )}
          onChange={(event) => setQuery(event.target.value)}
          placeholder={placeholder}
          autoComplete="off"
        />
      </Combobox.Button>
      <Combobox.Button className="absolute bottom-2 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
        <ChevronUpDownIcon
          className={cn("h-5 w-5 text-gray-400", props.small && "h-4 w-4")}
          aria-hidden="true"
        />
      </Combobox.Button>

      {filteredOptions.length > 0 ? (
        <Combobox.Options
          className={cn(
            "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
            props.small && "text-xs sm:text-xs",
            props.dropdownClassName,
          )}
        >
          {allowCreate &&
            query.length > 0 &&
            !filteredOptions.find(
              (option) =>
                option[optionLabel].toLowerCase() === query.toLowerCase(),
            ) && (
              <Combobox.Option
                key={"createOption"}
                value={{ [optionValue]: 0, [optionLabel]: query }}
                className={({ active }) =>
                  cn(
                    "relative cursor-default select-none py-2 pl-8 pr-4",
                    active ? "bg-indigo-600 text-white" : "text-gray-900",
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={cn(
                        "block truncate",
                        selected && "font-semibold",
                      )}
                    >
                      Create "{query}"
                    </span>

                    {selected && (
                      <span
                        className={cn(
                          "absolute inset-y-0 left-0 flex items-center pl-1.5",
                          active ? "text-white" : "text-indigo-600",
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            )}
          {filteredOptions.map((option) => (
            <Combobox.Option
              key={option[optionValue]}
              value={option}
              className={({ active }) =>
                cn(
                  "relative cursor-default select-none py-2 pl-8 pr-4",
                  active ? "bg-indigo-600 text-white" : "text-gray-900",
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <span
                    className={cn(
                      "block truncate",
                      selected && "font-semibold",
                    )}
                  >
                    {option[optionLabel]}
                  </span>

                  {selected && (
                    <span
                      className={cn(
                        "absolute inset-y-0 left-0 flex items-center pl-1.5",
                        active ? "text-white" : "text-indigo-600",
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      ) : (
        allowCreate &&
        query.length > 0 && (
          <Combobox.Options
            className={cn(
              "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
              props.small && "text-xs sm:text-xs",
            )}
          >
            <Combobox.Option
              key={"createOption"}
              value={{ [optionValue]: 0, [optionLabel]: query }}
              className={({ active }) =>
                cn(
                  "relative cursor-default select-none py-2 pl-8 pr-4",
                  active ? "bg-indigo-600 text-white" : "text-gray-900",
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <span
                    className={cn(
                      "block truncate",
                      selected && "font-semibold",
                    )}
                  >
                    Create "{query}"
                  </span>

                  {selected && (
                    <span
                      className={cn(
                        "absolute inset-y-0 left-0 flex items-center pl-1.5",
                        active ? "text-white" : "text-indigo-600",
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          </Combobox.Options>
        )
      )}
    </Combobox>
  );
};

RemoteCombobox.defaultProps = {
  defaultValue: [],
};

export default RemoteCombobox;
