import React, { Fragment } from 'react'
import { Indicator, Tooltip } from 'components/shared'
import { Menu, Transition } from '@headlessui/react'
import { FunnelIcon } from '@heroicons/react/24/outline'
import { FunnelIcon as SolidFunnelIcon } from '@heroicons/react/24/solid'
import { classNames, pluralize } from 'utils'

const DiscussionsFeedHeader = ({ discussions, filters, ...props }) => {
  const showAll = (e) => {
    e.preventDefault()
    props.onChange({ name: 'limit', value: parseInt(filters.limit) + 200 })
  }

  const filtersApplied = Object
                        .entries(filters)
                        .filter(([key, value]) => {
                          if(key === 'search') return false
                          if(key === 'labels') return value && value.length > 0
                          if(key === 'order')  return false
                          if(key === 'hide_current_user_responses') return false
                          if(key === 'limit') return false

                          return value
                        }).map(([key, value]) => key)

  return (
    <div className="px-4 py-2 bg-gray-50 border-b border-gray-200 flex items-center justify-between">
      <div className="flex items-center space-x-2">
        <Tooltip text={ props.websocketConnected ? "Streaming new discussions live." : 'Connecting to websocket...' } >
          <Indicator pulse type={props.websocketConnected ? 'success' : 'loading'} />
        </Tooltip>
        <div className="text-xs text-gray-600">
          Showing <span className="font-semibold">
            { new Intl.NumberFormat('en-GB').format(discussions.length) }
          </span> discussions. { Object.keys(filters).includes('limit') && filters.limit &&
                                 <a
                                  className="text-blue-500 hover:text-blue-700 cursor-pointer"
                                  onClick={showAll}>Load more</a> }
        </div>
      </div>

      {
        props.showFilters
        ? props.filtersHidden
        ? <Tooltip text={filtersApplied.length > 0 ? `Filters applied: ${filtersApplied.join(', ')}` : 'Show filters and search' } >
            <div className="relative cursor-pointer text-gray-600 hover:text-gray-800">
              <FunnelIcon
               onClick={props.onToggleFilters}
               className="h-5 w-5" />
              {
                filtersApplied.length > 0 &&
                <div className="h-2 w-2 rounded-full bg-red-600 absolute top-0 right-0" />
              }
            </div>
          </Tooltip>
        : <div className="relative cursor-pointer text-gray-600 hover:text-gray-800">
            <SolidFunnelIcon
             onClick={props.onToggleFilters}
             className="h-5 w-5" />
            {
              filtersApplied.length > 0 &&
              <div className="h-2 w-2 rounded-full bg-red-600 absolute top-0 right-0" />
            }
          </div>
        : null
      }
    </div>
  )
}

export default DiscussionsFeedHeader
