import React, { Fragment, useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import LiveSessionDetails from "components/live-sessions/LiveSessionDetails";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { Button } from "components/shared";
import { CalendarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useQuery } from "hooks";

const LiveSessionNotifier = ({ ...props }) => {
  const { API, currentUser } = useContext(AppContext);
  const { pathWithQuery } = useQuery();
  const location = useLocation();
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [liveSessions, setLiveSessions] = useState([]);

  const fetchLiveSessions = async () => {
    const filters = {
      status: "upcoming",
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      course_id: currentUser.courseId,
      not_viewed_by: currentUser.id,
    };

    const response = await API.get(`/live_sessions`, { params: filters });

    if (response) {
      setLiveSessions(response);
    }
  };

  useEffect(() => {
    fetchLiveSessions();
  }, [location]);

  useEffect(() => {
    if (liveSessions?.length > 0) setShow(true);
  }, [liveSessions]);

  const signupForLiveSession = async (id) => {
    const response = await API.post(`/live_session_attendances`, {
      live_session_attendance: {
        user_id: currentUser.id,
        live_session_id: id,
      },
    });

    if (response) {
      handleDismiss(id);
      history.push(
        pathWithQuery(null, {
          liveSessionSignupId: id,
        }),
      );
    }
  };

  const handleDismiss = (id) => {
    API.post(`/live_session_views`, {
      live_session_view: { user_id: currentUser.id, live_session_id: id },
    });
    setShow(false);
  };

  const nextLiveSession = liveSessions[0];
  if (!nextLiveSession) return null;

  return (
    <div
      aria-live="assertive"
      className="z-20 pointer-events-none fixed inset-0 flex items-end justify-end px-4 py-6 sm:p-6 z-10"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`pointer-events-auto w-full max-w-md overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <CalendarIcon
                    className="h-8 w-8 mt-0.5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  {nextLiveSession && (
                    <LiveSessionDetails
                      short
                      size="sm"
                      liveSession={nextLiveSession}
                    />
                  )}

                  {nextLiveSession?.hasAvailability && (
                    <div className="mt-3 flex space-x-2 items-center">
                      <Button
                        onClick={() => signupForLiveSession(nextLiveSession.id)}
                        variant="secondary"
                        size="sm"
                      >
                        Sign up
                      </Button>
                      <p className="text-sm text-gray-400">
                        or{" "}
                        <Link
                          onClick={() => handleDismiss(nextLiveSession.id)}
                          to="/live"
                          className="text-blue-500"
                        >
                          see all upcoming sessions
                        </Link>
                      </p>
                    </div>
                  )}
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => handleDismiss(nextLiveSession.id)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default LiveSessionNotifier;
