import React, { Fragment, useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { useFetch, usePagination, useTour } from "hooks";
import Discussion from "components/coaching/Discussion";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { classNames } from "utils";
import { pure } from "recompose";

const Discussions = ({
  question,
  omitCurrentUser = false,
  locked = false,
  ...props
}) => {
  const { currentUser } = useContext(AppContext);
  const excludedIdsParam = omitCurrentUser
    ? `&excluded_user_ids[]=${currentUser.id}`
    : "";

  const [discussions, setDiscussions] = useState([]);

  const handleReceiveDiscussions = (newDiscussions) => {
    if (
      newDiscussions.map((discussion) => discussion.id).toString() ===
      discussions.map((discussion) => discussion.id).toString()
    )
      return;

    setDiscussions(newDiscussions);
  };

  // const tour = useTour('other-discussions')

  const { loadingOrError } = useFetch(
    `questions/${question.id}/discussions?order=recommended${excludedIdsParam}`,
    handleReceiveDiscussions,
    [question?.id],
  );
  const { page, Pagination } = usePagination(discussions);

  if (loadingOrError)
    return (
      <div className={classNames(props.className)}>
        {!props.omitHeader && (
          <div className="mt-2 relative z-10">
            <div className="ml-8 border-indigo-500 text-indigo-600 whitespace-nowrap pb-2 border-b-2 font-medium text-sm mb-0 inline-block">
              {locked && (
                <LockClosedIcon className="h-3 w-3 inline mr-1 -translate-y-px" />
              )}
              Other answers
            </div>
            <div className="ml-1 text-gray-400 pb-2 text-xs mb-0 inline-block">
              {locked
                ? "(answer to unlock)"
                : discussions && `(${discussions.length})`}
            </div>
            <div className="absolute bottom-0 right-0 left-0 h-px bg-gray-200 -z-10" />
          </div>
        )}
        <div className="relative min-h-full h-64">{loadingOrError}</div>
      </div>
    );

  return (
    <div id="other-discussions-tour-1" className={props.className}>
      {!props.omitHeader && (
        <div className="mt-2 relative z-10">
          <div className="ml-8 border-indigo-500 text-indigo-600 whitespace-nowrap pb-2 border-b-2 font-medium text-sm mb-0 inline-block">
            {locked && (
              <LockClosedIcon className="h-3 w-3 inline mr-1 -translate-y-px" />
            )}
            Other answers
          </div>
          <div className="ml-1 text-gray-400 pb-2 text-xs mb-0 inline-block">
            {locked
              ? "(answer to unlock)"
              : discussions && `(${discussions.length})`}
          </div>

          <div className="absolute bottom-0 right-0 left-0 h-px bg-gray-200 -z-10" />
        </div>
      )}

      {/*{ !locked && tour }*/}

      <div
        className={classNames(
          "divide-y divide-gray-200",
          locked && "blur-sm pointer-events-none",
        )}
      >
        {discussions &&
          page.map((discussion, index) => (
            <Discussion
              key={discussion.id}
              htmlId={index === 0 ? "other-discussions-tour-2" : null}
              className={index === discussions.length - 1 ? "mb-8" : "mb-8 @xs:mb-4"}
              {...discussion}
              {...props}
            />
          ))}

        <Pagination />
      </div>
    </div>
  );
};

export default pure(Discussions);
