import React, { useState, useEffect } from 'react'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'

const useAnalytics = (currentUser, location) => {
  const [analytics, setAnalytics] = useState(null)
  const { setPathPageView, setIdentity } = useTrackingCode()

  useEffect(() => {
    if(window.analytics && window.analytics !== undefined) {
      setAnalytics(window.analytics)
    }
  }, [])

  useEffect(() => {
    if(analytics) {
      const traits = {
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email
      }

      const options = {}

      analytics.identify(
        currentUser.id,
        traits,
        options
      )

      setIdentity(traits)
    }
  }, [analytics])

  useEffect(() => {
    if(analytics) {
      analytics.page()
      setPathPageView(location.pathname)
    }
  }, [location, analytics])

  return [analytics, setAnalytics]
}

export default useAnalytics
