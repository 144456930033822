import React, { Fragment, useState, useContext } from "react";
import { Loading, Tabs } from "components/shared";
import AppContext from "contexts/AppContext";

import Discussion from "components/coaching/Discussion";
import QuestionHeader from "components/coaching/QuestionHeader";
import Discussions from "components/coaching/Discussions";
import AiQuestionHelper from "components/questions/AiQuestionHelper";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { LockClosedIcon } from "@heroicons/react/20/solid";

import { possessive } from "utils";
import { pure } from "recompose";

// question structure
// {
//   id,
//   text,
//   constraints,
//   starters,
// }

const RegularQuestion = ({
  question,
  user,
  constraints,
  discussion,
  stage,
  ...props
}) => {
  const { currentUser } = useContext(AppContext);

  const defaultResponses = React.useMemo(
    () => [question.lastResponse].filter((n) => n),
    [],
  );

  const [responses, setResponses] = useState(defaultResponses);

  const lastResponse = React.useMemo(
    () => responses[responses.length - 1],
    [responses],
  );
  const discussionsLocked = React.useMemo(
    () => responses.length === 0,
    [responses],
  );

  if (!discussion) return <Loading />;

  const handleSubmitResponse = (response) => {
    setResponses([...responses, response]);
    if (props.onSubmitResponse) props.onSubmitResponse(response);
  };

  const handleDeleteResponse = (responseId) => {
    setResponses([
      ...responses.filter((response) => response.id !== responseId),
    ]);
  };

  const handleLoadDiscussion = (discussion) => {
    setResponses(discussion.responses);
  };

  return (
    <>
      <QuestionHeader
        question={question}
        constraints={constraints}
        stage={stage}
        user={user}
        small={props.small}
        warmup={props.warmup}
        onStartUpdate={props.onStartUpdate}
        onUpdate={props.onUpdate}
        onErrorUpdate={props.onErrorUpdate}
        showTitle={props.showTitle}
      />
      <Tabs>
        <Tabs.Tab
          header={`${
            user.id === currentUser.id ? "Your" : possessive(user.firstName)
          } answer`}
          headerClassName="ml-8 @xs:ml-6 @md:ml-16"
        >
          <div className="items-center pr-6 pb-8 @xs:pr-4 @xs:pb-2">
            <div className="max-w-prose w-full">
              <Discussion
                id={discussion.id}
                question={question}
                constraints={constraints}
                extraResponseParams={props.extraResponseParams}
                onLoad={handleLoadDiscussion}
                onSubmitResponse={handleSubmitResponse}
                onDeleteResponse={handleDeleteResponse}
                highlightedResponseId={props.selectedResponseId}
                highlightedCommentId={props.selectedCommentId}
                includeTrailingLine={props.includeAiCoach && question.aiCoachEnabled && lastResponse}
              />
            </div>

            {lastResponse &&
              (props.warmup || props.includeAiCoach) &&
              question.aiCoachEnabled && (
                <div className="w-full max-w-prose @md:mt-8">
                  <AiQuestionHelper
                    className="px-8 @xs:px-0 @xs:pl-4"
                    showWarmupMessage={!props.includeAiCoach && props.warmup}
                    user={user}
                    question={question}
                    response={lastResponse}
                    onAiCoachResponse={props.onAiCoachResponse}
                  />
                </div>
              )}
          </div>
        </Tabs.Tab>

        {props.includeOtherAnswers && (
          <Tabs.Tab
            disabled={discussionsLocked}
            header={
              <>
                {discussionsLocked && (
                  <LockClosedIcon className="h-3 w-3 inline mr-1 -translate-y-px" />
                )}
                Other answers
                <div className="ml-1 text-gray-400 text-xs mb-0 inline-block">
                  {discussionsLocked && "(answer to unlock)"}
                </div>
              </>
            }
          >
            <Discussions
              locked={discussionsLocked}
              omitCurrentUser
              omitActivity
              omitLastName
              omitHeader
              question={question}
            />
          </Tabs.Tab>
        )}
      </Tabs>
    </>
  );
};

export default pure(RegularQuestion);
