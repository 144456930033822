import React, { useState, useContext, useMemo } from 'react'
import AppContext from 'contexts/AppContext'
import { Pill } from 'components/shared'
import { useFetch, useQuery } from 'hooks'
import { GiftIcon } from '@heroicons/react/24/outline'
import { pluralize } from 'utils'

const FreeTrialManager = ({ ...props }) => {
  const { currentUser, freeTrialActivityLimit } = useContext(AppContext)
  const { addQuery } = useQuery()
  const [attempts, setAttempts] = useState(null)

  useFetch(`attempts?user_id=${currentUser.id}`, setAttempts)

  const completedAttempts = useMemo(() => attempts?.filter(a => a.status === 'complete'), [attempts])

  if(!attempts) return null

  return(
    <Pill
     id='freeTrialManager'
     color='green'
     className='absolute top-3 right-3'
     onClick={() => addQuery('upgrade', true)}
     Icon={ GiftIcon }>
      <span className='font-bold mr-1'>Trial</span>
      { pluralize(freeTrialActivityLimit - completedAttempts.length, 'activity') } remaining
    </Pill>
  )
}

export default FreeTrialManager