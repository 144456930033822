import React, { Fragment, useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { ContentEditable, Spinner } from "components/shared";
import { useDebounce } from "react-use";

const Editable = ({ path, transform, children, ...props }) => {
  const { API, editorMode } = useContext(AppContext);

  const [content, setContent] = useState(children);
  const [debouncedContent, setDebouncedContent] = useState(content);
  const [isLoading, setIsLoading] = useState(false);

  const [, cancel] = useDebounce(
    () => {
      if (editorMode) setDebouncedContent(content);
    },
    1000,
    [content, editorMode],
  );

  const update = async () => {
    const response = await API.put(path, transform(debouncedContent), {
      onError: props.onError,
    });

    if (response) {
      setIsLoading(false);
      if (props.onChange) props.onChange(debouncedContent);
    }
  };

  useEffect(() => {
    if (editorMode) update();
  }, [debouncedContent, editorMode]);

  return (
    <div className='inline-flex items-center space-x-1'>
      <ContentEditable
        interactive
        className='inline'
        html={content}
        onChange={(e) => {
          setIsLoading(true);
          if (props.beforeChange) props.beforeChange(e.target.value);
          setContent(e.target.value);
        }}
      />
      {isLoading && <Spinner className='inline h-3 w-3 text-gray-400' />}
    </div>
  );
};

export default Editable;
