import React, { useContext, useEffect, useState } from "react";
import { useFetch, usePut } from "hooks";
import NotificationsChannel from "channels/notifications_channel";

const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [notificationsConnected, setNotificationsConnected] = useState(false);

  const { fetch: fetchNotifications } = useFetch(
    "notifications",
    setNotifications,
    [],
  );

  const handleReceiveNotification = (data) => {
    setNotifications([JSON.parse(data.notification), ...notifications]);
  };

  useEffect(() => {
    NotificationsChannel.connected = () => setNotificationsConnected(true);
    NotificationsChannel.received = handleReceiveNotification;
  }, [notifications]);

  const { put: updateNotification } = usePut(fetchNotifications);

  const setNotificationRead = (id) => {
    const newNotifications = notifications.map((notification) => {
      if (notification.id === id) return { ...notification, status: "read" };
      return notification;
    });

    updateNotification(`notifications/${id}`, { status: "read" });
  };

  return {
    notifications,
    setNotifications,
    setNotificationRead,
    notificationsConnected,
  };
};

export default useNotifications;
