import React from "react";
import { Modal } from "components/shared";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import { useQuery } from "hooks";
import AddUser from "components/admin/AddUser";

const AddUserModal = ({ ...props }) => {
  const { watchQuery, deleteQuery } = useQuery();

  const open = watchQuery("addUser");
  const onClose = () => {
    deleteQuery("addUser");
    if (props.onChange) props.onChange();
  };

  return (
    <Modal
      size="sm"
      dismissible
      open={!!open}
      onClose={onClose}
      Icon={UserPlusIcon}
      title="Add a user"
    >
      <AddUser onClose={onClose} />
    </Modal>
  );
};

export default AddUserModal;
