import React from 'react'
import { PresentationChartLineIcon } from '@heroicons/react/24/outline'
import { pluralize, classNames } from 'utils'

const ActivityMetricsBar = ({ activity, ...props }) => {
  const attemptsWithRatings = activity.completedAttempts.filter(attempt => attempt.rating)

  if(!attemptsWithRatings || attemptsWithRatings.length === 0) return null

  const averageRating = parseFloat(
    attemptsWithRatings
    .map(attempt => attempt.rating)
    .reduce((acc, curr) => acc + curr)
  ) / attemptsWithRatings.length

  const RATINGS_TO_COLORS = {
    3: 'text-red-500',
    4: 'text-yellow-500',
    5: 'text-green-500'
  }

  const ratingColor = RATINGS_TO_COLORS[Math.ceil(averageRating)]

  return(
    <div className={props.className}>
      <div className="text-sm text-gray-400">
        <PresentationChartLineIcon className="h-5 w-5 mr-1 inline" aria-hidden="true" />
        Rated <span className={classNames('font-medium', ratingColor)}>{ averageRating.toFixed(1) }/5</span> by { pluralize(attemptsWithRatings.length, 'user') }
      </div>
    </div>
  )
}

export default ActivityMetricsBar
