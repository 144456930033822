import React from "react";
import { Modal } from "components/shared";
import { DocumentCheckIcon } from "@heroicons/react/24/solid";
import { useQuery } from "hooks";
import ManageLicenses from 'components/admin/ManageLicenses'

const ManageLicensesModal = () => {
  const { watchQuery, deleteQuery } = useQuery();

  const open = watchQuery('manageLicenses')
  const onClose = () => deleteQuery('manageLicenses')

  return (
    <Modal
      size='2xl'
      dismissible
      open={!!open}
      onClose={onClose}
      Icon={DocumentCheckIcon}
      title="Manage Licenses"
    >
      <ManageLicenses onClose={onClose} />
    </Modal>
  );
};

export default ManageLicensesModal;
