import React, { Fragment, useContext } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import AppContext from 'contexts/AppContext'
import UserProfileHeader from 'components/users/UserProfileHeader'
import UserProfile from 'components/users/UserProfile'
import UserNotes from 'components/users/UserNotes'
import Timeline from 'components/coaching/Timeline'
import TwoUpDiscussions from 'components/discussions/TwoUpDiscussions'
import { Tabs } from 'components/shared'

const DirectoryUser = ({ user, cohort, ...props }) => {
  const { currentUser } = useContext(AppContext)
  const { path } = useRouteMatch()
  const match = useRouteMatch(`${path}/:id/:subPath`)
  const { subPath } = match?.params || {}

  return(
    <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none">
      <article className="relative">
          <UserProfileHeader user={user} onChange={props.onChange} />

          <Tabs>
            <Tabs.Tab
             header="Profile"
             className="px-12 sm:px-14 lg:px-16 py-4"
             headerClassName="ml-12 sm:ml-14 lg:ml-16"
             link={`${path}/${user.id}/profile`}
             selected={subPath && subPath.split('/')[0] === 'profile'}>
              <UserProfile
               user={user}
               cohort={cohort}
               onChange={props.onChange} />
            </Tabs.Tab>

            {
              (currentUser.isTrainer || currentUser.isAdmin) &&
              <Tabs.Tab
               link={`${path}/${user.id}/activity`}
               selected={subPath && subPath.split('/')[0] === 'activity'}
               header="Activity"
               className="px-12 sm:px-14 lg:px-16 py-4">
                <Timeline reverse user={user} />
              </Tabs.Tab>
            }

            <Tabs.Tab
             header="Discussions"
             className="w-full"
             link={`${path}/${user.id}/discussions`}
             selected={subPath && subPath.split('/')[0] === 'discussions'}>
             <Route path={`${path}/${user.id}`}>
              <TwoUpDiscussions user={user} includeOtherAnswers />
             </Route>
            </Tabs.Tab>

            {
              (currentUser.isTrainer || currentUser.isAdmin) &&
              <Tabs.Tab
               link={`${path}/${user.id}/notes`}
               selected={subPath && subPath.split('/')[0] === 'notes'}
               header={
                <>
                 Notes
                 <span className="text-xs text-gray-400 ml-1 inline-block">
                   ({ user.notes?.length })
                 </span>
                </>
               }
               className="px-12 sm:px-14 lg:px-16 py-4">
               <UserNotes user={user} />
              </Tabs.Tab>
            }
          </Tabs>
      </article>
    </main>
  )
}

export default DirectoryUser
