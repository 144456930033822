import React, { useState } from "react";
import Graph from "components/graph/Graph";
import { useFetch } from "hooks";

const UserGraph = ({ id, courseId, isGraphExpanded }) => {
  const [baseGraph, setBaseGraph] = useState(null);
  const [graph, setGraph] = useState(null);
  const [goal, setGoal] = useState(null);

  const handleReceiveAttempts = (attempts) => {
    const completedAttemptActivityIds = attempts
      .filter(({ status }) => status === "complete")
      .map(({ activity }) => activity.id);
    const currentAttemptActivityIds = attempts
      .filter(({ status }) => status === "in_progress")
      .map(({ activity }) => activity.id);

    const graph = {
      ...baseGraph,
      activities: baseGraph.activities.map((activity) => {
        const unlocked =
          !(activity.construction || activity.permalocked) &&
          (activity.starter ||
            activity.previous.every(({ id }) =>
              completedAttemptActivityIds.includes(id),
            ));

        return {
          completedAttempt: completedAttemptActivityIds.includes(activity.id),
          currentAttempt: currentAttemptActivityIds.includes(activity.id),
          unlocked,
          ...activity,
        };
      }),
    };

    setGraph(graph);
  };

  useFetch(`courses/${courseId}/graph`, setBaseGraph, [id, courseId]);
  const { loadingOrError } = useFetch(
    `attempts?user_id=${id}`,
    handleReceiveAttempts,
    [baseGraph],
  );

  const handleReady = (nodes) => {
    // (nodes.length > 0) && setLoaded(true)
  };

  const handleNodeClick = (node) => {
    // history.push(`/activities/${node.data().slug}/preview`)
  };

  if (!graph?.activities) return null;
  if (graph.activities.length === 0) return null;

  return (
    <Graph
      width="100%"
      height="100%"
      useHtmlLabels
      onNodeClick={handleNodeClick}
      onReady={handleReady}
      currentGoal={goal}
      initialPan={{ x: graph.activities[0].x, y: graph.activities[0].y }}
      {...graph}
    />
  );
};

export default UserGraph;
