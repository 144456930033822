import React, { useState } from "react";
import { useQueryParam, NumberParam } from "use-query-params";
import { Modal } from "components/shared";
import UserMail from "components/users/UserMail";

const UserMailModal = ({ ...props }) => {
  const [id, setId] = useQueryParam("userMail", NumberParam);

  const onClose = () => {
    setId(null);
  };

  return (
    <Modal size={"5xl"} className="p-0" open={!!id} onClose={onClose}>
      <UserMail
        id={id}
        onChange={props.onChange}
        className="h-[640px] max-h-[90vh]"
      />
    </Modal>
  );
};

export default UserMailModal;
