import React, { createContext, useState, useEffect, useContext } from 'react'
import AppContext from 'contexts/AppContext'
import { post } from 'api'

const HubspotChatContext = createContext({})

const HubspotChatContextProvider = ({ children }) => {
  const { apiUrl, formToken, currentUser } = useContext(AppContext)

  const [chatToken, setChatToken] = useState(null)

  useEffect(() => {
    const body = {
      email: currentUser.email,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName
    }

    post(
      `${apiUrl}/hubspot-chat-tokens`,
      { body, formToken },
      ({ token }) => setChatToken(token)
    )
  }, [currentUser])

  useEffect(() => {
    if(chatToken) {
      window.hsConversationsSettings = {
         identificationEmail: currentUser.email,
         identificationToken: chatToken
      }

      if(window.HubSpotConversations && window.HubSpotConversations.widget && !process.env.CYPRESS) {
        window.HubSpotConversations.widget.load()
      }
    }
  }, [chatToken, window.HubSpotConversations])

  return(
    <HubspotChatContext.Provider value={chatToken}>
      { children }
    </HubspotChatContext.Provider>
  )
}

export default HubspotChatContextProvider