import React from "react";
import { Modal } from "components/shared";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";
import { useHistory } from "react-router-dom";
import { useQuery } from "hooks";
import AddOrganization from "components/admin/AddOrganization";

const AddOrganizationModal = ({ ...props }) => {
  const { watchQuery, deleteQuery } = useQuery();
  const history = useHistory();

  const open = watchQuery("addOrganization");
  const onClose = () => {
    deleteQuery("addOrganization");
    if(props.onChange) props.onChange()
  };

  return (
    <Modal
      size="sm"
      dismissible
      open={!!open}
      onClose={onClose}
      Icon={BuildingOfficeIcon}
      title="Add an organization"
    >
      <AddOrganization onClose={onClose} />
    </Modal>
  );
};

export default AddOrganizationModal;
