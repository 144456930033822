import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import { TextInput, Button, Form } from "components/shared";
import { useFlash } from "hooks";

const AddFeatureAnnouncement = ({ ...props }) => {
  const { API } = useContext(AppContext);
  const { successFlash, errorFlash } = useFlash();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (formData) => {
    const body = {
      feature_announcement: {
        text: formData.text,
        link_text: formData.link_text,
        link: formData.link,
      },
    };

    setIsLoading(true);
    const response = await API.post(`/feature_announcements`, body, {
      onError: errorFlash,
    });
    setIsLoading(false);

    if (response) {
      successFlash(`Feature announcement created successfully.`);
      if (props.onSubmit) props.onSubmit(response);
      if (props.onClose) props.onClose();
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="text-left pt-4 space-y-4">
      <TextInput required label="Text" name="text" />
      <TextInput label="Link text" name="link_text" />
      <TextInput
        label="Link path"
        name="link"
        hint="Should be a local path"
        placeholder="e.g. /live"
      />
      <div className="mt-5 sm:mt-6">
        <Button className="w-full block" type="submit" disabled={isLoading}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default AddFeatureAnnouncement;
