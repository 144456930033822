import React, { Fragment, useState } from "react";
import Discussions from "components/coaching/Discussions";
import Questions from "components/questions/Questions";

const PollActivity = ({ user, extraResponseParams, stage, ...props }) => {
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const discussionsLocked = currentQuestion && !currentQuestion.userHasAnswered;

  const handleAdvance = (response) => {
    setCurrentQuestion({ ...currentQuestion, userHasAnswered: true });
  };

  const handleClickQuestionHeader = (question) => {
    setCurrentQuestion(question);
  };

  return (
    <div className="h-full z-0 flex flex-1 overflow-hidden">
      <main className="@container relative z-0 flex-1 overflow-y-auto focus:outline-none">
        <Questions
          className="pt-0"
          questions={stage.questions}
          stage={stage}
          user={user}
          currentQuestion={currentQuestion}
          extraResponseParams={extraResponseParams}
          onAdvance={handleAdvance}
          onClick={handleClickQuestionHeader}
        />
      </main>
      <aside className="@container relative hidden w-1/3 flex-shrink-0 overflow-y-auto overflow-x-hidden border-l border-gray-200 xl:flex xl:flex-col">
        {currentQuestion && (
          <Discussions
            question={{ id: currentQuestion.id }}
            locked={discussionsLocked}
            omitCurrentUser
            omitActivity
            omitLastName
          />
        )}
      </aside>
    </div>
  );
};

export default PollActivity;
