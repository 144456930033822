import React, { Fragment, useState } from "react";
import { Hint, RemoteTextArea } from "components/shared";
import UserNote from "components/users/UserNote";
import { useFetch } from "hooks";

const UserNotes = ({ user, ...props }) => {
  const [notes, setNotes] = useState([]);

  const { fetch, loadingOrError } = useFetch(
    `users/${user.id}/notes`,
    setNotes,
    [user.id],
  );

  const handleAddNote = () => {
    fetch();
    props.onAddNote && props.onAddNote();
  };

  return (
    <>
      <Hint className="mb-2">
        Notes are only visible to other trainers and admins.
      </Hint>

      <RemoteTextArea
        title="Note"
        className="mb-8"
        name="note"
        rows={4}
        placeholder={`e.g. ${user.firstName} is doing great at...`}
        method="post"
        path={`users/${user.id}/notes`}
        transform={(text) => ({ note: { text } })}
        onSubmit={handleAddNote}
      />

      {loadingOrError
        ? loadingOrError
        : notes.map((note) => (
            <UserNote
              className="mb-8"
              key={note.id}
              onView={props.onViewNote}
              {...note}
            />
          ))}
    </>
  );
};

export default UserNotes;
