import React, { useContext } from "react";
import AppContext from "contexts/AppContext";
import { defaultProps, defaultBlockSchema } from "@blocknote/core";
import {
  getDefaultReactSlashMenuItems,
  createReactBlockSpec,
} from "@blocknote/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import Question from "components/questions/Question";

export default function getCustomEditorComponents(stage) {
  const { currentUser, API } = useContext(AppContext);

  // Questions can only be added on stages
  if (!stage) {
    return {
      blockSchema: {
        ...defaultBlockSchema,
      },
      slashMenuItems: [...getDefaultReactSlashMenuItems()],
    };
  }

  const QuestionBlock = createReactBlockSpec({
    type: "question",
    propSchema: {
      ...defaultProps,
      questionId: {
        default: 25,
      },
      stageId: {
        default: 1
      },
      stageSlug: {
        default: 'stag-slug'
      }
    },
    render: ({ block }) => (
      <div className="question-in-article -ml-16 not-prose">
        <Question
          id={block.props.questionId}
          stage={{
            id: block.props.stageId,
            slug: block.props.stageSlug
          }}
          user={currentUser}
          includeOtherAnswers
          includeAiCoach
          className="px-0"
        />
      </div>
    ),
  });

  const questionSlashMenuItem = {
    name: "Insert a question",
    execute: async (editor) => {
      // this needs to be dynamic by posting (or listing out with a dropdown)
      const question = await API.post("/questions", {
        question: { type: "regular" },
        activity_stage: { id: stage.id },
      });
      editor.insertBlocks(
        [
          {
            type: "question",
            props: { questionId: question.id, stageId: stage.id, stageSlug: stage.slug },
          },
        ],
        editor.getTextCursorPosition().block,
        "before",
      );
    },
    aliases: ["question", "q"],
    group: "Learning",
    icon: <QuestionMarkCircleIcon className="h-6 w-6" />,
    hint: "Inserts a free-text question.",
  };

  return {
    blockSchema: {
      ...defaultBlockSchema,
      question: QuestionBlock,
    },
    slashMenuItems: [...getDefaultReactSlashMenuItems(), questionSlashMenuItem],
  };
}
