import React, { useState, useEffect } from "react";
import { Button } from "components/shared";
import LearnerRepo from "components/github/LearnerRepo";
import { useFetch, usePost } from "hooks";

const LearnerHooksStatus = (props) => {
  const [hooksSynced, setHooksSynced] = useState(false);

  const { post, data } = usePost(
    `projects/${props.id}/actions/setup-learner-hooks`,
    props.onSuccess,
  );

  const handleHookStatus = ({ status }) => {
    if (status === "success") setHooksSynced(true);
    else setHooksSynced(false);
  };

  const { data: checkingForHooks } = useFetch(
    `projects/${props.id}/statuses/learner-hooks-synced`,
    handleHookStatus,
  );

  const handleClick = (e) => {
    e.preventDefault();
    post();
  };

  if (checkingForHooks.isLoading) return <span>Checking for hooks...</span>;

  if (hooksSynced) return <span>Hooks synced.</span>;

  return (
    <Button
      onClick={handleClick}
      size="sm"
      loading={data.isLoading}
      error={data.errorMessage}
    >
      {props.buttonText}
    </Button>
  );
};

LearnerHooksStatus.defaultProps = {
  buttonText: "Sync hooks",
};

export default LearnerHooksStatus;
