import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Loading } from "components/shared";
import Certified from "components/certification/Certified";
import GetCertified from "components/certification/GetCertified";

const Certification = () => {
  const { currentUser, API } = useContext(AppContext);
  const [certification, setCertification] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchCertification = async () => {
    setLoading(true);
    const response = await API.get(`/certifications`, {
      params: { user_id: currentUser.id },
    });
    if (response && response.length > 0) {
      setCertification(response[0]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCertification();
  }, []);

  if (loading) return <Loading />;
  if (certification) return <Certified certification={certification} />;
  return <GetCertified />;
};

export default Certification;
