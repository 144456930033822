import React, { Fragment, useContext } from "react";
import AppContext from "contexts/AppContext";
import { Tooltip, Button } from "components/shared";
import { Link } from "react-router-dom";
import {
  Cog6ToothIcon,
  PlusIcon,
  ArrowsRightLeftIcon,
} from "@heroicons/react/24/outline";
import { Menu } from "@headlessui/react";
import { useQuery } from "hooks";
import AddCourseModal from "components/courses/AddCourseModal";
import SelectCourseModal from "components/courses/SelectCourseModal";
import { cn } from "utils";

const CourseMenuContent = ({ ...props }) => {
  const { pathWithQuery } = useQuery();

  return (
    <Menu as="div" {...props} className="">
      <Menu.Items
        static
        className="w-56 rounded-md bg-slate-700 focus:outline-none"
      >
        <div>
          <Menu.Item disabled>
            <span
              className={"text-slate-400 block px-4 py-2 text-sm rounded-t-md"}
            >
              Manage courses
            </span>
          </Menu.Item>

          <Menu.Item key={"add-course"}>
            {({ active }) => (
              <Link
                to={pathWithQuery(null, { addCourse: true })}
                className={cn(
                  "text-white block w-full text-left font-semibold px-4 py-2 text-sm cursor-pointer flex items-center space-x-2",
                  active && "bg-slate-800",
                  "rounded-b-md",
                )}
              >
                <PlusIcon className="w-4 h-4" />
                <span>Add course</span>
              </Link>
            )}
          </Menu.Item>

          <Menu.Item key={"switch-course"}>
            {({ active }) => (
              <Link
                to={pathWithQuery(null, { selectCourse: true })}
                className={cn(
                  "text-white block w-full text-left font-semibold px-4 py-2 text-sm cursor-pointer flex items-center space-x-2",
                  active && "bg-slate-800",
                  "rounded-b-md",
                )}
              >
                <ArrowsRightLeftIcon className="w-4 h-4" />
                <span>Switch course</span>
              </Link>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
};

const CourseMenu = ({ ...props }) => {
  const { currentUser } = useContext(AppContext);

  return (
    <>
      <div className="absolute top-2 right-2">
        <Tooltip on="click" offset={[-90, 10]} panel={<CourseMenuContent />}>
          <Button variant="secondary" Icon={Cog6ToothIcon} />
        </Tooltip>
      </div>
      {(currentUser.isCourseDesigner || currentUser.isAdmin) && (
        <AddCourseModal onChange={props.onChange} />
      )}

      {(currentUser.isCourseDesigner || currentUser.isAdmin) && (
        <SelectCourseModal onChange={props.onChange} />
      )}
    </>
  );
};

export default CourseMenu;
