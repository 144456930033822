import React from 'react'
import { classNames } from 'utils'

const DotBadge = ({ value, size='sm', ...props }) => {
  const SIZE_TO_CLASSES = {
    'xs': 'h-4 w-4 text-[10px]',
    'sm': 'h-5 w-5 text-xs'
  }

  return(
    <div
     className={classNames(
      'absolute -top-2 -right-2 rounded-full bg-red-400 flex items-center justify-center font-semibold text-white',
      SIZE_TO_CLASSES[size],
      props.className
     )}>
      { value }
    </div>
  )
}

export default DotBadge
