import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import { useFlash } from "hooks";

const useAPI = () => {
  const { API } = useContext(AppContext);
  const { errorFlash } = useFlash();

  const [isLoading, setIsLoading] = useState(false);

  // SETTINGS
  const getSettings = async (userId, callback) => {
    setIsLoading(true);
    const res = await API.get(`/users/${userId}/settings`);
    if (res) {
      setIsLoading(false);
      callback(res);
    }
  };

  // QUESTIONS
  const updateQuestion = async (id, properties, callback) => {
    const body = {
      question: {
        ...properties,
      },
    };

    setIsLoading(true);

    const res = await API.put(`/questions/${id}`, body, {
      onError: errorFlash,
    });

    if (res) {
      setIsLoading(false);
      if (callback) callback(res);
    }
  };

  // TODO homogenise this as we're basically always updating activity questions
  // TODO bring timestamp in here too
  const updateActivityQuestion = async (questionId, stageSlug, properties, callback) => {
    const body = {
      question: {
        ...properties,
      },
    };

    setIsLoading(true);

    const res = await API.put(`/activity_stages/${stageSlug}/questions/${questionId}`, body, {
      onError: errorFlash,
    });

    if (res) {
      setIsLoading(false);
      if (callback) callback(res);
    }
  };

  // TODO rename this to deleteActivityQuestion
  const deleteActivityQuestion = async (questionId, stageSlug, callback) => {
    setIsLoading(true);

    const res = await API.destroy(
      `/activity_stages/${stageSlug}/questions/${questionId}`,
      {
        onError: errorFlash,
      },
    );

    if (res) {
      setIsLoading(false);
      if (callback) callback(res);
    }
  };

  return {
    isLoading,
    getSettings,
    updateQuestion,
    updateActivityQuestion,
    deleteActivityQuestion,
  };
};

export default useAPI;
