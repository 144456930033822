import React, { useContext, useEffect, useRef } from "react";
import AppContext from "contexts/AppContext";
import { useRouteMatch } from "react-router-dom";
import { Feed } from "components/shared";
import NotificationInFeed from "components/notifications/NotificationInFeed";
import { usePagination } from "hooks";
import { pluralize } from "utils";

const NotificationsFeed = ({
  notifications,
  setNotificationRead,
  selectedNotificationId,
  ...props
}) => {
  const { currentUser } = useContext(AppContext);
  const { path } = useRouteMatch();

  const currentNotificationRef = useRef(null);

  const NOTIFICATIONS_PER_PAGE = 10;

  const { page, setPageIndex, Pagination } = usePagination(notifications, {
    perPage: NOTIFICATIONS_PER_PAGE,
  });

  useEffect(() => {
    if (selectedNotificationId) {
      const selectedNotificationIndex = notifications.indexOf(
        notifications.find(
          (notification) => notification.id === selectedNotificationId,
        ),
      );

      if (!selectedNotificationIndex) setPageIndex(0);

      setPageIndex(
        Math.max(
          Math.floor(selectedNotificationIndex / NOTIFICATIONS_PER_PAGE),
          0,
        ),
      );
    }
  }, [notifications, selectedNotificationId]);

  return (
    <div className="h-screen flex flex-col">
      <div className="py-4 px-4 border-b border-gray-200">
        <h2 className="text-lg font-medium text-gray-900 flex items-center">
          Notifications
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          {pluralize(notifications.length, "notification")},{" "}
          {
            notifications.filter(
              (notification) => notification.status === "unread",
            ).length
          }{" "}
          unread
        </p>
      </div>
      <Feed className="flex-grow">
        {page.map((notification) => (
          <NotificationInFeed
            preview
            key={notification.id}
            notification={notification}
            ref={currentNotificationRef}
            selected={
              selectedNotificationId &&
              notification.id === selectedNotificationId
            }
            onClick={() => {
              if (notification.status === "unread")
                setNotificationRead(notification.id);
            }}
          />
        ))}
      </Feed>
      <Pagination small />
    </div>
  );
};

export default NotificationsFeed;
