import React from 'react'
import { Hint } from 'components/shared'
import { cn } from 'utils'

const Detail = ({ title, defaultValue, extra, children, ...props }) => {
  return(
    <div className={cn("sm:col-span-1", props.className)}>
      <dt className="text-sm font-medium text-gray-500 flex space-x-2">
        { title }
        { extra }
      </dt>
      <dd className="mt-1 text-sm text-gray-900">
        {
          children
          ? children
          : <Hint>{defaultValue}</Hint>
        }
      </dd>
    </div>
  )
}

Detail.defaultProps = {
  defaultValue: 'Not given'
}

export default Detail
