import React, { useContext } from "react";
import AppContext from "contexts/AppContext";
import { useHistory } from "react-router-dom";
import { useFlash } from "hooks";

export default function useAuthorization(roles) {
  const { currentUser } = useContext(AppContext);
  const history = useHistory();
  const { warningFlash } = useFlash();

  let authorized;

  if (!roles.find((role) => currentUser[role])) {
    warningFlash(
      "You lack the permissions to access that page.",
      "You tried to access a protected page",
    );
    history.replace("/");

    return false;
  } else {
    authorized = true;
  }

  return { authorized };
}

