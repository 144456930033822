import React, { useState, useContext } from "react";
import AppContext from "contexts/AppContext";
import { useParams, useHistory } from "react-router-dom";
import { Panel, Loading, RatingAlert, Button } from "components/shared";
import Questions from "components/questions/Questions";
import { useFetch, usePut, usePanel } from "hooks";
import { pure } from "recompose";

const ActivitySummary = () => {
  const { currentUser } = useContext(AppContext);

  const { slug } = useParams();
  const history = useHistory();

  const { show, hide } = usePanel("/activities/:slug/summary", "/");

  const [attempt, setAttempt] = useState(null);
  const [activity, setActivity] = useState(null);

  const handleReceiveAttempt = (attempts) => setAttempt(attempts[0]);

  useFetch(`activities/${slug}`, setActivity, [slug, show]);
  useFetch(
    `attempts?graph_activity_slug=${slug}&user_id=${currentUser.id}`,
    handleReceiveAttempt,
    [slug, show],
  );

  const afterComplete = () => {
    history.push(activity.afterPathOnComplete);
  };

  const { put, data } = usePut(afterComplete);

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      attempt: {
        status: "complete",
      },
    };

    put(`attempts/${attempt.id}`, body);
  };

  if (!activity || !attempt)
    return (
      <Panel size="md" show={show} onHide={hide}>
        <Panel.Header />
        <Panel.Body>
          <Loading />
        </Panel.Body>
        <Panel.Footer />
      </Panel>
    );

  const allQuestions = activity.stages.flatMap((stage) => stage.questions.map(q => ({ ...q, stage })));
  const questions = allQuestions.filter(
    (q, index) => q.userHasAnswered && allQuestions.map((q) => q.id).indexOf(q.id) === index,
  );

  return (
    <Panel size={"md"} show={show} onHide={hide}>
      <Panel.Header dismissible onHide={() => hide("/")} className="px-6 py-4">
        <div>
          <div className="flex items-start justify-between">
            <h3 className="font-semibold text-gray-700">
              {attempt.status === "complete"
                ? `Review "${activity.title}"`
                : `Complete "${activity.title}"`}
            </h3>
          </div>
          <p className="text-gray-400 text-sm">
            Revise questions you've answered.
          </p>
        </div>
      </Panel.Header>
      <Panel.Body className="@container">
        <RatingAlert attempt={attempt} />

        <h4 className="font-semibold text-gray-400 text-sm px-6 pt-4">
          Questions
        </h4>

        <Questions includeAiCoach questions={questions} user={currentUser} />
      </Panel.Body>
      <Panel.Footer className="space-x-2">
        <Button variant="secondary" onClick={() => hide(-1)}>
          Back
        </Button>

        {attempt.status !== "complete" && (
          <Button
            id="confirmCompleteActivityButton"
            disabled={data.isLoading}
            onClick={handleSubmit}
          >
            {data.isLoading ? "Submitting" : "Complete Activity"}
          </Button>
        )}
      </Panel.Footer>
    </Panel>
  );
};

export default pure(ActivitySummary);
