import React, { useState, useEffect } from "react";
import { Loading, Hint, Button } from "components/shared";
import PollHeader from "components/questions/PollHeader";
import Categories from "components/questions/Categories";
import Discussion from "components/coaching/Discussion";
import { usePost } from "hooks";
import { categoryTitles, newAllocationNote, pluralize } from "utils";
import { pure } from "recompose";

const PollQuestion = ({
  id,
  entity,
  categories,
  discussion,
  constraints,
  onSubmitResponse,
  extraResponseParams = {},
  ...props
}) => {
  const [checkedCategories, setCheckedCategories] = useState([]);

  const userHasPolled = !!discussion?.allocation;
  const userHasResponded = !discussion?.responses?.length === 0;

  useEffect(() => {
    if (discussion) {
      setCheckedCategories(discussion.allocation?.categories || []);
    }
  }, [discussion]);

  const onCheckCategory = (id) => {
    checkedCategories.map((c) => c.id).includes(id)
      ? setCheckedCategories(checkedCategories.filter((c) => c.id !== id))
      : setCheckedCategories([
          ...checkedCategories,
          categories.find((c) => c.id === id),
        ]);
  };

  const { post, data } = usePost(props.onCreateAllocation);

  const createAllocation = () => {
    const body = {
      discussion_id: discussion?.id,
      allocation: {
        entity: {
          id: entity.id,
        },
        categories: checkedCategories.map((c) => c.id),
      },
    };

    post(`/allocations`, body);
  };

  return (
    <>
      <PollHeader {...entity} />

      <Categories
        categories={categories}
        checkedCategories={checkedCategories}
        question={{ id }}
        allowChange={!userHasPolled}
        onCheck={userHasPolled ? null : onCheckCategory}
        showPercentage={userHasPolled}
        label={
          userHasPolled
            ? `How did other users vote?`
            : `What do you vote? (click as many as you like)`
        }
        advanceButton={
          !userHasPolled && (
            <Button
              id={"selectCategoriesButton"}
              onClick={createAllocation}
              disabled={data.isLoading}
            >
              {data.isLoading
                ? "Submitting..."
                : `Vote for ${pluralize(checkedCategories.length, "category")}`}
            </Button>
          )
        }
      />

      {userHasPolled && (
        <div className="max-w-prose w-full">
          <Discussion
            id={discussion?.id}
            onSubmitResponse={onSubmitResponse}
            extraResponseParams={extraResponseParams}
            constraints={constraints}
            hint={newAllocationNote(
              entity,
              checkedCategories,
              "Why did you allocate ",
              "?",
            )}
            placeholder={newAllocationNote(
              entity,
              checkedCategories,
              "My reasoning for allocating ",
              " is...",
            )}
          />
        </div>
      )}
    </>
  );
};

export default pure(PollQuestion);
