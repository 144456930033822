import React, { useContext } from "react";
import Question from "components/questions/Question";
import { Hint } from "components/shared";
import AppContext from "contexts/AppContext";
import { pure } from "recompose";

import { objStrToJson } from "utils";

const QuestionCustomBlock = ({ blockValue }) => {
  const { currentUser } = useContext(AppContext);
  const text = blockValue.properties.title[0][0];
  const stage = blockValue.stage;
  const [keyword, ...params] = text.split(" ");

  try {
    const paramsObj = JSON.parse(objStrToJson(params.join(" ")));
    return (
      <Question
        {...paramsObj}
        user={currentUser}
        stage={stage}
        includeOtherAnswers
        includeAiCoach
      />
    );
  } catch (err) {
    console.error(err);
    return (
      <Hint>
        Tried to load custom question block but failed. Given text is: {text}
      </Hint>
    );
  }
};

export default pure(QuestionCustomBlock);
