import React, { useContext } from "react";
import AppContext from "contexts/AppContext";
import { Hint, Button, PlaceholderText } from "components/shared";
import TranscriptionStatus from "components/activities/InteractiveVideo/TranscriptionStatus";
import Questions from "components/questions/Questions";
import Caption from "components/activities/InteractiveVideo/Caption";
import { cn } from "utils";

const Transcript = ({
  user,
  stage,
  captions,
  questions,
  currentQuestion,
  playedSeconds,
  transcribeJob,
  ...props
}) => {
  const { editorMode } = useContext(AppContext);

  if (editorMode && transcribeJob && props.isLoading)
    return (
      <TranscriptionStatus
        transcribeJob={transcribeJob}
        onCancelTranscribeJob={props.onCancelTranscribeJob}
        onRetryTranscribeJob={props.onGenerateTranscript}
      />
    );

  const transcriptWithQuestions = [
    ...(captions ?? []),
    ...(questions ?? []).map((question) => ({
      ...question,
      transcriptBlockType: "question",
    })),
  ].sort((a, b) => a.timestamp - b.timestamp);

  if (props.isLoading) {
    return (
      <div className="py-5 px-6 space-y-4">
        <Hint>Fetching transcription job status...</Hint>
        <PlaceholderText lines={12} size="md" />
      </div>
    );
  }

  return (
    <div className="py-5">
      {!captions && (
        <div className="flex items-center justify-between space-x-4 px-6 mb-3">
          <Hint small>No transcript available for this video.</Hint>
          {editorMode && (
            <Button type="pill" onClick={props.onGenerateTranscript}>
              Generate
            </Button>
          )}
        </div>
      )}
      {transcriptWithQuestions.map((captionOrQuestion, index) => {
        if (captionOrQuestion.transcriptBlockType === "caption")
          return (
            <Caption
              key={`caption-${captionOrQuestion.id}`}
              {...captionOrQuestion}
              className={cn(index === 0 && "-mt-2")}
              stage={stage}
              isCurrent={
                playedSeconds > captionOrQuestion.start &&
                playedSeconds < captionOrQuestion.end
              }
              playedSeconds={playedSeconds}
              onAddQuestion={props.onAddQuestion}
            />
          );

        if (captionOrQuestion.transcriptBlockType === "question")
          return (
            <Questions
              small
              allowDeleteQuestion
              includeAiCoach
              includeOtherAnswers
              key={`questions-${captionOrQuestion.id}`}
              questions={[captionOrQuestion]}
              user={user}
              currentQuestion={currentQuestion}
              extraResponseParams={props.extraResponseParams}
              onComplete={props.onCompleteQuestion}
              onStartUpdate={props.onStartUpdate}
              onUpdate={props.onUpdate}
              onErrorUpdate={props.onErrorUpdate}
              onUpdateTimestamp={props.onUpdateTimestamp}
              onDelete={props.onDelete}
              stage={stage}
              onCompleteText="Continue video"
            />
          );
      })}
    </div>
  );
};

Transcript.defaultProps = {
  captions: [],
  questions: [],
};

export default Transcript;
