import React, { Fragment, useState } from "react";
import { Alert, Spinner, Button } from "components/shared";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useAiCoach, useIsMounted, usePut } from "hooks";
import { stripTags, interpolate, classNames } from "utils";
import moment from "moment";

const AiCertificationHelper = ({ user, initialText, ...props }) => {
  const isMounted = useIsMounted();

  const { generateCertificationOverview, loading } = useAiCoach();

  const [text, setText] = useState(initialText);
  const [updatedAt, setUpdatedAt] = useState(
    user.certificationSummaryUpdatedAt,
  );

  const { put: updateUser } = usePut();

  const generateText = async () => {
    const { response } = await generateCertificationOverview(user);

    if (isMounted.current) {
      const now = new Date();

      setText(response);
      setUpdatedAt(now);
      updateUser(`users/${user.id}`, {
        user: {
          learner_certification_summary: response,
          learner_certification_summary_updated_at: now,
        },
      });
    }
  };

  if (loading)
    return (
      <Alert className={props.className} type="loading">
        AI Coaching...
      </Alert>
    );

  if (props.onLoad) props.onLoad();

  return (
    <Alert className={props.className} type="bot">
      {text ? (
        <>
          <div className="flex justify-between space-x-2">
            <div className="font-semibold">AI Certification overview</div>
            <Button
              type="pill"
              className="font-semibold cursor-pointer no-underline hover:opacity-80 text-xs"
              onClick={() => generateText()}
              Icon={ArrowPathIcon}
            >
              Regenerate
            </Button>
          </div>
          <div className="text-xs">Generated {moment(updatedAt).fromNow()}</div>
          <div className="pt-1">{text}</div>
        </>
      ) : (
        <a
          className="font-semibold cursor-pointer no-underline hover:opacity-80"
          onClick={() => generateText()}
        >
          Generate certification overview
        </a>
      )}
      <div className="mt-2 text-xs opacity-70 z-10">
        I'm a bot that judges whether learners are ready to be certified. Feel
        free to make use of my suggestions!
      </div>
    </Alert>
  );
};

export default AiCertificationHelper;
