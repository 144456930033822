import moment from "moment";

export const welcome = (to, from) =>
  `Hi ${to.firstName ? to.firstName : "there"},

I'm ${
  from.firstName
}, a coach here on TechMap. I just wanted to say 🎉 welcome to TechMap! We're excited to have you on board.

We're here to help you succeed – if you're finding something tricky to understand, you can reach out to us.

Feel free to reach out to me any time.

Yours,

${from.firstName}`.trim();

export const helpStarting = (to, from) =>
  `Hi ${to.firstName ? to.firstName : "there"},

I'm ${
    from.firstName
  }, one of the TechMap coaches. I noticed that you signed up ${moment(
    to.createdAt,
  ).fromNow()} but you haven't gotten started yet.

Anything I can do to help?

Yours,

${from.firstName}
`.trim();

export const templates = {
  welcome,
  helpStarting,
};

export const emailFromTemplate = (type, { to, from }) => {
  return templates[type](to, from);
};
