import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import AppContext from "contexts/AppContext";
import GraphContext from "contexts/GraphContext";
import QueryContext from "contexts/QueryContext";

import { Navigation } from "components/shared";
import {
  useQueryParams,
  StringParam,
  DateParam,
  NumberParam,
  BooleanParam,
  ArrayParam,
} from "use-query-params";

import DiscussionsPage from "components/pages/DiscussionsPage";
import GraphPage from "components/graph/GraphPage";
import FlowGraphPage from 'components/pages/FlowGraphPage';
import NotificationsPage from "components/pages/NotificationsPage";
import SettingsPage from "components/pages/SettingsPage";
import LiveSessionsPage from "components/pages/LiveSessionsPage";
import CertificationPage from "components/pages/CertificationPage";
import ManagePage from "components/pages/ManagePage";

import UpfrontPaymentModal from "components/payments/UpfrontPaymentModal";
import OnboardingModal from "components/onboarding/OnboardingModal";
import StreakModal from "components/onboarding/StreakModal";
import IntroModal from "components/onboarding/IntroModal";
import UpgradeModal from "components/upgrading/UpgradeModal";
import PaymentSuccessModal from "components/upgrading/PaymentSuccessModal";
import LiveSessionSignupModal from "components/live-sessions/LiveSessionSignupModal";
import AddFeatureAnnouncementModal from "components/admin/AddFeatureAnnouncementModal";

import { useNotifications, useFlash } from "hooks";

import { pure } from "recompose";

const Main = (props) => {
  const { currentUser } = useContext(AppContext);

  const queryConfiguration = {
    start: DateParam,
    end: DateParam,
    cohort_id: NumberParam,
    organization_id: NumberParam,
    getStarted: BooleanParam,
    onboardingStage: NumberParam,
    completeOnboarding: BooleanParam,
    upgrade: BooleanParam,
    "payment-point": StringParam,
    destroyUserId: NumberParam,
    conversationOptionId: NumberParam,
    user: NumberParam,
    addUser: BooleanParam,
    addOrganization: BooleanParam,
    addCohort: BooleanParam,
    manageLicenses: BooleanParam,
    liveSessionSignupId: NumberParam,
    addLiveSession: BooleanParam,
    addFeatureAnnouncement: BooleanParam,
    certifyUserId: NumberParam,
    emailUsers: ArrayParam,
    liveSessionStatus: StringParam,
    addCourse: BooleanParam,
    selectCourse: BooleanParam,
    addStageFromLibrary: BooleanParam,
  };

  const [query, setQuery] = useQueryParams(queryConfiguration);

  const location = useLocation();
  const history = useHistory();

  const [panelShown, setPanelShown] = useState(null);
  const [graphContext, setGraphContext] = useState(null);

  const { notifications, setNotificationRead, notificationsConnected } =
    useNotifications();
  const { showFlash } = useFlash();

  useEffect(() => {
    if (props.flash) {
      Object.keys(props.flash).forEach((key) =>
        showFlash(null, props.flash[key], { type: key }),
      );
    }
  }, [props.flash]);

  useEffect(() => {
    if (!currentUser.selectedPlanType && !query.getStarted) {
      setQuery({ ...query, getStarted: true });
    } else if (
      !(currentUser.firstName && currentUser.lastName) &&
      query.onboardingStage !== 1 &&
      !query.getStarted
    ) {
      setQuery({ ...query, onboardingStage: 1 });
    }
  }, [location]);

  return (
    <QueryContext.Provider value={{ query, setQuery, queryConfiguration }}>
      <GraphContext.Provider value={{ graphContext, setGraphContext }}>
        <div className="flex h-full">
          <Navigation
            hideOn={["start", "onboarding"]}
            {...{ notifications, setNotificationRead }}
          />
          <main className="flex-1 overflow-hidden">
            <Switch>
              <Route path="/">
                {currentUser.featureFlagsEnabled.find(
                  ({ slug }) => slug === "graph-v2",
                ) ? (
                  <FlowGraphPage />
                ) : (
                  <GraphPage />
                )}
              </Route>
            </Switch>

            <Route path="/discussions">
              <DiscussionsPage />
            </Route>

            <Route path="/notifications">
              <NotificationsPage
                {...{
                  notifications,
                  setNotificationRead,
                  notificationsConnected,
                }}
              />
            </Route>

            <Route path="/settings" exact>
              <SettingsPage />
            </Route>

            <Route path="/live" exact>
              <LiveSessionsPage />
            </Route>

            <Route path="/certification" exact>
              <CertificationPage />
            </Route>

            <Route path="/manage">
              <ManagePage />
            </Route>

            {/* Modals */}
            <UpfrontPaymentModal onClose={() => history.push("/")} />

            <OnboardingModal />

            <IntroModal />

            <StreakModal />

            {/*
           <SetOnboardingGoalOverlay
            open={query.get('onboardingStage') === '3'}
            tourSlug={'setting-goals'}
            onClose={() => history.push('/')} />
          */}

            <UpgradeModal />

            <PaymentSuccessModal />

            <LiveSessionSignupModal />

            {currentUser.isAdmin && <AddFeatureAnnouncementModal />}
          </main>
        </div>
      </GraphContext.Provider>
    </QueryContext.Provider>
  );
};

// Main.whyDidYouRender = true

export default pure(Main);
