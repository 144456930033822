import React, { useMemo, useEffect, useState, useContext } from "react";
import AppContext from "contexts/AppContext";
import GraphContext from "contexts/GraphContext";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
  AvatarList,
  Avatar,
  Loading,
  Panel,
  Button,
  ContentEditable,
  Pill,
  Tooltip,
} from "components/shared";
import { H3, Hint } from "components/typography";
import { cn, pluralize, toSentence } from "utils";
import {
  WrenchIcon,
  LockClosedIcon,
  LinkIcon,
  TrophyIcon,
} from "@heroicons/react/20/solid";
import ActivityPreviewDescription from "components/activityPreviews/ActivityPreviewDescription";
import TermsBar from "components/activityPreviews/TermsBar";
import ActivityMetricsBar from "components/activityPreviews/ActivityMetricsBar";
import TimeEstimate from "components/activityPreviews/TimeEstimate";
import confetti from "canvas-confetti";
import {
  useGraphApi,
  useFetch,
  usePost,
  useDestroy,
  usePanel,
  useQuery,
  useFlash,
} from "hooks";

const ActivityPreview = React.forwardRef(
  ({ currentGoal, setCurrentGoal, onSetAttempt, show, ...props }, ref) => {
    const { API, currentUser, freeTrialActivityLimit, editorMode } =
      useContext(AppContext);
    const { graphContext } = useContext(GraphContext);
    let match = useRouteMatch("/activities/:slug/preview");
    const slug = match?.params.slug;
    const history = useHistory();
    const { addQuery } = useQuery();
    const { errorFlash } = useFlash();

    const hide = (path = "/") => history.push(path);

    const [activity, setActivity] = useState(null);
    const [attempts, setAttempts] = useState([]);

    const { fetch, data: activityLoadingData } = useFetch(
      `activities/${slug}/preview`,
      setActivity,
      [slug, show],
    );
    useFetch(`attempts?user_id=${currentUser.id}`, setAttempts, [slug, show]);

    const attempt = attempts.find((attempt) => attempt.activity.slug === slug);
    const completedAttempts = useMemo(
      () => attempts.filter((attempt) => attempt.status === "complete"),
      [attempts],
    );

    // BEGIN making changes
    const ACTIVITY_PROPERTIES = {
      title: "title",
      slug: "slug",
      shortDescription: "short description",
      timeEstimate: "time estimate",
      descriptionBlocks: "long description",
    };

    const [isSaving, setIsSaving] = useState(false);

    const [changes, setChanges] = useState({});
    const handleChange = (change) => {
      const newChanges = { ...changes, ...change };
      setChanges(newChanges);
    };

    const saveChanges = async (e) => {
      e.preventDefault();

      setIsSaving(true);

      const properties = {
        ...(changes.title && { title: changes.title }),
        ...(changes.slug && { slug: changes.slug }),
        ...(changes.shortDescription && {
          short_description: changes.shortDescription,
        }),
        ...(changes.timeEstimate && { time_estimate: changes.timeEstimate }),
        ...(changes.descriptionBlocks && {
          description_blocks: changes.descriptionBlocks,
        }),
      };

      const response = await API.put(
        `/activities/${slug}`,
        {
          activity: properties,
        },
        { onError: (message) => { errorFlash(message); setIsSaving(false); } },
      );
      if (response) {
        setChanges({});
        setIsSaving(false);
        if (props.onChange) props.onChange();

        if (response.slug && response.slug !== slug) {
          history.push(`/activities/${response.slug}/preview`);
        } else {
          fetch();
        }
      }
    };

    const unsavedChanges = Object.entries(changes).filter(([key, value]) => {
      if (changes[key] === activity[key]) return false;
      if (
        key === "descriptionBlocks" &&
        (changes[key] === activity.descriptionBlocks ||
          JSON.stringify(changes[key]) === activity.descriptionBlocks)
      ) {
        return false;
      }

      return true;
    });

    // END making changes

    useEffect(() => {
      if (graphContext) {
        graphContext.removeAllHighlights();

        if (currentGoal)
          graphContext.highlightShortestPathTo(
            graphContext.getElementById(currentGoal.id),
          );
        if (activity)
          graphContext.highlight(graphContext.getElementById(activity.id));
      }
    }, [graphContext, activity]);

    // BEGIN setting goals
    const onSetUserGoal = (activity) => {
      if (activity?.id) confetti();
      setCurrentGoal(activity);
      hide();
    };

    const { post: postUserGoal } = usePost(onSetUserGoal);
    const { destroy: destroyUserGoal } = useDestroy(onSetUserGoal);

    const handleSetGoal = async (activity) => {
      if (!activity) return destroyUserGoal(`users/${currentUser.id}/goals`);

      const body = { goal: { graph_activity_id: activity.id } };

      postUserGoal(`users/${currentUser.id}/goals`, body);
    };
    // END setting goals

    const { findOrCreateAttempt } = useGraphApi();

    const openActivity = () =>
      findOrCreateAttempt(activity, (attempt) => {
        props.onOpenActivity();
        if (activity.url) {
          window.open(activity.url);
          history.go(0);
        } else {
          hide(`/activities/${activity.slug}`);
        }
      });

    if (!activity || activityLoadingData.isLoading)
      return (
        <Panel size="md" show={show} onHide={hide} ref={ref}>
          <Panel.Header />
          <Panel.Body>
            <Loading />
          </Panel.Body>
          <Panel.Footer />
        </Panel>
      );

    return (
      <Panel size="md" show={show} ref={ref}>
        <Panel.Header dismissible onHide={hide} className="px-6 py-4">
          <div>
            <div className="flex items-start">
              {editorMode ? (
                <H3>
                  <ContentEditable
                    interactive
                    html={changes.title ?? activity.title}
                    onChange={(e) => handleChange({ title: e.target.value })}
                  />
                </H3>
              ) : (
                <H3>{activity.title}</H3>
              )}
              {editorMode ? (
                <TimeEstimate
                  editable
                  onChange={(e) =>
                    handleChange({ timeEstimate: e.target.value })
                  }
                  className="ml-2 mt-1"
                  time={changes.timeEstimate ?? activity.timeEstimate ?? "0"}
                />
              ) : (
                activity.timeEstimate && (
                  <TimeEstimate
                    className="ml-2 mt-1"
                    time={activity.timeEstimate}
                  />
                )
              )}
            </div>

            {editorMode && (
              <Hint className="max-w-[320px]">
                <ContentEditable
                  className="truncate"
                  interactive
                  html={
                    changes.slug ?? activity.slug ?? "untitled-activity-123"
                  }
                  onChange={(e) => handleChange({ slug: e.target.value })}
                />
              </Hint>
            )}

            {editorMode ? (
              <Hint>
                <ContentEditable
                  interactive
                  html={
                    changes.shortDescription ??
                    activity.shortDescription ??
                    "Enter a short description."
                  }
                  onChange={(e) =>
                    handleChange({ shortDescription: e.target.value })
                  }
                />
              </Hint>
            ) : (
              <Hint>{activity.shortDescription}</Hint>
            )}
          </div>
        </Panel.Header>

        <Panel.Body>
          {(currentUser.isAdmin || currentUser.isCourseDesigner) && (
            <ActivityMetricsBar
              className="px-6 py-4 border-b border-gray-200"
              activity={activity}
            />
          )}
          {((!activity.githubProjectSlug &&
            activity.terms &&
            activity.terms.length > 0) ||
            editorMode) && (
            <TermsBar
              activity={activity}
              editable={editorMode}
              className="px-6 py-4 border-b border-gray-200"
              terms={changes.terms ?? activity.terms}
            />
          )}
          <ActivityPreviewDescription
            className="px-6 py-4"
            activity={activity}
          />
        </Panel.Body>

        <Panel.Footer className="justify-between">
          {editorMode ? (
            unsavedChanges.length === 0 ? (
              <div className="h-full flex items-center">
                <Pill color="gray">0 changed properties</Pill>
              </div>
            ) : (
              <Tooltip
                text={`Changed properties: ${toSentence(
                  unsavedChanges.map(([key, _]) => ACTIVITY_PROPERTIES[key]),
                )}`}
                className="h-full flex items-center"
              >
                <Pill color="yellow">
                  {pluralize(unsavedChanges.length, "changed property")}
                </Pill>
              </Tooltip>
            )
          ) : activity.currentUsers.length > 0 ? (
            <AvatarList
              size="lg"
              limit={4}
              users={activity.currentUsers}
              tooltip={true}
            />
          ) : (
            <Hint className="flex items-center">No current learners.</Hint>
          )}
          <div className="flex space-x-2">
            {editorMode && (
              <Button
                disabled={isSaving || unsavedChanges.length === 0}
                onClick={saveChanges}
              >
                {isSaving ? "Saving..." : "Save changes"}
              </Button>
            )}
            {editorMode ? (
              <Button onClick={openActivity}>Edit activity</Button>
            ) : activity.construction ? (
              <Button disabled Icon={WrenchIcon}>
                Under construction
              </Button>
            ) : activity.permalocked || !activity.unlocked ? (
              <Button disabled Icon={LockClosedIcon}>
                Locked
              </Button>
            ) : attempt?.status === "complete" ? (
              <Button
                onClick={openActivity}
                Icon={activity.url ? LinkIcon : null}
              >
                View
              </Button>
            ) : attempt?.status === "in_progress" ? (
              <Button
                onClick={openActivity}
                Icon={activity.url ? LinkIcon : null}
              >
                Resume
              </Button>
            ) : !currentUser.license &&
              completedAttempts.length >= parseInt(freeTrialActivityLimit) ? (
              <Button
                variant="secondary"
                onClick={() => addQuery("upgrade", true)}
              >
                Upgrade to start
              </Button>
            ) : (
              <Button
                onClick={openActivity}
                Icon={activity.url ? LinkIcon : null}
              >
                Start
              </Button>
            )}
            {editorMode ? null : !activity.unlocked ? (
              currentGoal?.id === activity.id ? (
                <Button onClick={() => handleSetGoal(null)} Icon={TrophyIcon}>
                  Unset goal
                </Button>
              ) : (
                <Button
                  onClick={() => handleSetGoal(activity)}
                  Icon={TrophyIcon}
                >
                  Set goal
                </Button>
              )
            ) : !attempt ? null : attempt.status === "complete" ? (
              <Button
                variant="secondary"
                onClick={() => hide(`/activities/${slug}/summary`)}
              >
                Summary
              </Button>
            ) : (activity.url && attempt.status === "in_progress") ||
              (activity.githubProjectSlug &&
                attempt.status === "in_progress") ? (
              <Button
                variant="secondary"
                onClick={() => hide(`/activities/${slug}/summary`)}
              >
                Complete
              </Button>
            ) : null}
          </div>
        </Panel.Footer>
      </Panel>
    );
  },
);

export default ActivityPreview;
