import React from "react";
import { Modal } from "components/shared";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import { useQuery } from "hooks";
import AddLiveSession from 'components/live-sessions/AddLiveSession'

const AddLiveSessionModal = () => {
  const { watchQuery, deleteQuery } = useQuery();

  const open = watchQuery('addLiveSession')
  const onClose = () => deleteQuery('addLiveSession')

  return (
    <Modal
      size='md'
      dismissible
      open={!!open}
      onClose={onClose}
      Icon={CalendarDaysIcon}
      title="Schedule a live session"
    >
      <AddLiveSession onClose={onClose} />
    </Modal>
  );
};

export default AddLiveSessionModal;
