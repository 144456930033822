import React, { Fragment, useContext, useMemo } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import AppContext from "contexts/AppContext";
import UserProfileHeader from "components/users/UserProfileHeader";
import UserProfile from "components/users/UserProfile";
import UserNotes from "components/users/UserNotes";
import Timeline from "components/coaching/Timeline";
import UserTranscript from "components/transcripts/UserTranscript";
import CertifyUserModal from "components/users/CertifyUserModal";
import { Tabs, DotBadge } from "components/shared";

const User = ({ user, cohort, ...props }) => {
  const { currentUser } = useContext(AppContext);

  const unviewedNotes = useMemo(() => {
    if (!user.notes) return [];

    return user.notes.filter(
      (note) =>
        !note.viewers.map((viewer) => viewer.id).includes(currentUser.id),
    );
  }, [user]);

  return (
    <main className="relative z-0 flex-1 overflow-y-auto overflow-x-hidden focus:outline-none">
      <article className="relative">
        <UserProfileHeader user={user} onChange={props.onChange} />

        <Tabs>
          <Tabs.Tab header="Profile" className="px-8 py-4">
            <UserProfile
              user={user}
              cohort={cohort}
              onChange={props.onChange}
            />
          </Tabs.Tab>

          {(currentUser.isTrainer || currentUser.isAdmin || currentUser.isManager) && (
            <Tabs.Tab header="Activity" className="px-8 py-4">
              <Timeline reverse user={user} />
            </Tabs.Tab>
          )}

          {(currentUser.isTrainer || currentUser.isAdmin || currentUser.isManager) && (
            <Tabs.Tab header="Transcript" className="w-full pt-4">
              <UserTranscript
                user={user}
                showSidebar={false}
                showNewDiscussionsLink={false}
                onChangeCertification={props.onChange}
              />
            </Tabs.Tab>
          )}

          {(currentUser.isTrainer || currentUser.isAdmin) && (
            <Tabs.Tab
              header={
                <div className="relative">
                  <span>Notes</span>
                  {unviewedNotes.length > 0 && (
                    <DotBadge
                      className="left-full ml-0.5"
                      value={unviewedNotes.length}
                    />
                  )}
                </div>
              }
              className="px-8 py-4"
            >
              <UserNotes
                user={user}
                onAddNote={props.onChange}
                onViewNote={props.onViewNote}
              />
            </Tabs.Tab>
          )}
        </Tabs>
      </article>
      {(currentUser.isAdmin || currentUser.isTrainer) && (
        <CertifyUserModal onChange={props.onChange} />
      )}
    </main>
  );
};

export default User;
