import React, {
  forwardRef,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import AppContext from "contexts/AppContext";
import Message from "components/bloom/Message";
import Attachment from "components/bloom/Attachment";
import { motion } from "framer-motion";
import { Button } from "components/shared";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";

const Chat = forwardRef(({ messages, attachments, user, ...props }, ref) => {
  const { editorMode } = useContext(AppContext);
  const [text, setText] = useState("");

  const canSubmit = props.canSubmit && text && text.length > 0;

  const onSubmit = (e) => {
    e.preventDefault();

    if (!canSubmit) return;

    props.onSubmit(text);

    ref.current.value = null;
    setText(null);
  };

  const messagesContainerRef = useRef(null);

  useEffect(() => {
    messagesContainerRef.current.scrollTop =
      messagesContainerRef.current.scrollHeight;
  }, [messages]);

  return (
    <motion.div
      initial={{ y: 20 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.35, ease: [0.075, 0.82, 0.165, 1] }}
      className="flex flex-col shadow-md bg-white border-l border-r border-gray-200  h-full overflow-hidden"
    >
      <div className="flex items-center justify-between border-b border-gray-200 px-4 h-12 bg-gray-50">
        <p className="text-sm font-semibold leading-6">
          Chatting with {user.name}
        </p>

        {editorMode && (
          <Button size="xs" variant="secondary" onClick={props.onStartEdit}>
            Edit
          </Button>
        )}
      </div>
      <div
        ref={messagesContainerRef}
        className="py-6 px-4 overflow-y-auto overscroll-contain grow"
      >
        {messages.map((message) => (
          <Message
            key={`message-${message.id}`}
            id={`message-${message.id}`}
            message={message}
          />
        ))}
      </div>

      <form
        className="w-full h-16 flex items-center justify-center bg-gray-100 px-4 shadow space-x-4 shrink-0 border-t border-gray-200"
        onSubmit={onSubmit}
      >
        <div className="w-full bg-white rounded-sm px-4 py-2 flex space-x-2">
          <input
            ref={ref}
            onChange={(e) => setText(e.target.value)}
            name="message"
            disabled={!props.canSubmit}
            className="w-full focus:outline-none border-none focus:ring-0 p-0"
            type="text"
            placeholder={
              props.isRespondingToUser && user.name
                ? `Waiting for ${user.name} to finish writing...`
                : "Type your reply"
            }
          />
          {attachments.map((option, i) => (
            <Attachment
              key={`attachment-${i}`}
              onUnattach={() => props.onUnattach(option)}
              color={option.color}
            >
              {option.title}
            </Attachment>
          ))}
        </div>

        <Button disabled={!canSubmit} Icon={PaperAirplaneIcon} type="submit">
          {messages.length > 1 ? null : "Send"}
        </Button>

        {props.extraButton}
      </form>
    </motion.div>
  );
});

export default Chat;
