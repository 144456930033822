import React, { useContext } from "react";
import AppContext from "contexts/AppContext";
import { Button, RemoteSelectInput } from "components/shared";
import { useHistory } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useFlash } from "hooks";
import { cn } from "utils";

const AddQuestion = ({ stage, ...props }) => {
  const { API } = useContext(AppContext);
  const history = useHistory();
  const { errorFlash } = useFlash();

  const addQuestion = async () => {
    const body = {
      question: {
        type: "regular",
        title: "Untitled Question",
        text: "Enter your question here.",
        ...props.extraParams,
      },
      activity_stage: {
        id: stage.id,
      },
    };

    const response = await API.post("/questions", body, {
      onError: errorFlash,
    });

    if (response) {
      if (props.onChange) props.onChange();
    }
  };

  const addQuestionFromLibrary = async (id) => {
    const body = {
      activity_question: {
        question_id: id,
        activity_stage_id: stage.id,
      },
    };

    const response = await API.post("/activity_questions", body, {
      onError: errorFlash,
    });

    if (response) {
      if (props.onChange) props.onChange();
    }
  };

  return (
    <div
      className={cn(
        "w-full flex items-center justify-center px-4 py-4 space-x-4",
        props.stack && "flex-col space-y-4"
      )}
    >
      <Button onClick={addQuestion} Icon={PlusIcon}>
        Add a new question
      </Button>
      <span className="text-gray-400">or</span>
      <RemoteSelectInput
        selectClassName="w-72"
        title="Question"
        defaultOption="Select a question"
        disableDefaultOption
        fetchPath="questions"
        optionValue={"id"}
        optionLabel={"title"}
        optionPrelabel={"stage_count"}
        sortBy={"stage_count"}
        onChange={(e) => addQuestionFromLibrary(e.target.value)}
      />
    </div>
  );
};

export default AddQuestion;
