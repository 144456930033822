import React from "react";
import { Avatar } from "components/shared";
import { cn } from "utils";

const AvatarList = ({ users, size = "md", ...props }) => {
  const SIZE_TO_AVATAR_SIZES = {
    sm: "tiny",
    md: "small",
    lg: "medium",
  };

  const SIZE_TO_SPACING = {
    sm: "-space-x-1",
    md: "-space-x-2",
    lg: "-space-x-2",
  };

  const distinctUsers = [
    ...new Map(users.map((user) => [user["id"], user])).values(),
  ];
  const limitedUsers = props.limit
    ? distinctUsers.slice(0, props.limit)
    : distinctUsers;

  return (
    <div className={cn("flex overflow-hidden", SIZE_TO_SPACING[size])}>
      {limitedUsers.map((user) => (
        <Avatar
          key={user.id}
          className="ring-2 ring-white"
          src={user.avatar}
          size={SIZE_TO_AVATAR_SIZES[size]}
          {...props}
        />
      ))}
      {props.limit && distinctUsers.length > props.limit && (
        <Avatar
          key="last"
          type="text"
          text={`+${distinctUsers.length - props.limit}`}
          className="ring-2 ring-white"
          size={SIZE_TO_AVATAR_SIZES[size]}
          {...props}
        />
      )}
    </div>
  );
};

export default AvatarList;
