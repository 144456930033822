import React, { useContext, useState, useCallback } from "react";
import AppContext from "contexts/AppContext";
import { useDropzone } from "react-dropzone";
import { Hint } from "components/typography";
import { ProgressBar, Loading } from "components/shared";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { useFlash } from "hooks";
import { cn } from "utils";

const VideoUploader = ({ path, params, ...props }) => {
  const { API } = useContext(AppContext);
  const { successFlash, errorFlash } = useFlash();
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onDrop = useCallback(async (acceptedFiles) => {
    setIsUploading(true);
    const file = acceptedFiles[0];
    const response = API.upload(path, file, 'video[file]', {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        setProgress(percentCompleted);
      },
      onSuccess: (response) => {
        setIsUploading(false);
        successFlash('Video uploaded successfully');
        if(props.onSuccess) props.onSuccess(response);
      },
      onError: (errors) => {
        errorFlash(errors);
        setIsUploading(false);
      },
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center",
        props.className,
      )}
      {...getRootProps()}
    >
      <div className="flex flex-col items-center justify-center space-y-4 border border-dashed border-gray-200 border-2 p-8 cursor-pointer rounded-lg hover:border-gray-400 group transition-all duration-200">
        <input {...getInputProps()} />
        <ArrowUpTrayIcon className="h-8 w-8 text-gray-300 group-hover:text-gray-400 transition-all duration-200" />
        {isUploading ? (
          <>
            <Hint tiny className="mb-1" center>
              {progress < 100 ? (
                <>Uploading ({progress}%)</>
              ) : (
                <>Finalising upload...</>
              )}
            </Hint>
            <ProgressBar className="w-72" progress={progress} />
          </>
        ) : (
          <Hint center>
            Drop a video or{" "}
            <span className="text-blue-400 cursor-pointer">click here</span> to
            upload.
          </Hint>
        )}
      </div>
    </div>
  );
};

export default VideoUploader;
