import React, { useState } from "react";
import { Loading } from "components/shared";
import { useFetch, useFlash } from "hooks";
import { CustomNotionRenderer } from "components/notion";
import { parsePageId } from "utils";
import { pure } from "recompose";

const NotionActivity = ({ user, stage, ...props }) => {
  const [blockMap, setBlockMap] = useState(null);

  const { errorFlash } = useFlash();

  const notionId = parsePageId(stage.notionUrl, { uuid: false });

  const { loadingOrError } = useFetch(
    `notion/${notionId}`,
    setBlockMap,
    [stage],
    {
      onError: () =>
        errorFlash(
          "There was an error fetching the article. Please try again later.",
        ),
      onlyIf: notionId,
    },
  );

  if (!notionId)
    return errorFlash("There was an error parsing the Notion page ID.");

  return (
    <div className="h-full overflow-auto pb-8">
      {blockMap ? (
        <CustomNotionRenderer user={user} blockMap={blockMap} stage={stage} />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default pure(NotionActivity);
