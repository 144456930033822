import React, { useContext, useState, useEffect, useMemo, useRef } from "react";
import AppContext from "contexts/AppContext";
import DirectoryFilters from "components/users/DirectoryFilters";
import UserInList from "components/users/UserInList";
import { useFilters } from "hooks";
import {
  SelectInput,
  Loading,
  SearchBar,
  Checkbox,
  Spinner,
} from "components/shared";
import {
  cn,
  pluralize,
  isInViewport,
  isBelowViewport,
  removeDiacritics,
} from "utils";

const Directory = ({ users, selectedUser, selectedUsers, ...props }) => {
  const { currentUser } = useContext(AppContext);

  const { filters, handleFilterChange } = useFilters({});

  const currentUserRef = useRef(null);

  useEffect(() => {
    if (selectedUser) {
      const element = currentUserRef.current;
      if (element && !isInViewport(element)) {
        element.scrollIntoView({
          behavior: "smooth",
          block: isBelowViewport(element) ? "end" : "start",
          inline: "nearest",
          offset: "20",
        });
      }
    }
  }, [selectedUser]);

  const isDisplayed = (user) => {
    if (
      filters.search &&
      !removeDiacritics(user.name.toLowerCase()).includes(
        removeDiacritics(filters.search.toLowerCase()),
      )
    )
      return false;
    if (filters.cohort && user.cohortId !== filters.cohort) return false;
    if (filters.organization && user.organizationId !== filters.organization)
      return false;
    if (filters.course && user.courseId !== filters.course) return false;
    if (
      filters.labels &&
      filters.labels.length > 0 &&
      !user.labels
        .map((label) => label.id)
        .some((id) => filters.labels.map((label) => label.id).includes(id))
    )
      return false;
    if (filters.requestingCertification && !user.certificationRequested)
      return false;

    return true;
  };

  const directory = useMemo(
    () =>
      users
        ?.sort((a, b) => a.firstName?.localeCompare(b.firstName))
        .reduce((dir, user) => {
          if (!isDisplayed(user)) return dir;

          const firstLetterOfFirstName =
            user.firstName && user.firstName[0].toUpperCase();

          if (firstLetterOfFirstName) {
            const subdirectory = dir[firstLetterOfFirstName] || [];
            dir[firstLetterOfFirstName] = [...subdirectory, user];
          }

          return dir;
        }, {}),
    [users, filters],
  );

  const displayedUsers = useMemo(() => {
    return users.filter((user) => isDisplayed(user));
  }, [users, filters]);

  const [displayedUsersIsChecked, setDisplayedUsersIsChecked] = useState(false);

  const handleCheck = (user) => {
    if (props.onCheck) props.onCheck(user);
  };

  useEffect(() => {
    if (
      displayedUsers.every((user) =>
        selectedUsers?.map((u) => u.id).includes(user.id),
      )
    ) {
      setDisplayedUsersIsChecked(true);
    } else {
      setDisplayedUsersIsChecked(false);
    }
  }, [displayedUsers, selectedUsers]);

  const handleCheckDisplayedUsers = () => {
    if (displayedUsersIsChecked) {
      if (props.onBatchUncheck) props.onBatchUncheck(displayedUsers);
      setDisplayedUsersIsChecked(false);
    } else {
      if (props.onBatchCheck) props.onBatchCheck(displayedUsers);
      setDisplayedUsersIsChecked(true);
    }
  };

  const selectedUsersNotDisplayed = useMemo(() => {
    return selectedUsers?.filter((user) => !displayedUsers.includes(user));
  }, [selectedUsers, displayedUsers]);

  return (
    <div className={cn("flex flex-col flex-shrink-0 h-full", props.className)}>
      <div className="px-6 pt-5 pb-4 border-b border-gray-200">
        <div className="flex space-x-4">
          <SearchBar
            onChange={(e) =>
              handleFilterChange({ name: "search", value: e.target.value })
            }
          />
          <DirectoryFilters filters={filters} onChange={handleFilterChange} />
        </div>
      </div>

      <nav
        className="min-h-0 flex-1 h-full overflow-y-auto relative"
        aria-label="Directory"
      >
        {props.showCurrentUser && (
          <ul>
            <UserInList
              key="current-user"
              linkFn={props.linkFn}
              user={users.find((u) => u.id === currentUser.id)}
              ref={selectedUser?.id === currentUser.id ? currentUserRef : null}
              isCurrent={selectedUser?.id === currentUser.id}
              onClick={(user) => props.onClick(currentUser)}
            />
          </ul>
        )}

        <div className="relative h-full">
          <div
            className={cn(
              "sticky top-0 z-10 border-b border-gray-200 bg-gray-50 px-6 py-1 text-xs text-gray-500",
            )}
          >
            <div className="flex justify-between items-center">
              {props.loading ? (
                <h3 className="flex items-center space-x-2">
                  <Spinner size="xs" className="text-gray-500 h-4 w-4" />
                  <span>Loading users</span>
                </h3>
              ) : (
                <h3>
                  Displaying {displayedUsers.length} of{" "}
                  {pluralize(users.length, "user")}
                </h3>
              )}
              {props.onCheck && props.checkable && (
                <div className="flex items-center space-x-2">
                  <span className="text-gray-500 text-xs">
                    {selectedUsers.length} selected
                    {selectedUsersNotDisplayed.length > 0 &&
                      ` (${selectedUsersNotDisplayed.length} not in view)`}
                  </span>
                  <Checkbox
                    checked={
                      displayedUsers.length > 0 && displayedUsersIsChecked
                    }
                    disabled={displayedUsers.length === 0}
                    readOnly={true}
                    onClick={handleCheckDisplayedUsers}
                  />
                </div>
              )}
            </div>
          </div>
          {Object.keys(directory).map((letter, index) => (
            <div key={letter} className="relative">
              <div
                className={cn(
                  "sticky top-0 z-10 border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500",
                  index !== 0 && "border-t",
                )}
              >
                <h3>{letter}</h3>
              </div>
              <ul role="list" className="relative z-0 divide-y divide-gray-200">
                {directory[letter].map((user) => (
                  <UserInList
                    key={user.id}
                    linkFn={props.linkFn}
                    ref={selectedUser?.id === user.id ? currentUserRef : null}
                    user={user}
                    isCurrent={selectedUser?.id === user.id}
                    checkable={props.checkable}
                    onClick={(user) => setSelectedUserId(user.id)}
                    checked={selectedUsers?.map((u) => u.id).includes(user.id)}
                    onClick={(user) => props.onClick(user)}
                    onCheck={() => handleCheck(user)}
                  />
                ))}
              </ul>
            </div>
          ))}
        </div>
      </nav>
    </div>
  );
};

export default Directory;
