import React, { Fragment, useContext } from "react";
import AppContext from "contexts/AppContext";
import QuestionTitle from "components/questions/QuestionTitle";
import QuestionIntro from "components/questions/QuestionIntro";
import { cn } from "utils";

const QuestionHeader = ({ question, constraints, stage, user, ...props }) => {
  const { editorMode } = useContext(AppContext);
  return (
    <div
      className={cn(
        "relative pt-4 px-8 @xs:px-6 @md:pl-16 mb-2",
        props.className,
      )}
    >
      {props.showTitle && (
        <QuestionTitle
          question={question}
          beforeChange={props.onStartUpdate}
          onChange={props.onUpdate}
          onError={props.onErrorUpdate}
          warmup={props.warmup}
        />
      )}

      <QuestionIntro
        editable={!!editorMode}
        showInterpolation
        excerptLength={props.questionExcerptLength}
        small={props.small}
        className={cn(
          "mb-0 bg-transparent text-gray-900 @xs:text-sm @md:text-base",
          props.small ? "text-sm" : "prose",
        )}
        user={user}
        question={question}
        stage={stage}
        constraints={constraints}
        onStartUpdate={props.onStartUpdate}
        onUpdate={props.onUpdate}
        onErrorUpdate={props.onErrorUpdate}
      />
    </div>
  );
};

QuestionHeader.defaultProps = {
  showTitle: true,
};

export default QuestionHeader;
