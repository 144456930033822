import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Button } from "components/shared";
import { BeakerIcon } from "@heroicons/react/24/outline";
import { Switch } from "@headlessui/react";
import { useFlash } from "hooks";
import { cn } from "utils";

const FeatureFlags = ({ ...props }) => {
  const { currentUser, setCurrentUser, API } = useContext(AppContext);
  const [featureFlags, setFeatureFlags] = useState([]);
  const [enabledIds, setEnabledIds] = useState(
    currentUser.featureFlagsEnabled.map((flag) => flag.id),
  );
  const [loading, setLoading] = useState(true);
  const [flagsLoadingIds, setFlagsLoadingIds] = useState([]);

  const { successFlash, errorFlash } = useFlash();

  const fetchFeatureFlags = async () => {
    setLoading(true);
    const response = await API.get("/feature_flags");

    if (response) {
      setFeatureFlags(response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeatureFlags();
  }, []);

  const toggleEnabled = async (featureFlagId) => {
    setFlagsLoadingIds([...flagsLoadingIds, featureFlagId]);

    const body = {
      user_feature_flag: {
        user_id: currentUser.id,
        feature_flag_id: featureFlagId,
      },
    };

    if (enabledIds.includes(featureFlagId)) {
      setEnabledIds(enabledIds.filter((id) => id !== featureFlagId));
      const response = await API.destroy(`/user_feature_flags`, body, {
        onError: errorFlash,
      });
      if (response) successFlash(response.message);
    } else {
      setEnabledIds([...enabledIds, featureFlagId]);
      const response = await API.post(`/user_feature_flags`, body, {
        onError: errorFlash,
      });
      if (response) successFlash(response.message);
    }

    setFlagsLoadingIds(flagsLoadingIds.filter((id) => id !== featureFlagId));
  };

  const enableBetaTester = async () => {
    const response = await API.put(`/users/${currentUser.id}`, {
      user: { beta_tester: true },
    });

    if (response) setCurrentUser(response);
  };

  if (!currentUser.betaTester)
    return (
      <div className="flex justify-end">
        <Button
          variant="secondary"
          size="sm"
          Icon={BeakerIcon}
          onClick={enableBetaTester}
        >
          Join the beta
        </Button>
      </div>
    );

  return (
    <ul role="list" className="mt-2 divide-y divide-gray-200">
      {featureFlags.map((flag) => (
        <Switch.Group
          key={flag.id}
          as="li"
          className="flex items-center justify-between py-4"
        >
          <div className="flex flex-col">
            <Switch.Label
              as="p"
              className="text-sm font-medium text-gray-900 mb-1"
              passive
            >
              {flag.title}
            </Switch.Label>
            <Switch.Description className="text-sm text-gray-500">
              {flag.description}
            </Switch.Description>
          </div>
          <Switch
            enabled={!flagsLoadingIds.includes(flag.id)}
            checked={enabledIds.includes(flag.id)}
            onChange={() => toggleEnabled(flag.id)}
            className={cn(
              enabledIds.includes(flag.id) ? "bg-teal-500" : "bg-gray-200",
              "relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2",
            )}
          >
            <span
              aria-hidden="true"
              className={cn(
                enabledIds.includes(flag.id)
                  ? "translate-x-5"
                  : "translate-x-0",
                "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
        </Switch.Group>
      ))}
    </ul>
  );
};

export default FeatureFlags;
