import React from "react";
import { useQueryParam, ArrayParam } from "use-query-params";
import { Modal } from "components/shared";
import EmailUsers from "components/admin/EmailUsers";

const EmailUsersModal = ({ ...props }) => {
  const [ids, setIds] = useQueryParam("emailUsers", ArrayParam);

  const onClose = () => {
    setIds(null);
  };

  return (
    <Modal
      noPadding
      size={"4xl"}
      open={!!ids}
      onClose={onClose}
    >
      {
        ids && (
          <EmailUsers
            defaultCheckedIds={ids.map((id) => parseInt(id))}
            onChange={props.onChange}
            className="h-[640px] max-h-[90vh]"
          />
        )
      }
    </Modal>
  );
};

export default EmailUsersModal;
