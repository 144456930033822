import React, { useState } from "react";
import axios from "axios";

export default function useNewApi(apiUrl, formToken) {
  const [loading, setLoading] = useState(false);

  const apiCall = async (
    path,
    { method, params = {}, body = {}, onError } = {},
  ) => {
    try {
      const options = {
        method,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRF-Token": formToken,
        },
      };

      if (method !== "get") {
        options.body = JSON.stringify(body);
      }

      setLoading(true);

      const encodedParams =
        Object.keys(params).length > 0 ? `?${new URLSearchParams(params)}` : "";

      const response = await fetch(`${apiUrl}${path}${encodedParams}`, options);

      if (response.ok) {
        try {
          const data = await response.json();

          setLoading(false);
          return data;
        } catch (err) {
          setLoading(false);
          console.error(err);
          console.error(response);

          if (onError) onError(err?.message);
        }
      } else {
        setLoading(false);
        console.error("Response not OK");
        console.error(response);

        if (response.status === 500) {
          if (onError) {
            try {
              const data = await response.json();
              return onError(data.message, `Server error ${response.status}`);
            } catch {
              return onError(
                response.statusText,
                `Server error ${response.status}`,
              );
            }
          }
        } else {
          // should always be of the form
          // { message: 'Error here' }
          const err = await response.json();

          if (onError)
            onError(err?.message, `Server error: ${response.statusText}`);
        }
      }
    } catch (err) {
      console.error(err);

      if (onError)
        onError(err?.message, `Client error: ${err?.name || "Unknown error"}`);
    }
  };

  const get = async (path, { params = {}, onError } = {}) => {
    const response = await apiCall(path, { method: "get", params, onError });
    return response;
  };

  const post = async (path, body, { onError } = {}) => {
    const response = await apiCall(path, { method: "post", body, onError });
    return response;
  };

  const put = async (path, body, { onError } = {}) => {
    const response = await apiCall(path, { method: "put", body, onError });
    return response;
  };

  const destroy = async (path, body, { onError } = {}) => {
    const response = await apiCall(`${path}`, {
      method: "delete",
      body,
      onError,
    });
    return response;
  };

  const upload = async (
    path,
    file,
    name,
    {
      onUploadProgress = () => {},
      onSuccess = () => {},
      onError = () => {},
    } = {},
  ) => {
    const formData = new FormData();
    formData.append(name, file);

    axios
      .post(`${apiUrl}${path}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          "X-CSRF-Token": formToken,
        },
        onUploadProgress,
      })
      .then((response) => {
        onSuccess(response.data);
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          onError(
            `${error.response.status} ${error.response.data} ${error.response.headers}`,
          );
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          onError(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          onError(error.message);
        }

        return false;
      });
  };

  return {
    get,
    post,
    put,
    destroy,
    loading,
    upload,
  };
}
