import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import { cn, allocationNote, pluralize } from "utils";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  ArrowUpRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  TrashIcon,
  ChatBubbleOvalLeftIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { ArrowPathIcon, FlagIcon, StarIcon } from "@heroicons/react/20/solid";
import ResponseText from "components/coaching/ResponseText";
import Comment from "components/coaching/Comment";
import CommentForm from "components/coaching/CommentForm";
import { useQuery, useFlash } from "hooks";
import GenericPositiveCommentButton from "components/discussions/GenericPositiveCommentButton";
import {
  Button,
  Avatar,
  Badge,
  Pill,
  PlaceholderText,
  Indicator,
} from "components/shared";
import { pure } from "recompose";

const Response = ({
  id,
  question,
  discussion,
  user,
  updatedAt,
  text,
  comments,
  attempt,
  ...props
}) => {
  const { currentUser, API } = useContext(AppContext);
  const { errorFlash } = useFlash();
  const [isLoading, setIsLoading] = useState(false);
  const [localComments, setLocalComments] = useState(comments);
  const [showComments, setShowComments] = useState(
    !!props.highlightedCommentId,
  );

  const { pathWithQuery } = useQuery();

  const allowDelete =
    props.allowDelete && (currentUser.isAdmin || currentUser.id === user?.id);

  const onDelete = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const res = await API.destroy(
      `/discussions/${discussion.id}/responses/${id}`,
      {
        onError: errorFlash,
      },
    );

    if (res) {
      setIsLoading(false);
      if (props.onDelete) props.onDelete({ id });
    }
  };

  const handleAddComment = (comment) => {
    setLocalComments([...localComments, comment]);
  };

  const handleDeleteComment = ({ id }) => {
    setLocalComments([...localComments.filter((comment) => comment.id !== id)]);
  };

  // if(!user) return null

  return (
    <div className={cn(isLoading && "opacity-50", props.className)}>
      {props.isRevision && props.showIsRevision && (
        <div className='-mt-8'>
          <Pill
            color="green"
            Icon={ArrowPathIcon}
            className="@xs:ml-0 @sm:ml-12 mb-2"
          >
            Revision
          </Pill>
        </div>
      )}
      <div className={cn("relative", !props.isLast && "pb-8")}>
        {!props.isLast && (
          <span
            className="absolute top-5 left-5 @xs:left-4 @sm:left-5 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        )}

        <div className="relative flex items-start space-x-3">
          <div className="relative flex-shrink-0">
            {props.isUnviewed && (user !== currentUser) && (
              <Indicator className="absolute ring ring-2 ring-white top-0.5 left-0 z-10" />
            )}
            {currentUser.isTrainer && props.isFlagged && (
              <FlagIcon
                className={cn(
                  "absolute top-0 z-10 h-3 w-3 text-orange-500",
                  props.isUnviewed && "-left-4",
                )}
              />
            )}
            <Avatar
              className={cn(
                "flex @xs:h-8 @xs:w-8 @sm:h-10 @sm:w-10 h-10 w-10 ring-8 ring-white",
                props.avatarClassName,
              )}
              {...user}
            />
          </div>
          <div className="min-w-0 flex-1">
            <div
              className={cn("pr-4", props.isHighlighted && "bg-yellow-50")}
            >
              <div className="flex justify-between items-start truncate">
                {user ? (
                  <div className="text-sm truncate">
                    {
                      props.isExemplar && (
                        <StarIcon className='inline h-4 w-4 text-yellow-500 mr-1 relative -top-px' />
                      )
                    }
                    <Link
                      to={pathWithQuery('/manage', { user: user.id })}
                      className="font-medium text-gray-900 relative top-[0.5px]"
                    >
                      {user?.id === currentUser.id
                        ? "You"
                        : props.omitLastName
                        ? user?.firstName
                        : user?.name}
                    </Link>

                    {user?.isTrainer && (
                      <Badge className="ml-1" color="indigo">
                        Trainer
                      </Badge>
                    )}
                    {updatedAt && (
                      <div className="inline ml-1 text-xs text-gray-500">
                        {moment(updatedAt).fromNow()}
                      </div>
                    )}

                    {attempt?.activity && (
                      <div className="inline ml-1 text-xs text-gray-500">
                        in{" "}
                        <Link
                          to={`/activities/${attempt.activity.slug}`}
                          target="_blank"
                          className="font-semibold"
                        >
                          {attempt.activity.title}
                          <ArrowUpRightIcon className="h-3 w-3 inline ml-0.5 -translate-y-px" />
                        </Link>
                      </div>
                    )}
                  </div>
                ) : (
                  <PlaceholderText size="sm" className="w-64" />
                )}

                {props.extra}
              </div>
              {discussion?.user.id === user?.id && discussion?.allocation && (
                <div className="text-gray-400 text-xs">
                  {allocationNote({ allocation: discussion?.allocation })}
                </div>
              )}
              <div className="mt-1 text-sm text-gray-700">
                {props.preText}
                {props.isPlaceholder ? (
                  <PlaceholderText lines={3} />
                ) : (
                  <ResponseText text={text} question={question} />
                )}
              </div>
            </div>
            {props.showActions && (
              <div className="flex space-x-2">
                {props.allowRevise &&
                  discussion?.user.id === currentUser.id &&
                  !props.isRevising && (
                    <Button
                      id="other-discussions-tour-3"
                      type="pill"
                      className="mt-3"
                      Icon={ArrowPathIcon}
                      onClick={() => props.setIsRevising(true)}
                    >
                      Revise
                    </Button>
                  )}

                {props.allowRegenerate && (
                  <Button
                    type="pill"
                    className="mt-3"
                    Icon={SparklesIcon}
                    onClick={props.onRegenerate}
                  >
                    Regenerate
                  </Button>
                )}

                {props.allowComment &&
                  (showComments ? (
                    <Button
                      type="pill"
                      className="mt-3"
                      Icon={ChatBubbleOvalLeftIcon}
                      onClick={() => setShowComments(false)}
                    >
                      Hide comments
                    </Button>
                  ) : (
                    <Button
                      type="pill"
                      className="mt-3"
                      Icon={ChatBubbleOvalLeftIcon}
                      onClick={() => setShowComments(true)}
                    >
                      {localComments.length === 0
                        ? "Add comment"
                        : pluralize(localComments.length, "comment")}
                    </Button>
                  ))}

                {props.allowComment &&
                  !showComments &&
                  discussion?.user.id !== currentUser.id &&
                  (currentUser.isAdmin || currentUser.isTrainer) && (
                    <GenericPositiveCommentButton
                      user={user}
                      question={question}
                      discussion={discussion}
                      response={{ id, text }}
                      onSubmit={(comment) => {
                        setLocalComments([comment, ...localComments]);
                        setShowComments(true);
                      }}
                    />
                  )}

                {allowDelete && (
                  <Button
                    type="pill"
                    className="mt-3"
                    Icon={TrashIcon}
                    onClick={onDelete}
                    disabled={isLoading}
                  >
                    Delete
                  </Button>
                )}
              </div>
            )}

            {showComments && (
              <>
                {localComments.length > 0 && (
                  <div className="mt-4">
                    {localComments.map((comment, index) => (
                      <Comment
                        key={comment.id}
                        isLast={index === localComments.length - 1}
                        onDelete={handleDeleteComment}
                        discussion={discussion}
                        isHighlighted={
                          props.highlightedCommentId &&
                          comment.id === props.highlightedCommentId
                        }
                        response={{ id }}
                        {...comment}
                      />
                    ))}
                  </div>
                )}
                <CommentForm
                  className="mt-6"
                  user={user}
                  question={question}
                  discussion={discussion}
                  response={{ id, text }}
                  onSubmit={handleAddComment}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Response.defaultProps = {
  defaultIsCommenting: false,
  defaultShowComments: false,
  allowRevise: false,
  allowComment: true,
  allowDelete: true,
  comments: [],
  showActions: true,
};

// Response.whyDidYouRender = true

export default pure(Response);
