import React from "react";
import moment from "moment";

// all datetimes should be provided in UTC
const DateTime = ({
  datetime,
  showWeekday = true,
  showDay = true,
  showMonth = true,
  showYear = true,
  showTimeZone = true,
  day = "2-digit",
  month = "2-digit",
  ...props
}) => {
  const formatter = new Intl.DateTimeFormat(navigator.language, {
    weekday: showWeekday ? "long" : undefined,
    day: showDay ? day : undefined,
    month: showMonth ? month : undefined,
    year: showYear ? "numeric" : undefined,
    hour: "numeric",
    minute: "numeric",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: showTimeZone ? "short" : undefined,
    hour12: false,
  });

  const date = new Date(datetime);

  return (
    <time
      className={props.className}
      data-local="time"
      dateTime={datetime}
      title={formatter.format(date)}
      data-localized="true"
    >
      {formatter.format(date)}
    </time>
  );
};

export default DateTime;
