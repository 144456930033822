import React from "react";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import AttachmentBadge from "components/bloom/AttachmentBadge";

const Attachment = ({ children, color = "random", ...props }) => {
  return (
    <AttachmentBadge onDismiss={props.onUnattach} color={color}>
      <PaperClipIcon className="h-4 w-4 mr-1" />
      {children}
    </AttachmentBadge>
  );
};

export default Attachment;
