import React from 'react'
import { Hint, ProgressLine, Avatar } from 'components/shared'
import moment from 'moment'

const ApprovePullRequest = (props) => {
  return(
    <ProgressLine.Widget>
      <ProgressLine.Widget.Indicator
       filled={props.attempt?.pullRequest?.isApproved && props.attempt?.pullRequest?.status !== 'closed'}
       active={props.attempt?.pullRequestSubmitted && !props.attempt?.pullRequestApproved} />

      <ProgressLine.Widget.Details>
        <ProgressLine.Widget.Details.Header>
          <div className="WidgetTitle">
            Pull Request approved
            {
              props.attempt?.pullRequestApproved && props.attempt?.pullRequest?.status !== 'closed'
              && <Hint className="ml-1">
                   { moment(props.attempt?.pullRequest.approvedAt).fromNow() }
                 </Hint>
            }
          </div>
          <Hint>
            {
              props.attempt?.pullRequestApproved && props.attempt?.pullRequest?.status !== 'closed'
              && <>
                   <span className="mr-1">
                     <Avatar size="tiny" src={props.attempt?.pullRequest?.approverAvatar} />
                   </span>
                   <span className="mr-1">
                     { props.attempt?.pullRequest.approverGithubUsername }
                   </span>
                 </>
            }
          </Hint>
        </ProgressLine.Widget.Details.Header>
      </ProgressLine.Widget.Details>
    </ProgressLine.Widget>
  )
}

export default ApprovePullRequest