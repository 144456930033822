import React, { useState, useEffect, useContext } from 'react'
import ReactDOM from 'react-dom'

import AppContext from 'contexts/AppContext'
import { Loading, Button, Alert, LabelHeader } from 'components/shared'
import { get } from 'api'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { dndMove, dndReorder, shuffle, classNames } from 'utils'
import { pure } from 'recompose'

const Categoriser = ({ id, onComplete }) => {
  const { apiUrl } = useContext(AppContext)

  const handleReceiveCategoriser = (categoriser) => {
    const newCategories = categoriser.categories.map(category => {
      if(!category.isTray) return { ...category, items: [] }

      return { ...category, items: shuffle(categoriser.items) }
    })

    setState({ id: categoriser.id, title: categoriser.title, categories: newCategories })
  }
  const fetchCategoriser = () => get(`${apiUrl}/categorisers/${id}`, handleReceiveCategoriser)
  useEffect(fetchCategoriser, [id])

  const [state, setState] = useState(null)
  const [isCorrect, setIsCorrect] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const onDragEnd = ({ source, destination }) => {
    setIsChecked(false)

    // dropped outside a category
    if (!destination) return

    const startCategoryIndex = +source.droppableId
    const destinationCategoryIndex = +destination.droppableId

    const startCategory = state.categories[startCategoryIndex]
    const destinationCategory = state.categories[destinationCategoryIndex]

    const newState = { ...state }
    let result

    // dropped in the same category it left
    if(startCategoryIndex === destinationCategoryIndex) {
      const items = dndReorder(startCategory.items, source.index, destination.index)

      newState.categories[startCategoryIndex].items = items
    } else {
      result = dndMove(startCategory.items, destinationCategory.items, source, destination)

      newState.categories[startCategoryIndex].items = result[startCategoryIndex]
      newState.categories[destinationCategoryIndex].items = result[destinationCategoryIndex]
    }

    setState({ ...newState, categories: newState.categories.map(category => ({ ...category, items: category.items.map(item => ({ ...item, correctStatus: undefined }) ) }) ) })
    setIsCorrect(false)
  }

  const onCheck = () => {
    const newState = {
      ...state,
      categories: state.categories.map(category => ({
        ...category,
        items: category.items.map(item => ({
          ...item,
          correctStatus: (item.correctCategoryId === category.id) ? 'correct' : 'incorrect'
        }))
      }))
    }

    setState(newState)
    setIsChecked(true)
    setIsCorrect(newState.categories.map(({items}) => items).flat().every(item => item.correctStatus === 'correct'))
  }

  const handleCloseCheckedMessage = () => {
    const newState = {
      ...state,
      categories: state.categories.map(category => ({
        ...category,
        items: category.items.map(item => ({
          ...item,
          correctStatus: undefined
        }))
      }))
    }

    setState(newState)
    setIsChecked(false)
  }

  if(!state) return <div className="mx-auto max-w-7xl rounded-lg border-2 border-dashed border-gray-300 py-8 px-12 text-center">
                       <Loading />
                     </div>

  return(
    <div className="mx-auto max-w-7xl rounded-lg border-2 border-dashed border-gray-300 py-8 px-12 text-center">
      <LabelHeader className="mb-4">Categorise these items into the categories below.</LabelHeader>
      <div className="flex flex-col space-y-8">
        <DragDropContext onDragEnd={onDragEnd}>
          { state.categories.map(( { title, items, isTray }, index ) => <Categoriser.Category
                                                                         key={`droppable-category-${index}`}
                                                                         { ...{title, isTray, items, index} } /> )}
        </DragDropContext>
      </div>

      <div className="mt-4">
        {
          isChecked &&
          <Alert
           className="mb-4"
           dismissible
           variant={ isCorrect ? "success" : "warning" }
           onClose={handleCloseCheckedMessage}>
            { 
              isCorrect 
              ? "Nice job! That's the right order."
              : "Not quite! Try changing something."
            }
          </Alert>
        }

        { isChecked && isCorrect
          ? onComplete &&
            <Button
             onClick={onComplete}>
             Complete
            </Button>
          : <Button
             onClick={onCheck}>
             Check answer
            </Button>  }
      </div>
    </div>
  )
}

Categoriser.Category = ({ title, items, isTray, index }) => {
  return(
    <div>
    {
      !isTray &&
      <LabelHeader className="mb-2">{title}</LabelHeader>
    }
    <Droppable droppableId={`${index}`}>
      {( provided, snapshot ) => (
        <div
         ref={provided.innerRef}
         className={classNames(
          "flex flex-col space-y-2 relative p-8 border-2 border-dashed border-gray-300 rounded-lg bg-gray-50",
          snapshot.isDraggingOver && 'bg-gray-100 border-gray-500'
         )}
         { ...provided.droppableProps }>
          { items.map((item, index) => <Categoriser.Item key={item.id} { ...item } { ...{index} } />)}
          { provided.placeholder }
        </div>
      )}
    </Droppable>
    </div>
  )
}


Categoriser.Item = ({ index, ...props }) => {

  return(
     <Draggable
      key={props.id}
      draggableId={`${props.id}`}
      index={index}>
      { (provided, snapshot) => <PortalAwareItem {...{provided, snapshot, ...props}} /> }
    </Draggable>
  )
}

const portal = document.createElement('div')
portal.classList.add('dnd-portal')
document.body.appendChild(portal)

const PortalAwareItem = ({ provided, snapshot, content, correctStatus }) => {
  const child = <div ref={provided.innerRef}
                className={classNames(
                  "text-sm text-center rounded-md border border-gray-300 cursor-grab hover:border-indigo-400 hover:bg-gray-50 bg-white px-2 py-2 shadow-sm",
                  correctStatus === 'correct' && "border-green-500",
                  correctStatus === 'incorrect' && "border-red-500",
                  snapshot.isDragging && "border-indigo-600 bg-gray-50"
                )}
                { ...provided.draggableProps }
                { ...provided.dragHandleProps }>
                  <div
                  className="CategoriserItem__content"
                  dangerouslySetInnerHTML={ {__html: content} } />
               </div>

  if(!snapshot.isDragging) return child

  return ReactDOM.createPortal(child, portal)
}

export default pure(Categoriser)
