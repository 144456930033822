import React from 'react'
import { Page } from 'components/shared'
import Certification from 'components/certification/Certification'

const CertificationPage = () => {
  return (
    <Page>
      <Certification />
    </Page>
  )
}

export default CertificationPage