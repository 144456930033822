import React from 'react'
import { motion } from "framer-motion";
import Attachment from 'components/bloom/Attachment'
import { cn } from 'utils'

const Message = ({ message, ...props }) => {
  return(
    <div
     id={props.id}
     className={cn(
      'clear-both mb-2',
      message.from === 'you' ? 'float-right' : 'float-left',
      props.className
    )}>
      <motion.div
        initial={{ y: -20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.35, ease: [0.075, 0.82, 0.165, 1] }}
      >
        <span
         className={cn(
          'text-sm text-gray-400 w-full block',
          message.from === 'you' ? 'text-right' : 'text-left',
          props.labelPosition === 'right' && 'text-right'
         )}>From {message.from}</span>
        <div
         className={cn(
          'px-4 py-2 rounded-lg shadow-sm max-w-lg min-h-[40px] inline-flex items-center clear-both',
          'bg-gray-200 rounded-l float-left',
          message.from === 'you' &&'bg-blue-300 rounded-r float-right',
          message.from === 'coach' && 'bg-[#E0FCD6] rounded-r float-right'
         )}>
          {
            message.text === '...'
            ? (
              <div className='flex space-x-1'>
                <div className='bg-black opacity-30 rounded-full h-3 w-3 transition animate-bounce' />
                <div className='bg-black opacity-30 rounded-full h-3 w-3 transition animate-bounce animation-delay-[100ms]' />
                <div className='bg-black opacity-30 rounded-full h-3 w-3 transition animate-bounce animation-delay-[200ms]' />
              </div>
            ) : message.text
          }
        </div>
      </motion.div>
      {
        message.attachments?.length > 0 && (
          <motion.div
           initial={{ y: -20 }}
           animate={{ y: 0 }}
           transition={{ duration: 0.35, ease: [0.075, 0.82, 0.165, 1] }}
           className='flex max-w-lg space-x-2 pt-2 clear-both items-center justify-end'>
            {
              message.attachments.map(attachment => (
                <Attachment
                 key={attachment.id}>
                 { attachment.title }
                </Attachment>
              ))
            }
          </motion.div>
        )
      }
    </div>
  )
}

export default Message