import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Link, useLocation } from "react-router-dom";
import { Banner } from "components/shared";

const FeatureAnnouncement = ({ ...props }) => {
  const { API, currentUser } = useContext(AppContext);
  const location = useLocation();
  const [featureAnnouncements, setFeatureAnnouncements] = useState([]);
  const [show, setShow] = useState(false);

  const fetchFeatureAnnouncements = async () => {
    const response = await API.get(`/feature_announcements`, {
      params: { not_viewed_by: currentUser.id },
    });

    if (response) {
      setFeatureAnnouncements(response);
    }
  };

  useEffect(() => {
    fetchFeatureAnnouncements();
  }, [location]);

  useEffect(() => {
    if (featureAnnouncements?.length > 0) setShow(true);
  }, [featureAnnouncements]);

  const handleDismiss = (id) => {
    API.post(`/feature_announcement_views`, {
      feature_announcement_view: {
        user_id: currentUser.id,
        feature_announcement_id: id,
      },
    });
    fetchFeatureAnnouncements();
    setShow(false);
  };

  const latestFeatureAnnouncement = featureAnnouncements[0];
  if (!latestFeatureAnnouncement) return null;
  if (!show) return null;

  return (
    <Banner onClose={() => handleDismiss(latestFeatureAnnouncement.id)}>
      {latestFeatureAnnouncement.text}{" "}
      <Link
        to={latestFeatureAnnouncement.link}
        className="whitespace-nowrap font-semibold"
        onClick={() => handleDismiss(latestFeatureAnnouncement.id)}
      >
        {latestFeatureAnnouncement.linkText}&nbsp;
        <span aria-hidden="true">&rarr;</span>
      </Link>
    </Banner>
  );
};

export default FeatureAnnouncement;
