import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Label, RangeSlider } from "components/shared";
import { useFlash } from "hooks";
import { titleize } from "utils";

const HarshnessSlider = ({ initialValue, ...props }) => {
  const { currentUser, API } = useContext(AppContext);
  const { errorFlash, noticeFlash } = useFlash();
  const [harshness, setHarshness] = useState(initialValue);

  useEffect(() => {
    setHarshness(initialValue)
  }, [initialValue])

  if(harshness === null) return null;

  const HARSHNESS_TO_TEXT = {
    0: "easygoing",
    1: "lenient",
    2: "normal",
    3: "challenging",
    4: "extreme",
  };

  const handleChangeComplete = async () => {
    const body = {
      settings: {
        ai_harshness: harshness,
      },
    };

    const response = await API.put(`/users/${currentUser.id}/settings`, body, {
      onError: errorFlash,
    });

    if (response) {
      noticeFlash(
        `AI harshness updated to ${HARSHNESS_TO_TEXT[response.aiHarshness]}.`,
      );

      if (props.onChangeComplete) props.onChangeComplete(harshness);
    }
  };

  return (
    <div className="w-18 flex items-center space-x-2">
      <Label small>{titleize(HARSHNESS_TO_TEXT[harshness])}</Label>
      <RangeSlider
        min={Math.min(
          ...Object.keys(HARSHNESS_TO_TEXT).map((k) => parseInt(k)),
        )}
        max={Math.max(
          ...Object.keys(HARSHNESS_TO_TEXT).map((k) => parseInt(k)),
        )}
        step={1}
        defaultValue={harshness}
        onChange={setHarshness}
        onChangeComplete={handleChangeComplete}
      />
    </div>
  );
};

export default HarshnessSlider;
