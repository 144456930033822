import React, { useContext, useState, useEffect } from 'react'
import AppContext from 'contexts/AppContext'
import { Button, Alert, Loading, Card } from 'components/shared'
import { H2, P } from 'components/typography'
import UserDetails from 'components/users/UserDetails'

const DestroyUser = ({ id, ...props }) => {
  const { API, currentUser } = useContext(AppContext)
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const fetchUser = async () => {
    const user = await API.get(`/users/${id}`)
    setUser(user)
  }

  useEffect(() => {
    if(id) fetchUser()
  }, [id])

  if(!currentUser.isAdmin) return null

  const destroyUser = async () => {
    setIsLoading(true)
    await API.destroy(`/users/${id}`)
    setIsLoading(false)
    props.onClose()
  }

  return(
    <div className='px-6 pt-4 pb-8 space-y-4'>
      <H2>Delete user #{user?.id}</H2>
      <P>
        Are you sure you want to delete the following user?
      </P>

      <Card>
      {
        user
        ? <UserDetails user={user} />
        : <Loading />
      }
      </Card>

      <Alert type='alert'>All user details, discussions, and data will be permanently deleted. This action cannot be undone.</Alert>

      <div className='flex justify-center items-center space-x-4 pt-4'>
        <Button disabled={isLoading} onClick={destroyUser} variant='danger'>Yes, delete user</Button>
        <Button disabled={isLoading} onClick={props.onClose} variant='secondary'>No, cancel</Button>
      </div>
    </div>
  )
}

export default DestroyUser