import React, { useContext } from 'react'
import { get, post, put } from 'api'
import AppContext from 'contexts/AppContext'

const useGraphApi = () => {
  const { apiUrl, formToken, currentUser } = useContext(AppContext)

  const findOrCreateAttempt = (activity, callback = null) => {
    const body = { attempt: { graph_activity_id: activity.id } }

    post(`${apiUrl}/attempts`, {formToken, body}, callback)
  }

  const completeAttempt = ({ id, ...params }, callback = null) => {
    const body = { attempt: { status: 'complete', completed_at: Date.now(), ...params } }

    put(`${apiUrl}/attempts/${id}`, { formToken, body }, callback)
  }

  return { findOrCreateAttempt, completeAttempt }
}

export default useGraphApi
