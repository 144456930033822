import React, { useState, useEffect, useContext } from "react";
import AppContext from "contexts/AppContext";
import { BlockNoteEditor } from "@blocknote/core";
import { BlockNoteView, useBlockNote } from "@blocknote/react";
import { useDebounce } from "react-use";
import { Loading } from "components/shared";
import { usePut } from "hooks";
import { cn } from "utils";
import getCustomEditorComponents from "components/editor/getCustomEditorComponents";

const Editor = ({ debounceDelay = 2000, stage, ...props }) => {
  const { API, currentUser, formToken } = useContext(AppContext);

  const initialContent = React.useMemo(() => {
    const blocks =
      typeof props.initialContent === "string"
        ? JSON.parse(props.initialContent)
        : props.initialContent;

    return blocks;
  }, [props.initialContent]);

  const [content, setContent] = useState(initialContent);
  const [debouncedContent, setDebouncedContent] = useState(initialContent);

  const [, cancel] = useDebounce(
    () => {
      if (props.editable) setDebouncedContent(content);
    },
    debounceDelay,
    [content],
  );

  const update = async (blocks) => {
    const body = props.transform(blocks);
    const response = await API.put(props.updatePath, body, {
      onError: props.onErrorUpdate,
    });

    if (response) {
      if (props.onUpdate) props.onUpdate(blocks);
    }
  };

  useEffect(() => {
    if (content !== initialContent && props.onStartUpdate)
      props.onStartUpdate();
  }, [content]);

  useEffect(() => {
    if (props.editable) update(debouncedContent);
  }, [debouncedContent]);

  const { blockSchema, slashMenuItems } = getCustomEditorComponents(stage);

  const editor = useBlockNote({
    editable: props.editable,
    onEditorContentChange: (editor) => {
      setContent(JSON.stringify(editor.topLevelBlocks));
    },
    blockSchema,
    slashMenuItems,
    ...(initialContent?.length > 0 &&
      (initialContent[1] || initialContent[0].content.length > 0) && {
        initialContent,
      }),
  });

  useEffect(() => {
    if (editor) editor.isEditable = props.editable;
  }, [editor, props.editable]);

  const [blocksToReplace, setBlocksToReplace] = useState([]);

  useEffect(() => {
    if (blocksToReplace.length > 0) {
      editor.replaceBlocks(editor.topLevelBlocks, blocksToReplace);
      setBlocksToReplace([]);
    }
  }, [editor, blocksToReplace]);

  useEffect(() => {
    if (props.initialMarkdown && editor && initialContent?.length === 0) {
      const replaceBlocksWithMarkdown = async () => {
        const blocks = await editor.HTMLToBlocks(props.initialMarkdown);
        setBlocksToReplace(blocks);
      };

      replaceBlocksWithMarkdown();
    }
  }, [props.initialMarkdown, props.initialContent, editor]);

  if (!editor.topLevelBlocks) return null;

  return (
    <div
      className={cn(
        `Editor Editor--${props.editable ? "editable" : "non-editable"}`,
        props.className,
      )}
    >
      <div className={cn("prose", props.editorClassName)}>
        <BlockNoteView editor={editor} theme="light" />
      </div>
    </div>
  );
};

Editor.defaultProps = {
  initialContent: "[]",
};

export default Editor;
