import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import {
  Form,
  TextInput,
  EmailInput,
  TextArea,
  AvatarUploader,
  Page,
  Spinner,
  Button,
  Hint,
} from "components/shared";
import FeatureFlags from "components/users/FeatureFlags";
import { Disclosure, Switch } from "@headlessui/react";
import { useFlash, usePut } from "hooks";
import { post } from "api";
import { classNames } from "utils";

const SettingsPage = () => {
  const { currentUser, setCurrentUser, rootUrl, formToken } =
    useContext(AppContext);
  const [notificationsEnabled, setNotificationsEnabled] = useState(
    currentUser.notificationsEnabled,
  );
  const [passwordResetSent, setPasswordResetSent] = useState(false);
  const [resetPasswordIsLoading, setResetPasswordIsLoading] = useState(false);

  const { showFlash } = useFlash();

  const { put, data } = usePut((user) => {
    setCurrentUser(user);
    showFlash("Settings updated", "Your settings were successfully updated.", {
      type: "success",
    });
  });

  const handleSubmit = (formData) => {
    const settings = {
      notifications_enabled: notificationsEnabled,
    };

    const body = {
      user: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        bio: formData.bio,
        avatar: formData.avatar,
      },
      settings,
    };

    put(`users/${currentUser.id}`, body);
  };

  const changePassword = () => {
    setResetPasswordIsLoading(true);

    const body = {
      user: {
        email: currentUser.email,
      },
    };

    post(
      `${rootUrl}/users/password`,
      { body, formToken },
      () => {
        setResetPasswordIsLoading(false);
        setPasswordResetSent(true);
        showFlash(
          "Instructions sent",
          "Password reset instructions sent. Check your email.",
          { type: "success" },
        );
      },
      () => {
        setResetPasswordIsLoading(false);
        showFlash(
          "Problem sending instructions",
          "There was a problem sending instructions. Please reload the page and try again.",
          { type: "error" },
        );
      },
    );
  };

  return (
    <Page>
      <Disclosure
        as="div"
        className="relative overflow-hidden bg-sky-700 pb-32"
      >
        {({ open }) => (
          <>
            <div
              aria-hidden="true"
              className={classNames(
                open ? "bottom-0" : "inset-y-0",
                "absolute inset-x-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:inset-y-0",
              )}
            >
              <div className="absolute inset-0 flex">
                <div
                  className="h-full w-1/2"
                  style={{ backgroundColor: "#0a527b" }}
                />
                <div
                  className="h-full w-1/2"
                  style={{ backgroundColor: "#065d8c" }}
                />
              </div>
              <div className="relative flex justify-center">
                <svg
                  className="flex-shrink-0"
                  width={1750}
                  height={308}
                  viewBox="0 0 1750 308"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M284.161 308H1465.84L875.001 182.413 284.161 308z"
                    fill="#0369a1"
                  />
                  <path
                    d="M1465.84 308L16.816 0H1750v308h-284.16z"
                    fill="#065d8c"
                  />
                  <path
                    d="M1733.19 0L284.161 308H0V0h1733.19z"
                    fill="#0a527b"
                  />
                  <path
                    d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z"
                    fill="#0a4f76"
                  />
                </svg>
              </div>
            </div>
            <header className="relative py-10">
              <div className="mx-auto max-w-screen-md px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-white">
                  Settings
                </h1>
              </div>
            </header>
          </>
        )}
      </Disclosure>

      <main className="relative -mt-32">
        <div className="mx-auto max-w-screen-md px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <Form
                className="divide-y divide-gray-200 lg:col-span-12"
                onSubmit={handleSubmit}
              >
                {/* Profile section */}
                <div className="py-6 px-4 sm:p-6 lg:pb-8 grid grid-cols-6 gap-x-6 gap-y-6">
                  <div className="col-span-6">
                    <h2 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                      Profile
                    </h2>
                    <p className="-mt-2 text-sm text-gray-500">
                      Techmap is a community of learners. It's not obligatory to
                      use your real name and photo, but it keeps the place
                      feeling nice and human!
                    </p>
                  </div>

                  <div className="col-span-6">
                    <AvatarUploader />
                  </div>

                  <div className="col-span-3">
                    <TextInput
                      name="firstName"
                      label="First name"
                      defaultValue={currentUser.firstName}
                      autoComplete="given-name"
                      className="mt-1"
                      required={true}
                    />
                  </div>

                  <div className="col-span-3">
                    <TextInput
                      name="lastName"
                      label="Last name"
                      defaultValue={currentUser.lastName}
                      autoComplete="family-name"
                      className="mt-1"
                      required={true}
                    />
                  </div>

                  <div className="col-span-3">
                    <EmailInput
                      name="email"
                      label="Email address"
                      defaultValue={currentUser.email}
                      autoComplete="email"
                      className="mt-1"
                      required={true}
                    />
                  </div>

                  <div className="col-span-3 flex items-end pb-2">
                    {passwordResetSent ? (
                      <Hint small>
                        Instructions for changing your password have been sent
                        to {currentUser.email}.
                      </Hint>
                    ) : (
                      <Button
                        type="pill"
                        onClick={changePassword}
                        disabled={resetPasswordIsLoading}
                      >
                        {resetPasswordIsLoading
                          ? "Sending password reset email..."
                          : "Change your password"}
                      </Button>
                    )}
                  </div>

                  <div className="col-span-6">
                    <TextArea
                      name="bio"
                      label="Bio"
                      placeholder="e.g. I have been working at Acme, Inc. as a headhunter for 8 years..."
                      defaultValue={currentUser.bio}
                    />
                  </div>

                  {currentUser.cohort?.title &&
                    currentUser.cohort.title !== "No Cohort" && (
                      <div className="col-span-6 sm:col-span-3">
                        <TextInput
                          disabled
                          name="cohort"
                          label="Cohort"
                          defaultValue={currentUser.cohort.title}
                          className="mt-1"
                        />
                      </div>
                    )}

                  {currentUser.organization?.name && (
                    <div className="col-span-6 sm:col-span-3">
                      <TextInput
                        disabled
                        name="organization"
                        label="Organization"
                        defaultValue={currentUser.organization.name}
                        className="mt-1"
                      />
                    </div>
                  )}
                </div>

                {/* Privacy section */}
                <div className="divide-y divide-gray-200 pt-6">
                  <div className="px-4 sm:px-6">
                    <div>
                      <h2 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                        Privacy
                      </h2>
                      <p className="-mt-2 text-sm text-gray-500">
                        Adjust notification settings here.
                      </p>
                    </div>
                    <ul role="list" className="mt-2 divide-y divide-gray-200">
                      <Switch.Group
                        as="li"
                        className="flex items-center justify-between py-4"
                      >
                        <div className="flex flex-col">
                          <Switch.Label
                            as="p"
                            className="text-sm font-medium text-gray-900 mb-1"
                            passive
                          >
                            Receive Notifications via email
                          </Switch.Label>
                          <Switch.Description className="text-sm text-gray-500">
                            Receive email notifications when someone gives you
                            feedback.
                          </Switch.Description>
                        </div>
                        <Switch
                          checked={notificationsEnabled}
                          onChange={setNotificationsEnabled}
                          className={classNames(
                            notificationsEnabled
                              ? "bg-teal-500"
                              : "bg-gray-200",
                            "relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2",
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              notificationsEnabled
                                ? "translate-x-5"
                                : "translate-x-0",
                              "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                            )}
                          />
                        </Switch>
                      </Switch.Group>
                    </ul>
                  </div>

                  <div className="divide-y divide-gray-200 pt-6">
                    <div className="px-4 sm:px-6">
                      <div>
                        <h2 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                          Beta
                        </h2>
                        <p className="-mt-2 text-sm text-gray-500">
                          Opt in to beta features here.
                        </p>
                      </div>
                      <FeatureFlags />
                    </div>
                  </div>

                  <div className="mt-4 flex justify-end py-4 px-4 sm:px-6">
                    <Button type="submit" disabled={data.isLoading}>
                      {data.isLoading ? "Saving..." : "Save changes"}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </Page>
  );
};

export default SettingsPage;
