import React, { Fragment } from 'react'
import { Popover } from '@headlessui/react'
import { RemoteSelectInput, RemoteCombobox, DotBadge, Checkbox } from 'components/shared'
import { FunnelIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { classNames } from 'utils'

const TranscriptFilters = ({ filters, ...props }) => {
  const filtersApplied = Object
                        .entries(filters)
                        .filter(([key, value]) => {
                          if(key === 'search') return false
                          if(key === 'labels') return value && value.length > 0
                          if(key === 'order')  return false
                          if(key === 'hide_current_user_responses') return false
                          if(key === 'limit') return false

                          return value
                        }).map(([key, value]) => key)

  return(
    <Popover>
      <Popover.Button
       className={classNames(
        "relative inline-flex justify-center rounded-md border border-gray-300 bg-white px-3.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2",
        props.className
       )}>
        <span className="sr-only">Open filters</span>
        <FunnelIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        {
          filtersApplied.length > 0 &&
          <DotBadge value={filtersApplied.length} />
        }
      </Popover.Button>

      <Popover.Panel
       className={classNames(
        "absolute mt-2 z-50 bg-white rounded-md shadow-sm border border-gray-200 min-w-[240px]",
        props.panelClassName
       )}>
        <div className="bg-gray-50 border-b border-gray-200 px-4 py-2">
          <h4 className="text-xs text-gray-500 font-medium">Apply filters</h4>
        </div>

        <div className="px-4 py-4 space-y-2 flex flex-col items-end">
          <div className="w-full">
            <div
             className="flex items-center justify-between px-2 py-2 hover:bg-gray-50 cursor-pointer rounded-md"
             onClick={() => props.onChange({ name: 'showOnlyKeyQuestions', value: !filtersApplied.includes('showOnlyKeyQuestions') })}>
              <span className="text-sm">Show only key questions</span>
              <Checkbox
               readOnly
             checked={filtersApplied.includes('showOnlyKeyQuestions')} />
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  )
}

export default TranscriptFilters
