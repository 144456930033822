import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Alert, Loading } from "components/shared";
import { put as apiPut } from "api";
import { useFlash, useIsMounted } from "hooks";

const usePut = (onSuccess, onError = null) => {
  const isMounted = useIsMounted();

  const { apiUrl, formToken } = useContext(AppContext);
  const { noticeFlash, errorFlash } = useFlash();

  const [data, setData] = useState({ isLoading: false, errorMessage: null });

  const handleSuccess = (data) => {
    // success is often a setState call
    if (isMounted.current) {
      if (data.isNotification) {
        noticeFlash(data.message);
      }

      if (onSuccess) onSuccess(data);
      setData({ ...data, isLoading: false });
    }
  };

  const handleError = async (response) => {
    console.log("there was an error");
    let error;

    if (isMounted.current) {
      error = await response.json();

      setData({
        isLoading: false,
        errorMessage: error.message
          ? error.message
          : `Server error ${response.status}`,
      });
      errorFlash(error.message);

      if (onError) onError(error);
    }
  };

  const put = (path, body = {}) => {
    setData({ ...data, isLoading: true });
    apiPut(
      `${apiUrl}/${path}`,
      { formToken, body },
      handleSuccess,
      handleError,
    );
  };

  const loadingOrError = () => {
    return data.errorMessage ? (
      <Alert type="danger">{data.errorMessage}</Alert>
    ) : data.isLoading ? (
      <Loading />
    ) : null;
  };

  return { put, data, setData, loadingOrError };
};

export default usePut;
