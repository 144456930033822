import React from 'react'

import { NotionRenderer } from "react-notion"
import { pure } from 'recompose'

import { QuestionCustomBlock } from 'components/notion'

const CustomNotionRenderer = ({ blockMap, stage }) => {
  const KEYWORDS_TO_BLOCK_TYPES = {
    '/Question': 'question'
  }

  const blockMapWithCustomBlocks = Object.keys(blockMap).reduce((newMap, key) => {
    const block = blockMap[key]

    if(!block.value) return newMap

    if(block.value.type === 'text' && block.value.properties?.title) {
      const firstWord = block.value.properties.title[0][0].split(' ')[0]

      if(Object.keys(KEYWORDS_TO_BLOCK_TYPES).includes(firstWord)) {
        block['value']['type'] = KEYWORDS_TO_BLOCK_TYPES[firstWord]
        block['value']['stage'] = stage
      }
    }

    newMap[key] = block

    return newMap
  }, {})

  const customBlockComponents = {
    'question': QuestionCustomBlock
  }

  return(
    <NotionRenderer
     blockMap={blockMapWithCustomBlocks}
     customBlockComponents={customBlockComponents}
     fullPage={true}
     darkMode={false} />
  )
}

export default pure(CustomNotionRenderer)
