import React, { useState } from "react";
import { useInterval } from "hooks";
import { classNames } from "utils";

const ScrollingImage = ({ src, ...props }) => {
  const [style, setStyle] = useState({ translateY: 0 });

  useInterval(() => {
    const newTranslation = style.translateY - 1;

    setStyle({
      transform: `translate(0, ${newTranslation}px)`,
      translateY: newTranslation,
    });
  }, 30);

  return (
    <div className={classNames("relative overflow-hidden", props.className)}>
      <div className="absolute inset-x-0 top-0 h-12 z-20 bg-gradient-to-b from-white to-transparent" />
      <img src={src} className="w-full top-0 z-10" style={style} />
      <div className="absolute inset-x-0 bottom-0 h-12 z-20 bg-gradient-to-t from-white to-transparent" />
    </div>
  );
};

export default ScrollingImage;
