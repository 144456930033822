import React, { Fragment, useContext } from "react";
import AppContext from "contexts/AppContext";
import { Avatar, Button } from "components/shared";
import Editable from "components/design/Editable";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { H3 } from "components/typography";
import Logo from "images/logo.png";
import moment from "moment";

const TranscriptHeader = ({ stage, title, author, updatedAt, captions, videoUrl, ...props }) => {
  const { editorMode } = useContext(AppContext);

  return (
    <>
      <div className="bg-white px-4 py-4 sm:px-6">
        <div className="flex items-start justify-between space-x-2">
          {editorMode ? (
            <H3>
              <Editable
                path={`/activity_stages/${stage.slug}`}
                transform={(title) => ({ activity_stage: { title } })}
                beforeChange={props.beforeChange}
                onChange={props.onChange}
              >
                {title}
              </Editable>
            </H3>
          ) : (
            <H3>{title}</H3>
          )}

          {(captions && captions.length > 0 || editorMode) && (!!videoUrl) && (
            <Button
              type="pill"
              className="mt-px"
              Icon={!captions && editorMode && ExclamationTriangleIcon}
              onClick={props.onToggleTranscript}
            >
              {props.showTranscript ? "Hide" : "Show"} transcript
            </Button>
          )}
        </div>

        <div className="mt-1 text-sm flex items-center">
          <Avatar className="mr-1" src={author.avatar} size="tiny" />
          <div className="flex items-baseline">
            <span className="text-gray-400 mr-1">by</span>
            <span className="text-gray-500 mr-1">{author.firstName}</span>
            <span className="text-xs text-gray-400">
              {moment(updatedAt).fromNow()}
            </span>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="ml-8 @xs:ml-6 @md:ml-16 border-indigo-500 text-indigo-600 whitespace-nowrap pb-2 border-b-2 font-medium text-sm mb-0 inline-block">
          {props.showTranscript ? "Transcript + Questions" : "Questions"}
        </div>

        <div className="h-px bg-gray-200 -z-10 absolute bottom-0 left-0 right-0" />
      </div>
    </>
  );
};

TranscriptHeader.defaultProps = {
  author: { avatar: Logo, firstName: "Whatever" },
};

export default TranscriptHeader;
