import React, { Fragment, useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import Editor from "components/editor/Editor";
import EditMetadataBar from "components/design/EditMetadataBar";

const ActivityPreviewDescription = ({ activity, editable, ...props }) => {
  const { editorMode } = useContext(AppContext);

  const [isLoadingMeta, setIsLoadingMeta] = useState(false);
  const [metaError, setMetaError] = useState(null);

  const initialMarkdown =
    activity.description?.length > 0
      ? activity.description
      : `Enter a long description here.
      <br /><br />
      You can format text, add headings, insert images, and more.
      <br /><br />
      Just start editing me, then hit <code>slash (/)</code> to begin.`;

  return (
    <>
      {editorMode && (
        <EditMetadataBar
          className='px-6'
          isLoading={isLoadingMeta}
          setIsLoading={setIsLoadingMeta}
          error={metaError}
          showTitle={false}
        />
      )}
      <Editor
        className={props.className}
        initialMarkdown={initialMarkdown}
        initialContent={activity.descriptionBlocks}
        editable={editorMode}
        transform={(blocks) => ({
          activity: {
            description_blocks: blocks,
          },
        })}
        updatePath={`/activities/${activity.slug}`}
        debounceDelay={500}
        onStartUpdate={() => setIsLoadingMeta(true)}
        onUpdate={() => setIsLoadingMeta(false)}
        onErrorUpdate={() => setMetaError(true)}
      />
    </>
  );
};

export default ActivityPreviewDescription;
