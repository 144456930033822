import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Hint } from "components/typography";
import { Loading } from "components/shared";
import RegularQuestion from "components/questions/RegularQuestion";
import PollQuestion from "components/questions/PollQuestion";
import OrdererQuestion from "components/questions/OrdererQuestion";
import PairerQuestion from "components/questions/PairerQuestion";
import CategoriserQuestion from "components/questions/CategoriserQuestion";
import { useFlash } from "hooks";
import { pure } from "recompose";

const Question = ({ id, constraints, user, stage, ...props }) => {
  const { API } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [discussion, setDiscussion] = useState(null);
  const [question, setQuestion] = useState(null);
  const { errorFlash } = useFlash();

  const fetchQuestion = async () => {
    setIsLoading(true);
    const path = `/questions/${id}?${stage ? `activity_stage_id=${stage.id}` : ''}`;
    const response = await API.get(path, { onError: errorFlash });

    if (response) {
      setQuestion(response);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestion();
  }, [id, user]);

  const findOrCreateDiscussion = async () => {
    const body = {
      discussion: {
        user_id: user.id,
        question_id: id,
      },
    };

    const discussion = await API.post("/discussions", body, {
      onError: errorFlash,
    });

    if (discussion) {
      setDiscussion({ ...discussion });
    }
  };

  useEffect(() => {
    setDiscussion(null);
    findOrCreateDiscussion();
  }, [id]);

  const onAdvance = (response) => {
    findOrCreateDiscussion();
    setQuestion({ ...question, lastResponse: response });
    if (props.onAdvance) props.onAdvance(response);
  };

  const updateResponse = async (response, text, judgement) => {
    if(!text) return;

    const body = {
      response: {
        ai_coach_response: text,
        ai_coach_judgement: judgement,
      },
    };

    await API.put(`/responses/${response.id}`, body, {
      onError: errorFlash,
    });
  };

  const handleUpdateQuestion = (question) => {
    setQuestion(question);
    if (props.onUpdate) props.onUpdate(question);
  };

  const renderQuestion = (type) => {
    const QUESTION_TYPES = {
      unclassified: <Hint>No question type specified for Question #{id}.</Hint>,
      regular: (
        <RegularQuestion
          {...props}
          discussion={discussion}
          onSubmitResponse={onAdvance}
          user={user}
          question={question}
          constraints={constraints}
          onStartUpdate={props.onStartUpdate}
          onUpdate={handleUpdateQuestion}
          onErrorUpdate={props.onErrorUpdate}
          onAiCoachResponse={updateResponse}
          stage={stage}
        />
      ),
      poll: (
        <PollQuestion
          discussion={discussion}
          onCreateAllocation={findOrCreateDiscussion}
          onSubmitResponse={onAdvance}
          user={user}
          {...props}
          {...question}
          constraints={constraints}
          stage={stage}
        />
      ),
      orderer: (
        <OrdererQuestion user={user} {...props} {...question} stage={stage} />
      ),
      pairer: (
        <PairerQuestion user={user} {...props} {...question} stage={stage} />
      ),
      categoriser: (
        <CategoriserQuestion
          user={user}
          {...props}
          {...question}
          stage={stage}
        />
      ),
    };

    return <div className="Question">{QUESTION_TYPES[type]}</div>;
  };

  if (isLoading) return <Loading />;
  if (question) return renderQuestion(question.type);
  return null;
};

Question.defaultProps = {
  type: "unclassified",
};

export default pure(Question);
