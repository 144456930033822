import React, { useContext, useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import AppContext from "contexts/AppContext";
import { useFetch } from "hooks";
import DiscussionsChannel from "channels/discussions_channel";
import { removeDiacritics } from "utils";

const useDiscussions = (filters) => {
  const { currentUser } = useContext(AppContext);

  let { path } = useRouteMatch();
  const prePath = path === "/discussions" ? path : `${path}/discussions`;

  let match = useRouteMatch([
    `${prePath}/:selectedDiscussionId/responses/:selectedResponseId/comments/:selectedCommentId`,
    `${prePath}/:selectedDiscussionId/responses/:selectedResponseId`,
    `${prePath}/:selectedDiscussionId`,
  ]);

  let { selectedDiscussionId, selectedResponseId, selectedCommentId } =
    match?.params || {};

  selectedDiscussionId = parseInt(selectedDiscussionId);
  selectedResponseId = parseInt(selectedResponseId);
  selectedCommentId = parseInt(selectedCommentId);

  const [rawDiscussions, setRawDiscussions] = useState([]);
  const [selectedDiscussion, setSelectedDiscussion] = useState(null);

  const filtersToParams = Object.entries(filters)
    .map(([key, value]) => {
      if (typeof value === "object" && value !== null)
        return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
      return `${key}=${value}`;
    })
    .join("&");

  const handleReceiveDiscussion = (data) => {
    if (data.from && data.from !== currentUser.id) {
      const discussion = { ...JSON.parse(data.discussion), event: data.event };

      if (filters?.user_id && filters.user_id !== discussion.user.id) {
        return;
      }

      const rawDiscussionsWithoutDiscussion = rawDiscussions.filter(
        (d) => d.id !== discussion.id,
      );
      setRawDiscussions([discussion, ...rawDiscussionsWithoutDiscussion]);
    }
  };

  const handleReceiveSelectedDiscussion = (discussion) => {
    const discussionWithView = {
      ...discussion,
      viewers: [...discussion.viewers, currentUser.id],
    };
    setSelectedDiscussion(discussionWithView);
  };

  useEffect(() => {
    const newDiscussions = rawDiscussions.map((discussion) =>
      discussion.id === selectedDiscussion.id
        ? {
            ...selectedDiscussion,
            viewers: [...selectedDiscussion.viewers, currentUser.id],
          }
        : discussion,
    );

    setRawDiscussions(newDiscussions);
  }, [selectedDiscussion]);

  const { fetch: fetchDiscussions, loadingOrError: loadingDiscussions } =
    useFetch(`/discussions?${filtersToParams}`, setRawDiscussions, [
      filtersToParams,
    ]);

  const {
    fetch: fetchSelectedDiscussion,
    loadingOrError: loadingSelectedDiscussion,
  } = useFetch(
    `/discussions/${selectedDiscussionId}`,
    handleReceiveSelectedDiscussion,
    [selectedDiscussionId],
  );

  const [websocketConnected, setWebsocketConnected] = useState(false);

  useEffect(() => {
    DiscussionsChannel.connected = () => setWebsocketConnected(true);
    DiscussionsChannel.received = handleReceiveDiscussion;
  }, [rawDiscussions]);

  const isDisplayed = (discussion) => {
    if (!discussion.responses || discussion.responses.length === 0)
      return false;

    const user = discussion.user;

    if (!user) return false;

    if (discussion.id === selectedDiscussionId) return true;

    if (filters.hideViewed && discussion.viewers.includes(currentUser.id))
      return false;
    if (
      filters.search &&
      !removeDiacritics(user.name.toLowerCase()).includes(
        removeDiacritics(filters.search.toLowerCase()),
      )
    )
      return false;
    if (filters.cohort && `${user.cohortId}` !== filters.cohort) return false;
    if (
      filters.organization &&
      `${user.organizationId}` !== filters.organization
    )
      return false;
    if (filters.course && `${user.courseId}` !== filters.course) return false;
    if (
      filters.labels &&
      filters.labels.length > 0 &&
      !user.labels
        .map((label) => label.id)
        .some((id) => filters.labels.map((label) => label.id).includes(id))
    )
      return false;

    return true;
  };

  const discussions = rawDiscussions.filter(isDisplayed);

  let highlightedResponse;

  if (selectedDiscussion) {
    const responsesWithoutCurrentUser = selectedDiscussion.responses.filter(
      (response) => response.user.id !== currentUser.id,
    );
    highlightedResponse =
      responsesWithoutCurrentUser[responsesWithoutCurrentUser.length - 1];
  }

  return {
    fetchDiscussions,
    fetchSelectedDiscussion,
    loadingDiscussions,
    loadingSelectedDiscussion,
    discussions,
    selectedDiscussion,
    selectedDiscussionId,
    selectedResponseId,
    selectedCommentId,
    highlightedResponse,
    websocketConnected,
  };
};

export default useDiscussions;
