import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import AppContext from "contexts/AppContext";
import { CheckCircleIcon, StarIcon } from "@heroicons/react/24/outline";
import { SearchBar, Indicator, Feed } from "components/shared";
import DiscussionsFeedHeader from "components/coaching/DiscussionsFeedHeader";
import DirectoryFilters from "components/users/DirectoryFilters";
import { usePagination } from "hooks";
import moment from "moment";
import QuestionIntro from "components/questions/QuestionIntro";
import { useRouteMatch } from "react-router-dom";
import {
  classNames,
  excerpt,
  interpolate,
  stripTags,
  isInViewport,
  isBelowViewport,
} from "utils";

const DiscussionsFeed = ({
  discussions,
  filters = {},
  selectedDiscussionId,
  ...props
}) => {
  const { currentUser } = useContext(AppContext);
  const { path } = useRouteMatch();
  const [hideFilters, setHideFilters] = useState(true);

  const currentDiscussionRef = useRef(null);

  const DISCUSSIONS_PER_PAGE = 8;

  const { page, setPageIndex, Pagination } = usePagination(discussions, {
    perPage: DISCUSSIONS_PER_PAGE,
  });

  useEffect(() => {
    if (selectedDiscussionId) {
      const selectedDiscussionIndex = discussions.indexOf(
        discussions.find(
          (discussion) => discussion.id === selectedDiscussionId,
        ),
      );

      if (!selectedDiscussionIndex) setPageIndex(0);

      setPageIndex(
        Math.max(Math.floor(selectedDiscussionIndex / DISCUSSIONS_PER_PAGE), 0),
      );

      const element = currentDiscussionRef.current;
      if (element && !isInViewport(element)) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
          offset: "20",
        });
      }
    }
  }, [discussions, selectedDiscussionId]);

  return (
    <div className="h-screen flex flex-col">
      {hideFilters ? null : (
        <div className="px-4 py-4 border-b border-gray-200">
          <div className="flex space-x-4">
            <SearchBar
              submittable
              defaultValue={filters.search}
              placeholder="Search users"
              onSubmit={(text) =>
                props.handleFilterChange({ name: "search", value: text })
              }
            />
            <DirectoryFilters
              showHideViewed
              filters={filters}
              onChange={props.handleFilterChange}
            />
          </div>
        </div>
      )}
      <DiscussionsFeedHeader
        websocketConnected={props.websocketConnected}
        currentUser={currentUser}
        discussions={discussions}
        filters={filters}
        onChange={props.handleFilterChange}
        showFilters={props.showFilters}
        filtersHidden={hideFilters}
        onToggleFilters={() => setHideFilters(!hideFilters)}
      />
      <Pagination small top />
      <Feed className="flex-grow">
        {page.map((discussion) => {
          let responses = discussion.responses;

          if (!responses) return null;

          responses = responses.sort((a, b) =>
            a.createdAt.localeCompare(b.createdAt),
          );

          let lastResponse = responses[responses.length - 1];

          if (props.hideCurrentUser) {
            const responsesWithoutCurrentUser = responses.filter(
              (response) => response.userId !== currentUser.id,
            );
            lastResponse =
              responsesWithoutCurrentUser[
                responsesWithoutCurrentUser.length - 1
              ];
          }

          if (props.hideCurrentUser && !lastResponse) return null;

          const link =
            path === "/discussions"
              ? `${path}/${discussion.id}/responses/${lastResponse.id}`
              : `${path}/discussions/${discussion.id}/responses/${lastResponse.id}`;

          return (
            <Feed.Item
              key={discussion.id}
              ref={
                selectedDiscussionId && selectedDiscussionId === discussion.id
                  ? currentDiscussionRef
                  : null
              }
              to={link}
              selected={
                selectedDiscussionId && selectedDiscussionId === discussion.id
              }
            >
              <Feed.Item.Header>
                <Feed.Item.Header.Title>
                  <div className="flex items-center space-x-2">
                    {!discussion.viewers?.includes(currentUser.id) && (
                      <Indicator />
                    )}

                    <span>{discussion.user.name}</span>
                  </div>
                </Feed.Item.Header.Title>

                <Feed.Item.Header.Meta>
                  <time dateTime={discussion.updatedAt}>
                    {moment(lastResponse.updatedAt).fromNow()}
                  </time>
                </Feed.Item.Header.Meta>
              </Feed.Item.Header>

              <Feed.Item.Content>
                {discussion.question?.text && (
                  <Feed.Item.Content.Meta>
                    <QuestionIntro
                      small
                      truncate
                      editable={false}
                      question={discussion.question}
                      className='opacity-60'
                      user={discussion.user}
                    />
                  </Feed.Item.Content.Meta>
                )}

                <Feed.Item.Content.Content>
                  {lastResponse.text}
                </Feed.Item.Content.Content>
              </Feed.Item.Content>
            </Feed.Item>
          );
        })}
      </Feed>
      <Pagination small />
    </div>
  );
};

DiscussionsFeed.defaultProps = {
  hideCurrentUser: false,
};

export default DiscussionsFeed;
