import React, { useState, useEffect, useContext } from 'react'
import UploaderContext from 'contexts/UploaderContext'

// see https://cloudinary.com/documentation/upload_widget_reference#parameters
// for options
const useCloudinaryWidget = (onUpload, options = {}) => {
  const { cloudName, uploadPreset } = useContext(UploaderContext)

  const [uploader, setUploader] = useState(null)

  useEffect(() => {
    if(window?.cloudinary) {
      setUploader(window.cloudinary.createUploadWidget({
        cloudName,
        uploadPreset,
        ...options
      },
      onUpload
      ))
    }
  }, [])

  const showUploader = (callback = null) => {
    uploader.open()
    if(callback) callback()
  }

  const hideUploader = (callback = null) => {
    uploader.close()
    if(callback) callback()
  }

  return {
    uploader,
    setUploader,
    showUploader,
    hideUploader
  }
}

export default useCloudinaryWidget
