import React, { useContext, useMemo } from "react";
import QueryContext from "contexts/QueryContext";
import { encodeQueryParams } from "use-query-params";
import { stringify } from "query-string";

const useQuery = () => {
  const { query, setQuery, queryConfiguration } = useContext(QueryContext);

  const queryContains = (object) => {
    return Object.entries(object).every(([key, value]) => {
      if (!query[key]) return false;

      if (value instanceof Date) {
        return query[key]?.toDateString() === value?.toDateString();
      }

      return query[key] === value;
    });
  };

  const watchQuery = (key, expression = value => value) => {
    return useMemo(() => expression(query[key]), [query[key]])
  }

  const mutateQuery = (mutation) => {
    setQuery({
      ...query,
      ...mutation,
    });
  };

  const addQuery = (key, value) => {
    mutateQuery({ [key]: value });
  };

  const deleteQuery = (key) => {
    mutateQuery({ [key]: undefined });
  };

  // used to generate Link#to that preserves or changes query params
  const pathWithQuery = (
    pathname,
    search = {},
    includeExistingSearch = true
  ) => {
    const pathPart = pathname ? { pathname: pathname } : {};

    const filteredQuery = Object.entries(query).reduce((acc, [key, value]) => {
      if (value === undefined) return acc;
      return { ...acc, [key]: value };
    }, {});

    const encodedQuery = includeExistingSearch
      ? encodeQueryParams(queryConfiguration, { ...filteredQuery, ...search })
      : encodeQueryParams(queryConfiguration, { ...search });

    const searchPart = { search: stringify(encodedQuery) };

    return {
      ...pathPart,
      ...searchPart,
    };
  };

  return {
    query,
    queryContains,
    watchQuery,
    setQuery,
    mutateQuery,
    addQuery,
    deleteQuery,
    pathWithQuery,
  };
};

export default useQuery;
