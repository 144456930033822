import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import AppContext from "contexts/AppContext";
import {
  Form,
  PlaceholderText,
  TextInput,
  TextArea,
  Button,
} from "components/shared";
import { Hint } from "components/typography";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { useMetaEnter, useFlash, useConfirm } from "hooks";
import { pluralize } from "utils";

const ComposeEmail = ({ to, defaultSubject, defaultText, ...props }) => {
  const { API } = useContext(AppContext);
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [ConfirmModal, confirmSend] = useConfirm(
    "Send email?",
    `Are you sure you want to send this email to ${
      to
        ? to.constructor === Array
          ? `${pluralize(to.length, "user")}`
          : `${to.name} (${to.email})`
        : "this user"
    }?`,
  );

  const { warningFlash, successFlash } = useFlash();

  const subjectInput = useRef();
  const bodyInput = useRef();

  useEffect(() => {
    if (defaultSubject) setSubject(defaultSubject);
  }, [defaultSubject]);

  useEffect(() => {
    if (defaultText) setText(defaultText);
  }, [defaultText]);

  const SUBMISSION_CHECKS = [
    {
      condition: () => subject.length === 0,
      message: () => "You must set a subject.",
    },
    {
      condition: () => text.length === 0,
      message: () => "You must add some text to the body.",
    },
  ];

  const canSubmit = useMemo(
    () => SUBMISSION_CHECKS.every(({ condition }) => !condition()),
    [subject, text],
  );

  const handleSubmit = async () => {
    SUBMISSION_CHECKS.forEach(
      ({ condition, message }) => condition() && warningFlash(message()),
    );

    if (!canSubmit) return false;

    if (props.confirm) {
      const isConfirmed = await confirmSend();
      if (!isConfirmed) return false;
    }

    const body = {
      email: {
        subject,
        text,
        ...(to.constructor === Array
          ? { to_ids: to.map((to) => to.id) }
          : { to_id: to.id }),
      },
    };

    setIsSendingEmail(true);
    const response = await API.post("/emails", body, { onError: warningFlash });

    if (response) {
      successFlash(
        `Your email was sent to ${
          to.constructor === Array
            ? `${pluralize(to.length, "user")}`
            : `${to.name} (${to.email})`
        }`,
      );

      subjectInput.current.value = "";
      bodyInput.current.value = "";

      setSubject("");
      setText("");
      setIsSendingEmail(false);

      if (props.onSubmit) props.onSubmit();
    }
  };

  const { handleMetaEnter, metaKey } = useMetaEnter(handleSubmit);

  return (
    <>
      <Form className="flex flex-col items-end h-full relative">
        <div className="px-6 py-4 text-sm border-b border-gray-200 w-full">
          {to ? (
            to.constructor === Array ? (
              to.length > 0 ? (
                <>
                  Email to {to[0].name}{" "}
                  <Hint inline>and {pluralize(to.length - 1, "other")}</Hint>
                </>
              ) : (
                <Hint inline>Select user(s) to email</Hint>
              )
            ) : (
              <>
                Email to {to.name} <Hint inline>({to.email})</Hint>
              </>
            )
          ) : (
            <PlaceholderText size="sm" />
          )}
        </div>
        <TextInput
          ref={subjectInput}
          required
          className="w-full"
          inputClassName="shadow-none rounded-none border-t-0 border-l-0 border-r-0 border-b border-gray-200 focus:ring-0 focus:border-gray-200 py-4"
          name="subject"
          placeholder="Subject"
          onChange={(e) => setSubject(e.target.value)}
          value={subject}
          onKeyDown={handleMetaEnter}
        />
        <TextArea
          ref={bodyInput}
          required
          className="w-full flex-grow"
          containerClassName="border-none rounded-none shadow-none focus-within:ring-0"
          inputClassName="h-full"
          name="text"
          placeholder="Body"
          rows={8}
          defaultValue={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handleMetaEnter}
        />
        {props.extra}
        <Button
          Icon={PaperAirplaneIcon}
          disabled={!canSubmit || isSendingEmail}
          onClick={handleSubmit}
          className="absolute bottom-4 right-6"
        >
          {isSendingEmail ? (
            "Sending..."
          ) : (
            <>
              Send
              <span className="text-xs ml-1">({metaKey} + Enter)</span>
            </>
          )}
        </Button>
      </Form>
      <ConfirmModal />
    </>
  );
};

export default ComposeEmail;
