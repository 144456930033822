import React, { Fragment, useContext } from 'react'
import AppContext from 'contexts/AppContext'
import { Avatar, Tooltip } from 'components/shared'
import UserLabels from 'components/users/UserLabels'
import { classNames } from 'utils'
import { HandRaisedIcon, CheckBadgeIcon } from '@heroicons/react/24/outline'

const UserDetails = ({ user, ...props }) => {
  const { currentUser } = useContext(AppContext)

  return(
    <>
      <div className="flex-shrink-0 relative">
        {
          user.certificationRequested &&
          <div className='absolute -left-2 -top-1 bg-yellow-400 rounded-full h-5 w-5 flex items-center justify-center'>
            <Tooltip text='Requesting certification'>
              <HandRaisedIcon className='h-3 w-3 text-white' />
            </Tooltip>
          </div>
        }

        {
          user.certified &&
          <div className='absolute -left-2 -top-1 bg-indigo-600 rounded-full h-5 w-5 flex items-center justify-center'>
            <Tooltip text='Certified'>
              <CheckBadgeIcon className='h-4 w-4 text-indigo-50' />
            </Tooltip>
          </div>
        }
        <Avatar { ...user } />
      </div>

      <div className="min-w-0 flex-1">
        <div className="focus:outline-none flex justify-between items-center">
          <div className="pr-1 max-w-[100%]">
            <p className="text-sm font-medium text-gray-900">
              <span className="pr-1">
              {
               user.id === currentUser.id
               ? "You"
               : currentUser.isAdmin || currentUser.isTrainer
               ? user.name
               : user.firstName
              }
              </span>

              {
                (currentUser.isAdmin || currentUser.isTrainer) &&
                <UserLabels {...user } />
              }
            </p>
            <p className="truncate text-xs text-gray-500">
              {
                user.title && `${ user.title }`
              }
              {
                user.title && user.organization && ` at `
              }
              {
                user.organization && `${ user.organization.name }`
              }
            </p>
          </div>
          
          { props.children }
        </div>
      </div>
    </>
  )
}

export default UserDetails
