import React, { useState } from "react";
import { Popover } from "@headlessui/react";
import { SearchBar, RemoteTextInput, Button, Tooltip } from "components/shared";
import { TagIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import LabelInDialog from "components/users/LabelInDialog";
import { useFetch, useMetaEnter } from "hooks";
import { classNames } from "utils";

const UserLabelsMenu = ({ user, ...props }) => {
  const [labels, setLabels] = useState([]);
  const [searchText, setSearchText] = useState(null);

  const filteredLabels = React.useMemo(() => {
    if (!searchText) return labels;

    return labels.filter((label) =>
      label.text.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [user, labels, searchText]);

  const { fetch: fetchLabels } = useFetch(`labels`, setLabels, [user]);

  const { metaKey } = useMetaEnter();

  return (
    <Tooltip
      on="click"
      delay={200}
      white
      panel={
        <div className="rounded-md w-72">
          <RemoteTextInput
            title="Label"
            name="text"
            placeholder="Find or create label"
            method="post"
            path={"labels"}
            LeadingIcon={MagnifyingGlassIcon}
            onChange={(e) => setSearchText(e.target.value)}
            transform={(text) => ({ label: { text } })}
            onSubmit={fetchLabels}
            inputClassName="py-3 rounded-b-none border-t-0 border-l-0 border-r-0 shadow-none border-b border-gray-200"
          />

          {filteredLabels?.length > 0 ? (
            filteredLabels.map((label, index) => (
              <LabelInDialog
                key={label.id}
                user={user}
                className={classNames(
                  index === filteredLabels.length - 1 && "rounded-b-md",
                )}
                {...label}
                onChange={props.onChange}
              />
            ))
          ) : (
            <LabelInDialog
              key={0}
              disabled
              className="rounded-b-md"
              user={user}
              text={
                searchText
                  ? `No label ${searchText}. Create with ${metaKey} + Enter.`
                  : `No labels found. Create with ${metaKey} + Enter.`
              }
            />
          )}
        </div>
      }
    >
      <Tooltip text="Manage labels">
        <TagIcon className="-mb-1.5 h-6 w-6 text-gray-400 hover:text-gray-600 cursor-pointer" />
      </Tooltip>
    </Tooltip>
  );
};

export default UserLabelsMenu;
