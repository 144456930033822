import React from 'react'
import { classNames } from 'utils'
import Pairer from 'components/questions/questionTypes/Pairer'

const PairerQuestion = (props) => {
  return(
    <div className={classNames("px-4 py-2", props.className)}>
      <Pairer id={props.pairerId} onComplete={props.onComplete} />
    </div>
  )
}

export default PairerQuestion
