import React, { useContext } from 'react'
import AppContext from 'contexts/AppContext'
import { SparklesIcon } from '@heroicons/react/24/outline'
import { Button } from 'components/shared'
import { usePost } from 'hooks'
import { classNames } from 'utils'

const GenericPositiveCommentButton = ({ user, question, discussion, response, ...props }) => {
  const { currentUser } = useContext(AppContext)
  const { post, data: commentData } = usePost(props.onSubmit)

  const POSITIVE_EMOJI = ["😄", "😃", "😺", "⭐", "🤩", "🚀", "😍", "🎉", "💪", "🤘", "👍", "🙏", "🙇", "🙌", "✅", "👌", "😻", "💃", "💥", "🐋", "✨"]

  const POSITIVE_STATEMENTS = [
    "Great work",
    "Love it",
    "Top job",
    "Well done",
    "Nice answer",
    "Right on",
    "Nailed it",
    "Fabulous",
    "Super",
    "Wicked",
    "Nice one",
    "Great",
    "You got it"
  ]

  const PUNCTUATIONS = ['!', '.']

  const comment = () => {
    const statement = POSITIVE_STATEMENTS[Math.floor(Math.random() * POSITIVE_STATEMENTS.length)]
    const punctuation = PUNCTUATIONS[Math.floor(Math.random() * PUNCTUATIONS.length)]
    const emoji = POSITIVE_EMOJI[Math.floor(Math.random() * POSITIVE_EMOJI.length)]

    const isEmojiFirst = Math.floor(Math.random() * 2) === 0 // 50%
    const hasPunctuation = Math.floor(Math.random() * 4) !== 0 // 75%
    const useFirstName = Math.floor(Math.random() * 4) === 0 // 25%

    if(isEmojiFirst) {
      return `${emoji} ${statement}${useFirstName ? `, ${user.firstName}` : ''}${hasPunctuation ? punctuation : ''}`
    }

    return `${statement}${useFirstName ? `, ${ user.firstName }` : ''}${hasPunctuation? punctuation : ''} ${emoji}`
  }

  const submit = async (e) => {
    e.preventDefault()
    const body = { comment: { text: comment().trim(), user_id: currentUser.id } }

    post(`discussions/${discussion.id}/responses/${response.id}/comments`, body)
  }

  return(
    <Button
     type="pill"
     className={ classNames("mt-3", commentData.loading && 'animate-pulse') }
     Icon={SparklesIcon}
     onClick={submit}
     disabled={commentData.loading}>
     {
      commentData.loading
      ? 'Adding comment'
      : 'Positive comment'
     }
    </Button>
  )
}

export default GenericPositiveCommentButton