import React, { useState, useEffect, useContext } from 'react'
import { get } from 'api'
import AppContext from 'contexts/AppContext'

const useWatchJob = (onSuccess, onError = null) => {
  const { apiUrl } = useContext(AppContext)

  const [poller, setPoller] = useState(null)
  const [job, setJob] = useState(null)
  const [data, setData] = useState({ isLoading: false, errorMessage: null })

  useEffect(() => {
    if(job) {
      if(job.status === 'failed' || job.activity === 'Cancelled') {
        clearInterval(poller)
        setData({ ...data, isLoading: false })
      }

      if(job.status === 'complete') {
        clearInterval(poller)
        setData({ ...data, isLoading: false })
        onSuccess()
      }
    } else {
      clearInterval(poller)
      setData({ ...data, isLoading: false })
    }
  }, [job])

  const handleError = (error) => {
    clearInterval(poller)
    setData({ ...data, isLoading: false, errorMessage: error })
    if(onError) onError(error)
  }

  const watchJob = (jid) => {
    if(!jid) return

    setData({ ...data, isLoading: true })

    setPoller(setInterval(() => {
      get(`${apiUrl}/jobs/${jid}/status`,
          setJob,
          handleError)
    }, 5000))
  }

  return { watchJob, job, data }
}

export default useWatchJob
