import React, { useState, useEffect, useContext } from "react";
import AppContext from "contexts/AppContext";
import { ContentEditable, Loading, Button } from "components/shared";
import { useFlash } from "hooks";
import { useDebounce } from "react-use";
import { cn } from "utils";

const EditMetadataBar = React.forwardRef(
  (
    { stage, isLoading, setIsLoading, showTitle = true, error, ...props },
    ref,
  ) => {
    const { API, editorMode } = useContext(AppContext);
    const { errorFlash } = useFlash();

    const [title, setTitle] = useState(stage?.title || `Untitled Stage`);
    const [debouncedTitle, setDebouncedTitle] = useState(title);
    const handleTitleChange = (e) => {
      setTitle(e.target.value);
      setIsLoading(true);
    };

    const [, cancel] = useDebounce(
      () => {
        if (editorMode) setDebouncedTitle(title);
      },
      2000,
      [title, editorMode],
    );

    const hasExtraActions = props.allowManageIntro || props.allowPublish;

    const updateStage = async (properties, callback) => {
      const body = {
        activity_stage: properties,
      };

      const response = await API.put(`/activity_stages/${stage.slug}`, body, {
        onError: errorFlash,
      });

      if (response) {
        setIsLoading(false);
        if (callback) callback(response);
      }
    };

    useEffect(() => {
      if (editorMode && stage) {
        updateStage({ title: debouncedTitle });
      }
    }, [debouncedTitle, editorMode]);

    return (
      <div
        ref={ref}
        className={cn(
          "flex justify-between px-8 py-2 border-b border-gray-200",
          props.className,
        )}
      >
        <div className="text-sm text-gray-500 h-8 flex items-center">
          Editing
          {showTitle && (
            <ContentEditable
              className="font-semibold inline focus:outline-none ml-1"
              html={title}
              onChange={handleTitleChange}
            />
          )}
        </div>

        <div className="flex space-x-4 items-center h-8">
          {error ? (
            <div className="text-sm text-gray-400">
              Error:{" "}
              {typeof error === "object" ? error.message : "changes not saved"}
            </div>
          ) : isLoading ? (
            <Loading
              size="sm"
              message="Saving changes..."
              className="relative"
            />
          ) : (
            <div className="text-sm text-gray-400">Changes saved</div>
          )}

          {hasExtraActions && (
            <div className="flex items-center justify-center space-x-2">
              {props.allowManageIntro && (
                <Button
                  disabled={isLoading}
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    !!stage.introBlocks
                      ? props.onEditIntro()
                      : updateStage({ intro_blocks: [] }, props.onAddIntro)
                  }}
                >
                  {!!stage.introBlocks
                    ? "Edit intro"
                    : "Add intro"}
                </Button>
              )}
              {props.allowPublish && (
                <Button
                  disabled={isLoading}
                  size="sm"
                  onClick={() => updateStage({ draft: !stage.isDraft }, props.onUpdate)}
                >
                  {stage.isDraft ? "Publish" : "Unpublish"}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default EditMetadataBar;
