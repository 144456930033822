import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import UserGraph from "components/users/UserGraph";
import UserFlowGraph from "components/users/UserFlowGraph";
import { Avatar, Button, Tooltip } from "components/shared";
import { H2, Hint } from "components/typography";
import UserLabels from "components/users/UserLabels";
import UserLabelsMenu from "components/users/UserLabelsMenu";
import { ReactFlowProvider } from "reactflow";
import { useQuery } from "hooks";
import {
  EnvelopeIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from "@heroicons/react/20/solid";
import { classNames } from "utils";
import ManualCertificationToggle from "components/certification/ManualCertificationToggle";

const UserProfileHeader = ({ user, ...props }) => {
  const { currentUser } = useContext(AppContext);
  const [graphExpanded, setGraphExpanded] = useState(false);
  const [showLabelModal, setShowLabelModal] = useState(false);

  const { pathWithQuery } = useQuery();

  const completeActivityAttemptsWithRatings = user.activityAttempts.filter(a => (a.status === "complete" && a.rating !== null))

  return (
    <div>
      <div>
        <div
          className={classNames(
            "w-full relative border-b border-gray-200",
            graphExpanded ? "h-screen" : "h-48",
          )}
        >
          {user.featureFlagsEnabled.find(({ slug }) => slug === "graph-v2") ? (
            <ReactFlowProvider>
              <UserFlowGraph user={user} />
            </ReactFlowProvider>
          ) : (
            <UserGraph id={user.id} courseId={user.course?.id} />
          )}
        </div>
      </div>
      <div className="px-8">
        <div
          className={classNames(
            "relative z-10 flex items-end space-x-5",
            graphExpanded ? "-mt-32" : "-mt-12",
          )}
        >
          <div className="flex">
            <Avatar
              {...user}
              size="xxl"
              className={classNames(
                "ring-4",
                user.selectedPlanType === "paid"
                  ? "ring-indigo-600"
                  : "ring-white",
              )}
            />
          </div>
          <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
              <H2 className="truncate flex items-center">
                {currentUser.isAdmin || currentUser.isTrainer ? (
                  <span className="font-bold text-2xl text-gray-900 pr-2">
                    {currentUser.id === user.id ? "You" : user.name}
                  </span>
                ) : (
                  <span className="font-bold text-2xl text-gray-900 pr-2">
                    {currentUser.id === user.id ? "You" : user.firstName}
                  </span>
                )}

                {(currentUser.isAdmin || currentUser.isTrainer) && (
                  <>
                    <UserLabels {...user} className="pr-2" />
                    <UserLabelsMenu user={user} onChange={props.onChange} />
                  </>
                )}
              </H2>
            </div>
            <div className="relative justify-stretch mt-6 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
              <div className="absolute -top-10 right-0">
                {graphExpanded ? (
                  <Button
                    type="pill"
                    onClick={() => setGraphExpanded(false)}
                    Icon={ArrowsPointingInIcon}
                  >
                    Minimise
                  </Button>
                ) : (
                  <Button
                    type="pill"
                    onClick={() => setGraphExpanded(true)}
                    Icon={ArrowsPointingOutIcon}
                  >
                    Expand
                  </Button>
                )}
              </div>
              {(currentUser.isAdmin || currentUser.isTrainer) && (
                <div className="flex flex-wrap space-x-2 items-center justify-end">
                  <ManualCertificationToggle
                    small
                    user={user}
                    onChangeCertification={props.onChange}
                  />
                  <Button
                    size="sm"
                    type="routeLink"
                    variant="secondary"
                    Icon={EnvelopeIcon}
                    to={pathWithQuery(null, { userMail: user.id })}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
          <h1 className="truncate flex items-center">
            {currentUser.isAdmin || currentUser.isTrainer ? (
              <span className="text-2xl font-bold text-gray-900 pr-1">
                {user.name}
              </span>
            ) : (
              <span className="text-2xl font-bold text-gray-900 pr-1">
                {user.firstName}
              </span>
            )}

            {(currentUser.isAdmin || currentUser.isTrainer) && (
              <>
                <UserLabels {...user} className="pr-2" />
                <UserLabelsMenu user={user} onChange={props.onChange} />
              </>
            )}
          </h1>
        </div>
        {(currentUser.isAdmin || currentUser.isTrainer) && completeActivityAttemptsWithRatings.length > 0 && (
          <Tooltip panel={
            <ul className='py-4 px-8 text-white opacity-90 text-xs'>
              {completeActivityAttemptsWithRatings.map(attempt => (
                <li key={attempt.id}>
                  <span>{attempt.activity.title}: {attempt.rating}</span>
                </li>
              ))}
            </ul>
          }>
          <Hint inline small>
            Average activity rating: {Math.round(completeActivityAttemptsWithRatings.reduce((acc, a) => acc + a.rating, 0) / completeActivityAttemptsWithRatings.length, 2)}
          </Hint>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default UserProfileHeader;
