import React from "react";
import { motion } from "framer-motion";
import { PaperClipIcon, EyeIcon } from "@heroicons/react/24/outline";
import { Button } from "components/shared";
import { useQuery } from "hooks";
import { cn } from "utils";

const Option = ({ id, title, summary, detail, information, color, ...props }) => {
  const { pathWithQuery } = useQuery();

  const COLOR_TO_BG = {
    gray: "bg-gray-50",
    red: "bg-red-50",
    yellow: "bg-yellow-50",
    green: "bg-green-50",
    blue: "bg-blue-50",
    indigo: "bg-indigo-50",
    purple: "bg-purple-50",
    pink: "bg-pink-50",
  };

  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{
        delay: 0.15 + (props.index + 1) * 0.35,
        duration: 0.95,
        ease: [0.075, 0.82, 0.165, 1],
      }}
      className="bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow"
    >
      <div
        className={cn(
          "flex items-center justify-between space-x-4 border-b border-gray-200 px-4 py-2 font-semibold text-gray-600 text-sm rounded-t-lg",
          COLOR_TO_BG[color],
        )}
      >
        <div className="flex items-center space-x-2">
          <div>{title}</div>
        </div>

        <div className="flex items-center space-x-2">
          {information && (
            <Button
              type="routeLink"
              to={pathWithQuery(null, { conversationOptionId: id })}
              variant="secondary"
              size="xs"
              Icon={EyeIcon}
            >
              View
            </Button>
          )}
          <Button
            variant="secondary"
            size="xs"
            Icon={PaperClipIcon}
            onClick={props.attached ? props.onUnattach : props.onAttach}
          >
            {props.attached ? "Unattach" : "Attach"}
          </Button>
        </div>
      </div>
      <div className="px-4 pt-2 pb-4 space-y-1">
        <div className="font-semibold text-gray-400 text-sm">{summary}</div>
        <div className="text-gray-600 text-xs">{detail}</div>
      </div>
    </motion.div>
  );
};

export default Option;
