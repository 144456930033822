import React, { useContext, useMemo } from 'react'
import AppContext from 'contexts/AppContext'
import QueryContext from 'contexts/QueryContext'
import { Modal, Button } from 'components/shared'

const PaymentSuccessModal = ({ ...props }) => {
  const { currentUser } = useContext(AppContext)
  const { query, setQuery } = useContext(QueryContext)

  const open = useMemo(() => query['payment-point'] === "end-of-trial", [query['payment-point']])
  const onClose = () => setQuery({ ...query, "payment-point": undefined })

  return(
    <Modal
     size={'prose'}
     open={open}
     onClose={onClose}
     title={`Nice one, ${currentUser.firstName}!`}
     titleClassName='text-3xl pb-4'
     button={
      <Button
       id='dismissModalButton'
       onClick={onClose}
       className='block mx-auto'
       size='lg'>
       🎉  Let's go!
      </Button>}>
      <div className='prose'>
        <p>You're now a full member of TechMap for life. Let's keep learning.</p>
      </div>
    </Modal>
  )
}

export default PaymentSuccessModal