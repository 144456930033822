import React, { forwardRef } from "react";
import { cn } from "utils";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

export const H1 = (props) => (
  <h1 className={cn("text-3xl font-medium text-gray-700", props.className)}>
    {props.children}
  </h1>
);

export const H2 = (props) => (
  <h2 className={cn("text-2xl font-medium text-gray-700", props.className)}>
    {props.children}
  </h2>
);

export const H3 = forwardRef((props, ref) => (
  <h3
    ref={ref}
    className={cn("text-xl font-medium text-gray-700", props.className)}
  >
    {props.children}
  </h3>
));

export const H4 = (props) => (
  <h4 className={cn("text-lg font-medium text-gray-700", props.className)}>
    {props.children}
  </h4>
);

export const H5 = (props) => (
  <h5 className={cn("text-base font-medium text-gray-700", props.className)}>
    {props.children}
  </h5>
);

export const P = (props) => (
  <p
    className={cn(
      "text-gray-700",
      (props.sm || props.small) && "text-sm",
      props.xs && "text-xs",
      props.className,
    )}
  >
    {props.children}
  </p>
);

export const A = (props) => (
  <a
    {...props}
    className={cn(
      "text-indigo-600 hover:text-indigo-800 cursor-pointer",
      props.className,
    )}
  >
    {props.children}
    {props.target === "_blank" && (
      <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block ml-1 opacity-80 relative -top-px -mr-0.5" />
    )}
  </a>
);

export const Hint = ({
  children,
  className,
  center,
  big,
  small,
  tiny,
  xs,
  sm,
  inline,
}) => {
  return (
    <div
      className={cn(
        "text-sm text-gray-400 font-normal",
        center && "text-center",
        (tiny || small || xs || sm) && "text-xs",
        big && "text-base",
        inline && "inline",
        className,
      )}
    >
      {children}
    </div>
  );
};

export const Semibold = (props) => (
  <span className={cn("font-semibold", props.className)}>{props.children}</span>
);
