import React, { useState, useEffect, useContext } from "react";
import AppContext from "contexts/AppContext";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useQuery } from "hooks";
import { Modal, Loading } from "components/shared";
import { cn } from "utils";

const ViewOptionModal = ({ ...props }) => {
  const { API } = useContext(AppContext);

  const [option, setOption] = useState(null);
  const { watchQuery, deleteQuery } = useQuery();

  const id = watchQuery("conversationOptionId");
  const onClose = () => deleteQuery("conversationOptionId");

  const fetchOption = async (id) => {
    const response = await API.get(`/options/${id}`);
    setOption(response);
  };

  useEffect(() => {
    if (id) fetchOption(id);
  }, [id]);

  if (!option)
    return (
      <Modal size="prose" open={!!id} onClose={onClose}>
        <Loading />
      </Modal>
    );

  return (
    <Modal size="prose" open={!!id} onClose={onClose} dismissible>
      <ReactMarkdown
        className={"prose-sm prose-ul:list-disc prose-ol:list-decimal px-4 py-6 text-left"}
        children={option.information}
        remarkPlugins={[remarkGfm]}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <SyntaxHighlighter
                {...props}
                children={String(children).replace(/\n$/, "")}
                style={oneDark}
                language={match[1]}
                PreTag="div"
              />
            ) : (
              <code {...props} className={className}>
                {children}
              </code>
            );
          },
        }}
      />
    </Modal>
  );
};

export default ViewOptionModal;
