import React, { useRef, useContext } from 'react'
import AppContext from 'contexts/AppContext'
import { TextInput } from 'components/shared'
import { put } from 'api'
import { pure } from 'recompose'

const ChangeTrackingBranch = ({ issue, attempt, afterChangeBranch }) => {
  const { apiUrl, formToken } = useContext(AppContext)

  const issueNumber = attempt?.issueUrl
                      ? attempt.issueUrl.split('/')[attempt.issueUrl.split('/').length - 1]
                      : null

  const inputRef = useRef(null)
  const handleChangeBranch = (e) => {
    e.preventDefault()
    const body = { issue_attempt: { tracking_branch: inputRef.current.value } }
    put(`${apiUrl}/issues/${issue.slug}/issue_attempts/${attempt.id}/update_tracking_branch`,
        { formToken, body },
        afterChangeBranch)
  }

  return(
    <TextInput
     ref={inputRef}
     name="trackingBranch"
     placeholder={`e.g. ${ issueNumber }-${ issue.slug }`}
     defaultValue={attempt.trackingBranch}
     button={true}
     buttonText="Update"
     size="sm"
     onSubmit={handleChangeBranch} />
  )
}

export default pure(ChangeTrackingBranch)