import React from 'react'
import { Indicator } from 'components/shared'

const LearnerIssue = (props) => {
  const isDuplicated = !!props.issueUrl

  return(
    <div className="LearnerIssue d-flex">
      {
        props.indicator
        && <Indicator
            Positive={ isDuplicated }
            tooltip={ !isDuplicated }
            title={ 'Issue not cloned' }
            message={ 'Start this challenge to clone the issue to your Github repository.' }
            className="mr-1" />
      }
      <span className="mr-1">
        Issue{ !isDuplicated && ' not started' }
      </span>

      {
        isDuplicated
        && <a
            href={ props.issueUrl }
            target="_blank">
            { props.issueUrl }
           </a>
      }
     </div>
  )
}

LearnerIssue.defaultProps = {
  indicator: true
}

export default LearnerIssue