import React from "react";
import { Handle, Position } from "reactflow";
import { P } from "components/typography";
import { cn } from "utils";
import { BoltIcon, CheckIcon } from "@heroicons/react/24/outline";

// {
//   id: string;
//   data: T;
//   dragHandle?: boolean;
//   type?: string;
//   selected?: boolean;
//   isConnectable?: boolean;
//   zIndex?: number;
//   xPos: number;
//   yPos: number;
//   dragging: boolean;
//   targetPosition?: Position;
//   sourcePosition?: Position;
// }

const DemoNode = ({ data, isConnectable }) => {
  const isCurrent = !!data.currentAttempt;
  const isCompleted = !!data.completedAttempt;

  return (
    <span
      className={cn(
        "hover:shadow-md cursor-pointer opacity-40",
        data.unlocked && "opacity-100",
      )}
    >
      <Handle
        type="target"
        position={Position.Top}
        className={cn("opacity-0 top-0")}
      />
      <div
        className={cn(
          "bg-gray-100 border-gray-300 border px-4 py-2 rounded-lg flex items-center justify-center space-x-2",
          data.unlocked && "bg-white",
        )}
      >
        <P>{data.label}</P>
        {isCompleted ? (
          <CheckIcon className="h-4 w-4 p-0.5 rounded-full border border-lime-500 text-lime-500 bg-lime-200" />
        ) : isCurrent ? (
          <BoltIcon className="h-4 w-4 p-0.5 rounded-full border border-yellow-400 text-yellow-400 bg-yellow-200" />
        ) : null}
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        className={cn("opacity-0 bottom-0")}
      />
    </span>
  );
};

export default DemoNode;
