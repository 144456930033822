import React, { useState, useContext } from "react";
import AppContext from "contexts/AppContext";
import { Configuration, OpenAIApi } from "openai";
import { stripTags, interpolate } from "utils";
import { useIsMounted } from "hooks";
import CoachAvatar from "images/ai-coach.gif";
import {
  emailPrompts,
  coachResponsePrompt,
  judgeResponsePrompt,
  warmupResponsePrompt,
} from "prompts";

const useAiCoach = () => {
  const { openAiApiKey, heliconeApiKey, currentUser, learnerIndexUrl } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const isMounted = useIsMounted();

  const configuration = (extraHeaders = {}) => {
    return new Configuration({
      apiKey: openAiApiKey,
    });
  };

  const coach = {
    id: 0,
    firstName: "AI Coach",
    name: "AI Coach",
    isTrainer: true,
    avatar: CoachAvatar,
  };

  const generate = async (
    question,
    response,
    user,
    { harshness = currentUser.aiHarshness, instructions = "" } = {},
  ) => {
    setLoading(true);

    const openai = new OpenAIApi(configuration());

    try {
      const completion = await openai.createChatCompletion({
        model: "gpt-4o",
        messages: [
          {
            role: "system",
            content: coachResponsePrompt(question, response, {
              harshness,
              instructions,
            }),
          },
        ],
        max_tokens: 1000,
        temperature: 0.7,
      });

      if (isMounted.current) {
        setLoading(false);
        return stripTags(
          interpolate(completion.data.choices[0].message.content.trim(), user),
        );
      }
    } catch (err) {
      console.error(err);

      if (isMounted.current) {
        setLoading(false);
        return "Looks good – I can't think of anything to add right now!";
      }
    }
  };

  const judge = async (
    question,
    response,
    user,
    { harshness = currentUser.aiHarshness, instructions = "" } = {},
  ) => {
    setLoading(true);

    const extraHeaders = {
      "Helicone-Property-Question-Id": question.id,
      "Helicone-Property-Response-Id": response.id,
      "Helicone-Property-Function": "judge",
    };

    const openai = new OpenAIApi(configuration(extraHeaders));

    try {
      const completion = await openai.createChatCompletion({
        model: "gpt-4o",
        messages: [
          {
            role: "system",
            content: judgeResponsePrompt(question, response, {
              harshness,
              instructions,
            }),
          },
        ],
        max_tokens: 100,
        temperature: 0.7,
      });

      if (isMounted.current) {
        setLoading(false);
        return stripTags(
          interpolate(completion.data.choices[0].message.content.trim(), user),
        );
      }
    } catch (err) {
      console.error(err);

      if (isMounted.current) {
        setLoading(false);
        return "";
      }
    }
  };

  const warmup = async (question, response, user) => {
    setLoading(true);

    const extraHeaders = {
      "Helicone-Property-Question-Id": question.id,
      "Helicone-Property-Response-Id": response.id,
      "Helicone-Property-Function": "warmup",
    };

    const openai = new OpenAIApi(configuration(extraHeaders));

    try {
      const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: warmupResponsePrompt(question, response),
          },
        ],
        max_tokens: 100,
        temperature: 0.7,
      });

      if (isMounted.current) {
        setLoading(false);
        return stripTags(
          interpolate(completion.data.choices[0].message.content.trim(), user),
        );
      }
    } catch (err) {
      console.error(err);

      if (isMounted.current) {
        setLoading(false);
        return "";
      }
    }
  };

  const indexApi = async (
    path,
    user,
    { query = null, model = "gpt-4o" } = {},
  ) => {
    setLoading(true);

    const res = await fetch(
      `${learnerIndexUrl}/users/${user.id}${path}${
        query
          ? `?query=${encodeURIComponent(query)}&model=${encodeURIComponent(
              model,
            )}`
          : `?model=${encodeURIComponent(model)}`
      }`,
    );

    if (res.ok) {
      try {
        const { response, sources, error } = await res.json();

        const data = {
          response,
          sources: sources.map((source) => JSON.parse(source)),
          error: error,
        };

        if (error) console.error(error);

        setLoading(false);
        return data;
      } catch (err) {
        console.error(err);
      }
    } else {
      console.error(res);
    }
  };

  const generateCertificationOverview = async (user) => {
    const data = await indexApi("/certify", user);
    return data;
  };

  const generateEmail = async (type, { to, from }) => {
    const data = await indexApi("/query", to, {
      query: emailPrompts[type](to, from),
      model: "gpt-4o",
    });
    return data;
  };

  const generateSubject = async (emailText) => {
    setLoading(true);

    const extraHeaders = {
      "Helicone-Property-Function": "generateSubject",
    };

    const openai = new OpenAIApi(configuration(extraHeaders));

    try {
      const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: emailPrompts.generateSubject(emailText),
          },
        ],
        max_tokens: 400,
        temperature: 0.7,
      });

      if (isMounted.current) {
        setLoading(false);
        return completion.data.choices[0].message.content.trim();
      }
    } catch (err) {
      console.error(err);

      if (isMounted.current) {
        setLoading(false);
        return "Subject could not be generated";
      }
    }
  };

  return {
    coach,
    generate,
    judge,
    warmup,
    generateCertificationOverview,
    generateEmail,
    generateSubject,
    loading,
    setLoading,
  };
};

export default useAiCoach;
