import React, { Fragment } from 'react'
import { Popover } from '@headlessui/react'
import { SelectInput, DotBadge } from 'components/shared'
import { FunnelIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { classNames } from 'utils'

const NotificationFilters = ({ filters, ...props }) => {
  const filtersApplied = Object
                        .entries(filters)
                        .filter(([key, value]) => {
                          if(key === 'order')  return false
                          if(key === 'limit') return false

                          return value
                        }).map(([key, value]) => key)

  return(
    <Popover>
      <Popover.Button className="relative inline-flex justify-center rounded-md border border-gray-300 bg-white px-3.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2">
        <span className="sr-only">Open filters</span>
        <FunnelIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        {
          filtersApplied.length > 0 &&
          <DotBadge value={filtersApplied.length} />
        }
      </Popover.Button>

      <Popover.Panel className="absolute mt-2 z-50 bg-white rounded-md shadow-sm border border-gray-200">
        <div className="bg-gray-50 border-b border-gray-200 px-4 py-2">
          <h4 className="text-xs text-gray-500 font-medium">Apply filters</h4>
        </div>

        <div className="px-4 py-4 space-y-2 flex flex-col items-end">
          <div className="flex w-full items-center space-x-2">
            {
              filtersApplied.includes('status') &&
              <XMarkIcon
               className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer"
               onClick={() => props.onChange({ name: 'status', value: null })} />
            }

            <SelectInput
             onChange={(e) => props.onChange({ name: 'status', value: e.target.value })}
             defaultValue={"0"}
             className="w-64">
             <option value="0" disabled>Filter by status</option>
             <option value="all">All</option>
             <option value="unread">Unread</option>
             <option value="read">Read</option>
            </SelectInput>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  )
}

export default NotificationFilters
