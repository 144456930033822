import React, { useState, useContext } from "react";
import { Button, Hint, ProgressLine } from "components/shared";
import LearnerIssue from "components/github/LearnerIssue";
import moment from "moment";
import { usePost, useWatchJob } from "hooks";
import AppContext from "contexts/AppContext";

const StartIssue = (props) => {
  const { currentUser } = useContext(AppContext);

  const { watchJob, data: duplicateToGithubJob } = useWatchJob(
    props.onChangeStatus,
  );

  const { post: startIssue, data } = usePost(
    `issues/${props.issue.id}/issue_attempts`,
    (attempt) => watchJob(attempt.jid),
  );

  const startChallengeOrNothing = () => {
    if (!(currentUser.githubUsername && currentUser.githubAccessToken))
      return null;
    if (props.attempt?.started) return null;
    return (
      <Button
        onClick={() => startIssue()}
        loading={data.isLoading || duplicateToGithubJob.isLoading}
      >
        {duplicateToGithubJob.isLoading
          ? `Duplicating Issue to Github`
          : "Start challenge"}
      </Button>
    );
  };

  return (
    <ProgressLine.Widget>
      <ProgressLine.Widget.Indicator
        filled={props.attempt?.started}
        active={!props.attempt?.started}
      />

      <ProgressLine.Widget.Details>
        <ProgressLine.Widget.Details.Header>
          <div className="WidgetTitle">
            Challenge started
            {props.attempt?.started && (
              <Hint className="ml-1">
                {moment(props.attempt.startedAt).fromNow()}
              </Hint>
            )}
          </div>
          {props.attempt?.started && (
            <Hint>
              <LearnerIssue {...props.attempt} indicator={false} />
            </Hint>
          )}
        </ProgressLine.Widget.Details.Header>

        <ProgressLine.Widget.Details.Action>
          {startChallengeOrNothing()}
        </ProgressLine.Widget.Details.Action>
      </ProgressLine.Widget.Details>
    </ProgressLine.Widget>
  );
};

export default StartIssue;
