import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "contexts/AppContext";
import { Hint, PlaceholderText } from "components/shared";
import { useFetch, usePost, useDestroy, useTour } from "hooks";
import Response from "components/coaching/Response";
import Responses from "components/coaching/Responses";
import DiscussionMenu from "components/discussions/DiscussionMenu";
import { useFlash } from "hooks";
import { cn } from "utils";

const Discussion = ({ id, constraints, staticDiscussion, ...props }) => {
  const { currentUser, API } = useContext(AppContext);
  const { errorFlash } = useFlash();
  const [discussion, setDiscussion] = useState(
    staticDiscussion ? staticDiscussion : { id },
  );
  const [viewInterval, setViewInterval] = useState(null);
  const [isViewed, setIsViewed] = useState(false);
  const [isFlagged, setIsFlagged] = useState(false);
  const [isExemplar, setIsExemplar] = useState(false);

  const element = useRef(null);

  // Discussion views
  const { post: postDiscussionView } = usePost(() => {
    setIsViewed(true);
    if (props.onView) props.onView(id);
  });

  const viewDiscussion = (e) => {
    if (isViewed) return false;

    postDiscussionView(`discussions/${id}/discussion_views`);
  };

  const { destroy: destroyDiscussionView } = useDestroy(() => {
    setIsViewed(false);
    if (props.onMarkUnviewed) props.onMarkUnviewed(id);
  });

  const unviewDiscussion = () =>
    destroyDiscussionView(
      `discussions/${id}/discussion_views?user_id=${currentUser.id}`,
    );

  const handleFetchDiscussion = (discussion) => {
    setDiscussion(discussion);
    setIsViewed(
      discussion.viewers && discussion.viewers.includes(currentUser.id),
    );
    setIsFlagged(
      discussion.flaggers && discussion.flaggers.includes(currentUser.id),
    );
    setIsExemplar(discussion.isExemplar);
    if (props.onLoad) props.onLoad(discussion);
  };

  // Discussion flags
  const { post: postDiscussionFlag } = usePost(() => {
    setIsFlagged(true);
    if (props.onFlag) props.onFlag(id);
  });

  const flagDiscussion = () =>
    postDiscussionFlag(`discussions/${id}/discussion_flags`);

  const { destroy: destroyDiscussionFlag } = useDestroy(() => {
    setIsFlagged(false);
    if (props.onMarkUnviewed) props.onMarkUnviewed(id);
  });

  const unflagDiscussion = () =>
    destroyDiscussionFlag(
      `discussions/${id}/discussion_flags?user_id=${currentUser.id}`,
    );

  // Discussion exemplars
  const updateDiscussion = async (id, properties) => {
    const body = {
      discussion: {
        ...properties,
      },
    };

    const res = await API.put(`/discussions/${id}`, body, {
      onError: errorFlash,
    });

    return res;
  };

  const exemplarDiscussion = async () => {
    const res = await updateDiscussion(id, { exemplar: true });
    if (res) setIsExemplar(true);
  };

  const unexemplarDiscussion = async () => {
    const res = await updateDiscussion(id, { exemplar: false });
    if (res) setIsExemplar(false);
  };

  const { loadingOrError } = useFetch(
    `discussions/${id}`,
    handleFetchDiscussion,
    [id],
    { onlyIf: id && !props.static },
  );

  if (loadingOrError)
    return (
      <div
        className={cn(
          "relative pt-8 px-8 @xs:pt-6 @xs:px-0 @xs:pl-4 min-h-20",
          props.className,
        )}
      >
        <Response isPlaceholder isLast showActions={false} />
      </div>
    );

  return (
    <div
      ref={element}
      id={props.htmlId || "answering-questions-tour-1"}
      className={cn("pt-8 px-8 @xs:pt-6 @xs:px-0 @xs:pl-4", props.className)}
      onMouseEnter={viewDiscussion}
    >
      {props.hint && <Hint className="@lg:pl-14 mb-2">{props.hint}</Hint>}
      <div className="relative">
        {currentUser.isTrainer && (
          <DiscussionMenu
            className="absolute top-0 right-0 z-10"
            onMarkUnviewed={unviewDiscussion}
            isFlagged={isFlagged}
            onFlag={flagDiscussion}
            onUnflag={unflagDiscussion}
            isExemplar={isExemplar}
            onExemplar={exemplarDiscussion}
            onUnexemplar={unexemplarDiscussion}
          />
        )}

        <Responses
          {...props}
          question={discussion.question}
          constraints={constraints}
          discussion={discussion}
          user={discussion.user}
          onChange={props.onChange}
          onSubmit={props.onSubmitResponse}
          onDelete={props.onDeleteResponse}
          isViewed={isViewed}
          isFlagged={isFlagged}
          isExemplar={isExemplar}
        />
      </div>
    </div>
  );
};

export default Discussion;
