import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Alert, Loading } from "components/shared";
import { post as apiPost } from "api";
import { useFlash, useIsMounted } from "hooks";

const usePost = (onSuccess, onError = null) => {
  const isMounted = useIsMounted();

  const { apiUrl, formToken } = useContext(AppContext);
  const { errorFlash } = useFlash();

  const [data, setData] = useState({ isLoading: false, errorMessage: null });

  const handleSuccess = (data) => {
    // success is often a setState call
    if (isMounted.current) {
      setData({ ...data, isLoading: false });
      if (onSuccess) onSuccess(data);
    }
  };

  const handleError = async (response) => {
    if (isMounted.current) {
      const error = await response.json();

      setData({ isLoading: false, errorMessage: error.message });
      errorFlash(error.message);
      if (onError) onError(error);
    }
  };

  const post = (path, body = {}) => {
    setData({ ...data, isLoading: true });
    apiPost(
      `${apiUrl}/${path}`,
      { formToken, body },
      handleSuccess,
      handleError,
    );
  };

  const loadingOrError = () => {
    return data.errorMessage ? (
      <Alert type="danger">{data.errorMessage}</Alert>
    ) : data.isLoading ? (
      <Loading />
    ) : null;
  };

  return { post, data, setData, loadingOrError };
};

export default usePost;
