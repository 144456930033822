import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import DatePicker from "tailwind-datepicker-react";
import {
  Detail,
  RemoteSelectInput,
  TextInput,
  TextArea,
  NumberInput,
  Button,
  Form,
  Alert,
  TimePicker,
  TimeZonePicker,
  Label,
  Tooltip,
} from "components/shared";
import { useFlash } from "hooks";
import moment from "moment";
import { cn } from "utils";

const AddLiveSession = ({ ...props }) => {
  const { API } = useContext(AppContext);
  const { successFlash, errorFlash } = useFlash();
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [courseId, setCourseId] = useState(null);

  // in the format YYYY-MM-DD
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  // in the format HH:mm
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");

  // in the TZ identifier format, e.g. America/Los_Angeles
  const [timeZone, setTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  const [showDatePicker, setShowDatePicker] = useState({
    startDate: false,
  });

  const toggleDatePicker = ({ datepicker, state }) => {
    setShowDatePicker({ [datepicker]: state });
  };

  const handleSubmit = async (formData) => {
    const startDateTime = `${startDate}T${startTime}:00.000`;
    const endDateTime = `${endDate}T${endTime}:00.000`;

    const body = {
      live_session: {
        title: formData.title,
        short_description: formData.short_description,
        long_description: formData.long_description,
        location: formData.location,
        max_number_of_attendees: formData.max_number_of_attendees,
        ...(courseId ? { course_id: courseId } : {}),
      },
      starts_at: {
        date: startDateTime,
        time_zone: timeZone,
      },
      ends_at: {
        date: endDateTime,
        time_zone: timeZone,
      },
    };

    setIsLoading(true);
    const response = await API.post(`/live_sessions`, body, {
      onError: errorFlash,
    });
    setIsLoading(false);

    if (response) {
      successFlash(`Live session scheduled successfully.`);
      props.onClose();
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="text-left pt-4 space-y-4">
      {errors.length > 0 && (
        <Alert type="alert" className="mb-2">
          Couldn't schedule live session: {errors.join(", ")}.
        </Alert>
      )}

      <Detail className="w-full" key="course" default="No course">
        <RemoteSelectInput
          required
          className="w-full"
          selectClassName="w-full"
          label="Course"
          title="Course"
          defaultOption="Select a course"
          fetchPath="courses"
          optionValue={"id"}
          optionLabel={"title"}
          onChange={(e) => setCourseId(e.target.value)}
        />
      </Detail>

      <TextInput
        label="Title"
        name="title"
        required
        placeholder="e.g. My Live Session"
      />

      <TextInput
        label="Location"
        name="location"
        required
        placeholder="e.g. http://zoom.us/myroom"
      />

      <TextInput
        label="Short Description"
        name="short_description"
        hint="Shown on the notifier."
        required
        placeholder="e.g. Discover the magic of HTML with Ray."
      />

      <div className="grid grid-cols-12 items-center justify-between gap-x-2">
        <div className="col-span-12">
          <Label className="mb-1 ml-1">
            Starts at
            <Tooltip text="This field is required." className="inline">
              <span className="superscript text-red-500">*</span>
            </Tooltip>
          </Label>
        </div>
        <div className="col-span-6">
          <DatePicker
            onChange={(date) => setStartDate(moment(date).format("YYYY-MM-DD"))}
            show={showDatePicker.startDate}
            setShow={(state) =>
              toggleDatePicker({ datepicker: "startDate", state })
            }
            options={{
              todayBtn: true,
              minDate: new Date(new Date().setDate(new Date().getDate() - 1)),
              theme: {
                input: "bg-white",
              },
            }}
          />
        </div>
        <TimePicker
          className="col-span-3"
          onChange={(time) => setStartTime(`${time.hours}:${time.minutes}`)}
        />
        <TimeZonePicker
          className="col-span-3"
          value={timeZone}
          onChange={setTimeZone}
        />
      </div>

      <div className="grid grid-cols-12 items-center justify-between gap-x-2">
        <div className="col-span-12">
          <Label className="mb-1 ml-1">
            Ends at
            <Tooltip text="This field is required." className="inline">
              <span className="superscript text-red-500">*</span>
            </Tooltip>
          </Label>
        </div>
        <div className="col-span-6">
          <DatePicker
            onChange={(date) => setEndDate(moment(date).format("YYYY-MM-DD"))}
            show={showDatePicker.endDate}
            setShow={(state) =>
              toggleDatePicker({ datepicker: "endDate", state })
            }
            options={{
              todayBtn: true,
              minDate: new Date(new Date().setDate(new Date().getDate() - 1)),
              theme: {
                input: "bg-white",
              },
            }}
          />
        </div>

        <TimePicker
          className="col-span-3"
          onChange={(time) => setEndTime(`${time.hours}:${time.minutes}`)}
        />

        <TimeZonePicker
          className="col-span-3"
          value={timeZone}
          onChange={setTimeZone}
        />
      </div>

      <TextArea
        label="Long Description"
        name="long_description"
        hint="Markdown is supported."
        placeholder="e.g. Find out all about **HTML** with Ray."
      />

      <Detail className="w-full" key="max_number_of_attendees" default="0">
        <NumberInput
          label="Max Number of Attendees"
          defaultValue={0}
          min={0}
          hint="0 means no limit"
          name="max_number_of_attendees"
        />
      </Detail>

      <div className="mt-5 sm:mt-6">
        <Button className="w-full block" type="submit" disabled={isLoading}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default AddLiveSession;
