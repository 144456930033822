import React, { useState, useEffect, useContext, useMemo } from "react";
import AppContext from "contexts/AppContext";
import GraphContext from "contexts/GraphContext";
import { Loading } from "components/shared";
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Graph from "./Graph";
import ActivityPreview from "./ActivityPreview";
import ActivityPanel from "./ActivityPanel";
import ActivitySummary from "./ActivitySummary";
import FreeTrialManager from "./FreeTrialManager";
import LiveSessionNotifier from "./LiveSessionNotifier";
import FeatureAnnouncement from './FeatureAnnouncement';

import { useGraphApi, useGraph, useGraphEditor } from "hooks";
import { pure } from "recompose";

const GraphPage = (props) => {
  const { currentUser, editorMode, API } = useContext(AppContext);
  const { graphContext } = useContext(GraphContext);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let history = useHistory();
  let { slug, action } = useRouteMatch([
    "/activities/:slug/:action",
    "/activities/:slug",
    "/activities",
    "/",
  ]).params;

  const [createActivity, updateActivity, createEdge, deleteEdge, onNodeDrag] =
    useGraphEditor();

  const [loaded, setLoaded] = useState(false);
  const [graph, fetchGraph] = useGraph(currentUser);

  useEffect(() => {
    fetchGraph();
  }, []);

  const [goal, setGoal] = useState(currentUser.goal);

  const highlightGoalPath = (goal) => {
    graphContext.removeAllHighlights();
    graphContext.highlightShortestPathTo(graphContext.getElementById(goal.id));
  };

  useEffect(() => {
    if (graphContext && graph && goal) highlightGoalPath(goal);
  }, [graphContext, graph, goal]);

  const { findOrCreateAttempt, completeAttempt } = useGraphApi();

  const completeOnboarding = () => {
    const onboardingActivity = graph.activities.find(
      ({ onboarding }) => onboarding,
    );

    if (!onboardingActivity) return;

    const activityAfterOnboarding = graph.activities.find(({ previous }) =>
      previous.some(({ id }) => id === onboardingActivity.id),
    );

    if (!activityAfterOnboarding) return;

    findOrCreateAttempt(onboardingActivity, ({ id }) => {
      setTimeout(
        completeAttempt(
          { id },
          () => {
            history.push(`/activities/${activityAfterOnboarding.slug}/preview`);
            fetchGraph();
          },
          500,
        ),
      );
    });
  };

  // route change hander
  // fires when the graph loads for the first time
  // fires whenever the location changes
  const handleLocationChange = ({ includeRoot }) => {
    if (!loaded || !graph.activities) return;

    if (query.get("completeOnboarding")) return completeOnboarding();

    if (includeRoot && location.pathname === "/") {
      fetchGraph();
      return graphContext.resetZoom();
    }

    const activity =
      slug && graph.activities.find((activity) => activity.slug === slug);

    if (activity) {
      graphContext.zoomToNode(graphContext.getElementById(activity.id));

      // redirect to preview if we can't start this activity
      action !== "preview" &&
        (activity.permalocked || activity.construction || !activity.unlocked) &&
        history.push(`/activities/${activity.slug}/preview`);
    }
  };

  useEffect(() => handleLocationChange({ includeRoot: true }), [location]);
  useEffect(() => handleLocationChange({ includeRoot: false }), [loaded]);

  return (
    <div
      className={`GraphPage ${
        loaded ? "GraphPage--loaded" : "GraphPage--loading"
      }`}
    >
      <FeatureAnnouncement />
      {graph?.activities && (
        <Graph
          id="activity-graph"
          editorMode={editorMode}
          setGraphContext={true}
          autolock={!editorMode}
          useHtmlLabels
          onEditLabel={({ slug, title }, callback) =>
            updateActivity({ slug, title })
          }
          onAddActivity={createActivity}
          onHideActivity={({ slug }, callback) =>
            updateActivity({ slug, show: false }, callback)
          }
          onUpdateActivity={updateActivity}
          onAddEdge={createEdge}
          onDeleteEdge={deleteEdge}
          onNodeDrag={onNodeDrag}
          onNodeClick={(node) =>
            history.push(`/activities/${node.data().slug}/preview`)
          }
          onReady={(nodes) => nodes.length > 0 && setLoaded(true)}
          showStarterFlag={
            !graph.activities
              .filter(({ introduction }) => introduction)
              .some(({ completedAttempt }) => completedAttempt)
          }
          currentGoal={goal}
          {...graph}
        />
      )}

      <ActivityPreview
        show={location.pathname.includes("/preview")}
        currentGoal={goal}
        setCurrentGoal={setGoal}
        onOpenActivity={fetchGraph}
        onChange={fetchGraph}
      />

      <Switch>
        <Route path="/activities/:slug/preview"></Route>

        <Route path="/activities/:slug/summary">
          <ActivitySummary />
        </Route>

        <Route path="/activities/:slug">
          <ActivityPanel />
        </Route>

        <Route path="/">
          {!currentUser.license && <FreeTrialManager />}
          <LiveSessionNotifier />
        </Route>
      </Switch>
    </div>
  );
};

export default pure(GraphPage);
