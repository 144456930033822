import React, { useEffect, useRef } from "react";
import Message from "components/bloom/Message";

const Coaching = ({ feedbacks, ...props }) => {
  const feedbacksContainerRef = useRef(null)

  useEffect(() => {
    feedbacksContainerRef.current.scrollTop = feedbacksContainerRef.current.scrollHeight
  }, [feedbacks])

  return (
    <div ref={feedbacksContainerRef} className="flex flex-col h-full overflow-y-auto overscroll-contain p-4">
      {feedbacks.map((feedback) => (
        <Message
          key={`feedback-${feedback.id}`}
          id={`feedback-${feedback.id}`}
          labelPosition="right"
          message={{ ...feedback, from: "coach" }}
          className="w-full"
        />
      ))}
    </div>
  );
};

export default Coaching;
