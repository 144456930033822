import React from "react";
import Activity from "./Activity";
import { useParams } from "react-router-dom";
import { Panel, Loading } from "components/shared";
import { usePanel } from "hooks";

const ActivityPanel = (props) => {
  const { show, hide } = usePanel("/activities/:slug");
  let { slug } = useParams();

  return (
    <Panel size="full" show={show}>
      <Activity
        slug={slug}
        onBackToGraph={() => hide(`/activities/${slug}/preview`)}
        onCompleteLastStage={() => hide(`/activities/${slug}/summary`)}
      />
    </Panel>
  );
};

export default ActivityPanel;
