import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Switch } from "@headlessui/react";
import { useFlash } from "hooks";
import { cn } from "utils";

const UserRoles = ({ user, ...props }) => {
  const { API } = useContext(AppContext);
  const { successFlash, errorFlash } = useFlash();

  const [localUser, setLocalUser] = useState(user ? user : {});

  const updateUser = async (properties) => {
    const response = await API.put(
      `/users/${user.id}`,
      { user: properties },
      {
        onError: errorFlash,
      },
    );

    if (response) {
      successFlash(`Successfully updated user roles.`);
      if (props.onChange) props.onChange();
    }
  };

  const handleUpdate = (localProp, serverProp, value) => {
    setLocalUser({...localUser, [localProp]: value });
    if(props.remote) updateUser({ [serverProp]: value })
  }

  return (
    <ul role="list" className="mt-2 divide-y divide-gray-200">
      <Switch.Group as="li" className="flex items-center justify-between py-4">
        <div className="flex flex-col">
          <Switch.Label
            as="p"
            className="text-sm font-medium text-gray-900 mb-1"
            passive
          >
            Learner
          </Switch.Label>
          <Switch.Description className="text-sm text-gray-500">
            Allow this user to do activities.
          </Switch.Description>
        </div>

        <Switch
          name="learner"
          checked={localUser.isLearner}
          onChange={(e) => handleUpdate("isLearner", "learner", e)}
          className={cn(
            localUser.isLearner ? "bg-teal-500" : "bg-gray-200",
            "relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2",
          )}
        >
          <span
            aria-hidden="true"
            className={cn(
              localUser.isLearner ? "translate-x-5" : "translate-x-0",
              "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
      </Switch.Group>

      <Switch.Group as="li" className="flex items-center justify-between py-4">
        <div className="flex flex-col">
          <Switch.Label
            as="p"
            className="text-sm font-medium text-gray-900 mb-1"
            passive
          >
            Manager
          </Switch.Label>
          <Switch.Description className="text-sm text-gray-500">
            Allow this user to view reports.
          </Switch.Description>
        </div>

        <Switch
          name="manager"
          checked={localUser.isManager}
          onChange={(e) => handleUpdate("isManager", "manager", e)}
          className={cn(
            localUser.isManager ? "bg-teal-500" : "bg-gray-200",
            "relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2",
          )}
        >
          <span
            aria-hidden="true"
            className={cn(
              localUser.isManager ? "translate-x-5" : "translate-x-0",
              "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
      </Switch.Group>

      <Switch.Group as="li" className="flex items-center justify-between py-4">
        <div className="flex flex-col">
          <Switch.Label
            as="p"
            className="text-sm font-medium text-gray-900 mb-1"
            passive
          >
            Trainer
          </Switch.Label>
          <Switch.Description className="text-sm text-gray-500">
            Allow this user to train other users.
          </Switch.Description>
        </div>

        <Switch
          name="trainer"
          checked={localUser.isTrainer}
          onChange={(e) => handleUpdate("isTrainer", "trainer", e)}
          className={cn(
            localUser.isTrainer ? "bg-teal-500" : "bg-gray-200",
            "relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2",
          )}
        >
          <span
            aria-hidden="true"
            className={cn(
              localUser.isTrainer ? "translate-x-5" : "translate-x-0",
              "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
      </Switch.Group>

      <Switch.Group as="li" className="flex items-center justify-between py-4">
        <div className="flex flex-col">
          <Switch.Label
            as="p"
            className="text-sm font-medium text-gray-900 mb-1"
            passive
          >
            Admin
          </Switch.Label>
          <Switch.Description className="text-sm text-gray-500">
            Allow this user to administrate.
          </Switch.Description>
        </div>

        <Switch
          name="admin"
          checked={localUser.isAdmin}
          onChange={(e) => handleUpdate("isAdmin", "admin", e)}
          className={cn(
            localUser.isAdmin ? "bg-teal-500" : "bg-gray-200",
            "relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2",
          )}
        >
          <span
            aria-hidden="true"
            className={cn(
              localUser.isAdmin ? "translate-x-5" : "translate-x-0",
              "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          />
        </Switch>
      </Switch.Group>
    </ul>
  );
};

export default UserRoles;
