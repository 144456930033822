import React, { Fragment } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { DateTimeRange } from "components/shared";
import { cn } from "utils";

const LiveSessionDetails = ({ liveSession, size = "md", ...props }) => {
  return (
    <div className={cn(props.className)}>
      {liveSession?.title && (
        <>
          <p
            className={cn(
              "text-xs tracking-loose font-medium text-blue-500 mb-0",
            )}
          >
            Upcoming live session
          </p>
          <p
            className={cn(
              "font-medium text-gray-900 mb-0",
              size === "sm" && "text-sm",
            )}
          >
            {liveSession.title}
          </p>
        </>
      )}

      <p
        className={cn(
          "text-sm tracking-loose font-medium text-blue-500 mb-0 pt-0.5",
          size === "sm" && "text-xs",
        )}
      >
        <DateTimeRange
          start={liveSession.startsAt}
          end={liveSession.endsAt}
        />
      </p>

      {liveSession?.shortDescription && (
        <p
          className={cn(
            "text-sm text-gray-500 mb-0",
            liveSession.title && "mt-1",
          )}
        >
          {liveSession.shortDescription}
        </p>
      )}

      {!props.short && (
        <ReactMarkdown
          className={cn(
            "prose mb-0 prose--no-margins pt-4",
            size === "sm" && "prose-sm pt-2",
          )}
          children={liveSession.longDescription}
          remarkPlugins={[remarkGfm]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                  {...props}
                  children={String(children).replace(/\n$/, "")}
                  style={oneDark}
                  language={match[1]}
                  PreTag="div"
                />
              ) : (
                <code {...props} className={className}>
                  {children}
                </code>
              );
            },
          }}
        />
      )}
    </div>
  );
};

export default LiveSessionDetails;
