import React, { useContext, useState, useMemo, useRef, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Timestamp, Button } from "components/shared";
import { PlusIcon } from "@heroicons/react/20/solid";
import AddQuestion from "components/design/AddQuestion";
import {
  classNames,
  stripTags,
  isInViewport,
  isAboveViewport,
  isBelowViewport,
} from "utils";

const Caption = ({ id, stage, text, timestamp, end, isCurrent, ...props }) => {
  const { editorMode } = useContext(AppContext);
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const [isAddingQuestion, setIsAddingQuestion] = useState(false);

  let ref = useRef(null);

  useEffect(() => {
    if (isCurrent) {
      const element = ref.current;
      if (!isInViewport(element)) {
        element.scrollIntoView({
          behavior: "smooth",
          block: isBelowViewport(element) ? "end" : "start",
          inline: "nearest",
        });
      }
    }
  }, [isCurrent]);

  return (
    <div
      ref={ref}
      className={classNames(
        "text-sm text-gray-600 px-4 border-l-4 border-white py-2 mb-0",
        isCurrent && "bg-yellow-50 border-l-4 border-yellow-300",
        props.className,
      )}
      onMouseEnter={() => setMouseIsOver(true)}
      onMouseLeave={() => setMouseIsOver(false)}
    >
      {editorMode && <Timestamp>{timestamp}</Timestamp>}
      {stripTags(text, " ")}
      {isAddingQuestion ? (
        <AddQuestion
         stack
         stage={stage}
         onChange={props.onAddQuestion}
         extraParams={{
          timestamp: end
         }} />
      ) : (
        editorMode &&
        mouseIsOver && (
          <div className="pt-4">
            <Button
              Icon={PlusIcon}
              type="pill"
              onClick={() => setIsAddingQuestion(true)}
            >
              Add Question
            </Button>
          </div>
        )
      )}
    </div>
  );
};

export default Caption;
