import React from "react";
import { Modal } from "components/shared";
import { MegaphoneIcon } from "@heroicons/react/24/solid";
import { useHistory } from "react-router-dom";
import { useQuery } from "hooks";
import AddFeatureAnnouncement from "components/admin/AddFeatureAnnouncement";

const AddFeatureAnnouncementModal = () => {
  const { watchQuery, deleteQuery } = useQuery();
  const history = useHistory();

  const open = watchQuery("addFeatureAnnouncement");
  const onClose = () => {
    deleteQuery("addFeatureAnnouncement");
  };

  return (
    <Modal
      size="sm"
      dismissible
      open={!!open}
      onClose={onClose}
      Icon={MegaphoneIcon}
      title="Announce a feature"
    >
      <AddFeatureAnnouncement onClose={onClose} />
    </Modal>
  );
};

export default AddFeatureAnnouncementModal;
