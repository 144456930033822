import React from "react";
import { Button, Hint } from "components/shared";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useQuery } from "hooks";

const CertificationControls = ({ user, ...props }) => {
  const { pathWithQuery } = useQuery();

  return (
    <div className="py-4 px-4 rounded-md border border-gray-200">
      <div className="flex justify-between items-center">
        <div>
          <Hint className="text-gray-500 inline">
            { user.firstName } requested certification.
          </Hint>
        </div>

        <div>
          <Button
            type="routeLink"
            to={pathWithQuery(null, { certifyUserId: user.id })}
            Icon={CheckBadgeIcon}
          >
            Manage
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CertificationControls;
