import React, { useMemo } from 'react'
import { Checkbox } from 'components/shared'
import { useFetch } from 'hooks'
import { classNames } from 'utils'

const Category = ({ id, title, checked, allocations, ...props }) => {
  const categoryAllocations = allocations
                              .filter(({categories}) => categories
                                                        .map(c => c.id)
                                                        .includes(id))

  const percentageFilled = useMemo(() => {
    if(categoryAllocations.length === 0) return 0

    return categoryAllocations.length * 100.0 / allocations.length
  }, [categoryAllocations])

  const handleCheck = (e) => {
    if(props.allowChange) {
      if(props.onCheck) props.onCheck(id)
    } else {
      e.preventDefault()
    }
  }

  return(
    <div
     id={`category-${props.index}`}
     className={classNames(
       "w-64 mr-4 mb-4 flex-shrink-0 overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6 flex justify-between items-center relative",
       props.allowChange ? 'cursor-pointer hover:bg-gray-50' : 'cursor-not-allowed bg-gray-50',
       checked && 'ring-2 ring-indigo-600',
       checked && !props.allowChange && 'ring-indigo-200'
     )}
     data-percentagefilled={ Math.round(percentageFilled) }
     onClick={handleCheck}>

      <h6 className="relative z-10 text-sm font-medium text-gray-900">
        {title}
        {
          props.showPercentage &&
          <span className="text-xs text-gray-400 ml-1">
            ({ Math.round(percentageFilled) }%)
          </span>
        }
      </h6>

      <Checkbox
       className={classNames(
        "relative z-10",
        checked && !props.allowChange && 'text-indigo-200'
       )}
       checked={checked}
       disabled={!props.allowChange}
       readOnly={true} />

      <div
       className="absolute z-0 inset-0 bg-indigo-200"
       style={ { width: `${percentageFilled}%` } } />
    </div>
    
  )
}

Category.defaultProps = {
  allowChange: false
}

export default Category