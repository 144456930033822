import React, { useState, useEffect, useContext } from 'react'
import { Loading, Alert, Button } from 'components/shared'
import { pure } from 'recompose'
import { get } from 'api'
import AppContext from 'contexts/AppContext'
import { classNames } from 'utils'

import { ArcherContainer, ArcherElement } from 'react-archer'

import { shuffle } from 'utils'

const Pairer = ({ id, onComplete }) => {
  const { apiUrl } = useContext(AppContext)

  const isPairableColumn = (columnId) => (state.columns[0] && state.columns[0].id === columnId)

  const itemIsCorrect = item => {
    if(isPairableColumn(item.columnId)) {
      const relations = relationMap.filter(relation => relation.sourceId === item.id)

      if(relations.length !== 1) return false

      const relation = relations[0]
      return relation && relation.targetId === item.correctPairId
    } else {
      const relations = relationMap.filter(relation => relation.targetId === item.id)

      if(relations.length !== 1) return false

      const relation = relations[0]
      return relation && relation.sourceId === item.correctPairId
    }
  }

  const [isChecked, setIsChecked] = useState(false)
  const [isCorrect, setIsCorrect] = useState(false)
  const onCheck = () => {
    const newColumns = state.columns.map(column => {
      const newColumn = { ...column,
                          items: column.items.map(item =>
                            ({ ...item, correctStatus: itemIsCorrect(item) ? 'correct' : 'incorrect' })
                          )
                        }
      return newColumn
    })

    const newState = { ...state, columns: newColumns }
    setState(newState)
    setIsChecked(true)
    setIsCorrect(newState.columns.flatMap(column => column.items).every(item => item.correctStatus === 'correct'))
  }

  const [state, setState] = useState(null)
  const handleReceivePairer = (pairer) => {
    const columns = pairer.columns.map(column => {
      const newColumn = { ...column,
                          items: shuffle(column.items)
                        }
      return newColumn
    })

    const newState = { ...pairer, columns: columns }
    setState(newState)
  }
  const fetchPairer = () => get(`${apiUrl}/pairers/${id}`, handleReceivePairer)
  useEffect(fetchPairer, [id])

  const [isPairing, setIsPairing] = useState(false)
  const [source, setSource] = useState(null)
  const [targetId, setTargetId] = useState(null)
  const [relationMap, setRelationMap] = useState([])

  useEffect(() => {
    if(isPairing) {
      const newColumns = state.columns.map(column => {
        const newColumn = { ...column,
                            items: column.items.map(item =>
                              ({ ...item, correctStatus: 'unchecked' })
                            )
                          }
        
        return newColumn
      })
      
      const newState = { ...state, columns: newColumns }
      setState(newState)
      setIsChecked(false)
      setIsCorrect(false)
    }
  }, [isPairing])

  if(!state) return <div className="Pairer">
                       <Loading />
                     </div>

  return(
    <ArcherContainer strokeColor="#5199E6" endMarker={false}>
    <div className="w-full">
      <div className="flex justify-between w-full space-x-4">
        { state.columns.map(column => <Pairer.Column key={column.id} {...column} isPairable={isPairableColumn(column.id)} { ...{isPairing, setIsPairing, source, setSource, targetId, setTargetId, relationMap, setRelationMap} } />) }
      </div>

        <div className="pt-6 flex flex-col items-center">
          {
            isChecked &&
            <Alert className="mb-4" dismissible type={ isCorrect ? "success" : "warning" } onClose={() => setIsChecked(false)}>
              <p>{ isCorrect ? "Nice job! That's right." : "Not quite! Try changing something." }</p>
            </Alert>
          }

          { isChecked && isCorrect ? onComplete && <Button variant="secondary" onClick={onComplete}>Complete</Button>
                                   : <Button variant="secondary" onClick={onCheck}>Check answer</Button>  }
        </div>
    </div>
    </ArcherContainer>
  )
}

Pairer.Column = (props) => {
  return(
    <div className="space-y-2">
      { props.items.map(item => <Pairer.Item key={item.id} { ...props } {...item} />) }
    </div>
  )
}

Pairer.Item = ({ id, content, columnId, correctStatus = 'unchecked', isPairable, isPairing, setIsPairing, source, setSource, targetId, setTargetId, relationMap, setRelationMap }) => {
  const isSelected = isPairing && source && id === source.id
  const isValidTarget = isPairing && source && (id !== source.id) && (columnId !== source.columnId)

  const relation = (sourceId, targetId) => ({
                                             sourceId: sourceId,
                                             targetId: targetId,
                                             sourceAnchor: 'right',
                                             targetAnchor: 'left'
                                           })

  const relations = () => {
    if(isPairing && (source.id === id) && targetId) return [relation(source.id, targetId)]

    return relationMap.filter(relation => (relation.sourceId === id))
  }

  const handleClick = (e) => {
    e.preventDefault()

    if(!isPairing && isPairable) {
      setIsPairing(true)
      setSource({ id, columnId })
    } else {
      if(isValidTarget) {
        setTargetId(null)
        setIsPairing(false)

        const relationMapWithoutRelationsFromThisSource = relationMap.filter(relation => relation.sourceId !== source.id)
        setRelationMap([ ...relationMapWithoutRelationsFromThisSource, relation(source.id, id) ])
      }
    }
  }

  const handleMouseEnter = () => {
    if(isPairing && isValidTarget) setTargetId(id)
  }

  const handleMouseLeave = () => {
    if(isPairing && isValidTarget) setTargetId(null)
  }

  return(
    <ArcherElement
     id={id}
     relations={relations()}>
    <div
     onClick={handleClick}
     onMouseEnter={handleMouseEnter}
     onMouseLeave={handleMouseLeave}
     className={classNames(
      'relative text-xs rounded-md border border-gray-300 bg-gray-50 px-2 py-2 shadow-sm',
      correctStatus === 'correct' && "border-green-500",
      correctStatus === 'incorrect' && "border-red-500",
      isPairable && "cursor-pointer hover:border-indigo-400 bg-white",
      isSelected && 'border-indigo-600',
      isPairing
      ? isValidTarget
        ? "cursor-pointer border-indigo-200 hover:border-indigo-400 bg-white"
        : 'cursor-not-allowed bg-gray-50 hover:border-gray-300'
      : null
     )}>
      <div className="" dangerouslySetInnerHTML={ { __html: content }} />
    </div>
    </ArcherElement>
  )
}

export default pure(Pairer)
