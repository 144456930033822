import React, { useMemo, forwardRef, useState, useEffect, useContext } from 'react'
import AppContext from 'contexts/AppContext'
import { Button } from 'components/shared'
import { PaperAirplaneIcon, PaperClipIcon } from '@heroicons/react/24/outline'
import { classNames, toSentence } from 'utils'

// Use like

/*
<Chat
 ref={inputRef}
 onSubmit={handleSubmit}
 messages={messages}
 attachments={attachments}
 onUnattach={unattach}
 canSubmit={canSubmit}
 isRespondingToUser={isRespondingToUser}
 users={users}
 extraButton={
  (props.allowSelectNextTechie && messages.length > 1) &&
  <div>
    <Button
     disabled={!canSubmit}
     onClick={nextTechie}
     Icon={UserPlusIcon} />
  </div>
 } />
 */

const AttachmentBadge = ({ color, children, ...props }) => {
  const COLOR_TO_CLASSES = {
    gray: {
      wrapper: 'bg-gray-50 text-gray-600 ring-gray-500/10',
      dismiss: {
        wrapper: 'hover:bg-gray-500/20',
        svg: 'stroke-gray-600/50 group-hover:stroke-gray-600/75',
      },
    },
    red: {
      wrapper: 'bg-red-50 text-red-700 ring-red-600/10',
      dismiss: {
        wrapper: 'hover:bg-red-600/20',
        svg: 'stroke-red-600/50 group-hover:stroke-red-600/75',
      },
    },
    yellow: {
      wrapper: 'bg-yellow-50 text-yellow-800 ring-yellow-600/20',
      dismiss: {
        wrapper: 'hover:bg-yellow-600/20',
        svg: 'stroke-yellow-700/50 group-hover:stroke-yellow-700/75',
      },
    },
    green: {
      wrapper: 'bg-green-50 text-green-700 ring-green-600/20',
      dismiss: {
        wrapper: 'hover:bg-green-600/20',
        svg: 'stroke-green-700/50 group-hover:stroke-green-700/75',
      },
    },
    blue: {
      wrapper: 'bg-blue-50 text-blue-700 ring-blue-700/10',
      dismiss: {
        wrapper: 'hover:bg-blue-600/20',
        svg: 'stroke-blue-700/50 group-hover:stroke-blue-700/75',
      },
    },
    indigo: {
      wrapper: 'bg-indigo-50 text-indigo-700 ring-indigo-700/10',
      dismiss: {
        wrapper: 'hover:bg-indigo-600/20',
        svg: 'stroke-indigo-600/50 group-hover:stroke-indigo-600/75',
      },
    },
    purple: {
      wrapper: 'bg-purple-50 text-purple-700 ring-purple-700/10',
      dismiss: {
        wrapper: 'hover:bg-purple-600/20',
        svg: 'stroke-violet-600/50 group-hover:stroke-violet-600/75',
      },
    },
    pink: {
      wrapper: 'bg-pink-50 text-pink-700 ring-pink-700/10',
      dismiss: {
        wrapper: 'hover:bg-pink-600/20',
        svg: 'stroke-pink-700/50 group-hover:stroke-pink-700/75',
      },
    }
  }

  const classNames = useMemo(() => {
    if(color === 'random') return Object.values(COLOR_TO_CLASSES)[Math.floor(Math.random() * Object.keys(COLOR_TO_CLASSES).length)]

    return COLOR_TO_CLASSES[color] || COLOR_TO_CLASSES.gray
  }, [color])

  return (
    <span className={classNames('inline-flex items-center gap-x-0.5 rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset whitespace-pre', classNames.wrapper, props.className)}>
      { children }
      {
        props.onDismiss && (
          <button
           type="button"
           className={classNames('group relative -mr-1 h-3.5 w-3.5 rounded-sm', classNames.dismiss.wrapper)}
           onClick={props.onDismiss}>
            <span className="sr-only">Remove</span>
            <svg viewBox="0 0 14 14" className={classNames('h-3.5 w-3.5 ', classNames.dismiss.svg)}>
              <path d="M4 4l6 6m0-6l-6 6" />
            </svg>
            <span className="absolute -inset-1" />
          </button>
        )
      }
    </span>
  )
}


const Attachment = ({ children, ...props }) => {
  return(
    <AttachmentBadge onDismiss={props.onUnattach} color='random'>
      <PaperClipIcon className='h-4 w-4 mr-1' />
      { children }
    </AttachmentBadge>
  )
}

const Message = ({ message, ...props }) => {
  const { currentUser } = useContext(AppContext)
  
  return(
    <div
     className={classNames(
      'clear-both mb-2',
      message.from.id === currentUser.id ? 'float-right' : 'float-left',
      props.className
    )}>
      <div
        id={props.id}
      >
        <span
         className={classNames(
          'text-sm text-gray-400 w-full block',
          message.from.id === currentUser.id ? 'text-right' : 'text-left',
          props.labelPosition === 'right' && 'text-right'
         )}>From {message.from.firstName}</span>
        <div
         className={classNames(
          'px-4 py-2 rounded-lg shadow-sm max-w-lg min-h-[40px] inline-flex items-center clear-both',
          'bg-gray-200 rounded-l float-left',
          message.from.id === currentUser.id &&'bg-blue-300 rounded-r float-right',
         )}>
          {
            message.text === '...'
            ? (
              <div className='flex space-x-1'>
                <div className='bg-black opacity-30 rounded-full h-3 w-3 transition animate-bounce' />
                <div className='bg-black opacity-30 rounded-full h-3 w-3 transition animate-bounce animation-delay-[100ms]' />
                <div className='bg-black opacity-30 rounded-full h-3 w-3 transition animate-bounce animation-delay-[200ms]' />
              </div>
            ) : message.text
          }
        </div>
      </div>
      {
        message.attachments?.length > 0 && (
          <div
           className='flex max-w-lg space-x-2 pt-2 clear-both items-center justify-end'>
            {
              message.attachments.map(attachment => (
                <Attachment
                 key={attachment.id}>
                 { attachment.title }
                </Attachment>
              ))
            }
          </div>
        )
      }
    </div>
  )
}

const Chat = forwardRef(({ messages, attachments, users, ...props }, ref) => {
  const { currentUser } = useContext(AppContext)
  const { inputRef, panelRef } = ref

  const [text, setText] = useState('')

  const canSubmit = props.canSubmit && text && text.length > 0

  const onSubmit = (e) => {
    e.preventDefault()

    if(!canSubmit) return

    props.onSubmit(text)

    inputRef.current.value = ''
    setText('')
  }

  useEffect(() => {
    panelRef.current.scrollTo({ top: panelRef.current.scrollHeight, behavior: 'smooth' })
  }, [messages])

  return(
    <div
     className='flex flex-col shadow-md bg-white border border-gray-200 h-full min-h-[400px] max-h-[80vh] rounded-md'>
      <div className='w-full h-16 flex items-center bg-gray-100 px-4 py-4 shadow space-x-4 shrink-0 border-b border-gray-200'>
        <span className='font-semibold text-gray-900'>
          Chat with { toSentence(users.map(u => u.id === currentUser.id ? 'You' : u.firstName)) }
        </span>
      </div>
      <div
       ref={panelRef}
       className='py-6 px-4 overflow-y-auto grow'>
        {
          messages.map(message => (
            <Message
             key={`message-${message.id}`}
             id={`message-${message.id}`}
             message={message} />
          ))
        }
      </div>

      <form
       className='w-full h-16 flex items-center justify-center bg-gray-100 px-4 shadow space-x-4 shrink-0 border-t border-gray-200'
       onSubmit={onSubmit}>
        <div className='w-full bg-white rounded-sm px-0 py-0 flex space-x-2'>
        <input
         ref={inputRef}
         onChange={(e) => setText(e.target.value)}
         name='message'
         disabled={!props.canSubmit}
         className='w-full focus:outline-none border-none'
         type='text'
         placeholder={'Write a message'} />
         {
          attachments.map(option => (
            <Attachment
             onUnattach={props.onUnattach}>
             { option.title }
            </Attachment>
          ))
         }
        </div>

        <Button
         disabled={!canSubmit}
         Icon={PaperAirplaneIcon}>
         { messages.length > 1 ? null : 'Send' }
        </Button>

        {
          props.extraButton
        }
      </form>
    </div>
  )
})

export { Message, Attachment, Chat }