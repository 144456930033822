import React, { useState } from "react";
import { removeDiacritics } from "utils";

const useFilters = (initialFilters = {}) => {
  const [filters, setFilters] = useState(initialFilters);

  const handleFilterChange = (filter, { resetOtherFilters = false } = {}) => {
    if (resetOtherFilters) {
      const newFilters = {};
      newFilters[filter.name] = filter.value;
      setFilters({ ...newFilters });
    } else {
      const newFilters = filters;
      newFilters[filter.name] = filter.value;
      setFilters({ ...newFilters });
    }
  };

  return {
    filters,
    handleFilterChange,
  };
};

export default useFilters;
