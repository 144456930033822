import React from "react";
import { Spinner } from "components/shared";
import { cn } from "utils";

const Loading = ({ message, ...props }) => {
  return (
    <div
      className={cn(
        "absolute inset-0 flex items-center justify-center min-h-20",
        props.size === "sm" && "min-h-12 text-sm",
        props.className,
      )}
    >
      <Spinner className="text-gray-400" />
      <span className="sr-only">Loading</span>
      {message && <div className={cn("text-gray-400 ml-2", props.size === "sm" && 'text-sm')}>{message}</div>}
    </div>
  );
};

export default Loading;
