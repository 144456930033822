import React from 'react'
import { Link } from 'react-router-dom'
import { classNames } from 'utils'

const Button = React.forwardRef(({ type, size, variant, Icon, onClick, children, ...props }, ref) => {
  if(type === 'pill') return(
    <button
     id={props.id}
     ref={ref}
     type="button"
     className={classNames(
      "inline-flex items-center flex-shrink-0 rounded-full bg-white border border-gray-300 px-2.5 py-0.5 text-xs font-medium text-gray-500 cursor-pointer hover:bg-gray-100",
      "disabled:border-gray-200 disabled:text-gray-300 disabled:cursor-normal disabled:hover:bg-white",
      props.className
     )}
     onClick={onClick}
     disabled={props.disabled}>
      { Icon && <Icon className="h-3 w-3 mr-1" /> }
      { children }
    </button>
  )

  const SIZE_TO_ICON_CLASSES = {
    xs: 'h-4 w-4',
    sm: 'h-4 w-4',
    md: 'h-5 w-5',
    lg: 'h-5 w-5',
    xl: 'h-5 w-5'
  }

  const SIZE_TO_ICON_MARGIN_CLASSES = {
    xs: '-ml-0.5 mr-2',
    sm: '-ml-0.5 mr-2',
    md: '-ml-1 mr-2',
    lg: '-ml-1 mr-3',
    xl: '-ml-1 mr-3'
  }

  const SIZE_TO_BUTTON_CLASSES = {
    xs: 'px-2.5 py-1.5 text-xs',
    sm: 'px-3 py-2 text-sm leading-4',
    md: 'px-4 py-2 text-sm',
    lg: 'px-4 py-2 text-base',
    xl: 'px-6 py-3 text-base'
  }

  const VARIANT_TO_COLOR_CLASSES = {
    primary: 'bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500 disabled:bg-indigo-300 disabled:hover:bg-indigo-300 disabled:cursor-default',
    secondary: 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500 disabled:bg-gray-50 disabled:hover:bg-gray-50 disabled:cursor-default',
    danger: 'bg-red-600 text-white hover:bg-red-700 disabled:bg-red-300 disabled:hover:bg-red-300 disabled:cursor-default',
    success: 'bg-green-600 text-white hover:bg-green-700 disabled:bg-green-300 disabled:hover:bg-green-300 disabled:cursor-default'
  }

  const VARIANT_TO_DISABLED_LINK_CLASSES = {
    primary: 'bg-indigo-300 hover:bg-indigo-300 cursor-default',
    secondary: 'bg-gray-50 hover:bg-gray-50 cursor-default',
    danger: 'bg-red-300 hover:bg-red-300 cursor-default',
    success: 'bg-green-300 hover:bg-green-300 cursor-default'
  }

  if(type === 'routeLink') return(
    <Link
     id={props.id}
     ref={ref}
     to={props.to}
     onClick={onClick}
     download={props.download}
     disabled={props.disabled}
     target={props.target}
     className={classNames(
       "inline-flex flex-shrink-0 items-center rounded border border-transparent font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer",
       VARIANT_TO_COLOR_CLASSES[variant],
       SIZE_TO_BUTTON_CLASSES[size],
       props.disabled && VARIANT_TO_DISABLED_LINK_CLASSES[variant],
       props.className
     )}>

      {
        Icon &&
        <Icon
         className={classNames(
           "h-3 w-3",
           SIZE_TO_ICON_CLASSES[size],
           children && SIZE_TO_ICON_MARGIN_CLASSES[size]
         )}
         aria-hidden="true" />
      }

      { children }
    </Link>
  )

  if(type === 'link') return(
    <a
     id={props.id}
     ref={ref}
     href={props.href}
     onClick={onClick}
     download={props.download}
     disabled={props.disabled}
     target={props.target}
     className={classNames(
       "inline-flex flex-shrink-0 items-center rounded border border-transparent font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer",
       VARIANT_TO_COLOR_CLASSES[variant],
       SIZE_TO_BUTTON_CLASSES[size],
       props.disabled && VARIANT_TO_DISABLED_LINK_CLASSES[variant],
       props.className
     )}>

      {
        Icon &&
        <Icon
         className={classNames(
           "h-3 w-3",
           SIZE_TO_ICON_CLASSES[size],
           children && SIZE_TO_ICON_MARGIN_CLASSES[size]
         )}
         aria-hidden="true" />
      }

      { children }
    </a>
  )

  return(
    <button
      id={props.id}
      ref={ref}
      type={type}
      onClick={onClick}
      disabled={props.disabled}
      className={classNames(
        "inline-flex flex-shrink-0 items-center rounded border border-transparent font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2",
        VARIANT_TO_COLOR_CLASSES[variant],
        SIZE_TO_BUTTON_CLASSES[size],
        props.className
      )}>

      {
        Icon &&
        <Icon
         className={classNames(
           "h-3 w-3",
           SIZE_TO_ICON_CLASSES[size],
           children && SIZE_TO_ICON_MARGIN_CLASSES[size]
         )}
         aria-hidden="true" />
      }

      { children }
    </button>
  )
})

Button.defaultProps = {
  type: 'button',
  size: 'md',
  variant: 'primary',
  disabled: false
}

export default Button
