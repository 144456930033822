import React, { useState, useContext } from 'react'
import AppContext from 'contexts/AppContext'
import NodeOverlay from 'components/graph/NodeOverlay'
import { TourPanel } from 'components/shared'
import { useFetch, usePost } from 'hooks'

const SetOnboardingGoalOverlay = ({ tourSlug, ...props }) => {
  const { currentUser, setCurrentUser } = useContext(AppContext)
  const goalSlugs = currentUser.suggestedActivities.map(activity => activity.slug)

  const [tour, setTour] = useState(null)
  useFetch(`tours/${tourSlug}`, setTour, [tourSlug])

  const { post, data } = usePost(setCurrentUser)

  const completeSetOnboardingGoal = () => {
    const body = {
      user_tour: { slug: tourSlug }
    }

    post(`users/${ currentUser.id }/user_tours`, body)
  }

  if(!props.open) return null

  if(!tour?.steps) return <div className='absolute inset-0 left-20 bg-black/30' />

  return(
    <div className='absolute inset-0 left-20 bg-black/30'>
      <TourPanel
       slug={tourSlug}
       step={tour.steps[0]}
       className='max-w-md ml-4 mt-4'
       closeProps={
        {
          onClick: props.onClose
        }
       } />

      {
        goalSlugs.map(slug => (
          <NodeOverlay
           key={slug}
           slug={slug}
           onClick={completeSetOnboardingGoal} />
        ))
      }
    </div>
  )
}

export default SetOnboardingGoalOverlay
