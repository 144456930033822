import React, { Fragment, useContext, useState } from "react";
import { EnvelopeIcon, FlagIcon } from "@heroicons/react/20/solid";
import { Avatar, Button, Hint, Tabs } from "components/shared";
import UserLabels from "components/users/UserLabels";
import UserLabelsMenu from "components/users/UserLabelsMenu";
import UserNotes from "components/users/UserNotes";
import { Link } from "react-router-dom";
import Timeline from "components/coaching/Timeline";
import moment from "moment";
import AppContext from "contexts/AppContext";
import { useQuery } from "hooks";
import { classNames } from "utils";

const SelectedDiscussionMeta = ({ id, user, ...props }) => {
  const { currentUser } = useContext(AppContext);
  const { pathWithQuery } = useQuery();

  if (!id) return null;

  return (
    <div>
      <div className="pt-5 px-4">
        <div className="flex items-center space-x-2">
          <Avatar size={"large"} {...user} />
          <div>
            <div className="flex items-center">
              <Link
                to={pathWithQuery(null, { user: user.id })}
                className="text-lg truncate text-gray-900 font-bold"
              >
                {user.name}
              </Link>

              {(currentUser.isAdmin || currentUser.isTrainer) && (
                <>
                  <UserLabels {...user} className="ml-2 pr-2" />
                  <UserLabelsMenu user={user} onChange={props.onChange} />
                </>
              )}
            </div>
            <Hint small>Joined TechMap {moment(user.createdAt).fromNow()}</Hint>
          </div>
        </div>

        <Button
          size="xs"
          type="link"
          className="mt-4"
          variant="secondary"
          Icon={EnvelopeIcon}
          href={`mailto:${user.email}`}
        >
          Email
        </Button>
      </div>

      <Tabs>
        <Tabs.Tab header="Activity" className="pt-2 px-2">
          <Timeline
            reverse
            user={user}
            highlightedResponseId={props.selectedResponseId}
          />
        </Tabs.Tab>

        <Tabs.Tab
          header={
            <>
              Notes
              <span className="text-xs text-gray-400 ml-1 inline-block">
                ({user.notes?.length})
              </span>
            </>
          }
          className="pt-2 px-2"
        >
          <UserNotes user={user} />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
};

export default SelectedDiscussionMeta;
