import React, { Fragment } from 'react'
import { classNames } from 'utils'
import { pure } from 'recompose'

const PlaceholderLine = ({ size = 'md', ...props }) => {
  const POSSIBLE_NUMBER_OF_BLOCKS = 3

  const numberOfBlocks = Math.floor(Math.random() * POSSIBLE_NUMBER_OF_BLOCKS + 1)

  let remainingWidth = 100

  const MAXIMUM_BLOCK_WIDTH = (100.0 / numberOfBlocks) + 20
  const MINIMUM_BLOCK_WIDTH = 30

  const blocks = Array.from(Array(numberOfBlocks)).map(block => {
    const blockWidth = Math.floor(Math.random() * MAXIMUM_BLOCK_WIDTH + 1)

    if(blockWidth > MAXIMUM_BLOCK_WIDTH) {
      remainingWidth -= MAXIMUM_BLOCK_WIDTH
      return {
        width: MINIMUM_BLOCK_WIDTH
      }
    }

    remainingWidth -= blockWidth

    if(remainingWidth < 0) return({ width: MINIMUM_BLOCK_WIDTH })

    return {
      width: blockWidth
    }
  })

  const SIZES_TO_HEIGHTS = {
    tiny: 'h-3',
    xs: 'h-3',
    small: 'h-4',
    sm: 'h-4',
    medium: 'h-6',
    md: 'h-6',
    large: 'h-8',
    lg: 'h-8',
    xl: 'h-12',
    '2xl': 'h-14',
    '3xl': 'h-16',
    '4xl': 'h-18',
    '5xl': 'h-20'
  }

  const height = SIZES_TO_HEIGHTS[size]

  return(
    <div className='flex items-stretch space-x-1 mb-1'>
      {
        blocks.map((block, i) => (
          <div
           key={i}
           className={classNames(
            height,
            'bg-gray-300 grow'
           )}
           style={{ width: `${block.width}%` }} />
        ))
      }
    </div>
  )
}

const PlaceholderText = ({ lines = 1, size = 'md', ...props }) => (
  <div
   className={classNames(
    'animate-pulse',
    props.className
   )}>
    {
      Array.from(Array(lines)).map((x, i) => <PlaceholderLine key={i} size={size} />)
    }
  </div>
)

// PlaceholderText.whyDidYouRender = true

export default pure(PlaceholderText)