import React, { useEffect, useContext, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Alert, Button, Spinner } from 'components/shared'
import AppContext from 'contexts/AppContext'
import GithubContext from 'contexts/GithubContext'
import { post } from 'api'

const GithubAuthentication = ({ buttonText, onSuccess, onFailure }) => {
  const { clientId } = useContext(GithubContext)
  const { apiUrl, currentUser, setCurrentUser, formToken, graphUrl } = useContext(AppContext)

  const location = useLocation()
  const history = useHistory()

  const [data, setData] = useState({ errorMessage: null, isLoading: false })

  const handleUpdateGithubDetails = (response) => {
    const user = {
      githubUsername: response.user.githubUsername,
      githubAccessToken: response.user.githubAccessToken
    }

    setCurrentUser({ ...currentUser, ...user })
    setData({ ...data, isLoading: false })
    if(onSuccess) onSuccess(response)
  }

  const handleErrorUpdatingGithubDetails = (error, response) => {
    setData({ ...data, errorMessage: error, isLoading: false })
  }

  const removeCodeFromUrl = () => {
    history.replace(location.pathname.split('?code=')[0])
  }

  useEffect(() => {
    // After requesting Github access, Github redirects back here with a `code` parameter
    const query = new URLSearchParams(location.search)

    if(query.get('error')) setData({ ...data, errorMessage: query.get('error') })

    const code = query.get('code')

    if(code) {
      setData({ ...data, isLoading: true })
      post(`${apiUrl}/users/${currentUser.id}/update-github-details`, { formToken, body: { code } }, (response) => {
        handleUpdateGithubDetails(response)
        removeCodeFromUrl(code)
      }, handleErrorUpdatingGithubDetails)
    }
  }, [location])

  return(
    <>
      {
        data.errorMessage &&
        <Alert
         type="warning"
         className="mb-4">
         { data.errorMessage }
        </Alert>
      }

      <Button
       type="link"
       className="block text-center"
       href={`https://github.com/login/oauth/authorize?scope=repo&client_id=${clientId}&redirect_uri=${graphUrl + location.pathname}`}
       disabled={data.isLoading}
       >
       { data.isLoading && <Spinner size="sm" /> }
       { buttonText }
      </Button>
    </>
  )
}

GithubAuthentication.defaultProps = {
  buttonText: 'Authenticate'
}

export default GithubAuthentication