import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import { Modal, PasswordInput, Button, Form, Alert } from "components/shared";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { useHistory } from "react-router-dom";
import { usePut, useFlash } from "hooks";
import { possessive } from "utils";

const ChangePasswordModal = ({ user, ...props }) => {
  const { currentUser } = useContext(AppContext);
  const history = useHistory();

  const { put, data } = usePut(() => {
    setErrors([]);

    if (currentUser.id === user.id) {
      successFlash(
        "Your password has been changed. You will now be logged out.",
      );
      setTimeout(() => history.go(0), 8000);
      if (props.onClose) props.onClose();
    } else {
      successFlash(
        `${possessive(user.firstName)} password changed successfully.`,
      );
      if (props.onClose) props.onClose();
    }
  });

  const { successFlash } = useFlash();

  const [errors, setErrors] = useState([]);

  const handleSubmit = (formData) => {
    const errors = [];

    if (formData.password !== formData.password_confirmation)
      errors.push("Password does not match password confirmation");
    if (formData.password.length <= 6)
      errors.push("Password must be at least 6 characters");

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const body = {
      user: {
        password: formData.password,
        password_confirmation: formData.password_confirmation,
      },
    };

    put(`users/${user.id}/passwords`, body);
  };

  return (
    <Modal
      dismissible
      open={props.open}
      onClose={props.onClose}
      Icon={LockClosedIcon}
      title="Change Password"
    >
      <Form onSubmit={handleSubmit} className="text-left pt-4">
        {errors.length > 0 && (
          <Alert type="alert" className="mb-2">
            Couldn't change password: {errors.join(", ")}.
          </Alert>
        )}
        <PasswordInput
          className="mb-2"
          required
          label="New password"
          name="password"
        />
        <PasswordInput
          required
          label="Confirm new password"
          name="password_confirmation"
        />
        <div className="mt-5 sm:mt-6">
          <Button
            className="w-full block"
            type="submit"
            disabled={data.isLoading}
          >
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
