import React, { useState } from "react";
import { Modal, Button } from "components/shared";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

// use like this:
// const MyComponent = () => {
//   const [ConfirmModal, confirmDelete] = useConfirm(
//     'Are you sure?',
//     'Are you sure you want to delete user "Isaac Kwok"?',
//   )
//   const handleDelete = async () => {
//     const isConfirmed = await confirmDelete()
//     if (isConfirmed) {/* ... */}
//     else {/* ... */}
//   }
//   return (
//     <>
//       <Button onClick={handleDelete}>Delete</Button>
//       <ConfirmModal />
//     </>
//   )
// }
const useConfirm = (title, message) => {
  const [promise, setPromise] = useState(null);

  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  const ConfirmationModal = () => (
    <Modal
      type='alert'
      className="z-50"
      open={promise !== null}
      size="sm"
      Icon={ExclamationTriangleIcon}
      title={title}
    >
      <div className='pt-4'>
        <div className="text-sm">{message}</div>
        <div className="pt-6 flex items-center justify-center space-x-2">
          <Button variant='danger' onClick={handleConfirm}>Yes</Button>
          <Button variant='secondary' onClick={handleCancel}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
  return [ConfirmationModal, confirm];
};

export default useConfirm;
