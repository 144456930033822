import React, { Fragment, useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Switch } from "@headlessui/react";
import { BookmarkSlashIcon } from "@heroicons/react/24/outline";
import { Button, Form, Alert, TextArea, Loading } from "components/shared";
import { useFlash, useConfirm } from "hooks";
import moment from "moment";
import { cn } from "utils";

const UpdateUserCertification = ({ user, ...props }) => {
  const { API, currentUser } = useContext(AppContext);
  const { successFlash, errorFlash } = useFlash();
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ConfirmationModal, confirmDelete] = useConfirm(
    `De-certify user?`,
    `Are you sure you want to de-certify ${user.name}? This cannot be undone.`,
  );
  const [certification, setCertification] = useState(null);

  const fetchCertification = async () => {
    setLoading(true);
    const response = await API.get(`/certifications`, {
      params: { user_id: user.id },
      onError: errorFlash,
    });

    if (response && response.length > 0) {
      setCertification(response[0]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCertification();
  }, [user]);

  const decertify = async () => {
    const isConfirmed = await confirmDelete();

    if (isConfirmed) {
      setLoading(true);
      const response = API.destroy(`/certifications/${certification.id}`, {
        onError: errorFlash,
      });

      if (response) {
        successFlash(`${user.firstName} was de-certified.`);
        if (props.onUpdate) props.onUpdate();
      }
    }
  };

  const handleSubmit = async (formData) => {
    const errors = [];

    if (formData.comment.length === 0)
      errors.push("You must add a certification comment.");

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const body = {
      certification: {
        trainer_id: currentUser.id,
        comment: formData.comment,
      },
    };

    setLoading(true);

    const response = await API.put(
      `/certifications/${certification.id}`,
      body,
      {
        onError: errorFlash,
      },
    );

    setLoading(false);

    if (response) {
      setCertification(response);
      successFlash(`User certification updated successfully.`);
      if (props.onUpdate) props.onUpdate();
    }
  };

  if (loading || !certification) return <Loading />;

  return (
    <>
      <Alert className="mt-4">
        {user.firstName} was certified{" "}
        {moment(certification.updatedAt).fromNow()}.
      </Alert>
      <Form onSubmit={handleSubmit} className="text-left pt-4 space-y-4">
        {errors.length > 0 && (
          <Alert type="alert" className="mb-2">
            Couldn't update certification: {errors.join(", ")}.
          </Alert>
        )}

        <TextArea
          required
          name="comment"
          label="Trainer Certification Comment"
          placeholder="e.g. You've really shown a good understanding of..."
          defaultValue={certification.comment}
          hint="This is shown to the user."
        />

        <div className="pt-6 flex items-center justify-center space-x-2">
          <Button type="submit" disabled={loading}>
            Update
          </Button>
          <Button
            Icon={BookmarkSlashIcon}
            variant="secondary"
            onClick={decertify}
          >
            De-certify user
          </Button>
        </div>
      </Form>
      <ConfirmationModal />
    </>
  );
};

export default UpdateUserCertification;
