import React, { Fragment, useState, useEffect, useRef } from "react";
import { Button, Alert, Badge } from "components/shared";
import { Hint } from "components/typography";
import certificate from "images/techmap-completion-certificate.jpg";
import * as htmlToImage from "html-to-image";
import moment from "moment";

const Certified = ({ certification, ...props }) => {
  const [certificateHref, setCertificateHref] = useState(null);
  const certificateRef = useRef(null);

  useEffect(() => {
    const makeCertificateDownloadable = async () => {
      const dataUrl = await htmlToImage.toJpeg(certificateRef.current, {
        cacheBust: true,
      });
      setCertificateHref(dataUrl);
    };

    if (certificateRef.current) {
      makeCertificateDownloadable();
    }
  }, [certification, certificateRef.current]);

  return (
    <>
      <div className="prose mx-auto px-6 py-12 pb-6">
        <h1 className="text-center">
          You are <span className="text-indigo-600">TechMap certified</span>!
        </h1>
        <p className="text-center font-semibold">
          Congratulations, {certification.user.firstName}! You have completed
          TechMap certification.
        </p>
        {certification.comment && (
          <Alert
            avatar={certification.trainer.avatar}
            type="notice"
            className="mt-4"
          >
            <p>
              <span className="font-semibold">
                {certification.trainer.firstName}
              </span>
              <Badge className="mx-1" color="blue">
                Trainer
              </Badge>
              <span className="text-xs">
                commented {moment(certification.updatedAt).fromNow()}
              </span>
            </p>
            {certification.comment}
          </Alert>
        )}
      </div>

      <div className="w-full max-w-prose mx-auto px-6 relative">
        <div className="w-full flex items-center justify-center space-x-4 mb-4">
          <Button
            size="lg"
            type="link"
            download={"techmap-completion-certificate.jpg"}
            href={certificateHref}
          >
            Download certificate
          </Button>
          <Hint>and</Hint>
          <Button
            size="lg"
            type="link"
            href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=TechMap%20Certified%20-%20The%20Official%20Tech%20For%20Tech%20Recruiter%20Course&organizationName=TechMap&organizationId=85328896&issueYear
    =${new Date().getFullYear()}&issueMonth=${new Date().getMonth()}&certUrl=https://techmap.app&certId=${
      certification.user.id
    }`}
            target="_blank"
            variant="secondary"
          >
            Add to LinkedIn
          </Button>
        </div>
        <div
          className="relative h-[800px] bg-contain bg-center"
          style={{ backgroundImage: `url('${certificate}')` }}
          ref={certificateRef}
        >
          {/*<img src={certificate} />*/}
          <div className="absolute top-[370px] w-full text-center text-2xl text-white opacity-90">
            {certification.user.name}
          </div>
        </div>
      </div>

      <div className="prose mx-auto px-6 py-12">
        <p>
          You can continue to learn using TechMap! We're always adding new
          content, and revising your answers is a great way to keep building on
          your knowledge.
        </p>
      </div>
    </>
  );
};

export default Certified;
