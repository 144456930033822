import React from "react";
import { cn } from "utils";

const LabelHeader = (props) => {
  return (
    <div
      className={cn(
        "text-gray-400 font-semibold text-sm @sm:block",
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};

export default LabelHeader;
