import React from 'react'
import { RemoteCombobox } from 'components/shared'
import { capitalize, toSentence } from 'utils'

const TermsBar = ({ activity, terms, ...props }) => {
  return(
    <div className={props.className}>
      <div className="text-sm font-medium text-gray-400 sm:w-40">
        Terms covered
      </div>
      {
        props.editable ? (
          <RemoteCombobox
            placeholder="Select terms"
            defaultOptions={terms}
            fetchPath="terms"
            updatePath={`activities/${activity.slug}`}
            updateTransform={(terms) => ({
              activity: { term_ids: terms.map(t => t.id) },
              activity_id: activity.id,
            })}
            defaultValue={terms}
            optionValue={"id"}
            optionLabel={"title"}
            allowCreate
            createPath={`/terms`}
            createTransform={(term) => ({
              term: { title: term.title }
            })}
          />
        ) : (
          <div className="text-sm text-gray-600">
            { toSentence(terms.map(term => capitalize(term.title))) }
          </div>
        )
      }
    </div>
  )
}

export default TermsBar
