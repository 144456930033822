import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { useAiCoach, useIsMounted } from "hooks";
import { stripTags, interpolate, classNames } from "utils";
import { Alert, Spinner } from "components/shared";
import Response from "components/coaching/Response";
import HarshnessSlider from "components/coaching/HarshnessSlider";
import AiJudgement from "components/coaching/AiJudgement";
import { useBlockNote } from "@blocknote/react";
import { useAPI } from "hooks";

const AiQuestionHelper = ({ user, question, response, ...props }) => {
  if (!question || !response) return null;
  const { editorMode } = useContext(AppContext);
  const { getSettings } = useAPI();
  const { generate, judge, coach, warmup } = useAiCoach();

  const isMounted = useIsMounted();

  const [loading, setLoading] = useState(!response.aiCoachResponse);
  const [text, setText] = useState(response.aiCoachResponse);
  const [judgement, setJudgement] = useState(response.aiCoachJudgement);

  const [questionContent, setQuestionContent] = useState(null);
  const [harshness, setHarshness] = useState(null);

  useEffect(() => {
    getSettings(user.id, ({ aiHarshness }) => setHarshness(aiHarshness));
  }, []);

  useEffect(() => {
    const setQuestionContentFromQuestion = async () => {
      if (question.blocks) {
        try {
          const blocks = JSON.parse(question.blocks);
          const editor = useBlockNote({ initialContent: blocks });
          const val = await editor.blocksToMarkdown(blocks);

          setQuestionContent(val);
        } catch {
          setQuestionContent(question.text);
        }
      } else {
        setQuestionContent(question.text);
      }
    };

    setQuestionContentFromQuestion();
  }, [question]);

  const generateText = async ({ harshness = harshness } = {}) => {
    const generated = await generate(
      { text: questionContent, id: question.id },
      response,
      user,
      { harshness, instructions: question.aiCoachInstructions },
    );

    if (isMounted.current) return generated;
  };

  const generateJudgement = async ({ harshness = harshness } = {}) => {
    const generated = await judge(
      { text: questionContent, id: question.id },
      response,
      user,
      { harshness, instructions: question.aiCoachInstructions },
    );

    if (isMounted.current) return generated;
  };

  const generateWarmupMessage = async () => {
    const generated = await warmup(
      { text: questionContent, id: question.id },
      response,
      user,
    );

    if (isMounted.current) return generated;
  };

  const generateFullText = async ({ harshness = harshness } = {}) => {
    setLoading(true);

    let judgement, text;

    if (props.showWarmupMessage) {
      text = await generateWarmupMessage();
      setText(text);
      setLoading(false);
    } else {
      [judgement, text] = await Promise.all([
        generateJudgement({ harshness }),
        generateText({ harshness }),
      ]);
      if (props.onAiCoachResponse)
        props.onAiCoachResponse(response, text, judgement);
      setJudgement(judgement);
      setText(text);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (questionContent && response.text && !response.aiCoachResponse) {
      generateFullText();
    }
  }, [questionContent, response.text]);

  if (loading)
    return (
      <Response
        isPlaceholder
        isLast
        showActions={false}
        allowDelete={false}
        user={coach}
        extra={
          <HarshnessSlider
            initialValue={harshness}
            onChangeComplete={(harshness) => {
              generateFullText({ harshness });
              fetchUserSettings();
            }}
          />
        }
        avatarClassName="-mt-[7px]"
        className={props.className}
      />
    );

  return (
    <Response
      className={props.className}
      allowRevise={false}
      allowComment={false}
      allowDelete={false}
      allowRegenerate={!props.showWarmupMessage}
      onRegenerate={generateFullText}
      user={coach}
      preText={<AiJudgement className="my-4" judgement={judgement} />}
      extra={
        <HarshnessSlider
          initialValue={harshness}
          onChangeComplete={(harshness) => {
            generateFullText({ harshness });
            fetchUserSettings();
          }}
        />
      }
      avatarClassName="-mt-[7px]"
      text={text}
      isLast
    />
  );
};

// AiQuestionHelper.whyDidYouRender = true

export default AiQuestionHelper;
