import React from 'react'
import Certification from 'components/certification/Certification'

const CertificationActivity = ({ user, stage, ...props }) => {
  return(
    <div className="CertificationActivity h-full">
      <div className='h-full overflow-y-auto'>
        <Certification />
      </div>
    </div>
  )
}

export default CertificationActivity