import React, { useState, useMemo, useContext } from "react";
import AppContext from "contexts/AppContext";

const useGraph = (user) => {
  const { API } = useContext(AppContext);

  const [baseGraph, setBaseGraph] = useState({});
  const [completedAttempts, setCompletedAttempts] = useState([]);
  const [currentAttempts, setCurrentAttempts] = useState([]);

  const fetchGraph = async () => {
    const [baseGraph, completedAttempts, currentAttempts] = await Promise.all([
      API.get(`/courses/${user.courseId}/graph`),
      API.get(`/attempts`, {
        params: { user_id: user.id, status: "complete" },
      }),
      API.get(`/attempts`, {
        params: { user_id: user.id, status: "in_progress" },
      }),
    ]);

    setBaseGraph(baseGraph);
    setCompletedAttempts(completedAttempts);
    setCurrentAttempts(currentAttempts);

    return toGraph(baseGraph, completedAttempts, currentAttempts);
  };

  const toGraph = (baseGraph, completedAttempts, currentAttempts) => {
    const completedAttemptActivityIds = completedAttempts.map(
      ({ activity }) => activity.id,
    );
    const currentAttemptActivityIds = currentAttempts.map(
      ({ activity }) => activity.id,
    );

    return {
      ...baseGraph,
      activities: baseGraph.activities?.map((activity) => {
        const unlocked =
          !(activity.construction || activity.permalocked) &&
          (activity.starter ||
            activity.previous.every(({ id }) =>
              completedAttemptActivityIds.includes(id),
            ));

        return {
          completedAttempt: completedAttemptActivityIds.includes(activity.id),
          currentAttempt: currentAttemptActivityIds.includes(activity.id),
          unlocked,
          ...activity,
        };
      }),
    };
  };

  const graph = useMemo(
    () => toGraph(baseGraph, completedAttempts, currentAttempts),
    [baseGraph, completedAttempts, currentAttempts],
  );

  return [graph, fetchGraph];
};

export default useGraph;
