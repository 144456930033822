import React from "react";
import { Modal, Button } from "components/shared";
import { useQuery } from "hooks";
import LiveSessionSignup from "components/live-sessions/LiveSessionSignup";

const LiveSessionSignupModal = ({ ...props }) => {
  const { watchQuery, deleteQuery } = useQuery();

  const id = watchQuery("liveSessionSignupId");
  const onClose = () => deleteQuery("liveSessionSignupId", id);

  return (
    <Modal size={"lg"} open={!!id} onClose={onClose} dismissible>
      <LiveSessionSignup id={id} className='py-4' />
    </Modal>
  );
};

export default LiveSessionSignupModal;
