import React, { useState, useEffect } from 'react'
import { Hint, ProgressLine, SelectInput, Loading } from 'components/shared'
import moment from 'moment'
import ChangePullRequest from 'components/github/actions/ChangePullRequest'

const SubmitPullRequest = (props) => {
  const [showSelectPullRequestForm, setShowSelectPullRequestForm] = useState(false)

  useEffect(() => {
    if(
      props.learnerRepo?.slug
      && props.attempt?.trackingBranch
      && (!props.attempt?.pullRequest || props.attempt?.pullRequest.status === 'closed')
    ) setShowSelectPullRequestForm(true)
  }, [props.attempt])

  const toggleShowSelectPullRequestForm = (e) => {
    e.preventDefault()

    setShowSelectPullRequestForm(!showSelectPullRequestForm)
  }

  return(
    <ProgressLine.Widget>
      <ProgressLine.Widget.Indicator
       filled={props.attempt?.pullRequestSubmitted}
       active={props.attempt?.tracked && !props.attempt?.pullRequestSubmitted} />

      <ProgressLine.Widget.Details>
        <ProgressLine.Widget.Details.Header>
          <div className="WidgetTitle">
            Pull Request submitted
            {
              props.attempt?.pullRequest
              && <Hint className="ml-1">
                   { moment(props.attempt.pullRequest.createdAt).fromNow() }
                 </Hint>
            }
          </div>
          <Hint>
            {
              showSelectPullRequestForm
              ? <ChangePullRequest
                  {...props}
                  show={showSelectPullRequestForm}
                  setShow={setShowSelectPullRequestForm}
                  afterChange={props.onChangeStatus} />
              : props.attempt?.pullRequestSubmitted
                && <> 
                     <span className="mr-1">
                       { props.attempt.pullRequest.title }
                     </span>
                     <a
                      href='#'
                      onClick={toggleShowSelectPullRequestForm}>
                      Change tracked PR
                     </a>
                   </>
            }
          </Hint>
        </ProgressLine.Widget.Details.Header>
      </ProgressLine.Widget.Details>
    </ProgressLine.Widget>
  )
}

export default SubmitPullRequest