import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { useQueryParam, NumberParam } from "use-query-params";
import { Panel, Loading } from "components/shared";
import { XMarkIcon } from "@heroicons/react/24/outline";
import User from "components/users/User";

const UserPanel = ({ ...props }) => {
  const { API, currentUser } = useContext(AppContext);
  const [id, setId] = useQueryParam("user", NumberParam);

  const hide = () => {
    setId(null);
  };

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUser = async () => {
    setIsLoading(true);
    const user = await API.get(`/users/${id}`);
    setUser(user);
    setIsLoading(false);
  };

  useEffect(() => {
    if (id) fetchUser();
  }, [id]);

  const handleChange = () => {
    if (id) fetchUser();
    if (props.onChange) props.onChange();
  };

  const handleViewNote = (id) => {
    const newUser = {
      ...user,
      notes: [
        ...user.notes.map((note) =>
          note.id === id
            ? {
                ...note,
                viewers: [...note.viewers, { id: currentUser.id }],
              }
            : note,
        ),
      ],
    };

    setUser({ ...newUser });
  };

  if (!id)
    return (
      <Panel size="md" show={!!id}>
        <Panel.Body>
          <button
            type="button"
            className="absolute right-2 top-2 z-10 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={hide}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </Panel.Body>
      </Panel>
    );

  return (
    <Panel size="md" show={!!id}>
      <Panel.Body>
        <button
          type="button"
          className="absolute right-2 top-2 z-10 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={hide}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        {isLoading ? (
          <Loading />
        ) : (
          <User
            user={user}
            onChange={handleChange}
            onViewNote={handleViewNote}
          />
        )}
      </Panel.Body>
    </Panel>
  );
};

export default UserPanel;
