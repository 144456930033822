import React, { useState } from "react";
import { Button, Hint, ProgressLine } from "components/shared";
import LearnerIssue from "components/github/LearnerIssue";
import moment from "moment";
import { usePost, useWatchJob } from "hooks";

const CompleteChallenge = (props) => {
  const startReflection = (e) => {
    e.preventDefault();
    props.onStartReflection();
  };

  const canComplete =
    props.attempt?.pullRequestMerged && !props.attempt?.completed;

  return (
    <ProgressLine.Widget>
      <ProgressLine.Widget.Indicator
        filled={props.attempt?.completed}
        active={canComplete}
      />

      <ProgressLine.Widget.Details>
        <ProgressLine.Widget.Details.Header>
          <div className="WidgetTitle">
            Challenge completed
            {props.attempt?.completed && (
              <Hint className="ml-1">
                {moment(props.attempt.completedAt).fromNow()}
              </Hint>
            )}
          </div>
        </ProgressLine.Widget.Details.Header>

        <ProgressLine.Widget.Details.Action>
          {props.attempt?.completed ? null : (
            <Button
              size="sm"
              className="-mt-2"
              onClick={startReflection}
              loading={props.isReflecting}
              disabled={!canComplete}
            >
              {props.isReflecting
                ? `Completing challenge...`
                : "Complete challenge"}
            </Button>
          )}
        </ProgressLine.Widget.Details.Action>
      </ProgressLine.Widget.Details>
    </ProgressLine.Widget>
  );
};

export default CompleteChallenge;
