import React, { useState } from "react";
import { Button, Hint, Spinner } from "components/shared";
import LearnerRepo from "components/github/LearnerRepo";
import { useFetch, usePost } from "hooks";

const LearnerRepoStatus = (props) => {
  const [repoSynced, setRepoSynced] = useState(false);

  const { data: checkingRepoSynced } = useFetch(
    `projects/${props.id}/statuses/learner-repo-synced`,
    ({ status }) => {
      if (status === "success") setRepoSynced(true);
      else setRepoSynced(false);
    },
  );

  const { post, data: syncingRepo } = usePost(
    `projects/${props.id}/actions/setup-learner-repo`,
    props.onSuccess,
  );

  const handleClick = (e) => {
    e.preventDefault();
    post();
  };

  if (checkingRepoSynced.isLoading)
    return <LearnerRepo loading className={props.className} />;

  if (repoSynced && props.learnerRepo)
    return <LearnerRepo {...props.learnerRepo} className={props.className} />;

  return (
    <Button
      onClick={handleClick}
      className={props.className}
      size="sm"
      disabled={syncingRepo.isLoading}
      error={syncingRepo.errorMessage}
    >
      {syncingRepo.isLoading && <Spinner />}
      Sync repo with {props.slug}
    </Button>
  );
};

LearnerRepoStatus.defaultProps = {
  slug: "Github",
};

export default LearnerRepoStatus;
