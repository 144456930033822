import React from "react";
import { Tooltip } from "components/shared";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArchiveBoxArrowDownIcon,
} from "@heroicons/react/24/outline";
import { cn } from "utils";

const EditStageMenu = ({ ...props }) => {
  return (
    <div
      className={cn(
        "text-gray-700 absolute top-full w-full pt-2 pr-6 cursor-pointer z-10 hidden group-hover:flex space-x-2 items-start justify-center",
        props.className,
      )}
    >
      {props.canMoveLeft && (
        <div
          className="group/item flex items-center justify-center space-x-1"
          onClick={props.onMoveLeft}
        >
          <Tooltip text="Move left">
            <div className="p-2 relative bg-white shadow-sm rounded-full cursor-pointer hover:bg-gray-50 hover:shadow-md flex items-center justify-center">
              <ChevronLeftIcon className="h-4 w-4" />
            </div>
          </Tooltip>
        </div>
      )}

      {props.canMoveRight && (
        <div className="group/item flex items-center justify-center space-x-1">
          <Tooltip text="Move right">
            <div
              className="p-2 bg-white shadow-sm rounded-full cursor-pointer hover:bg-gray-50 hover:shadow-md flex items-center justify-center"
              onClick={props.onMoveRight}
            >
              <ChevronRightIcon className="h-4 w-4" />
            </div>
          </Tooltip>
        </div>
      )}

      {props.canArchive && (
        <div
          className="group/item flex items-center justify-center space-x-1"
          onClick={props.onArchive}
        >
          <Tooltip text="Archive">
            <div className="p-2 bg-white shadow-sm rounded-full cursor-pointer hover:bg-gray-50 hover:shadow-md flex items-center justify-center">
              <ArchiveBoxArrowDownIcon className="h-4 w-4" />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default EditStageMenu;
