import React from "react";
import { cn } from "utils";

const ProgressBar = ({ progress, ...props }) => {
  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center relative w-full h-3 bg-gray-200 border-l-2 border-r-2 border-gray-200",
        props.className,
      )}
    >
      {progress > 0 && (
        <div
          className={cn(
            "h-2 absolute left-0 top-0.5 bg-blue-400",
            progress === 100 && "animate-pulse"
          )}
          style={{ width: `${progress}%` }}
        />
      )}
    </div>
  );
};

export default ProgressBar;
