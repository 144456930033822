import React, { useState, useEffect, useContext } from "react";
import AppContext from "contexts/AppContext";
import { useRouteMatch } from "react-router-dom";
import { Page, Feed, ThreeColumn, Button, Hint } from "components/shared";
import NotificationsFeed from "components/notifications/NotificationsFeed";
import QuestionHeader from "components/coaching/QuestionHeader";
import Discussion from "components/coaching/Discussion";
import Question from "components/questions/Question";
import Discussions from "components/coaching/Discussions";
import { useFetch, useQuery } from "hooks";
import { removeDiacritics } from "utils";
import { pure } from "recompose";
import moment from 'moment'

const NotificationsPage = ({
  notifications,
  setNotificationRead,
  notificationsConnected,
  ...props
}) => {
  const { currentUser } = useContext(AppContext);
  const { pathWithQuery } = useQuery();

  let { path } = useRouteMatch();
  let match = useRouteMatch([`${path}/:selectedNotificationId`]);
  let { selectedNotificationId } = match?.params || {};

  selectedNotificationId = parseInt(selectedNotificationId);

  const [selectedNotification, setSelectedNotification] = useState(null);

  const { loadingOrError: loadingSelectedNotification } = useFetch(
    `/notifications/${selectedNotificationId}`,
    setSelectedNotification,
    [selectedNotificationId],
  );

  const selectedNotificationIsHtml = selectedNotification?.html;

  return (
    <Page fullscreen>
      <ThreeColumn>
        <NotificationsFeed
          notifications={notifications}
          setNotificationRead={setNotificationRead}
          websocketConnected={notificationsConnected}
          selectedNotificationId={selectedNotificationId}
        />

        {selectedNotification?.type === "email_received" ? (
          <div className="prose mx-auto px-8 py-12">
            <Hint>
              From { selectedNotification.from.firstName } ({ selectedNotification.about.fromEmail })
            </Hint>
            <Hint>
              { moment(selectedNotification.createdAt).calendar() } ({ moment(selectedNotification.createdAt).fromNow() })
            </Hint>
            {selectedNotification.about.text}
            <div className='mt-4'>
              <Button
                className="no-underline"
                type="routeLink"
                to={pathWithQuery(null, {
                  userMail: selectedNotification.from.id,
                })}
              >
                Reply
              </Button>
            </div>
          </div>
        ) : selectedNotificationIsHtml ? (
          <div
            className="prose mx-auto px-8 py-12"
            dangerouslySetInnerHTML={{ __html: selectedNotification.html }}
          />
        ) : (
          selectedNotification?.about.discussion &&
          selectedNotification?.about.question && (
            <>
              <QuestionHeader
                question={selectedNotification.about.question}
                user={selectedNotification.from}
              />
              <Discussion
                includeAiCoach
                highlightedCommentId={selectedNotification.about.id}
                {...selectedNotification.about.discussion}
              />
            </>
          )
        )}

        {selectedNotificationIsHtml
          ? null
          : selectedNotification?.about.question && (
              <Discussions
                locked={false}
                omitCurrentUser
                omitActivity
                omitLastName
                question={{ id: selectedNotification.about.question.id }}
              />
            )}
      </ThreeColumn>
    </Page>
  );
};

// NotificationsPage.whyDidYouRender = true

export default pure(NotificationsPage);
