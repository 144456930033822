import React, { useContext, useState, useEffect, useMemo } from "react";
import AppContext from "contexts/AppContext";
import { ComposeEmail, Loading } from "components/shared";
import Directory from "components/users/Directory";

const EmailUsers = ({ defaultCheckedIds, ...props }) => {
  const { API } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [checkedIds, setCheckedIds] = useState(defaultCheckedIds);
  const [loading, setLoading] = useState(true);

  const fetchUsers = async () => {
    setLoading(true);
    const response = await API.get("/users");
    if (response) {
      setUsers(response.sort((a, b) => a.name.localeCompare(b.name)))
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const checkedUsers = useMemo(() => {
    return users.filter((user) => checkedIds.includes(user.id));
  }, [users, checkedIds]);

  const handleCheckUser = (user) => {
    if (checkedIds.includes(user.id)) {
      setCheckedIds(checkedIds.filter((id) => id !== user.id));
    } else {
      setCheckedIds([...checkedIds, user.id]);
    }
  };

  const handleBatchCheckUsers = (users) => {
    setCheckedIds([...checkedIds, ...users.map((u) => u.id)]);
  }

  const handleBatchUncheckUsers = (users) => {
    setCheckedIds(checkedIds.filter((id) => !users.map((u) => u.id).includes(id)));
  }

  return (
    <div className="grid grid-cols-12 divide-x divide-gray-200 h-[90vh]">
      <div className="col-span-6 h-[90vh]">
        <Directory
          checkable
          loading={loading}
          users={users}
          selectedUsers={checkedUsers}
          onCheck={handleCheckUser}
          onBatchCheck={handleBatchCheckUsers}
          onBatchUncheck={handleBatchUncheckUsers}
        />
      </div>
      <div className="col-span-6">
        <ComposeEmail confirm to={checkedUsers} />
      </div>
    </div>
  );
};

export default EmailUsers;
