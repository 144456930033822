import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import AppContext from "contexts/AppContext";
import FlowGraph from "components/graph/FlowGraph";
import ActivityPreview from "components/graph/ActivityPreview";
import ActivityPanel from "components/graph/ActivityPanel";
import ActivitySummary from "components/graph/ActivitySummary";
import CourseMenu from "components/courses/CourseMenu";
import { ReactFlowProvider } from "reactflow";
import { useGraph, useFlash } from "hooks";

const FlowGraphPage = ({ ...props }) => {
  const { currentUser, API, editorMode } = useContext(AppContext);
  const { notificationFlash } = useFlash();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { slug, action } = useRouteMatch([
    "/activities/:slug/:action",
    "/activities/:slug",
    "/activities",
    "/",
  ]).params;

  const [goal, setGoal] = useState(currentUser.goal);
  const [graph, fetchGraph] = useGraph(currentUser);

  useEffect(() => {
    const handleLocationChange = async () => {
      const graph = await fetchGraph();

      if (query.get("completeOnboarding")) return completeOnboarding();

      const activity =
        slug && graph.activities.find((activity) => activity.slug === slug);

      if (activity) {
        // redirect to preview if we can't start this activity
        action !== "preview" &&
          (activity.permalocked ||
            activity.construction ||
            !activity.unlocked) &&
          history.push(`/activities/${activity.slug}/preview`);
      }
    };

    handleLocationChange();
  }, [location]);

  const addActivity = async (activity) => {
    const body = {
      activity: {
        ...activity,
        course_id: currentUser.courseId,
      },
    };

    const response = await API.post("/activities", body);

    if (response) fetchGraph();
  };

  const updateActivity = async (activity) => {
    const body = {
      activity: {
        flow_x: activity.flowX,
        flow_y: activity.flowY,
      },
    };
    const response = await API.put(`/activities/${activity.id}`, body);

    if (response) fetchGraph();
  };

  const archiveActivity = async ({ id, label }) => {
    const response = await API.destroy(`/activities/${id}`);

    if (response) {
      notificationFlash(`"${label}" archived.`);
      fetchGraph();
    }
  };

  const addRelation = async (relation) => {
    const body = { relation };

    const response = await API.post("/relations", body);

    if (response) fetchGraph();
  };

  const deleteRelation = async (relation) => {
    const response = await API.destroy(`/relations/${relation.id}`);

    if (response) fetchGraph();
  };

  const previewRef = useRef(null);
  const ACTIVITY_PREVIEW_DEFAULT_WIDTH = 448;
  const zoomedOffset = previewRef.current
    ? previewRef.current.offsetWidth / 4
    : ACTIVITY_PREVIEW_DEFAULT_WIDTH / 4;

  return (
    <>
      <ReactFlowProvider>
        <FlowGraph
          graph={graph}
          focusSlug={slug}
          zoomedOffset={zoomedOffset}
          editable={editorMode}
          onAddActivity={addActivity}
          onUpdateActivity={updateActivity}
          onArchiveActivity={archiveActivity}
          onAddRelation={addRelation}
          onDeleteRelation={deleteRelation}
        />
      </ReactFlowProvider>

      {(currentUser.isAdmin || currentUser.isCourseDesigner) && editorMode && (
        <CourseMenu onChange={fetchGraph} />
      )}

      <ActivityPreview
        ref={previewRef}
        show={action === "preview"}
        currentGoal={goal}
        setCurrentGoal={setGoal}
        onOpenActivity={fetchGraph}
        onChange={fetchGraph}
      />

      <Switch>
        <Route path="/activities/:slug/preview"></Route>

        <Route path="/activities/:slug/summary">
          <ActivitySummary />
        </Route>

        <Route path="/activities/:slug">
          <ActivityPanel />
        </Route>

        {/*        <Route path="/">
          {!currentUser.license && <FreeTrialManager />}
          <LiveSessionNotifier />
        </Route>*/}
      </Switch>
    </>
  );
};

export default FlowGraphPage;
