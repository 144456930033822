import React, { useState, useContext } from 'react'
import { Button } from 'components/shared'
import { post } from 'api'
import AppContext from 'contexts/AppContext'

const SetupLearnerProject = ({ activity, onSuccess }) => {
  const { apiUrl, formToken } = useContext(AppContext)
  const [data, setData] = useState({ isLoading: false, errorMessage: null })

  const handleSuccess = (response) => {
    setData({ ...data, isLoading: false })
    onSuccess(response)
  }

  const handleError = (error, response) => {
    setData({ ...data, errorMessage: error, isLoading: false })
  }

  const handleClick = (e) => {
    e.preventDefault()

    setData({ ...data, isLoading: true })

    post(`${ apiUrl }/activities/${ activity.id }/repos/actions/setup-learner-project`, { formToken }, handleSuccess, handleError)
  }

  return(
    <Button onClick={handleClick} loading={data.isLoading} error={data.errorMessage}>
      Fix
    </Button>
  )
}

export default SetupLearnerProject