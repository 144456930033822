import React, { useState, useEffect, useRef } from 'react'
import { TextInput, ContentEditable } from 'components/shared'

const GraphLabel = ({ id, slug, title, color, allowEdit, onUpdate, autofocus, ...props }) => {
  const [text, setText] = useState(title)

  const contentEditableRef = useRef(null)

  useEffect(() => {
    if(autofocus && allowEdit) {
      const element = contentEditableRef.current.el.current

      element.innerHTML = ''
      element.focus()
    }
  }, [])

  const handleClick = e => {
    allowEdit
    ? e.target.focus()
    : props.onClick && props.onClick(e)
  }

  const handleKeyPress = e => e.key === 'Enter' && e.target.blur()

  const handleChange = e => setText(e.target.value)

  const handleBlur = () => onUpdate({ id, slug, title: text }) // should submit

  return(
    <span
     className='block mt-6 text-sm'
     onClick={handleClick}
     style={{color}}>
      { 
        allowEdit
        ? <ContentEditable
           ref={contentEditableRef}
           className="rounded-md"
           html={text}
           onKeyPress={handleKeyPress}
           onChange={handleChange}
           onBlur={handleBlur} />
        : title }
    </span>
  )
}

GraphLabel.defaultProps = {
  autofocus: false
}

export default GraphLabel