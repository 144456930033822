import React, { useContext } from 'react'
import GraphContext from 'contexts/GraphContext'
import { useHistory } from 'react-router-dom'
import { TrophyIcon } from '@heroicons/react/24/solid'

const NodeOverlay = ({ slug, ...props }) => {
  const { graphContext } = useContext(GraphContext)
  const history = useHistory()

  if(!graphContext) return null

  const highlightedNode = graphContext.nodes().find(node => node.data().slug === slug)

  if(!highlightedNode) return null

  const highlightStyle = {
    left: highlightedNode.renderedPosition().x,
    top: highlightedNode.renderedPosition().y
  }

  const labelStyle = {
    left: highlightedNode.renderedPosition().x,
    top: highlightedNode.renderedPosition().y
  }

  const handleClick = (e) => {
    e.preventDefault()
    if(props.onClick) props.onClick()
    setTimeout(() => history.push(`/activities/${slug}/preview?showSetGoalTour=true`), 400)
  }

  return(
    <span
     className='cursor-pointer hover:opacity-80'
     onClick={handleClick}>
      <div
       className='absolute text-xs text-blue-400 font-semibold h-6 flex items-center -mt-3 space-x-1 ml-4 text-yellow-400'
       style={labelStyle}>
        <TrophyIcon className='h-4 w-4 inline-block' />
      </div>
      <div
       className='absolute border border-2 border-yellow-400 rounded-full h-6 w-6 -mt-3 -ml-3 cursor-pointer'
       style={highlightStyle} />
    </span>
  )
}

export default NodeOverlay
