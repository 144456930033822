import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
// import { Highlighted } from 'components/shared'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {oneDark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import { classNames } from 'utils'

const ResponseText = ({ text, question, ...props }) => {
  // to use the Highlighted component,
  // <Highlighted
  //  text={text}
  //  words={ question?.constraints ? question.constraints.map(c => c.title) : [] } />

  return(
    <ReactMarkdown
     className={classNames(
       'mb-0 prose-sm prose--no-margins',
       props.className
     )}
     children={text}
     remarkPlugins={[remarkGfm]}
     components={{
           code({node, inline, className, children, ...props}) {
             const match = /language-(\w+)/.exec(className || '')
             return !inline && match ? (
               <SyntaxHighlighter
                 {...props}
                 children={String(children).replace(/\n$/, '')}
                 style={oneDark}
                 language={match[1]}
                 PreTag="div"
               />
             ) : (
               <code {...props} className={className}>
                 {children}
               </code>
             )
           }
         }} />
  )
}

export default ResponseText
