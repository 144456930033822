import React, { Fragment, useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Link } from "react-router-dom";
import {
  RemoteCombobox,
  Button,
  RemoteTextArea,
  Label,
  Spinner,
} from "components/shared";
import { Switch } from "@headlessui/react";
import { KeyIcon } from "@heroicons/react/24/outline";
import {
  countWords,
  excerpt,
  stripTags,
  interpolate,
  cn,
  toSentence,
} from "utils";
import QuestionEditor from "components/editor/Editor";
import { useAPI } from "hooks";
import { pure } from "recompose";

const EntityDescription = ({ entity }) => (
  <div>
    How would you assign{" "}
    <Link to={`?previewType=entity&previewId=${entity.id}`}>
      {entity.title}
    </Link>
    ?
  </div>
);

const QuestionIntro = ({
  question,
  constraints,
  stage,
  excerptLength,
  user,
  showInterpolation,
  ...props
}) => {
  const { editorMode } = useContext(AppContext);
  const { isLoading, updateQuestion, updateActivityQuestion } = useAPI();

  const textExceedsExcerpt =
    question.text && countWords(question.text) > excerptLength;

  const [isExcerpted, setIsExcerpted] = useState(!textExceedsExcerpt);

  const questionText = question.title
    ? question.title
    : question.text
    ? isExcerpted
      ? interpolate(question.text, user, { showInterpolation })
      : interpolate(excerpt(stripTags(question.text), excerptLength), user, {
          showInterpolation,
        })
    : null;

  const [aiCoachEnabled, setAiCoachEnabled] = useState(question.aiCoachEnabled);

  useEffect(() => {
    if (aiCoachEnabled !== question.aiCoachEnabled) {
      updateActivityQuestion(
        question.id,
        stage.slug,
        { ai_coach_enabled: aiCoachEnabled },
        props.onUpdate,
      );
    }
  }, [aiCoachEnabled]);

  return (
    <div className={cn(props.className)}>
      {editorMode && (
        <div className="py-4 flex space-x-2">
          <Button
            type="pill"
            Icon={KeyIcon}
            onClick={() =>
              updateQuestion(
                question.id,
                { key: !question.isKey },
                props.onUpdate,
              )
            }
            disabled={isLoading}
          >
            {question.isKey ? "Unset as key question" : "Set as key question"}
          </Button>
        </div>
      )}

      {question.entity ? (
        <EntityDescription entity={question.entity} />
      ) : (
        <QuestionEditor
          initialMarkdown={questionText}
          initialContent={question.blocks}
          editable={props.editable}
          updatePath={`/questions/${question.id}`}
          transform={(blocks) => ({
            question: { blocks },
          })}
          onStartUpdate={props.onStartUpdate}
          onUpdate={(blocks) =>
            props.onUpdate && props.onUpdate({ ...question, blocks })
          }
          onErrorUpdate={props.onErrorUpdate}
          editorClassName={cn(
            props.small ? "prose-sm" : "",
            props.truncate ? (props.small ? "h-6" : "h-8") : "",
          )}
        />
      )}
      {editorMode ? (
        <div className={cn("py-4", props.small && "py-2")}>
          <Label
            className={cn("ml-1 text-sm mb-1", props.small && "text-xs mb-0")}
          >
            Terms to include
          </Label>
          <RemoteCombobox
            small={props.small}
            placeholder="Add required terms"
            defaultOptions={constraints}
            fetchPath={"constraints"}
            updatePath={`constraints`}
            updateTransform={(constraints) => ({
              constraints: {
                ids: constraints.map((c) => c.id),
                question_id: question.id,
                stage_id: stage.id,
              },
            })}
            defaultValue={constraints}
            optionValue={"id"}
            optionLabel={"title"}
            allowCreate
            createPath={`/constraints`}
            createTransform={({ title }) => ({
              constraint: { title },
            })}
          />
        </div>
      ) : (
        constraints?.length > 0 && (
          <div
            className={cn(
              "text-sm text-gray-400 mt-2",
              props.small && "text-xs mt-1",
            )}
          >
            Use the following terms in your answer:{" "}
            {toSentence(constraints.map((c) => c.title))}.
          </div>
        )
      )}

      {editorMode && (
        <div className="py-4">
          <div className="flex space-x-2 items-center">
            <Label className="ml-1 text-sm">AI coach enabled?</Label>
            <Switch
              checked={aiCoachEnabled}
              onChange={setAiCoachEnabled}
              className={cn(
                aiCoachEnabled ? "bg-teal-500" : "bg-gray-200",
                "relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2",
              )}
            >
              <span
                aria-hidden="true"
                className={cn(
                  aiCoachEnabled ? "translate-x-5" : "translate-x-0",
                  "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                )}
              />
            </Switch>
            {isLoading && <Spinner className="inline h-3 w-3 text-gray-400" />}
          </div>

          <div className={cn("pt-4", !aiCoachEnabled && "hidden")}>
            <Label
              className={cn("ml-1 text-sm mb-1", props.small && "text-xs mb-0")}
            >
              AI coach instructions
            </Label>
            <RemoteTextArea
              title="AI coach instructions"
              disabled={!aiCoachEnabled}
              defaultValue={question.aiCoachInstructions}
              name="ai_coach_instructions"
              rows={4}
              placeholder={`e.g. Avoid giving feedback on...`}
              method="put"
              path={`activity_stages/${stage.slug}/questions/${question.id}`}
              transform={(text) => ({
                question: { ai_coach_instructions: text },
              })}
              onSubmit={(text) => {
                props.onUpdate &&
                  props.onUpdate({ ...question, aiCoachInstructions: text });
              }}
            />
          </div>
        </div>
      )}

      {textExceedsExcerpt && (
        <span
          className="italic text-gray-400 hover:text-gray-600 cursor-pointer text-xs ml-1"
          onClick={() => setIsExcerpted(!isExcerpted)}
        >
          {isExcerpted ? "Show Less" : "Expand"}
        </span>
      )}
      <span className={cn(props.small && "text-xs")}>{props.children}</span>
    </div>
  );
};

QuestionIntro.defaultProps = {
  excerptLength: Infinity,
};

export default pure(QuestionIntro);
