import React from 'react'
import { FlushList, Hint, Indicator, Avatar } from 'components/shared'
import moment from 'moment'

const IssueAttemptPreview = (props) => {
  return(
    <FlushList.Item className="IssueAttemptPreview FlushListPreview hover-gray-100">
      <a
       href={`${ props.pullRequest.url }/files`}
       target="_blank">
        <div className="d-flex">
          <Avatar
           size="medium"
           src={props.user.avatar}
           className="mr-2" />

          <div>
            <div className="FlushListPreviewHeader">
              <div className="FlushListPreviewTitle">
                { props.user.name }
              </div>
              <Hint tiny className="FlushListPreviewStatus ml-1">
                Completed { moment(props.completedAt).fromNow() }
              </Hint>
            </div>

            <Hint className="FlushListPreviewBody">
              <span className="mr05">
                Approved by
              </span>
              <Avatar
               size="tiny"
               className="mr05"
               src={props.pullRequest.approverAvatar} />
              <span className="mr05">
                { props.pullRequest.approverGithubUsername }
              </span>
              { moment(props.pullRequest.approvedAt).fromNow() }
            </Hint>
          </div>
        </div>
      </a>
    </FlushList.Item>
  )
}

export default IssueAttemptPreview