import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
} from "react";
import AppContext from "contexts/AppContext";
import {
  Button,
  PlaceholderText,
  Avatar,
  Indicator,
  ComposeEmail,
  Loading,
} from "components/shared";
import { ArrowUturnLeftIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { Hint } from "components/typography";
import { useAiCoach } from "hooks";
import { emailFromTemplate } from "templates/emails";
import { cn } from "utils";
import moment from "moment";

const UserMail = ({ id, ...props }) => {
  const { API, currentUser } = useContext(AppContext);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoadingEmails, setIsLoadingEmails] = useState(true);

  const isLoading = isLoadingUser || isLoadingEmails;

  const [user, setUser] = useState(null);
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState(null);

  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");

  const fetchUser = async () => {
    setIsLoadingUser(true);
    const user = await API.get(`/users/${id}`);
    setUser(user);
    setIsLoadingUser(false);
  };

  const fetchEmails = async () => {
    setIsLoadingEmails(true);
    const emails = await API.get(`/emails`, { params: { to_id: id } });
    setEmails(emails);
    setIsLoadingEmails(false);
  };

  useEffect(() => {
    if (!!id) {
      fetchUser();
      fetchEmails();
    }
  }, [id]);

  const [sources, setSources] = useState([]);

  const onClickEmail = (email) => {
    const newEmail = currentEmail?.id === email.id ? null : email;
    setCurrentEmail(newEmail);

    if (!email.readBy.map((user) => user.id).includes(currentUser.id)) {
      const userEmailNotification = email.emailNotifications.find(
        (emailNotification) => emailNotification.user.id === currentUser.id,
      );

      const body = {
        email_notification: {
          email_id: email.id,
          user_id: currentUser.id,
          status: "read",
        },
      };

      if (userEmailNotification) {
        API.put(`/email_notifications/${userEmailNotification.id}`, body);
      } else {
        API.post(`/email_notifications`, body);
      }

      setEmails([
        ...emails.map((e) =>
          e.id === email.id
            ? { ...e, readBy: [...e.readBy, { id: currentUser.id }] }
            : e,
        ),
      ]);

      if (props.onChange) props.onChange();
    }
  };

  const {
    generateEmail,
    generateSubject,
    loading: isGeneratingEmail,
  } = useAiCoach();

  const handleClickGenerateEmail = async (type) => {
    const { response, sources } = await generateEmail(type, {
      to: user,
      from: currentUser,
    });

    const subject = await generateSubject(response);

    setSubject(subject);
    setText(response);
    setSources(sources);
  };

  const handleClickGenerateStaticEmail = async (type) => {
    const response = emailFromTemplate(type, {
      to: user,
      from: currentUser,
    });

    const subject = await generateSubject(response);

    setSubject(subject);
    setText(response);
  };

  const handleSubmit = () => {
    fetchEmails();
    if (props.onChange) props.onChange();
  }

  return (
    <div className={props.className}>
      <div
        className={cn(
          "grid h-full",
          currentEmail ? "grid-cols-8" : "grid-cols-5",
        )}
      >
        <div
          className={cn(
            "h-full border-r border-gray-200",
            currentEmail ? "hidden" : "col-span-1",
          )}
        >
          <div className="px-6 py-4 text-sm border-b border-gray-200 w-full">
            {currentEmail ? (
              <div className="flex items-center justify-center">
                <SparklesIcon className="h-6 w-6 text-gray-600" />
              </div>
            ) : (
              <div className="flex items-center space-x-2 justify-center">
                <SparklesIcon className="h-4 w-4 text-gray-600" />
                <span>Automail</span>
              </div>
            )}
          </div>
          <div className="px-6 py-4 flex flex-col space-y-2">
            <Button
              onClick={() => handleClickGenerateStaticEmail("welcome")}
              className="justify-center"
              variant="secondary"
              size="sm"
            >
              🤗 Welcome!
            </Button>
            <Button
              onClick={() => handleClickGenerateStaticEmail("helpStarting")}
              className="justify-center"
              variant="secondary"
              size="sm"
            >
              🚀 Help starting
            </Button>
            <Button
              onClick={() => handleClickGenerateEmail("encouragement")}
              className="justify-center"
              variant="secondary"
              size="sm"
            >
              💪 Encouragement
            </Button>
            <Button
              onClick={() => handleClickGenerateEmail("comeBack")}
              className="justify-center"
              variant="secondary"
              size="sm"
            >
              💫 Come back!
            </Button>
          </div>
        </div>

        <div
          className={cn("col-span-3 border-r border-gray-200 h-full")}
        >
          {
            isGeneratingEmail
            ? <Loading message='Generating email...' />
            : (
              <ComposeEmail
                to={user}
                defaultSubject={subject}
                defaultText={text}
                onSubmit={handleSubmit}
                extra={
                  sources.length > 0 && (
                    <div className="w-full flex-shrink h-36 overflow-y-auto border-t border-gray-200 text-left divide-y divide-gray-200">
                      <div className="text-left px-3 py-2">
                        <Hint tiny>References</Hint>
                      </div>
                      {sources.map((source, index) => (
                        <div
                          key={`source-${index}`}
                          className="text-left text-xs text-gray-600 px-3 py-2"
                        >
                          {source.node?.text}
                        </div>
                      ))}
                    </div>
                  )
                }
              />
            )
          }
        </div>

        <div
          className={cn(
            "overflow-auto",
            currentEmail ? "col-span-2" : "col-span-1",
          )}
        >
          {isLoadingEmails ? (
            <div className="flex flex-col divide-y">
              <div className="px-4 py-2">
                <PlaceholderText size="xs" />
                <PlaceholderText size="sm" />
              </div>

              <div className="px-4 py-2">
                <PlaceholderText size="xs" />
                <PlaceholderText size="sm" />
              </div>

              <div className="px-4 py-2">
                <PlaceholderText size="xs" />
                <PlaceholderText size="sm" />
              </div>

              <div className="px-4 py-2">
                <PlaceholderText size="xs" />
                <PlaceholderText size="sm" />
              </div>

              <div className="px-4 py-2">
                <PlaceholderText size="xs" />
                <PlaceholderText size="sm" />
              </div>

              <div className="px-4 py-2">
                <PlaceholderText size="xs" />
                <PlaceholderText size="sm" />
              </div>

              <div className="px-4 py-2">
                <PlaceholderText size="xs" />
                <PlaceholderText size="sm" />
              </div>

              <div className="px-4 py-2">
                <PlaceholderText size="xs" />
                <PlaceholderText size="sm" />
              </div>

              <div className="px-4 py-2">
                <PlaceholderText size="xs" />
                <PlaceholderText size="sm" />
              </div>
            </div>
          ) : (
            <div className="flex flex-col divide-y">
              {emails.map((email, index) => (
                <div
                  key={`email-${index}`}
                  className={cn(
                    "cursor-pointer hover:bg-gray-50 px-4 py-2",
                    currentEmail?.id === email.id &&
                      "bg-indigo-50 hover:bg-indigo-100",
                  )}
                  onClick={() => onClickEmail(email)}
                >
                  <div className="flex items-center justify-start space-x-1">
                    {!email.readBy
                      .map((user) => user.id)
                      .includes(currentUser.id) && <Indicator />}
                    <Hint tiny className="text-left truncate">
                      {email.type === "inbound" && "Received "}
                      {email.type === "outbound" && "Sent "}
                      {moment(email.createdAt).fromNow()}
                    </Hint>
                  </div>
                  <div className="text-sm truncate text-left">
                    {email.type === "inbound" && (
                      <ArrowUturnLeftIcon className="w-4 h-4 text-gray-600 inline-block mr-1" />
                    )}
                    {email.subject}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {currentEmail && (
          <div className="col-span-3 border-l border-gray-200 divide-y overflow-auto">
            {
              <div className="flex items-baseline space-x-2 px-6 py-2 text-sm">
                <Hint className="w-14 flex-shrink-0 text-left">To</Hint>
                <div className="flex items-baseline space-x-2">
                  {currentEmail.to && (
                    <Avatar
                      {...currentEmail.to}
                      size="miniscule"
                      className="relative top-[3px]"
                    />
                  )}
                  <div className="text-left">
                    {currentEmail.to && currentEmail.to.firstName}
                    <Hint tiny className="truncate -mt-px">
                      {currentEmail.toEmail}
                    </Hint>
                  </div>
                </div>
              </div>
            }
            <div className="flex items-baseline space-x-2 px-6 py-2 text-sm">
              <Hint className="w-14 flex-shrink-0 text-left">From</Hint>
              <div className="flex items-baseline space-x-2">
                <Avatar
                  {...currentEmail.from}
                  size="miniscule"
                  className="relative top-[3px]"
                />
                <div className="text-left">
                  {currentEmail.from.firstName}
                  <Hint tiny className="truncate -mt-px">
                    {currentEmail.fromEmail}
                  </Hint>
                </div>
              </div>
            </div>
            <div className="flex items-baseline space-x-2 px-6 py-2 text-sm">
              <Hint className="w-14 flex-shrink-0 text-left">Subject</Hint>
              <div className="text-left truncate">{currentEmail.subject}</div>
            </div>
            <div className="prose-sm w-full px-6 py-4 text-left whitespace-pre-wrap">
              <Hint tiny>{moment(currentEmail.createdAt).calendar()}</Hint>
              {currentEmail.text}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserMail;
