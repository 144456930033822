import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import AppContext from "contexts/AppContext";
import { Button, Indicator, Loading } from "components/shared";
import { Hint } from "components/typography";
import ActivityAttemptTranscript from "components/transcripts/ActivityAttemptTranscript";
import QuestionHeader from "components/coaching/QuestionHeader";
import Discussion from "components/coaching/Discussion";
import TranscriptFilters from "components/transcripts/TranscriptFilters";
import TranscriptSidebar from "components/transcripts/TranscriptSidebar";
import {
  ArrowDownIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import { useImmer } from "use-immer";
import useDynamicRefs from "use-dynamic-refs";
import { useFilters } from "hooks";
import { cn, possessive } from "utils";

const UserTranscriptDiscussions = ({ user, ...props }) => {
  const { filters, handleFilterChange } = useFilters();
  const { currentUser, API } = useContext(AppContext);
  const [transcript, setTranscript] = useImmer(null);
  const [loading, setLoading] = useState(true);

  const fetchTranscript = async () => {
    setLoading(true);
    const response = await API.get(`/users/${user.id}/transcript`);

    if (response) {
      setTranscript(response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTranscript();
  }, [user]);

  const attemptsWithDiscussions = useMemo(() => {
    if (!transcript?.attempts) return [];

    return transcript.attempts
      .filter((attempt) => {
        if (attempt.discussions.length === 0) return false;
        if (attempt.discussions.flatMap((d) => d.responses).length === 0)
          return false;

        return true;
      })
      .map((attempt) => ({
        ...attempt,
        discussions: [
          ...new Map(
            attempt.discussions.map((discussion) => [
              discussion.question.text,
              discussion,
            ]),
          ).values(),
        ]
          .filter((discussion) => discussion.responses.length !== 0)
          .filter((discussion) =>
            filters.showOnlyKeyQuestions ? discussion.question.isKey : true,
          )
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
      }))
      .reverse();
  }, [transcript, filters]);

  const discussions = useMemo(
    () =>
      attemptsWithDiscussions
        .flatMap((a) => a.discussions)
        .filter((d) => d.responses.length !== 0),
    [attemptsWithDiscussions],
  );
  const unviewedDiscussions = useMemo(
    () => discussions.filter((d) => !d.viewers.includes(currentUser.id)),
    [discussions],
  );
  const nextUnviewedDiscussion = useMemo(
    () => unviewedDiscussions[0],
    [unviewedDiscussions],
  );

  const [getRef, setRef] = useDynamicRefs();

  const scrollToNextUnviewedDiscussion = () => {
    if (!nextUnviewedDiscussion) return;

    const element = getRef(nextUnviewedDiscussion.id).current;

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const scrollToAttempt = (attempt) => {
    const element = getRef(`attempt-${attempt.id}`).current;

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  // discussion views
  const handleViewDiscussion = (id) => {
    const viewedDiscussion = discussions.find((d) => d.id === id);

    const newTranscript = {
      ...transcript,
      attempts: transcript.attempts.map((attempt) => ({
        ...attempt,
        discussions: attempt.discussions.map((discussion) => {
          if (discussion.id === viewedDiscussion.id)
            return {
              ...discussion,
              viewers: [...discussion.viewers, currentUser.id],
            };

          return discussion;
        }),
      })),
    };

    setTranscript(newTranscript);
  };

  const handleUnviewDiscussion = (id) => {
    const unviewedDiscussion = discussions.find((d) => d.id === id);

    const newTranscript = {
      ...transcript,
      attempts: transcript.attempts.map((attempt) => ({
        ...attempt,
        discussions: attempt.discussions.map((discussion) => {
          if (discussion.id === unviewedDiscussion.id)
            return {
              ...discussion,
              viewers: discussion.viewers.filter(
                (viewer) => viewer !== currentUser.id,
              ),
            };

          return discussion;
        }),
      })),
    };

    setTranscript(newTranscript);
  };

  // discussion flags
  const handleFlagDiscussion = (id) => {
    const flaggedDiscussion = discussions.find((d) => d.id === id);

    const newTranscript = {
      ...transcript,
      attempts: transcript.attempts.map((attempt) => ({
        ...attempt,
        discussions: attempt.discussions.map((discussion) => {
          if (discussion.id === flaggedDiscussion.id)
            return {
              ...discussion,
              flaggers: [...discussion.flaggers, currentUser.id],
            };

          return discussion;
        }),
      })),
    };

    setTranscript(newTranscript);
  };

  const handleUnflagDiscussion = (id) => {
    const unflaggedDiscussion = discussions.find((d) => d.id === id);

    const newTranscript = {
      ...transcript,
      attempts: transcript.attempts.map((attempt) => ({
        ...attempt,
        discussions: attempt.discussions.map((discussion) => {
          if (discussion.id === unflaggedDiscussion.id)
            return {
              ...discussion,
              flaggers: discussion.flaggers.filter(
                (flagger) => flagger !== currentUser.id,
              ),
            };

          return discussion;
        }),
      })),
    };

    setTranscript(newTranscript);
  };

  if (loading || !transcript)
    return (
      <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
        <Hint className="text-center">
          <DocumentTextIcon className="h-16 w-16 text-center mx-auto mb-1 text-gray-300" />
          <h3 className="text-lg mb-0.5">Loading transcript</h3>
          Splicing together all {possessive(user.name)} activity, please wait...
        </Hint>
      </div>
    );

  if (discussions.length === 0)
    return (
      <>
        <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
          <TranscriptFilters
            filters={filters}
            onChange={handleFilterChange}
            className="absolute right-8 top-4"
            panelClassName="absolute right-8 mt-4"
          />
          <Hint className="text-center">
            <DocumentMagnifyingGlassIcon className="h-16 w-16 text-center mx-auto mb-1 text-gray-300" />
            <h3 className="text-lg mb-0.5">No discussions for {user.name}</h3>
            This user hasn't started any discussions yet.
            {Object.keys(filters).length > 0 && (
              <div className="text-xs text-blue-500">
                Maybe you have filters enabled?
              </div>
            )}
          </Hint>
        </div>
      </>
    );

  return (
    <div className='relative'>
      {props.showNewDiscussionsLink && unviewedDiscussions.length > 0 && (
        <div className="w-full h-12 relative flex items-end justify-center">
          <Button
            type="pill"
            className="flex fixed z-10 shadow-sm"
            onClick={scrollToNextUnviewedDiscussion}
          >
            <Indicator className="mr-1" />
            {unviewedDiscussions.length} new discussions
            <ArrowDownIcon className="h-3 w-3 text-gray-400 ml-1" />
          </Button>
        </div>
      )}
      <TranscriptFilters
        filters={filters}
        onChange={handleFilterChange}
        className="absolute right-8"
        panelClassName="absolute right-8 mt-4"
      />
      {attemptsWithDiscussions.map((attempt, index) => (
        <ActivityAttemptTranscript
          key={attempt.id}
          ref={props.setRef ? props.setRef(`attempt-${attempt.id}`) : null}
          className={cn(index === attemptsWithDiscussions.length - 1 && "mb-0")}
          attempt={attempt}
          user={user}
        >
          {attempt.discussions.map((discussion, index) => {
            if (discussion.responses.length === 0) return null;

            return (
              <div
                key={discussion.id}
                ref={props.setRef ? props.setRef(discussion.id) : null} //el => discussionsRef.current[j] = el
                className={cn(
                  "pb-4",
                  index !== attempt.discussions.length - 1 &&
                    "mb-4 border-b border-dashed border-gray-100",
                  discussion.question.isKey &&
                    "bg-yellow-50 pt-4 -mt-4 pb-4 mb-0",
                )}
              >
                {discussion && (
                  <QuestionHeader
                    small
                    questionExcerptLength={24}
                    className="pt-0 mb-4"
                    question={discussion.question}
                    user={user}
                  />
                )}

                <Discussion
                  id={discussion.id}
                  className="pt-0"
                  onView={handleViewDiscussion}
                  onMarkUnviewed={handleUnviewDiscussion}
                  onFlag={handleFlagDiscussion}
                  onUnflag={handleUnflagDiscussion}
                />
              </div>
            );
          })}
        </ActivityAttemptTranscript>
      ))}
      {props.showSidebar && (
        <div className="w-72 flex-shrink-0 overflow-y-auto">
          <TranscriptSidebar
            attempts={attemptsWithDiscussions}
            filters={filters}
            user={user}
            onClickAttempt={scrollToAttempt}
          />
        </div>
      )}
    </div>
  );
};

export default UserTranscriptDiscussions;
