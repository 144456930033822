import React, { useState } from "react";
import { Spinner } from "components/shared";
import { CheckIcon, TrashIcon } from "@heroicons/react/20/solid";
import { usePost, useDestroy } from "hooks";
import { classNames } from "utils";

const LabelInDialog = ({ id, text, user, ...props }) => {
  const { post, data: addLabelToUserData } = usePost(props.onChange);

  const { destroy: removeLabelFromUser, data: removeLabelFromUserData } =
    useDestroy(props.onChange);

  const { destroy: destroyLabel, data: destroyLabelData } = useDestroy(
    props.onChange,
  );

  const [userHasLabel, setUserHasLabel] = useState(
    user.labels && user.labels.map((label) => label.id).includes(id),
  );

  const toggleLabel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (props.disabled) return;

    if (userHasLabel) {
      setUserHasLabel(false);
      removeLabelFromUser(`users/${user.id}/labels/${id}`);
    } else {
      setUserHasLabel(true);
      post(`users/${user.id}/labels`, {
        label: { id },
      });
    }
  };

  const handleDestroyLabel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    destroyLabel(`labels/${id}`);
  };

  return (
    <div
      className={classNames(
        "group flex items-center justify-between max-w-sm text-gray-600 hover:bg-gray-50 cursor-pointer py-2 px-2 text-sm",
        props.disabled && "cursor-default bg-gray-50 text-gray-400",
        props.className,
      )}
      onClick={toggleLabel}
    >
      <div className="flex items-center">
        {addLabelToUserData.isLoading ||
        removeLabelFromUserData.isLoading ||
        destroyLabelData.isLoading ? (
          <Spinner className="text-gray-200" />
        ) : userHasLabel ? (
          <CheckIcon className="h-5 w-5" />
        ) : null}

        <div className={classNames("ml-2", userHasLabel && "font-semibold")}>
          {text}
        </div>
      </div>

      {!props.disabled && (
        <TrashIcon
          className="hidden group-hover:inline h-4 w-4 ml-1 text-gray-400 hover:text-gray-600 cursor-pointer"
          onClick={handleDestroyLabel}
        />
      )}
    </div>
  );
};

export default LabelInDialog;
