import React, { useEffect, useRef } from 'react'
import Cytoscape from 'cytoscape'
import CytoscapeComponent from 'react-cytoscapejs'
import COSEBilkent from 'cytoscape-cose-bilkent'
import cxtmenu from 'cytoscape-cxtmenu'
import Layers from 'cytoscape-layers'
import edgehandles from 'cytoscape-edgehandles'
import GraphLabel from 'components/graph/GraphLabel'
import NormalNodeImage from 'images/node.png'
import LockedNodeImage from 'images/node-locked.png'
import CompletedNodeImage from 'images/node-completed.png'
import InProgressNodeImage from 'images/node-in-progress.png'
import GoalNodeImage from 'images/node-goal.png'
import NormalNodeOnGoalPathImage from 'images/node-on-goal-path.png'
import LockedNodeOnGoalPathImage from 'images/node-locked-on-goal-path.png'
import CompletedNodeOnGoalPathImage from 'images/node-completed-on-goal-path'
import InProgressNodeOnGoalPathImage from 'images/node-in-progress-on-goal-path.png'
import ConstructionNodeImage from 'images/node-construction.png'
import { createRoot } from 'react-dom/client'

import GraphContext from 'contexts/GraphContext'

class Graph extends React.Component {
  static contextType = GraphContext

  activityHasPosition(activity) { return activity.x && activity.y }

  BG_COLOR = '#f8f9fa' // $gray-50
  BASE_COLOR = '#343a40' // $gray-800
  GRAY = '#adb5bd' // nearly $gray-500
  LIGHT_GRAY = '#dee2e6' // $gray-300
  WHITE = '#fff'
  YELLOW = '#ffc107' // $yellow
  LIGHT_YELLOW = '#fbeab2'
  RED = '#dc3545' // $red
  LIGHT_RED = '#FFC0B0'
  GREEN = '#28a745' // $green
  LIGHT_GREEN = '#afdebb'
  BLUE = '#60a5fa' // blue-400
  HIGHLIGHTED_COLOR = this.BLUE

  backgroundImage(node) {
    if(node.data('isCurrentGoal'))    return GoalNodeImage
    if(node.data('construction'))     return ConstructionNodeImage
    if(node.data('permalocked'))      return LockedNodeImage
    if(node.data('completedAttempt')) return CompletedNodeImage
    if(node.data('currentAttempt'))   return InProgressNodeImage
    if(node.data('unlocked'))         return NormalNodeImage

    return LockedNodeImage
  }

  backgroundImageForHighlightedNode(node) {
    if(node.data('construction'))     return ConstructionNodeImage
    if(node.data('permalocked'))      return LockedNodeOnGoalPathImage
    if(node.data('completedAttempt')) return CompletedNodeOnGoalPathImage
    if(node.data('currentAttempt'))   return InProgressNodeOnGoalPathImage
    if(node.data('isCurrentGoal'))    return GoalNodeImage
    if(node.data('unlocked'))         return NormalNodeOnGoalPathImage

    return LockedNodeOnGoalPathImage
  }

  labelColor(ele) {
    if(ele.data('isCurrentGoal'))    return this.BASE_COLOR
    if(ele.data('unlocked'))         return this.BASE_COLOR

    return this.GRAY
  }

  edgeColor(edge) {
    const source = edge.cy().getElementById(edge.data('source'))
    const target = edge.cy().getElementById(edge.data('target'))

    if(source.data('completedAttempt') && target.data('unlocked') && !target.data('permalocked')) return this.GRAY
    return this.LIGHT_GRAY
  }

  stylesheet() {
    return [
        { 
          selector: 'node',
          style: {
            'display': (node) => (node.data('show') ? 'element' : 'none'),
            'background-color': this.BG_COLOR,
            'background-image': this.backgroundImage,
            'background-image-containment': 'over',
            'background-fit': 'cover',
            'background-clip': 'none',
            'bounds-expansion': '20px',
            'border-color': this.BASE_COLOR,
            'label': (node) => this.props.useHtmlLabels ? '' : node.data('label'),
            'shape': (node) => node.data('isCurrentGoal') ? 'polygon' : 'ellipse',
            'shape-polygon-points': '-1 -1 -0.5 -0.4 0 -1 0.5 -0.4 1 -1 1 0.8 -1 0.8 -1 -1'
          }
        },
        {
          selector: 'node[label]',
          style: {
            'display': (node) => (node.data('show') ? 'element' : 'none'),
            'text-valign': 'bottom',
            'text-margin-y': '5',
            'color': this.labelColor,
            'text-wrap': 'wrap'
          }
        },
        {
          selector: 'edge',
          style: {
            'line-color': this.edgeColor,
            'width': '1px',
            'curve-style': 'bezier',
            'target-arrow-shape': 'triangle',
            'target-arrow-color': this.edgeColor
          }
        },
        {
          selector: '.highlighted',
          style: {
            'background-image': this.backgroundImageForHighlightedNode,
            'line-style': 'dashed',
            'line-color': this.HIGHLIGHTED_COLOR,
            'target-arrow-color': this.HIGHLIGHTED_COLOR
          }
        }
      ]
  }

  constructor(props) {
    super(props)

    this.stylesheet = this.stylesheet.bind(this)
    this.activityToData = this.activityToData.bind(this)
    this.handleNodeClick = this.handleNodeClick.bind(this)
    this.zoomToNode = this.zoomToNode.bind(this)
    this.highlight = this.highlight.bind(this)
    this.removeHighlight = this.removeHighlight.bind(this)
    this.removeAllHighlights = this.removeAllHighlights.bind(this)
    this.highlightShortestPathTo = this.highlightShortestPathTo.bind(this)
    this.shortestPathTo = this.shortestPathTo.bind(this)
    this.resetZoom = this.resetZoom.bind(this)

    this.labelColor = this.labelColor.bind(this)
    this.edgeColor = this.edgeColor.bind(this)

    this.handleRerenderActivity = this.handleRerenderActivity.bind(this)
    this.handleAddActivity = this.handleAddActivity.bind(this)
    this.handleDeleteEdge = this.handleDeleteEdge.bind(this)

    const positionsAreSet = props.activities.some(this.activityHasPosition)

    positionsAreSet || Cytoscape.use(COSEBilkent)

    this.state = {
      positionsAreSet: positionsAreSet,
      layout: positionsAreSet ? { name: 'preset' } : { name: 'cose-bilkent', idealEdgeLength: 100, numIter: 25000, nodeRepulsion: 8000 },
      stylesheet: this.stylesheet()
    }
  }

  componentDidMount() {
    if(this.props.onFirstRender !== undefined && !this.state.positionsAreSet) {
      const nodes = this.cy.nodes()
      this.props.onFirstRender(nodes)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.activities === nextProps.activities) {
      if(this.props.height !== nextProps.height || this.props.width !== nextProps.width) return true
      return false
    }

    return true
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.prevProps === this.props) return

    this.setState({ ...this.state, stylesheet: this.stylesheet() })
  }

  highlight(element) {
    element.addClass('highlighted')
  }

  removeHighlight(element) {
    element.removeClass('highlighted')
  }

  removeAllHighlights() {
    const elements = this.cy.$('.highlighted')
    for(let i = 0; i < elements.length; i++) {
      this.removeHighlight(elements[i])
    }
  }

  shortestPathTo(goal) {
    if(!goal || !goal.id()) return []

    const graph = this.cy.elements().filter(ele => ele.isEdge() || (ele.isNode() && ele.data('show')))

    let routes = this.cy.$('node[completedAttempt]')
                    .filter(node => node.outdegree() > 0)
                    .map(root => graph.aStar({ root, goal, directed: true }))
                    .filter(({ found }) => found)
                    .sort((a, b) => a.distance - b.distance)

    if(routes.length === 0) return []

    const path = [...new Set(routes.flatMap(route => route.path.toArray()))]

    if(goal.indegree() > 1) {
      const prerequisites = goal.incomers('[show]')
      return [...path, ...goal.incomers(), ...prerequisites.flatMap(prerequisite => this.shortestPathTo(prerequisite))]
    } else {
      return path
    }
  }

  highlightShortestPathTo(goal) {
    const path = this.shortestPathTo(goal)

    for(let i = 0; i < path.length; i++) {
      const element = path[i]
      this.highlight(element)
    }
  }

  zoomToNode(node, callback = null) {
    const cy = node.cy()

    if(!cy.animated()) {
      const center = cy.pan()
      const currentZoom = cy.zoom()
      const nodePosition = node.position()
      const renderedPosition = node.renderedPosition()
      const FINAL_ZOOM = 2.5

      const graphCenterX = (window.innerWidth / 2)
      const graphCenterY = (window.innerHeight / 2)

      const x = currentZoom === FINAL_ZOOM ? (center.x - renderedPosition.x + graphCenterX - graphCenterX / 2) : (center.x - renderedPosition.x + graphCenterX)
      const y = center.y - (renderedPosition.y) + graphCenterY

      const panAndZoom = ({ x, y }) => cy.animate({pan: {x, y}, easing: 'ease-in-out', complete: zoom})
      const zoom = () => {
        const newRenderedPosition = node.renderedPosition()
        const zoomCenterX = newRenderedPosition.x + (graphCenterX / 4) * currentZoom
        cy.animate({
                    zoom: { 
                      level: FINAL_ZOOM, 
                      renderedPosition: { x: zoomCenterX, y: graphCenterY } }, 
                      easing: 'ease-in-out',
                      complete: () => { callback && callback(node.data()) }
                    })
      }

      panAndZoom({ x, y })
    }
  }

  resetZoom(cb = null) {
    this.cy.animate({ fit: { eles: this.cy.nodes(), padding: 50 }, easing: 'ease-in-out', complete: cb })
  }

  handleNodeClick(e) {
    this.props.onNodeClick(e.target)
  }

  positionOrNull(activity) { return this.activityHasPosition(activity) ? ({ position: { x: parseFloat(activity.x), y: parseFloat(activity.y) } }) : null }

  activityToData(activity) {
    const isCurrentGoal = this.props.currentGoal && this.props.currentGoal.id === activity.id

    return { 
             group: 'nodes', 
             data: { label: activity.title, isCurrentGoal, ...activity },
             ...this.positionOrNull(activity) 
           } 
  }

  relationToData(relation) {
    return ({ group: 'edges', data: { id: `relation-${relation.id}`, source: relation.in_id, target: relation.out_id } }) 
  }

  handleRenderLabels({ autofocusId } = {}) {
    if(!this.cy.layers) Cytoscape.use(Layers)

    const layers = this.cy.layers()
    const lastLayer = layers.layers[layers.layers.length - 1]

    if(lastLayer && lastLayer.type === 'html') lastLayer.remove()

    const containerRoots = {}

    layers.renderPerNode(layers.append('html'), (container, consideredNodes) => {
        const currentNode = consideredNodes[0]
        const data = currentNode.data()

        if(data && data.show) {
          const autofocus = autofocusId && data.id === autofocusId

          container.className = 'w-32 text-center z-10'

          const component = (
            <GraphLabel
             autofocus={autofocus}
             allowEdit={false}
             onUpdate={this.handleUpdateActivity.bind(this)}
             color={this.labelColor(currentNode)}
             { ...data } />
           )

          if(!containerRoots[data.id]) {
            containerRoots[data.id] = createRoot(container)
            containerRoots[data.id].render(component)
          }
        }
      },
      { 
        transform: 'translate(-50%, 0)',
        position: 'center',
        uniqueElements: true,
        checkBounds: false
      })
  }

  handleRenderNewActivity(activity) {
    this.cy.add({
        group: 'nodes',
        data: activity,
        position: { x: activity.x, y: activity.y }
    });

    if(this.props.useHtmlLabels) {
      this.handleRenderLabels({ autofocusId: activity.id })
    }
  }

  handleRerenderActivity(nodeId, activity) {
    const node = this.cy.getElementById(nodeId)[0]

    node.data({ ...activity })

    if(this.props.useHtmlLabels) {
      this.handleRenderLabels()
    }
  }

  handleAddActivity(core, event, activityData={}) {
    if(this.state.isAddingSomething) return

    this.setState({ ...this.state, isAddingSomething: true })

    const data = {
      show: true,
      x: event.position.x,
      y: event.position.y,
      ...activityData
    }

    this.props.onAddActivity(data, (activity) => {
      this.setState({ ...this.state, isAddingSomething: false })
      this.handleRenderNewActivity(activity)
    })
  }

  handleUpdateActivity(activity) {
    this.props.onUpdateActivity(activity, this.handleRerenderActivity)
  }

  handleAddEdge(sourceNode, targetNode) {
    if(this.state.isAddingSomething) return

    this.setState({ ...this.state, isAddingSomething: true })

    this.props.onAddEdge(sourceNode, targetNode, (edge) => {
      this.setState({ ...this.state, isAddingSomething: false})
    })
  }

  handleDeleteEdge(edge) {
    this.props.onDeleteEdge(edge.data(), () => this.cy.remove(edge))
  }

  setupHandlers(cy) {
    this.cy = cy

    cy.off('tap')
    cy.off('dragfreeon')

    if(this.props.onNodeClick !== undefined) cy.on('tap', 'node', this.handleNodeClick)
    if(this.props.onNodeDrag !== undefined) cy.on('dragfreeon', 'node', this.props.onNodeDrag)

    if(this.props.useHtmlLabels) this.handleRenderLabels()

    if(this.props.editorMode) {
      const setupEdgeEditing = () => {
        if(!cy.edgehandles) Cytoscape.use(edgehandles)

        const edgehandle = cy.edgehandles()

        cy.on('ehcomplete', (event, sourceNode, targetNode, addedEdge) => {
          this.handleAddEdge(sourceNode, targetNode)
        })

        return edgehandle
      }

      const setupMenus = (edgehandle) => {
        if(!cy.cxtmenu) Cytoscape.use(cxtmenu)

        cy.cxtmenu({
          selector: 'node',
          commands: [
          {
            content: '<div class="EditorMenuItem"><i class="material-icons">route</i><label>Link</label></div>',
            select: (node) => edgehandle.start(node)
          },
          {
            content: '<div class="EditorMenuItem"><i class="material-icons">visibility_off</i><label>Hide</label></div>',
            select: (node) => this.handleUpdateActivity({ ...node.data(), show: false })
          }
          ]
        })

        cy.cxtmenu({
          selector: 'edge',
          commands: [
            {
              content: '<div class="EditorMenuItem"><i class="material-icons">delete</i><label>Delete</label></div>',
              select: this.handleDeleteEdge
            }
          ]
        })

        cy.cxtmenu({
          selector: 'core',
          commands: [
            {
              content: '<div class="EditorMenuItem"><i class="material-icons">add_circle</i><label>New</label></div>',
              select: this.handleAddActivity
            }
          ]
        })
      }

      setupMenus(setupEdgeEditing())
    }

    // expose the context for manipulation from outside
    cy.zoomToNode = this.zoomToNode;
    cy.resetZoom = this.resetZoom;
    cy.highlight = this.highlight;
    cy.removeHighlight = this.removeHighlight;
    cy.removeAllHighlights = this.removeAllHighlights;
    cy.shortestPathTo = this.shortestPathTo;
    cy.highlightShortestPathTo = this.highlightShortestPathTo;

    if(this.props.setGraphContext) {
      this.context.setGraphContext(cy)
    }

    cy.ready(this.props.onReady(cy.nodes()))

    return cy
  }

  sanitizeGraph(activities, relations) {
    // remove any relations where the activity is not included
    // e.g. if the relationship references an activity in a different module
    const activityIds = activities.map(activity => activity.id)

    const newRelations = relations.filter(relation => {
      return activityIds.includes(relation.in_id)
             && activityIds.includes(relation.out_id)
    })

    return [...activities.map(this.activityToData),
            ...newRelations.map(this.relationToData)]
  }

  render() {
    return(
      <CytoscapeComponent
       cy={this.setupHandlers.bind(this)}
       elements={this.sanitizeGraph(this.props.activities, this.props.relations)}
       stylesheet={this.state.stylesheet}
       layout={this.state.layout}
       minZoom={0.5}
       maxZoom={3}
       boxSelectionEnabled={false}
       autolock={this.props.autolock}
       style={ { width: this.props.width, height: this.props.height } }
       className="Graph"
       pan={this.props.initialPan} 
       id={this.props.id}
       />
    )
  }
}

Graph.defaultProps = {
  activities: [],
  relations: [],
  autolock: true,
  onReady: () => {},
  userCount: 1,
  width: '100vw',
  height: '100vh',
  animateStarterNodes: false,
  showStarterFlag: false,
  useHtmlLabels: false,
  onAddActivity: (data) => { console.log('activity added but this function has stopped here', data) },
  allowAddEdges: false,
  onAddEdge: (source, target) => { console.log('edge added but this function has stopped here', source, target) },
  onUpdateActivity: (activity, callback) => { console.log('activity updated but this function has stopped here', activity, callback)},
  onDeleteEdge: (edge, callback) => { console.log('edge deleted but this function has stopped here', edge, callback) },
  currentGoal: null,
  setGraphContext: false,
  initialPan: null,
}


export default Graph
