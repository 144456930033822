import React, { useEffect } from "react";
import FlowGraph from "components/graph/FlowGraph";
import { useGraph } from 'hooks'

const UserFlowGraph = ({ user, ...props }) => {
  const [graph, fetchGraph] = useGraph(user);

  useEffect(() => {
    fetchGraph();
  }, [user])

  return(
    <FlowGraph
      graph={graph}
      interactive={false}
      editable={false}
    />
  )
}

export default UserFlowGraph;