import React, { Fragment, useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import Response from "components/coaching/Response";
import ResponseForm from "components/coaching/ResponseForm";
import { Button, Indicator } from "components/shared";
import { pluralize, cn } from "utils";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { pure } from "recompose";

const Responses = ({
  question,
  discussion,
  user,
  highlightedResponseId,
  highlightedCommentId,
  extraResponseParams,
  constraints,
  ...props
}) => {
  const { currentUser } = useContext(AppContext);

  const highlightedCommentIsInPreviousRevision =
    !!highlightedCommentId &&
    discussion.responses
      ?.flatMap((r) => r.comments)
      .flatMap((c) => c.id)
      .includes(highlightedCommentId);

  const [isRevising, setIsRevising] = useState(props.defaultIsRevising);
  const [localResponses, setLocalResponses] = useState(discussion.responses);
  const [expandRevisions, setExpandRevisions] = useState(
    props.defaultExpandRevisions || highlightedCommentIsInPreviousRevision,
  );

  useEffect(() => {
    setIsRevising(props.defaultIsRevising);
  }, [props.defaultIsRevising]);

  const handleAddResponse = (response) => {
    setIsRevising(false);
    setLocalResponses([...localResponses, response]);
    if (props.onSubmit) props.onSubmit(response);
  };

  const handleDeleteResponse = ({ id }) => {
    setLocalResponses([
      ...localResponses.filter((response) => response.id !== id),
    ]);
    if (props.onDelete) props.onDelete(id);
  };

  if (!localResponses) return null;

  if (localResponses.length === 0 && user.id !== currentUser.id) return null;

  if (localResponses.length === 0)
    return (
      <ResponseForm
        discussion={discussion}
        question={question}
        constraints={constraints}
        extraResponseParams={extraResponseParams}
        onChange={props.onChange}
        onSubmit={handleAddResponse}
        placeholder={props.placeholder}
      />
    );

  const firstResponse = localResponses[0];
  const lastResponse = localResponses[localResponses.length - 1];

  return (
    <div className="max-w-prose">
      {expandRevisions &&
        localResponses.slice(0, -1) &&
        localResponses.slice(0, -1).map((response, index) => (
            <Response
              key={response.id}
              {...props}
              isLast={response.id === lastResponse.id}
              isHighlighted={
                highlightedResponseId && response.id === highlightedResponseId
              }
              highlightedCommentId={highlightedCommentId}
              discussion={discussion}
              constraints={constraints}
              question={question}
              isRevision={
                localResponses.length > 1 && firstResponse.id !== response.id
              }
              showIsRevision={true}
              onDelete={handleDeleteResponse}
              isUnviewed={!props.isViewed}
              isFlagged={props.isFlagged}
              isExemplar={props.isExemplar}
              {...{ isRevising, setIsRevising }}
              {...response}
            />
        ))}

      {!expandRevisions && localResponses.length > 1 && (
        <Button
          type="pill"
          onClick={() => setExpandRevisions(true)}
          Icon={ArrowPathIcon}
          className="bg-green-100 hover:bg-green-200 text-green-800 border-0 mb-4 @xs:ml-0 @sm:ml-12"
        >
          {pluralize(localResponses.length - 1, "revision")}
        </Button>
      )}

      {isRevising ? (
        <ResponseForm
          discussion={discussion}
          question={question}
          constraints={constraints}
          onClose={() => setIsRevising(false)}
          previousResponse={lastResponse}
          extraResponseParams={extraResponseParams}
          defaultValue={lastResponse?.text}
          onChange={props.onChange}
          onSubmit={handleAddResponse}
          expandRevisions={expandRevisions}
          placeholder={props.placeholder}
        />
      ) : (
          <Response
            key={lastResponse.id}
            {...props}
            allowRevise
            isLast={!props.includeTrailingLine}
            constraints={constraints}
            isHighlighted={
              highlightedResponseId && lastResponse.id === highlightedResponseId
            }
            highlightedCommentId={highlightedCommentId}
            discussion={discussion}
            question={question}
            onDelete={handleDeleteResponse}
            isRevision={localResponses.length > 1}
            showIsRevision={!!expandRevisions}
            isUnviewed={!props.isViewed}
            isFlagged={props.isFlagged}
            isExemplar={props.isExemplar}
            {...{ isRevising, setIsRevising }}
            {...lastResponse}
          />
      )}
    </div>
  );
};

Responses.defaultProps = {
  className: "",
  defaultExpandRevisions: false,
  includeTrailingLine: false,
};

// Responses.whyDidYouRender = true

export default pure(Responses);
