import React, { useContext, useMemo } from 'react'
import AppContext from 'contexts/AppContext'
import { Indicator } from 'components/shared'
import { FlagIcon } from '@heroicons/react/20/solid'
import { classNames } from 'utils'

const TranscriptSidebar = ({ attempts, filters, user, ...props }) => {
  const { currentUser } = useContext(AppContext)

  const shownAttempts = useMemo(() => {
    return filters.showOnlyKeyQuestions
           ? attempts.filter(a => a.discussions.some(d => d.question.isKey))
           : attempts
  }, [attempts, filters])

  return(
    <div className="h-full border-l border-gray-200">
      {
        shownAttempts.map((attempt, index) => (
          <div
           key={attempt.id}
           className={classNames(
            'w-full cursor-pointer hover:bg-gray-50 pr-4 py-4 flex items-center',
            index !== shownAttempts.length - 1 && 'border-b border-gray-200'
            )}
            onClick={() => props.onClickAttempt(attempt)}>
           <div className='w-8 flex items-center justify-center flex-col space-y-1'>
            {
              attempt.discussions.find(d => !d.viewers.includes(currentUser.id)) &&
              <Indicator className="mr-1" />
            }
            {
              attempt.discussions.find(d => d.flaggers.includes(currentUser.id)) &&
              <FlagIcon className='h-3 w-3 text-orange-500 mr-1' />
            }
           </div>
           <h5 className='font-semibold text-gray-700'>
            { attempt.activity.title }
           </h5>
          </div>
        ))
      }
    </div>
  )
}

export default TranscriptSidebar