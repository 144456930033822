import React, { useContext } from "react";
import AppContext from "contexts/AppContext";
import { Button } from "components/shared";
import { Hint, A } from "components/typography";
import { usePost } from "hooks";
import { classNames } from "utils";

const TourPanel = ({
  slug,
  continuous,
  index,
  step,
  steps,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  ...props
}) => {
  const { currentUser, setCurrentUser } = useContext(AppContext);

  const afterUpdate = (user) => {
    setCurrentUser(user);
    if (closeProps?.onClick) closeProps.onClick({ preventDefault: () => {} });
  };

  const { post, data } = usePost(afterUpdate);

  const handleClose = (e) => {
    e.preventDefault();

    const body = {
      user_tour: {
        slug,
      },
    };

    post(`users/${currentUser.id}/user_tours`, body);
  };

  return (
    <div
      className={classNames("bg-white shadow sm:rounded-lg", props.className)}
      {...tooltipProps}
    >
      <div className="px-4 py-5 sm:p-6">
        <Hint small className="text-indigo-400 font-semibold">
          {step.tourTitle} step{" "}
          <span className="font-bold">
            {step.number}/{step.total}
          </span>
        </Hint>
        {step.title && (
          <h3 className="font-semibold leading-6 text-gray-900">
            {step.title}
          </h3>
        )}
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          {step.content && <p>{step.content}</p>}
        </div>
        <div className="mt-3 flex items-center justify-between">
          {step.link && step.link.label && (
            <A
              href={step.link.url}
              target="_blank"
              className="font-semibold text-sm leading-6"
            >
              {step.link.label}
            </A>
          )}

          {continuous && index !== steps.length - 1 && (
            <Button {...primaryProps}>Next</Button>
          )}

          {step.button && (
            <Button size="sm" {...closeProps} onClick={handleClose}>
              {step.button.label}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TourPanel;
