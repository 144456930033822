import React, { useState } from 'react'
import { Hint, ProgressLine } from 'components/shared'
import ChangeTrackingBranch from 'components/github/actions/ChangeTrackingBranch'

const TrackIssue = (props) => {
  const [showTrackingForm, setShowTrackingForm] = useState(false)
  const toggleShowTrackingForm = (e) => {
    e.preventDefault()
    setShowTrackingForm(!showTrackingForm)
  }

  return(
    <ProgressLine.Widget>
      <ProgressLine.Widget.Indicator
       filled={props.attempt?.tracked}
       active={props.attempt?.started && !props.attempt.tracked} />

      <ProgressLine.Widget.Details>
        <ProgressLine.Widget.Details.Header>
          <div className="WidgetTitle">
            Tracking set up
          </div>
          {
            showTrackingForm
            ? <ChangeTrackingBranch
               issue={props.issue}
               attempt={props.attempt}
               afterChangeBranch={() => {
                setShowTrackingForm(false)
                props.onChangeStatus()
               }} />
            : props.attempt?.trackingBranch
              ? <Hint>
                  <span className="mr-1">
                    Tracking { props.attempt.trackingBranch }
                  </span>
                  <a
                   href='#'
                   onClick={toggleShowTrackingForm}>
                   Set tracking branch
                  </a>
                </Hint>
              : <Hint>
                  <a
                   href='#'
                   onClick={toggleShowTrackingForm}>
                   Set tracking branch
                  </a>
                </Hint>
          }
        </ProgressLine.Widget.Details.Header>
      </ProgressLine.Widget.Details>
    </ProgressLine.Widget>
  )
}

export default TrackIssue