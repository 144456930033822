import React, { useState } from "react";
import { FormattedEvent, PlaceholderText } from "components/shared";
import { useFetch } from "hooks";

const RemoteFormattedEvent = ({ id, ...props }) => {
  const [event, setEvent] = useState(null);

  useFetch(`events/${id}`, setEvent, [id], { onlyIf: id });

  if (!id) return null;
  if (!event)
    return (
      <div>
        <PlaceholderText size="xs" />
        <PlaceholderText size="sm" />
      </div>
    );

  return <FormattedEvent event={event} {...props} />;
};

export default RemoteFormattedEvent;
