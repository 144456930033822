import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Modal, Button } from "components/shared";
import IntroEditor from "components/editor/Editor";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useFlash } from "hooks";

const ActivityStageIntro = React.forwardRef(
  ({ stage, open, onClose, ...props }, ref) => {
    const { editorMode, API } = useContext(AppContext);
    const { errorFlash } = useFlash();

    const [localOpen, setLocalOpen] = useState(open);
    useEffect(() => {
      setLocalOpen(open);
    }, [open])

    const removeIntro = async () => {
      const body = {
        activity_stage: {
          intro: null,
          intro_blocks: null,
        },
      };

      const response = await API.put(`/activity_stages/${stage.slug}`, body, {
        onError: errorFlash,
      });

      if(response) {
        setLocalOpen(false);
        if(props.onRemoveIntro) props.onRemoveIntro();
      }
    }

    return (
      <Modal
        size="md"
        dismissible
        open={localOpen}
        onClose={onClose}
        button={
          editorMode && (
            <Button
              onClick={removeIntro}
              type="warning"
              Icon={TrashIcon}
            >
              Remove intro
            </Button>
          )
        }
      >
        <IntroEditor
          editorClassName="max-w-prose prose-sm prose-blue text-blue-700 text-left"
          initialContent={stage.introBlocks}
          editable={editorMode}
          updatePath={`/activity_stages/${stage.slug}`}
          transform={(blocks) => ({
            activity_stage: { intro_blocks: blocks },
          })}
          onStartUpdate={props.onStartUpdate}
          onUpdate={props.onUpdate}
          onErrorUpdate={props.onErrorUpdate}
        />
      </Modal>
    );
  },
);

export default ActivityStageIntro;
