import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import AppContext from "contexts/AppContext";
import {
  CalendarIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import {
  Loading,
  Avatar,
  AvatarList,
  DateTimeRange,
  Calendar,
  Button,
} from "components/shared";
import { H2, Hint } from "components/typography";
import { useHistory, Link } from "react-router-dom";
import AddLiveSessionModal from "components/live-sessions/AddLiveSessionModal";
import { useQuery, useFlash } from "hooks";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { cn, isInViewport } from "utils";

const LiveSessionsIndex = ({ ...props }) => {
  const { API, currentUser } = useContext(AppContext);
  const { watchQuery, mutateQuery, pathWithQuery } = useQuery();
  const history = useHistory();
  const { notificationFlash } = useFlash();
  const [selectedDate, setSelectedDate] = useState(null);
  const formatter = new Intl.DateTimeFormat("en-UK", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
  const sessionStatus = watchQuery("liveSessionStatus");
  if (!sessionStatus) mutateQuery({ liveSessionStatus: "upcoming" });

  const sessionOnSelectedDateRef = useRef(null);

  useEffect(() => {
    if (selectedDate) {
      const sessionOnSelectedDate = liveSessions.find(
        (liveSession) =>
          formatter.format(new Date(liveSession.startsAt)) === selectedDate,
      );

      if (sessionOnSelectedDate) {
        const element = sessionOnSelectedDateRef.current;
        if (element && !isInViewport(element, -100)) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          });
        }
      }
    }
  }, [selectedDate]);

  const [liveSessions, setLiveSessions] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchLiveSessions = async () => {
    setLoading(true);
    const filters = {
      course_id: currentUser.courseId,
      status: sessionStatus,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const response = await API.get(`/live_sessions`, { params: filters });

    if (response) {
      setLiveSessions(response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLiveSessions();
  }, [sessionStatus]);

  const signupForLiveSession = async (id) => {
    const response = await API.post(`/live_session_attendances`, {
      live_session_attendance: {
        user_id: currentUser.id,
        live_session_id: id,
      },
    });

    if (response) {
      fetchLiveSessions();
      history.push(
        pathWithQuery(null, {
          liveSessionSignupId: id,
        }),
      );
    }
  };

  const cancelSignupForLiveSession = async (id) => {
    const response = await API.destroy(`/live_session_attendances`, {
      live_session_attendance: {
        user_id: currentUser.id,
        live_session_id: id,
      },
    });

    if (response) {
      fetchLiveSessions();
      notificationFlash(
        "You have cancelled your signup for this live session.",
      );
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <div className="flex space-x-4">
        <div className="flex-grow pl-8 py-8">
          <div className="flex items-center space-x-4 lm-4 mb-6 px-4">
            <H2>Live sessions</H2>
            <nav
              className="flex space-x-2 rounded-lg bg-slate-100 p-1"
              aria-label="Live session status"
            >
              <Link
                to={pathWithQuery(null, { liveSessionStatus: "upcoming" })}
                className={cn(
                  "flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-xs font-semibold",
                  sessionStatus === "upcoming" && "bg-white shadow",
                )}
                type="button"
                aria-current={sessionStatus === "upcoming"}
              >
                <span
                  className={cn(
                    sessionStatus === "upcoming"
                      ? "text-slate-900"
                      : "text-slate-600",
                  )}
                >
                  Upcoming
                </span>
              </Link>
              <Link
                to={pathWithQuery(null, { liveSessionStatus: "past" })}
                className={cn(
                  "flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-xs font-semibold",
                  sessionStatus === "past" && "bg-white shadow",
                )}
                type="button"
                aria-current={sessionStatus === "past"}
              >
                <span
                  className={cn(
                    sessionStatus === "past"
                      ? "text-slate-900"
                      : "text-slate-600",
                  )}
                >
                  Past
                </span>
              </Link>
            </nav>
          </div>
          <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6">
            {loading ? (
              <Loading />
            ) : liveSessions.length === 0 ? (
              <Hint>No {sessionStatus} live sessions.</Hint>
            ) : (
              liveSessions.map((liveSession) => (
                <li
                  key={liveSession.id}
                  className={cn(
                    "relative py-6 px-4 rounded-md flex justify-between items-center",
                    formatter.format(new Date(liveSession.startsAt)) ===
                      selectedDate && "bg-blue-50",
                  )}
                  ref={
                    selectedDate &&
                    formatter.format(new Date(liveSession.startsAt)) ===
                      selectedDate
                      ? sessionOnSelectedDateRef
                      : null
                  }
                >
                  <div>
                    <div className="flex items-start space-x-1 text-gray-500 mb-1">
                      <dt className="flex items-center justify-center">
                        <span className="sr-only">Date</span>
                        <CalendarIcon
                          className="h-4 w-4 text-blue-400"
                          aria-hidden="true"
                        />
                      </dt>
                      <dd className="text-xs">
                        <DateTimeRange
                          start={liveSession.startsAt}
                          end={liveSession.endsAt}
                          size="sm"
                        />
                      </dd>
                    </div>
                    <div className="flex space-x-6">
                      <div className="flex-auto">
                        <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0 mb-0">
                          {liveSession.title}
                          <span className="text-xs text-gray-500 font-normal">
                            {" "}
                            with{" "}
                            <Avatar
                              size="miniscule"
                              {...liveSession.user}
                            />{" "}
                            {liveSession.user.firstName}
                          </span>
                        </h3>
                        <p className="text-sm text-gray-500">
                          {liveSession.shortDescription}
                        </p>
                        <ReactMarkdown
                          className={cn(
                            "prose prose-sm pt-2 mb-0 prose--no-margins",
                          )}
                          children={liveSession.longDescription}
                          remarkPlugins={[remarkGfm]}
                          components={{
                            code({
                              node,
                              inline,
                              className,
                              children,
                              ...props
                            }) {
                              const match = /language-(\w+)/.exec(
                                className || "",
                              );
                              return !inline && match ? (
                                <SyntaxHighlighter
                                  {...props}
                                  children={String(children).replace(/\n$/, "")}
                                  style={oneDark}
                                  language={match[1]}
                                  PreTag="div"
                                />
                              ) : (
                                <code {...props} className={className}>
                                  {children}
                                </code>
                              );
                            },
                          }}
                        />
                        <dl className="flex flex-col text-gray-500 xl:flex-row mt-1">
                          <div className="flex items-center space-x-1 mt-0">
                            <dt>
                              <span className="sr-only">Location</span>
                              <MapPinIcon
                                className="h-4 w-4 text-blue-400"
                                aria-hidden="true"
                              />
                            </dt>
                            <dd className="text-xs">{liveSession.location}</dd>
                          </div>

                          {liveSession.attendees.length > 0 && (
                            <div className="flex items-center space-x-2 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                              <dt>
                                <span className="sr-only">Attendees</span>
                                <UserGroupIcon
                                  className="h-4 w-4 text-blue-400"
                                  aria-hidden="true"
                                />
                              </dt>
                              <dd>
                                <AvatarList
                                  size="sm"
                                  users={liveSession.attendees}
                                />
                              </dd>
                            </div>
                          )}
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    {liveSession.attendees
                      .map((user) => user.id)
                      .includes(currentUser.id) ? (
                      <div className="flex items-center space-x-2">
                        <AddToCalendarButton
                          size="2"
                          name={liveSession.title}
                          options={[
                            "Apple",
                            "Google",
                            "iCal",
                            "Microsoft365",
                            "MicrosoftTeams",
                            "Outlook.com",
                            "Yahoo",
                          ]}
                          description={liveSession.shortDescription}
                          location={liveSession.location}
                          startDate={moment(liveSession.startsAt).format(
                            "YYYY-MM-DD",
                          )}
                          endDate={moment(liveSession.endsAt).format(
                            "YYYY-MM-DD",
                          )}
                          startTime={moment(liveSession.startsAt).format(
                            "HH:mm",
                          )}
                          endTime={moment(liveSession.endsAt).format("HH:mm")}
                          organizer={`${liveSession.user.name}|${liveSession.user.email}`}
                          timeZone="currentBrowser"
                          listStyle="modal"
                        />

                        <Button
                          onClick={() =>
                            cancelSignupForLiveSession(liveSession.id)
                          }
                          variant="secondary"
                          size="sm"
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : liveSession.hasAvailability ? (
                      <div className="flex items-center">
                        <Button
                          onClick={() => signupForLiveSession(liveSession.id)}
                          variant="secondary"
                          size="sm"
                        >
                          Sign up
                        </Button>
                      </div>
                    ) : (
                      <Button disabled variant="secondary" size="sm">
                        Full
                      </Button>
                    )}
                  </div>

                  {(liveSession.user.id === currentUser.id ||
                    currentUser.isAdmin) && (
                    <Menu as="div" className="absolute right-4 top-6">
                      <div>
                        <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                          <span className="sr-only">Open options</span>
                          <EllipsisHorizontalIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={cn(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm",
                                  )}
                                >
                                  Edit
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={cn(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm",
                                  )}
                                >
                                  Cancel
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </li>
              ))
            )}
          </ol>
        </div>
        <div className="flex-shrink-0 w-96 mt-10 pl-8 pr-8">
          <div className="fixed w-80">
            <Calendar
              events={liveSessions}
              eventDatetimeKey="startsAt"
              onSelectDate={setSelectedDate}
            />
            {(currentUser.isTrainer || currentUser.isAdmin) && (
              <Button
                type="routeLink"
                to={pathWithQuery(null, { addLiveSession: true })}
                className="mt-8 w-full justify-center"
              >
                Add live session
              </Button>
            )}
          </div>
        </div>
      </div>
      {(currentUser.isTrainer || currentUser.isAdmin) && (
        <AddLiveSessionModal onClose={fetchLiveSessions} />
      )}
    </>
  );
};

export default LiveSessionsIndex;
