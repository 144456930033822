import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import { motion } from "framer-motion";
import {
  Button,
  Form,
  TextInput,
  SelectInput,
  TextArea,
  Label,
  Checkbox,
} from "components/shared";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Hint } from "components/typography";
import { useFlash } from "hooks";

const PersonalityForm = ({ personality, stage, ...props }) => {
  const { API } = useContext(AppContext);
  const { errorFlash, successFlash } = useFlash();
  const [options, setOptions] = useState(personality?.options || []);
  const [coachEnabled, setCoachEnabled] = useState(
    !!stage.conversationCoachEnabled,
  );

  const toggleCoachEnabled = () => setCoachEnabled(!coachEnabled);

  const body = (params) => {
    const formattedBody = {
      personality: {
        ...params,
        options_attributes: options,
        stage_ids: [stage.id],
      },
      stage: {
        id: stage.id,
        conversation_coach_enabled: coachEnabled,
      },
    };

    return formattedBody;
  };

  const handleCreatePersonality = async (params) => {
    const response = await API.post("/personalities", body(params), {
      onError: errorFlash,
    });

    if (response) {
      successFlash(`${response.name} was created.`);
      props.onSubmit(response);
    }
  };

  const handleUpdatePersonality = async (params) => {
    const response = await API.put(
      `/personalities/${personality.id}`,
      body(params),
      {
        onError: errorFlash,
      },
    );

    if (response) {
      successFlash(`${response.name} was updated.`);
      props.onSubmit(response);
    }
  };

  const addOption = (e) => {
    e.preventDefault();

    const newOption = {
      title: undefined,
      summary: undefined,
      detail: undefined,
      information: undefined,
      index: options.length,
    };

    setOptions([...options, newOption]);
  };

  const updateOption = (index, property, value) => {
    const newOptions = [...options];
    newOptions[index][property] = value;
    setOptions(newOptions);
  };

  const removeOption = (index) => {
    const newOptions = [...options];
    newOptions[index]._destroy = "1";
    setOptions(newOptions);
  };

  return (
    <Form
      onSubmit={personality ? handleUpdatePersonality : handleCreatePersonality}
      className="flex overflow-hidden relative h-full"
    >
      <div className="shrink w-96 h-full overflow-y-auto pb-20">
        <div className="flex items-center justify-between border-b border-gray-200 px-6 h-12 bg-gray-50">
          <p className="text-sm font-semibold leading-6">Edit options</p>

          <Button size="xs" variant="secondary" onClick={addOption}>
            Add option
          </Button>
        </div>

        <div className="grid grid-cols-12 gap-y-6 px-5 py-4">
          <div className="col-span-12">
            <TextInput
              name="options_title"
              label="Options title"
              placeholder="e.g. Available Roles"
              defaultValue={personality?.optionsTitle}
            />
          </div>

          <div className="col-span-12 flex justify-between items-baseline -mb-4">
            <Label className="ml-1">Options</Label>
            <Hint small>Shown in random order</Hint>
          </div>

          {options.map((option, index) =>
            option._destroy ? null : (
              <motion.div
                key={`option-${index}`}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  duration: 0.95,
                  ease: [0.075, 0.82, 0.165, 1],
                }}
                className="col-span-12 rounded-md bg-white border border-gray-200 shadow-md"
              >
                <div className="flex justify-between items-center px-4 py-3 bg-gray-50 rounded-t-md">
                  <TextInput
                    required
                    placeholder="e.g. Big Data Engineer"
                    defaultValue={option.title}
                    onChange={(e) => updateOption(index, "title", e.target.value)}
                  />

                  <Button
                    size="xs"
                    variant="secondary"
                    Icon={TrashIcon}
                    onClick={(e) => removeOption(index)}
                  />
                </div>

                <div className="space-y-4 px-4 pt-2 pb-4">
                  <TextInput
                    label="Summary"
                    placeholder="e.g. Google"
                    defaultValue={option.summary}
                    onChange={(e) => updateOption(index, "summary", e.target.value)}
                  />

                  <TextArea
                    label="Detail"
                    placeholder="e.g. Lead engineer with general programming experience to lead a team of engineers working on Rails/React application."
                    defaultValue={option.detail}
                    onChange={(e) => updateOption(index, "detail", e.target.value)}
                  />

                  <TextArea
                    label="Information"
                    hint="The user can view this in a popover."
                    placeholder="e.g. A much more detailed description of this option."
                    defaultValue={option.information}
                    onChange={(e) => updateOption(index, "information", e.target.value)}
                  />
                </div>
              </motion.div>
            ),
          )}
        </div>
      </div>

      <div className="grow min-w-lg border-r border-l border-gray-200 h-full overflow-y-auto">
        <div className="flex items-center justify-between border-b border-gray-200 px-6 h-12 bg-gray-50">
          <p className="text-sm font-semibold leading-6">
            {personality ? `Edit ${personality.name}` : "Add a personality"}
          </p>

          <Button size="xs" type="submit">
            Save changes
          </Button>
        </div>

        <div className="grid grid-cols-12 gap-y-6 gap-x-4 px-5 py-4">
          <div className="col-span-6">
            <TextInput
              required
              name="name"
              label="Name"
              placeholder="e.g. Reisha"
              defaultValue={personality?.name}
            />
          </div>

          <div className="col-span-6">
            <TextInput
              required
              name="job_title"
              label="Job Title"
              placeholder="e.g. Cloud Solutions Architect"
              defaultValue={personality?.jobTitle}
            />
          </div>

          <div className="col-span-6">
            <TextInput
              required
              name="type"
              label="Type"
              placeholder="e.g. candidate"
              defaultValue={personality?.type}
            />
          </div>

          <div className="col-span-6">
            <SelectInput
              name="difficulty"
              label="Difficulty"
              defaultValue={personality?.difficulty ?? "easy"}
            >
              <option value="easy">Easy</option>
              <option value="mid">Mid</option>
              <option value="hard">Hard</option>
            </SelectInput>
          </div>

          <div className="col-span-12">
            <TextInput
              required
              name="need"
              label="Need"
              hint="The user will need to discover this."
              placeholder="e.g. I'm ready for a bigger Cloud challenge."
              defaultValue={personality?.need}
            />
          </div>

          <div className="col-span-12">
            <TextArea
              required
              name="first_message"
              label="First message"
              hint="This will be shown to the user straight away."
              placeholder="e.g. Hi, I'm Reisha. I want to get a job in Cloud Engineering."
              defaultValue={personality?.firstMessage}
            />
          </div>

          <div className="col-span-12">
            <TextInput
              name="personality"
              label="Personality"
              placeholder="e.g. Friendly and easygoing."
              defaultValue={personality?.personality}
            />
          </div>

          <div className="col-span-12">
            <TextArea
              name="extra"
              label="Extra details"
              placeholder="e.g. You have 8 years of experience using AWS."
              defaultValue={personality?.extra}
            />
          </div>

          <div className="col-span-12">
            <TextInput
              required
              name="success_case"
              label="Success case"
              placeholder="e.g. You have been offered a role that suits you."
              hint="When is the learner 'done'?"
              defaultValue={personality?.successCase}
            />
          </div>

          <div className="col-span-12">
            <TextArea
              name="system_prompt"
              label="System prompt"
              placeholder="e.g. See the user as an expert in their field."
              defaultValue={personality?.systemPrompt}
            />
          </div>
        </div>
      </div>

      <div className="overflow-y-auto shrink w-96 h-full pb-20">
        <div className="flex items-center justify-between border-b border-gray-200 px-6 h-12 bg-gray-50">
          <p className="text-sm font-semibold leading-6">Edit coach</p>

          <div className="flex items-center space-x-2">
            <Label small>Enable coach?</Label>
            <Checkbox
              readOnly
              checked={coachEnabled}
              onClick={toggleCoachEnabled}
            />
          </div>
        </div>

        <div className="grid grid-cols-12 gap-y-6 gap-x-4 px-5 py-4">
          <div className="col-span-12">
            <TextArea
              name="coach_prompt"
              label="Coach prompt"
              placeholder="e.g. Provide feedback in-context."
              defaultValue={personality?.coachPrompt}
              disabled={!coachEnabled}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default PersonalityForm;
