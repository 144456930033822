import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { useQueryParam, NumberParam } from "use-query-params";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { Modal, Loading, Alert } from "components/shared";
import { H3 } from "components/typography";
import CreateUserCertification from "components/users/CreateUserCertification";
import UpdateUserCertification from "components/users/UpdateUserCertification";
import UserTranscriptDiscussions from "components/transcripts/UserTranscriptDiscussions";
import AiCertificationHelper from "components/transcripts/AiCertificationHelper";
import { possessive } from "utils";

const CertifyUserModal = ({ ...props }) => {
  const { API } = useContext(AppContext);
  const [id, setId] = useQueryParam("certifyUserId", NumberParam);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const onClose = () => {
    setId(null);
    if (props.onClose) props.onClose();
  };

  const fetchUser = async () => {
    const response = await API.get(`/users/${id}`);

    if (response) {
      setUser(response);
      setLoading(false);
    }
  };

  useEffect(() => {
    if(id) fetchUser();
  }, [id]);

  const handleChange = () => {
    if (props.onChange) props.onChange();
    onClose();
  };

  if (loading || !user) {
    return (
      <Modal
        noPadding
        size={"5xl"}
        open={!!id}
        onClose={onClose}
        className="min-h-[240px]"
      >
        <Loading />
      </Modal>
    );
  }

  return (
    <Modal dismissible noPadding size={"5xl"} open={!!id} onClose={onClose}>
      <div className="flex items-center justify-center space-x-4 py-4">
        <div className="flex items-center justify-center bg-blue-100 rounded-full p-2">
          <CheckBadgeIcon
            className="h-6 w-6 text-blue-600"
            aria-hidden="true"
          />
        </div>
        <H3>
          {user.certified
            ? `Update ${possessive(user.firstName)} Certification`
            : `Certify ${user.firstName}`}
        </H3>
      </div>
      <div className="grid grid-cols-12 h-[70vh] border-t border-gray-200">
        <div className="col-span-6 px-6 pb-4 border-r border-gray-200 h-full overflow-y-auto relative">
          {user.certified ? (
            <UpdateUserCertification
              user={user}
              onUpdate={handleChange}
              onDecertify={handleChange}
            />
          ) : (
            <div>
              {user.certificationRequested && (
                <Alert avatar={user.avatar} className="mt-4">
                  {user.firstName} has requested certification.
                </Alert>
              )}
              <CreateUserCertification user={user} onUpdate={handleChange} />
            </div>
          )}
        </div>
        <div className="col-span-6 h-full overflow-y-auto relative text-left pt-4">
          <div className="px-6 mb-6">
            <AiCertificationHelper
              initialText={user.certificationSummary}
              user={user}
            />
          </div>
          <UserTranscriptDiscussions user={user} />
        </div>
      </div>
    </Modal>
  );
};

export default CertifyUserModal;
