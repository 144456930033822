import React, { useState, useEffect } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { cn, daysInMonth } from "utils";

const Calendar = ({ events, eventDatetimeKey, ...props }) => {
  const formatter = new Intl.DateTimeFormat("en-UK", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  const [currentDate, setCurrentDate] = useState(new Date());
  const [days, setDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    formatter.format(new Date()),
  );

  useEffect(() => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    setDays(
      daysInMonth(year, month, events, { eventDatetimeKey }).map((day) => ({
        ...day,
        isSelected: day.date === selectedDate,
      })),
    );

    if (props.onSelectDate) props.onSelectDate(selectedDate);
  }, [currentDate, selectedDate]);

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
    );
  };

  const selectDate = (date) => {
    const month = parseInt(date.split("/")[1]) - 1;
    const differenceInMonths = month - currentDate.getMonth();

    if (differenceInMonths !== 0) {
      setCurrentDate(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + differenceInMonths,
          1,
        ),
      );
    }

    setSelectedDate(date);
  };

  return (
    <div className={cn("text-center", props.className)}>
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          onClick={handlePrevMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold">
          {currentDate.toLocaleString("default", { month: "long" })}{" "}
          {currentDate.getFullYear()}
        </div>
        <button
          type="button"
          onClick={handleNextMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day, dayIdx) => (
          <button
            key={day.date}
            type="button"
            onClick={() => selectDate(day.date)}
            className={cn(
              "py-1.5 hover:bg-gray-100 focus:z-10",
              day.isCurrentMonth ? "bg-white" : "bg-gray-50",
              (day.isSelected || day.isToday) && "font-semibold",
              day.isSelected && "text-white",
              !day.isSelected &&
                day.isCurrentMonth &&
                !day.isToday &&
                "text-gray-900",
              !day.isSelected &&
                !day.isCurrentMonth &&
                !day.isToday &&
                "text-gray-400",
              day.isToday && !day.isSelected && "text-indigo-600",
              day.hasEvent && "text-white font-semibold",
              dayIdx === 0 && "rounded-tl-lg",
              dayIdx === 6 && "rounded-tr-lg",
              dayIdx === days.length - 7 && "rounded-bl-lg",
              dayIdx === days.length - 1 && "rounded-br-lg",
            )}
          >
            <time
              dateTime={day.date}
              className={cn(
                "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                day.isSelected && day.isToday && "bg-indigo-600",
                day.isSelected && !day.isToday && "bg-gray-900",
                day.hasEvent && "bg-blue-500",
                day.isSelected &&
                  day.hasEvent &&
                  "bg-blue-500 ring-2 ring-gray-900",
              )}
            >
              {day.date && typeof day.date === "string"
                ? day.date.split("/")[0]
                : ""}
            </time>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
