import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import { Form, TextInput } from "components/shared";
import { KeyIcon } from "@heroicons/react/24/solid";
import { A } from "components/typography";
import { usePut, useFlash } from "hooks";
import { classNames } from "utils";

const LicenseInput = ({ ...props }) => {
  const { currentUser, setCurrentUser } = useContext(AppContext);
  const [show, setShow] = useState(false);

  const { successFlash, errorFlash } = useFlash();

  const { put } = usePut(async (user) => {
    if (user.license) {
      await setCurrentUser(user);
      successFlash("License was set correctly.");
      props.onClose(false);
    } else {
      errorFlash(`We couldn't apply that license. Please try again.`);
    }
  });

  const handleSubmit = (data) => {
    const body = {
      user: {
        license: data.licenseKey,
      },
    };

    put(`users/${currentUser.id}`, body);
  };

  return (
    <div className={classNames(props.className)}>
      {show ? (
        <div className="mx-auto max-w-sm">
          <Form onSubmit={handleSubmit}>
            <TextInput
              id="licenseKey"
              name="licenseKey"
              placeholder="Enter key code"
              defaultValue={currentUser.license}
              className="mt-1"
              button={true}
              buttonText={"Submit"}
            />
          </Form>
        </div>
      ) : (
        <div className="mx-auto max-w-sm">
          <A
            onClick={() => setShow(true)}
            className="text-sm font-semibold opacity-60"
          >
            I have a license key
          </A>
        </div>
      )}
    </div>
  );
};

export default LicenseInput;
