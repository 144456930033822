// The main component that is rendered to the * route (once authenticated)
import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";

import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

import { Flash } from "components/shared";

import Main from "components/Main";

import AppContext from "contexts/AppContext";
import HubspotChatContextProvider from "contexts/HubspotChatContext";
import FlashContext from "contexts/FlashContext";
import UploaderContext from "contexts/UploaderContext";
import GithubContext from "contexts/GithubContext";
import useNewApi from "api2";
import {
  CheckBadgeIcon,
  DocumentTextIcon,
  DocumentCheckIcon,
  QuestionMarkCircleIcon,
  VideoCameraIcon,
  ChartBarIcon,
  ChatBubbleLeftRightIcon,
  AcademicCapIcon,
  WrenchScrewdriverIcon,
  RocketLaunchIcon,
  ChatBubbleLeftIcon,
  BeakerIcon,
} from "@heroicons/react/24/outline";

import { useAnalytics } from "hooks";

// if (process.env.NODE_ENV === "development") {
//   const whyDidYouRender = require("@welldone-software/why-did-you-render");

//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

const Root = (props) => (
  <Router>
    <App {...props} />
  </Router>
);

const App = ({
  apiUrl,
  notionApiUrl,
  streamingApiUrl,
  formToken,
  graphUrl,
  graphUri,
  emailDomain,
  rootUrl,
  learnerIndexUrl,
  loggedInUser,
  githubClientId,
  cloudinaryOptions,
  freeTrialActivityLimit,
  flash,
  openAiApiKey,
  heliconeApiKey,
}) => {
  const location = useLocation();

  const STATUSES = {
    waiting: "waiting",
    loading: "loading",
    success: "success",
    failure: "failure",
    error: "error",
  };

  const stageTypes = {
    "open-ended question": {
      name: "Question",
      type: "open-ended question",
      Icon: QuestionMarkCircleIcon,
    },
    allocator: {
      name: "Poll",
      type: "allocator",
      Icon: ChartBarIcon,
    },
    "interactive video": {
      name: "Video",
      type: "interactive video",
      Icon: VideoCameraIcon,
    },
    article: {
      name: "Article",
      type: "article",
      Icon: DocumentTextIcon,
    },
    notion: {
      name: "Article",
      type: "notion",
      Icon: DocumentCheckIcon,
    },
    internal: {
      name: "Activity",
      type: "internal",
      Icon: QuestionMarkCircleIcon,
    },
    review: {
      name: "Review",
      type: "review",
      Icon: QuestionMarkCircleIcon,
    },
    questions: {
      name: "Exercise",
      type: "questions",
      Icon: QuestionMarkCircleIcon,
    },
    certification: {
      name: "Certification",
      type: "certification",
      Icon: CheckBadgeIcon,
    },
    intro: {
      name: "Question",
      type: "intro",
      Icon: QuestionMarkCircleIcon,
    },
    bloom: {
      name: "Conversation",
      type: "bloom",
      Icon: ChatBubbleLeftRightIcon,
    },
    assessment: {
      name: "Checkpoint",
      type: "assessment",
      Icon: AcademicCapIcon,
    },
    workshop: {
      name: "Workshop",
      type: "workshop",
      Icon: WrenchScrewdriverIcon,
    },
    project: {
      name: "Project",
      type: "project",
      Icon: RocketLaunchIcon,
    },
    exercise: {
      name: "Exercise",
      type: "exercise",
      Icon: QuestionMarkCircleIcon,
    },
    explainer: {
      name: "Explainer",
      type: "explainer",
      Icon: ChatBubbleLeftIcon,
    },
    practical: {
      name: "Practical",
      type: "practical",
      Icon: BeakerIcon,
    },
  };

  const AVAILABLE_STAGE_TYPES = [
    "open-ended question",
    "interactive video",
    "article",
    "allocator",
    "bloom"
  ]

  const stageMetadata = (type) => {
    return (
      stageTypes[type] || {
        name: "Exercise",
        type: "exercise",
        Icon: QuestionMarkCircleIcon,
      }
    );
  };

  const [analytics, setAnalytics] = useAnalytics(loggedInUser, location);

  const [currentUser, setCurrentUser] = useState(loggedInUser);

  const [flashShow, setFlashShow] = useState(false);
  const [flashHeader, setFlashHeader] = useState(null);
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashType, setFlashType] = useState(null);

  const [editorMode, setEditorMode] = useState(false);

  const API = useNewApi(apiUrl, formToken);

  return (
    <AppContext.Provider
      value={{
        apiUrl,
        notionApiUrl,
        streamingApiUrl,
        formToken,
        graphUrl,
        graphUri,
        rootUrl,
        learnerIndexUrl,
        emailDomain,
        currentUser,
        setCurrentUser,
        STATUSES,
        editorMode,
        setEditorMode,
        API,
        freeTrialActivityLimit,
        openAiApiKey,
        heliconeApiKey,
        stageTypes,
        stageMetadata,
        AVAILABLE_STAGE_TYPES,
      }}
    >
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <HubspotChatContextProvider>
          <UploaderContext.Provider value={{ ...cloudinaryOptions }}>
            <FlashContext.Provider
              value={{
                show: flashShow,
                setShow: setFlashShow,
                header: flashHeader,
                setHeader: setFlashHeader,
                message: flashMessage,
                setMessage: setFlashMessage,
                type: flashType,
                setType: setFlashType,
              }}
            >
              <GithubContext.Provider value={{ clientId: githubClientId }}>
                <Main flash={flash} />
                <Flash />
              </GithubContext.Provider>
            </FlashContext.Provider>
          </UploaderContext.Provider>
        </HubspotChatContextProvider>
      </QueryParamProvider>
    </AppContext.Provider>
  );
};

export default Root;
