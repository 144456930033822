import React, { useMemo, useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Link } from "react-router-dom";
import {
  Handle,
  Position,
  useStore,
  useUpdateNodeInternals,
  NodeToolbar,
  useReactFlow,
} from "reactflow";
import { P } from "components/typography";
import { Tooltip, ContentEditable } from "components/shared";
import { cn } from "utils";
import { useConfirm } from "hooks";
import {
  BoltIcon,
  CheckIcon,
  LockClosedIcon,
  ArrowRightIcon,
  LinkIcon,
  PencilIcon,
  ArchiveBoxArrowDownIcon,
} from "@heroicons/react/24/outline";

// {
//   id: string;
//   data: T;
//   dragHandle?: boolean;
//   type?: string;
//   selected?: boolean;
//   isConnectable?: boolean;
//   zIndex?: number;
//   xPos: number;
//   yPos: number;
//   dragging: boolean;
//   targetPosition?: Position;
//   sourcePosition?: Position;
// }

const ActivityNode = ({ id, data }) => {
  const [showToolbar, setShowToolbar] = useState(false);
  const [toolbarTimeout, setToolbarTimeout] = useState(null);
  const nodes = useStore((store) => store.getNodes());
  const selectedNode = nodes.find((node) => node.selected);

  const isUnlocked = data.unlocked;

  const updateNodeInternals = useUpdateNodeInternals();

  const connectionNodeId = useStore((state) => state.connectionNodeId);
  const isConnecting = !!connectionNodeId;
  const isTarget = connectionNodeId && connectionNodeId !== id;

  const reactFlow = useReactFlow();

  useEffect(() => {
    updateNodeInternals(id);
  }, [data.editable, updateNodeInternals]);

  const isSelected = useMemo(() => {
    return selectedNode?.data?.slug === data.slug;
  }, [selectedNode]);

  const isPreviousToSelected = useMemo(() => {
    return selectedNode?.data?.previous
      ?.map((activity) => String(activity.id))
      .includes(String(data.id));
  }, [selectedNode]);

  const isNextToSelected = useMemo(() => {
    return selectedNode?.data?.next
      ?.map((activity) => String(activity.id))
      .includes(String(data.id));
  }, [selectedNode]);

  const isConnectedToSelected = isPreviousToSelected || isNextToSelected;
  const isCurrent = !!data.currentAttempt;
  const isCompleted = !!data.completedAttempt;

  const startLink = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const [ConfirmModal, confirmArchive] = useConfirm(
    "Archive Activity?",
    `Are you sure you want to archive the "${data.label}" activity?`,
  );

  const handleArchive = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const isConfirmed = await confirmArchive();
    if (isConfirmed) {
      reactFlow.setNodes(nodes.filter((node) => node.id !== id));
    }
  };

  return (
    <>
      <Link
        to={`/activities/${data.slug}/preview`}
        className={cn(
          "group/activitynode transition-opacity duration-[600ms] hover:shadow-md cursor-pointer",
          isSelected || "opacity-20 hover:opacity-40",
          isConnectedToSelected && "opacity-40 hover:opacity-60",
          data.unlocked
            ? "group-[.nothing-selected]:opacity-100"
            : "group-[.nothing-selected]:opacity-40 group-[.nothing-selected]:hover:opacity-100",
          isTarget && "opacity-100",
        )}
        onMouseEnter={() => setShowToolbar(true)}
        onMouseLeave={() =>
          setToolbarTimeout(setTimeout(() => setShowToolbar(false), 200))
        }
      >
        {!isConnecting && (
          <Handle
            className="w-full h-full absolute inset-0 opacity-0"
            position={Position.Right}
            type="source"
            isConnectableStart={false}
          />
        )}

        <Handle
          className="w-full h-full absolute inset-0 opacity-0"
          position={Position.Left}
          type="target"
          isConnectableStart={false}
        />

        <div
          className={cn(
            "bg-gray-100 border-gray-300 border px-4 py-2 rounded-lg flex items-center justify-center space-x-2",
            data.unlocked && "bg-white",
            isTarget && "bg-lime-100",
            isSelected && isUnlocked && isCompleted && "border-lime-500",
            isSelected && isUnlocked && !isCompleted && isCurrent && "border-yellow-400",
            isSelected && isUnlocked && !isCompleted && !isCurrent && "border-indigo-400",
          )}
        >
          <P>{data.label}</P>
          {isUnlocked ? (
            isCompleted ? (
              <CheckIcon className="h-4 w-4 p-0.5 rounded-full border border-lime-500 text-lime-500 bg-lime-200" />
            ) : isCurrent ? (
              <BoltIcon className="h-4 w-4 p-0.5 rounded-full border border-yellow-400 text-yellow-400 bg-yellow-200" />
            ) : (
              <ArrowRightIcon className="h-4 w-4 p-0.5 rounded-full border border-indigo-400 text-indigo-400 bg-indigo-100" />
            )
          ) : (
            <LockClosedIcon className="h-4 w-4 p-0.5 rounded-full border border-gray-400 text-gray-400 bg-gray-200" />
          )}
          {}
        </div>

        {data.editable && (
          <NodeToolbar
            isVisible={showToolbar && !isConnecting}
            position={"top"}
            className="space-x-2 flex flex items-start justify-center"
            onMouseEnter={() => clearTimeout(toolbarTimeout)}
          >
            <div
              className="group/item flex items-center justify-center space-x-1"
              onClick={startLink}
            >
              <Tooltip text="Link">
                <div className="p-2 relative bg-white shadow-sm rounded-full cursor-pointer hover:bg-gray-50 hover:shadow-md flex items-center justify-center">
                  <LinkIcon className="h-4 w-4" />
                  <Handle
                    className="w-full h-full absolute inset-0 top-4 opacity-0"
                    position={Position.Right}
                    type="source"
                  />
                </div>
              </Tooltip>
            </div>

            <div className="group/item flex items-center justify-center space-x-1">
              <Tooltip text="Edit">
                <div className="p-2 bg-white shadow-sm rounded-full cursor-pointer hover:bg-gray-50 hover:shadow-md flex items-center justify-center">
                  <PencilIcon className="h-4 w-4" />
                </div>
              </Tooltip>
            </div>

            <div
              className="group/item flex items-center justify-center space-x-1"
              onClick={handleArchive}
            >
              <Tooltip text="Archive">
                <div className="p-2 bg-white shadow-sm rounded-full cursor-pointer hover:bg-gray-50 hover:shadow-md flex items-center justify-center">
                  <ArchiveBoxArrowDownIcon className="h-4 w-4" />
                </div>
              </Tooltip>
            </div>
          </NodeToolbar>
        )}
      </Link>
      <ConfirmModal />
    </>
  );
};

export default ActivityNode;
