import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Button, Spinner } from 'components/shared'
import { CheckBadgeIcon, ChevronDownIcon, BookmarkSlashIcon, BellAlertIcon } from '@heroicons/react/24/outline'
import { useHistory } from 'react-router-dom'
import { usePost, useQuery } from 'hooks'
import { classNames } from 'utils'

const ManualCertificationToggle = ({ user, ...props }) => {
  const { pathWithQuery } = useQuery()
  const history = useHistory()

  const actions = [
    {
      display: true,
      label: 'Manage certification',
      onClick: () => history.push(pathWithQuery(null, { certifyUserId: user.id })),
      Icon: (props) => <CheckBadgeIcon {...props} />
    }
  ].filter(action => action.display)

  return(
    <Menu as='div' className={props.className}>
      <Menu.Button
       as={Button}
       Icon={CheckBadgeIcon}
       variant={(user.certified || user.certificationRequested) ? 'primary' : 'secondary'}
       size={props.small ? 'sm' : 'md'}>
        {
          user.certified
          ? 'Certified'
          : user.certificationRequested
          ? 'Requested'
          : 'Not certified'
        }
        <ChevronDownIcon className="ml-2 h-3 w-3 -mr-2" aria-hidden="true" />
      </Menu.Button>

      <Transition
       enter="transition duration-100 ease-out"
       enterFrom="transform scale-95 opacity-0"
       enterTo="transform scale-100 opacity-100"
       leave="transition duration-75 ease-out"
       leaveFrom="transform scale-100 opacity-100"
       leaveTo="transform scale-95 opacity-0">

        <Menu.Items
         className={classNames(
          "absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100"
         )}>
          <div className='py-1'>
          {
            actions.map(Action => (
              <Menu.Item key={Action.label}>
                {
                  ({ active }) => (
                    <a
                     className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'bg-white text-gray-700',
                      'group flex items-center px-4 py-2 text-sm cursor-pointer'
                     )}
                     onClick={Action.onClick}>
                     <Action.Icon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                     { Action.label }
                    </a>
                  )
                }
              </Menu.Item>
            ))
          }
          </div>
        </Menu.Items>

      </Transition>
    </Menu>
  )
}

export default ManualCertificationToggle
