import React, { useState } from 'react'
import GithubAuthentication from 'components/github/Authentication'
import { Modal } from 'components/shared'
import { ArrowPathIcon } from '@heroicons/react/24/outline'

const GithubAuthenticationAlert = (props) => {
  const [open, setOpen] = useState(true)

  return(
    <Modal
     dismissible
     open={open}
     type="warning"
     onClose={() => setOpen(false)}
     Icon={ArrowPathIcon}
     title="Authenticate with Github"
     description="To complete projects, sync issues, and track Pull Requests, Graph needs to connect to Github."
     button={
      <GithubAuthentication
       buttonText="Authenticate with Github" />
     } />
  )
}

export default GithubAuthenticationAlert
