import React, { useState, useEffect, useContext } from "react";
import AppContext from "contexts/AppContext";
import { Alert, Loading } from "components/shared";
import { destroy as apiDestroy } from "api";
import { useFlash, useIsMounted } from "hooks";

const useDestroy = (onSuccess, onError = null) => {
  const isMounted = useIsMounted();

  const { apiUrl, formToken } = useContext(AppContext);
  const { errorFlash } = useFlash();

  const [data, setData] = useState({ isLoading: false, errorMessage: null });

  const handleSuccess = (data) => {
    // success is often a setState call
    if (isMounted.current) {
      if (onSuccess) onSuccess(data);
      setData({ ...data, isLoading: false });
    }
  };

  const handleError = async (response) => {
    if (isMounted.current) {
      const error = await response.json();

      setData({ isLoading: false, errorMessage: error.message });
      errorFlash(error.message);
      if (onError) onError(error);
    }
  };

  const destroy = (path) => {
    setData({ ...data, isLoading: true });
    apiDestroy(`${apiUrl}/${path}`, { formToken }, handleSuccess, handleError);
  };

  const loadingOrError = () => {
    return data.errorMessage ? (
      <Alert type="danger">{data.errorMessage}</Alert>
    ) : data.isLoading ? (
      <Loading />
    ) : null;
  };

  return { destroy, data, setData, loadingOrError };
};

export default useDestroy;
