import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'hooks'
import { Avatar } from 'components/shared'
import { Hint } from 'components/typography'

const UserInCommandBar = ({ id, avatar, name, email, organization, ...props }) => {
  const { pathWithQuery } = useQuery()

  return(
    <Link
     className='flex items-center justify-between block w-full'
     to={pathWithQuery(null, { user: id })}
     onClick={props.onClick}>
     <div className='flex items-center space-x-2'>
       <Avatar size='sm' avatar={avatar} />
       <div>
        {
          name && (
            <div>{ name }</div>
          )
        }
         {
          email && (
            <Hint>{ email }</Hint>
          )
         }
      </div>
     </div>
        <div className='opacity-0 group-data-[selected=true]:opacity-100 transition-opacity ease-in-out'>
         <svg className="ml-1" width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23 23V5H43V43H5V23H23Z" fill="none" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><path d="M33 13V33H13" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><path d="M17 29L13 33L17 37" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/></svg>
        </div>
    </Link>
  )
}

export default UserInCommandBar