import React, { Fragment } from "react";
import QuestionHeader from "components/coaching/QuestionHeader";
import Discussions from "components/coaching/Discussions";
import Discussion from "components/coaching/Discussion";

const SelectedDiscussion = ({
  id,
  selectedResponseId,
  selectedCommentId,
  ...discussion
}) => {
  if (!id) return null;

  return (
    <>
      {discussion && <QuestionHeader {...discussion} />}
      <Discussion
        id={id}
        highlightedResponseId={selectedResponseId}
        highlightedCommentId={selectedCommentId}
        defaultExpandRevisions
        defaultIsCommenting
      />

      <Discussions className="mt-6" question={discussion.question} />
    </>
  );
};

export default SelectedDiscussion;
