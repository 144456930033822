import React from "react";
import { cn } from "utils";

const Image = (props) => {
  return (
    <img className={cn(props.className)} src={props.src} alt={props.alt} />
  );
};

export default Image;
