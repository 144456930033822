import React, { useState } from "react";
import { cn } from "utils";

const RangeSlider = ({
  min = 0,
  max = 5,
  step= 1,
  disabled = false,
  defaultValue = 0,
  labels = [],
  ...props
}) => {

  const handleChange = (e) => {
    if (disabled) return;
    if (props.onChange) props.onChange(e.target.value);
  };

  const handleChangeComplete = (e) => {
    if (disabled) return;
    if (props.onChangeComplete) props.onChangeComplete(e.target.value);
  };

  return (
    <div
      className={cn("relative -mt-0.5", labels.length > 0 && "mb-6", props.className)}
    >
      <input
        type="range"
        className={cn(
          "w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700",
          props.inputClassName,
        )}
        value={defaultValue}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        onChange={handleChange}
        onMouseUp={handleChangeComplete}
      />
      {labels.map(({ content, className }) => (
        <span
          className={cn(
            "text-sm text-gray-500 dark:text-gray-400 absolute -bottom-6",
            className,
          )}
        >
          {content}
        </span>
      ))}
    </div>
  );
};

export default RangeSlider;
