import React, { useContext } from "react";
import AppContext from "contexts/AppContext";
import { Modal, Button } from "components/shared";
import { ForwardIcon } from "@heroicons/react/24/outline";
import { P, Semibold } from "components/typography";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { useQuery } from "hooks";
import { getNextWeekday } from "utils";

const StreakModal = ({ ...props }) => {
  const { currentUser } = useContext(AppContext);
  const { watchQuery, addQuery } = useQuery();

  const open = watchQuery("onboardingStage", (value) => value === 2);
  const onClose = () => addQuery("onboardingStage", 3);

  return (
    <Modal
      size={"md"}
      open={open}
      onClose={onClose}
      title={`2x your learning`}
      titleClassName="text-3xl pb-4"
      Icon={ForwardIcon}
      button={
        <div className="flex items-center justify-center pb-4 space-x-4">
          <AddToCalendarButton
            size="5"
            name="TechMap learning time"
            options={[
              "Apple",
              "Google",
              "iCal",
              "Microsoft365",
              "MicrosoftTeams",
              "Outlook.com",
              "Yahoo",
            ]}
            description={"Dedicated time for TechMap."}
            startDate={getNextWeekday("friday").format("YYYY-MM-DD")}
            endDate={getNextWeekday("friday").format("YYYY-MM-DD")}
            startTime={"13:00"}
            endTime={"14:00"}
            recurrence="weekly"
            recurrence_interval="1"
            recurrence_byDay="FR"
            organizer={`${currentUser.name}|${currentUser.email}`}
            timeZone="currentBrowser"
            listStyle="modal"
          />

          <Button variant='secondary' onClick={onClose}>Move on</Button>
        </div>
      }
    >
      <div className="prose">
        <P>
          Learners who set up a regular event in their calendar for TechMap are{" "}
          <Semibold>twice as likely to get certified</Semibold> as those who
          don't.
        </P>

        <P>Most people do it on <Semibold>Friday afternoon</Semibold>.</P>

        <P>Click below to add a handy recurring event to your calendar. You can adjust it to suit your needs.</P>
      </div>
    </Modal>
  );
};

export default StreakModal;
