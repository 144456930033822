import React, { Fragment, useState, useEffect, useContext } from "react";
import AppContext from "contexts/AppContext";
import ActivityStage from "./ActivityStage";
import NoActivityStages from "./NoActivityStages";
import StageControls from "./StageControls";
import AddStageFromLibraryModal from "components/design/AddStageFromLibraryModal";
import { Panel, Loading } from "components/shared";
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { useFetch, usePost, useDestroy, useFlash, useStageList } from "hooks";
import { pure } from "recompose";

const Activity = ({ slug, ...props }) => {
  const { currentUser, API, stageMetadata } = useContext(AppContext);
  let { path, url } = useRouteMatch();
  let history = useHistory();
  let location = useLocation();

  const [activity, setActivity] = useState(null);
  const [attempt, setAttempt] = useState(null);
  const [stages, setStages] = useStageList();

  const { errorFlash } = useFlash();

  const fetchActivity = async () => {
    const activity = await API.get(`/activities/${slug}`, {
      onError: errorFlash,
    });
    if (activity) {
      setActivity(activity);
      setStages(activity.stages);
    }
  };

  const fetchAttempts = async () => {
    const attempts = await API.get(
      `/attempts?graph_activity_slug=${slug}&user_id=${currentUser.id}`,
      { onError: errorFlash },
    );
    if (attempts) {
      setAttempt(attempts[0]);
    }
  };

  useEffect(() => {
    fetchActivity();
    fetchAttempts();
  }, [slug]);

  const gotoFirstStage = (stages) => {
    const nextStageSlug = stages[0] ? stages[0].slug : "no-stages";

    history.replace(`/activities/${slug}/stages/${nextStageSlug}`);
  };

  useEffect(() => {
    if (activity && !location.pathname.includes("/stages/"))
      gotoFirstStage(activity.stages);
  }, [activity]);

  const insertStage = async (stage, { redirectPath = null } = {}) => {
    const newStages = await stages.addStage(stage);
    setStages(newStages);
    history.replace(
      redirectPath ? redirectPath : `/activities/${slug}/stages/${stage.slug}`,
    );
  };

  const addStage = async (type) => {
    const body = {
      activity_stage: {
        graph_activity_id: activity.id,
        type,
      },
    };

    const stage = await API.post("/activity_stages", body);

    if (stage) {
      insertStage(stage);
    }
  };

  const archiveStage = async (stageSlug) => {
    const body = {
      activity_stage: {
        archived: true,
      },
    };

    const stage = await API.put(`/activity_stages/${stageSlug}`, body, {
      onError: errorFlash,
    });

    if (stage) {
      const newStages = await stages.archiveStage(stage);
      setStages(newStages);
      gotoFirstStage(newStages);
    }
  };

  const changeStage = async (stageSlug, changes = []) => {
    const stage = stages.find((stage) => stage.slug === stageSlug);

    changes.forEach(async (change) => {
      if (change.type === "moveLeft") {
        const newStages = await stages.moveLeft(stage);
        setStages(newStages);
      }

      if (change.type === "moveRight") {
        const newStages = await stages.moveRight(stage);
        setStages(newStages);
      }
    });
  };

  if (!activity || !attempt) return <Loading />;

  return (
    <>
      <Panel.Header className="h-16 min-h-16 flex items-center justify-between">
        <Route path={`${path}/stages/:stageSlug`}>
          <StageControls
            stages={stages}
            onChangeStage={changeStage}
            onHidePanel={props.onHidePanel}
            onAddStage={addStage}
            onArchiveStage={archiveStage}
            onBackToGraph={props.onBackToGraph}
            onCompleteLastStage={props.onCompleteLastStage}
          />
        </Route>
      </Panel.Header>

      <Panel.Body>
        <Switch>
          <Route path={`${path}/stages/no-stages`}>
            <NoActivityStages />
          </Route>

          <Route path={`${path}/stages/:stageSlug`}>
            <ActivityStage stages={stages} attempt={attempt} onUpdate={fetchActivity} />
          </Route>
        </Switch>
      </Panel.Body>

      {(currentUser.isAdmin || currentUser.isTrainer) && (
        <AddStageFromLibraryModal
          activity={activity}
          onAddStage={insertStage}
        />
      )}
    </>
  );
};

export default pure(Activity);
