import React, { useState, useContext, useRef, useLayoutEffect } from 'react'
import { Button } from 'components/shared'
import { post } from 'api'
import AppContext from 'contexts/AppContext'

const Fix = ({ url, onSuccess, autofix }) => {
  const { apiUrl, formToken } = useContext(AppContext)
  const [data, setData] = useState({ isLoading: false, errorMessage: null })

  const button = useRef(null)

  const handleSuccess = (response) => {
    setData({ ...data, isLoading: false })
    onSuccess(response)
  }

  const handleError = (error, response) => {
    setData({ ...data, errorMessage: error, isLoading: false })
  }

  const handleClick = (e) => {
    e.preventDefault()

    setData({ ...data, isLoading: true })

    post(url, { formToken }, handleSuccess, handleError)
  }

  useLayoutEffect(() => {
    if(button.current && autofix) button.current.click()
  }, [button])

  return(
    <Button ref={button} onClick={handleClick} disabled={data.isLoading} error={data.errorMessage}>
      { data.isLoading ? 'Fixing...' : 'Fix' }
    </Button>
  )
}

Fix.defaultProps = {
  autofix: false
}

export default Fix