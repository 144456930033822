import React from "react";
import { Modal } from "components/shared";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { useQuery } from "hooks";
import SelectCourse from "components/courses/SelectCourse";

const SelectCourseModal = ({ ...props }) => {
  const { watchQuery, deleteQuery } = useQuery();

  const open = watchQuery("selectCourse");
  const onClose = () => {
    deleteQuery("selectCourse");
    if (props.onChange) props.onChange();
  };

  return (
    <Modal
      size="sm"
      dismissible
      open={!!open}
      onClose={onClose}
      Icon={ArrowsRightLeftIcon}
      title="Switch current course"
    >
      <SelectCourse onClose={onClose} />
    </Modal>
  );
};

export default SelectCourseModal;
