import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import { TextInput, Button, Form } from "components/shared";
import { useFlash } from "hooks";

const AddOrganization = ({ ...props }) => {
  const { API } = useContext(AppContext);
  const { successFlash, errorFlash } = useFlash();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (formData) => {
    const body = {
      organization: {
        name: formData.name,
      },
    };

    setIsLoading(true);
    const response = await API.post(`/organizations`, body, {
      onError: errorFlash,
    });
    setIsLoading(false);

    if (response) {
      successFlash(`Organization created successfully.`);
      if (props.onSubmit) props.onSubmit(response);
      if (props.onClose) props.onClose();
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="text-left pt-4 space-y-4">
      <TextInput required label="Name" name="name" />
      <div className="mt-5 sm:mt-6">
        <Button className="w-full block" type="submit" disabled={isLoading}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default AddOrganization;
