import React, { useState, useEffect } from "react";
import { cn } from "utils";

const TimePicker = ({
  defaultHours = 12,
  defaultMinutes = 0,
  ...props
}) => {
  const [time, setTime] = useState({
    hours: defaultHours,
    minutes: defaultMinutes
  });

  const handleChange = ({ name, value }) => {
    setTime({
      ...time,
      [name]: value,
    });
  };

  useEffect(() => {
    props.onChange(time);
  }, [time]);

  return (
    <div className={cn("bg-white rounded-lg text-xs", props.className)}>
      <div className="flex items-baseline justify-center">
        <select
          name="hours"
          className="bg-right pr-5 pl-1 bg-transparent text-xs appearance-none outline-none border-none"
          value={time.hours}
          onChange={(e) =>
            handleChange({ name: "hours", value: parseInt(e.target.value) })
          }
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
          <option value="21">21</option>
          <option value="22">22</option>
          <option value="23">23</option>
        </select>
        <span>:</span>
        <select
          name="minutes"
          className="bg-right pr-5 pl-1 bg-transparent text-xs appearance-none outline-none border-none"
          value={time.minutes}
          onChange={(e) =>
            handleChange({ name: "minutes", value: parseInt(e.target.value) })
          }
        >
          <option value="0">00</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="45">45</option>
          <option value="50">50</option>
        </select>
      </div>
    </div>
  );
};

export default TimePicker;
