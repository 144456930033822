import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

import { Button, Alert, LabelHeader } from 'components/shared'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { dndMove, dndReorder, shuffle, classNames } from 'utils'
import { pure } from 'recompose'

const Orderer = ({ items, onComplete }) => {
  const showByDefaultItems = items.filter(item => item.showByDefault)
  const normalItems        = items.filter(item => !item.showByDefault)

  const shuffledItems = [...showByDefaultItems, ...shuffle(normalItems)]

  const [state, setState] = useState([shuffledItems])
  const [isCorrect, setIsCorrect] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const onDragStart = () => {
    setIsChecked(false)
  }

  const onDragEnd = ({ source, destination }) => {
    // dropped outside the list
    if (!destination) return

    const startIndex = +source.droppableId
    const destinationIndex = +destination.droppableId

    const newState = [...state]
    let result

    // dropped in the same space it left
    if(startIndex === destinationIndex) {
      const items = dndReorder(state[startIndex], source.index, destination.index);

      newState[startIndex] = items;
    } else {
      result = dndMove(state[startIndex], state[destinationIndex], source, destination);

      newState[startIndex] = result[startIndex];
      newState[destinationIndex] = result[destinationIndex];
    }

    setState(newState.map(items => items.map(item => ({ ...item, correctStatus: undefined }))))
    setIsCorrect(false)
  }

  const onCheck = () => {
    const newState = [...state.map(items => items.map((item, index) => ({ ...item, correctStatus: (index === item.correctOrder) ? 'correct' : 'incorrect' })))]
    setState(newState)
    setIsChecked(true)
    setIsCorrect(newState.flat().every(item => item.correctStatus === 'correct'))
  }

  return(
    <div className="mx-auto max-w-7xl rounded-lg border-2 border-dashed border-gray-300 py-8 px-12 text-center">
      <DragDropContext onDragEnd={onDragEnd} onDragstart={onDragStart}>
        { state.map(( items, index ) => <Orderer.Space
                                         key={`droppable-space-${index}`}
                                         { ...{items, index} } /> )}
      </DragDropContext>

      <div className="">
        {
          isChecked &&
          <Alert
           dismissible
           className="mb-4"
           variant={ isCorrect ? "success" : "warning" }
           onClose={() => setIsChecked(false)}>
            {
              isCorrect
              ? "Nice job! That's the right order."
              : "Not quite! Try changing something."
            }
          </Alert>
        }

        {
          isChecked && isCorrect
          ? onComplete &&
            <Button
             onClick={onComplete}>
             Complete
            </Button>
          : <Button
             onClick={onCheck}>
             Check answer
            </Button>
        }
      </div>
    </div>
  )
}

Orderer.Space = ({ items, index }) => {
  return(
    <div className="mx-auto max-w-3xl pb-4">
    <LabelHeader className="mb-4">
     Drag and drop these items into the right order.
    </LabelHeader>
    <Droppable droppableId={`${index}`}>
      {( provided, snapshot ) => (
        <div
         ref={provided.innerRef}
         className={classNames(
           "flex flex-col space-y-2 relative"
         )}
         { ...provided.droppableProps }>
          { items.map((item, index) => <Orderer.Item key={item.id} { ...item } { ...{index} } />)}
          { provided.placeholder }
        </div>
      )}
    </Droppable>
    </div>
  )
}


Orderer.Item = ({ index, ...props }) => {
  if(props.showByDefault) return(
                            <div className="relative text-sm cursor-not-allowed rounded-md border border-gray-300 bg-gray-50 px-2 py-2 shadow-sm">
                              <div className="" dangerouslySetInnerHTML={ { __html: props.content }} />
                            </div>
                          )

  return(
     <Draggable
      key={props.id}
      draggableId={`${props.id}`}
      index={index}>
      {(provided, snapshot) => <PortalAwareItem {...{provided, snapshot, ...props}} />}
    </Draggable>
  )
}

const portal = document.createElement('div')
portal.classList.add('dnd-portal')
document.body.appendChild(portal)

const PortalAwareItem = ({ provided, snapshot, content, correctStatus, showByDefault }) => {
  const child = <div
                 ref={provided.innerRef}
                 className={classNames(
                  "text-sm text-center rounded-md border border-gray-300 cursor-grab hover:border-indigo-400 hover:bg-gray-50 bg-white px-2 py-2 shadow-sm",
                  correctStatus === 'correct' && "border-green-500",
                  correctStatus === 'incorrect' && "border-red-500",
                  snapshot.isDragging && "border-indigo-600 bg-gray-50"
                 )}
                 { ...provided.draggableProps }
                 { ...provided.dragHandleProps }>
                   <div className="" dangerouslySetInnerHTML={ {__html: content} } />
                </div>

  if(!snapshot.isDragging) return child

  return ReactDOM.createPortal(child, portal)
}

export default pure(Orderer)
