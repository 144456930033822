import React, { useState, useEffect } from 'react'
import { Button } from 'components/shared'
import AppContext from 'contexts/AppContext'
import { useFetch, useInterval } from 'hooks'
import Api from 'api2'
import Logo from 'images/techmap-logo.png'

const App = ({ apiUrl, formToken, orderId, graphUrl }) => {
  const [order, setOrder] = useState({ id: orderId })
  const [loading, setLoading] = useState(false)

  const API = new Api(apiUrl, formToken)

  const fetchOrder = async () => {
    setLoading(true)
    const order = await API.get(`/orders/${ orderId }`)
    setOrder(order)
    setLoading(false)
  }

  useEffect(() => {
    fetchOrder()
  }, [])

  useInterval(() => {
    fetchOrder()
  }, 5000)

  return(
    <div class="flex items-center justify-center w-full h-full">
      <div class="flex justify-center flex-col space-y-6 items-center space-x-1 text-sm text-gray-700 h-screen">         
          {
            order?.fulfilled
            ? (
              <>
                <div className='flex flex-col items-center justify-center space-y-4'>
                  <img src={Logo} className='mx-auto mb-4 h-12 w-12' />
                  Your payment was successful! Please check your email for confirmation.
                </div>

                <Button id="returnToTechMapButton" type='link' href={`${graphUrl}?payment-point=${order.paymentPoint}`}>
                  Return to TechMap
                </Button>
              </>
            ) : (
            <>
              <p>
                We are fulfilling your order! Please remain on this page until you have confirmation.
              </p>
              {
                loading && (
                  <div>
                    <svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
                      <path clip-rule='evenodd'
                        d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                        fill='currentColor' fill-rule='evenodd' />
                    </svg>
                    <p>Checking order status...</p>
                  </div>
                )
              }
            </>
            )
          }
      </div>
    </div>
  )
}

export default App