import React, { useState, useEffect, useContext, useMemo } from "react";
import AppContext from "contexts/AppContext";
import DatePicker from "tailwind-datepicker-react";
import {
  Label,
  Table,
  Avatar,
  Button,
  Spinner,
  RemoteSelectInput,
  RemoteFormattedEvent,
  DotBadge,
  CommandBar,
} from "components/shared";
import { Switch, Route, Link, useHistory } from "react-router-dom";
import { pluralize, percentage } from "utils";
import {
  EnvelopeIcon,
  UserIcon,
  UsersIcon,
  TrashIcon,
  MagnifyingGlassIcon,
  UserPlusIcon,
  BuildingOfficeIcon,
  UserGroupIcon,
  DocumentCheckIcon,
} from "@heroicons/react/24/solid";
import { useQuery, useFlash, useAuthorization, useMetaEnter } from "hooks";
import UserInCommandBar from "components/users/UserInCommandBar";
import UserPanel from "components/users/UserPanel";
import DestroyUserModal from "components/admin/DestroyUserModal";
import AddUserModal from "components/admin/AddUserModal";
import AddOrganizationModal from "components/admin/AddOrganizationModal";
import AddCohortModal from "components/admin/AddCohortModal";
import ManageLicensesModal from "components/admin/ManageLicensesModal";
import EmailUsersModal from "components/admin/EmailUsersModal";
import UserMailModal from "components/users/UserMailModal";
import { classNames } from "utils";
import moment from "moment";

const Manage = () => {
  const { authorized } = useAuthorization([
    "isAdmin",
    "isTrainer",
    "isManager",
  ]);
  if (!authorized) return null;

  const { API, currentUser } = useContext(AppContext);
  const { errorFlash } = useFlash();
  const history = useHistory();
  const { metaKey } = useMetaEnter();

  const [course, setCourse] = useState(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { query, queryContains, mutateQuery, pathWithQuery } = useQuery();

  const [filters, setFilters] = useState({});

  useEffect(() => {
    const { start } = query;

    if (!start) {
      const startDate = new Date(
        moment().subtract(6, "months").format("YYYY-MM-DD"),
      );

      startDate.setHours(0, 0, 0, 0);

      const defaultFilters = {
        start: startDate,
      };

      mutateQuery({ ...defaultFilters });
      setFilters({ ...defaultFilters });
    } else {
      setFilters({ ...{ start } });
    }
  }, []);

  useEffect(() => {
    if (query.start) {
      fetchUsers();
    }
  }, [query.start, query.cohort_id, query.organization_id]);

  const fetchCourse = async () => {
    const course = await API.get(`/courses/${currentUser.courseId}`, {
      onError: errorFlash,
    });

    if (course) setCourse(course);
  };

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchUsers = async ({ showLoading = true } = {}) => {
    if (showLoading) setIsLoading(true);

    const users = await API.get(`/manage`, {
      params: query,
      onError: errorFlash,
    });

    if (users) setUsers(users);
    if (showLoading) setIsLoading(false);
  };

  const isSynced = useMemo(() => queryContains(filters), [query, filters]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isSynced) mutateQuery(filters);
  };

  const certified = (user) => user.certified;
  const notStarted = (user) =>
    !certified(user) && user.activityAttempts.length < 2;

  const active = (user) =>
    !certified(user) &&
    !notStarted(user) &&
    user.events.filter((event) => {
      return (
        event.actor === "learner" &&
        Date.parse(event.createdAt) >
          Date.parse(moment().subtract(1, "months").format("YYYY-MM-DD"))
      );
    }).length > 0;
  const rest = (user) => !certified(user) && !notStarted(user) && !active(user);

  const userData = useMemo(() => {
    return {
      certified: users.filter(certified),
      notStarted: users.filter(notStarted),
      active: users.filter(active),
      rest: users.filter(rest),
    };
  }, [users]);

  const handleChangeFilters = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const [showDatePicker, setShowDatePicker] = useState({
    start: false,
  });

  const toggleDatePicker = ({ datepicker, state }) => {
    setShowDatePicker({ ...showDatePicker, [datepicker]: state });
  };

  const headers = [
    {
      key: "name",
      title: "Name",
      className: "w-3/12 pl-4 text-xs",
    },
    {
      key: "lastAction",
      title: "Last action",
      className: "w-1/5 text-xs",
    },
    {
      key: "lastTouch",
      title: "Last touch",
      className: "w-1/5 text-xs",
    },
    {
      key: "signedUp",
      title: "Signed up",
      className: "w-1/5 text-xs",
    },
  ];

  if (currentUser.isManager) {
    headers.push({
      key: "completion",
      title: "%",
      className: 'text-xs'
    });
  }

  if (currentUser.isTrainer || currentUser.isAdmin) {
    headers.push({
      key: "email",
    });
  }

  if (currentUser.isAdmin) {
    headers.push({
      key: "destroy",
    });
  }

  const columns = (user) => {
    const unviewedNotes = user.notes.filter(
      (note) =>
        !note.viewers.map((viewer) => viewer.id).includes(currentUser.id),
    );

    const unviewedEmails = user.sentEmails.filter(
      (email) =>
        !email.readBy.map((viewer) => viewer.id).includes(currentUser.id),
    );

    const learnerEvents = user.events.filter(
      (event) => event.actor === "learner",
    );

    const lastLearnerEvent = learnerEvents[learnerEvents.length - 1];

    const otherEvents = user.events.filter(
      (event) => event.actor !== "learner",
    );

    const lastOtherEvent = otherEvents[otherEvents.length - 1];

    const completion =
      Math.min(Math.round((user.activityAttempts.filter((a) => a.status === "complete").length *
        100.0) /
      course?.shownActivities.length), 100.0);

    const basicColumns = [
      {
        key: "name",
        children: (
          <Link
            className="hover:text-indigo-600 relative text-xs"
            to={pathWithQuery(null, { user: user.id })}
          >
            {unviewedNotes.length > 0 && (
              <DotBadge
                className="-left-2 -top-3 border border-2 border-white"
                value={unviewedNotes.length}
              />
            )}
            <Avatar
              {...user}
              size="tiny"
              className={classNames(
                "mr-3",
                user.selectedPlanType === "paid" && "ring-2 ring-indigo-600",
              )}
            />
            {user.id === currentUser.id ? (
              "You"
            ) : user.name.length > 1 ? (
              user.name
            ) : (
              <span className="font-normal text-gray-400 italic">
                {user.email}
              </span>
            )}
          </Link>
        ),
        className: "font-medium text-gray-900 pl-4 truncate",
      },
      {
        key: "lastAction",
        children: (
          <RemoteFormattedEvent id={lastLearnerEvent?.id} isCompact isTiny />
        ),
      },
      {
        key: "lastTouch",
        children: (
          <RemoteFormattedEvent id={lastOtherEvent?.id} isCompact isTiny />
        ),
      },
      {
        key: "signedUp",
        children: (
          <span className="text-xs">{moment(user.createdAt).fromNow()}</span>
        ),
      },
    ];

    const managerColumns = currentUser.isManager
      ? [
          {
            key: "completion",
            children: <span className="text-xs">{completion}%</span>,
          },
        ]
      : [];

    const trainerColumns = currentUser.isTrainer
      ? [
          {
            key: "email",
            children: (
              <Link
                to={pathWithQuery(null, { userMail: user.id })}
                className="text-gray-200 hover:text-gray-600 cursor-pointer text-right relative"
              >
                {unviewedEmails.length > 0 && (
                  <DotBadge
                    className="right-auto -left-4 -top-3"
                    size="xs"
                    value={unviewedEmails.length}
                  />
                )}
                <EnvelopeIcon className="h-5 w-5 inline" />
              </Link>
            ),
            className: "text-right pr-4",
          },
        ]
      : [];

    const adminColumns = currentUser.isAdmin
      ? [
          {
            key: "destroy",
            children: (
              <Link
                to={pathWithQuery(null, { destroyUserId: user.id })}
                className="text-gray-200 hover:text-gray-600 cursor-pointer text-right"
              >
                <TrashIcon className="h-5 w-5 inline" />
              </Link>
            ),
          },
        ]
      : [];

    return [...basicColumns, ...managerColumns, ...trainerColumns, ...adminColumns];
  };

  const [showConversationModal, setShowConversationModal] = useState(false);

  const handleChangeUser = (user) => {
    const newUsers = users.map((oldUser) =>
      oldUser.id === user.id ? user : oldUser,
    );

    setUsers(newUsers);
  };

  return (
    <div>
      <div className="flex justify-center items-center space-x-4 px-6 py-4">
        <div className="text-gray-900 text-sm">
          Showing{" "}
          {isLoading ? (
            <Spinner className="inline text-gray-900 mx-1" />
          ) : (
            <span className="font-semibold">{users.length}</span>
          )}{" "}
          {pluralize(users.length, "user", false)}
          {(currentUser.isAdmin || currentUser.isTrainer) && " from"}
        </div>
        {(currentUser.isAdmin || currentUser.isTrainer) && (
          <>
            <div>
              <RemoteSelectInput
                selectClassName="w-48"
                title="Organization"
                defaultOption="All organizations"
                fetchPath="organizations"
                optionValue={"id"}
                optionLabel={"name"}
                onChange={(e) =>
                  handleChangeFilters({ organization_id: e.target.value })
                }
              />
            </div>
            <div className="text-gray text-sm">in</div>
            <div>
              <RemoteSelectInput
                selectClassName="w-48"
                title="Cohort"
                defaultOption="All cohorts"
                fetchPath="cohorts"
                optionValue={"id"}
                optionLabel={"title"}
                onChange={(e) =>
                  handleChangeFilters({ cohort_id: e.target.value })
                }
              />
            </div>
          </>
        )}

        <div className="text-gray text-sm">active since</div>
        <div>
          {isLoading ? (
            <div>
              <DatePicker
                show={false}
                options={{
                  defaultDate: filters.start,
                  theme: {
                    input: "bg-gray-100",
                  },
                }}
              />
            </div>
          ) : (
            <DatePicker
              onChange={(date) => {
                const newDate = new Date(moment(date).format("YYYY-MM-DD"));
                newDate.setHours(0, 0, 0, 0);
                handleChangeFilters({ start: newDate });
              }}
              show={showDatePicker.start}
              setShow={(state) =>
                toggleDatePicker({ datepicker: "start", state })
              }
              options={{
                todayBtn: true,
                defaultDate: filters.start,
                maxDate: new Date(),
                theme: {
                  input: "bg-white",
                },
              }}
            />
          )}
        </div>

        <Button disabled={isSynced} onClick={handleSubmit}>
          Update
        </Button>

        <Button
          size="xs"
          variant="secondary"
          className="opacity-60"
          Icon={MagnifyingGlassIcon}
          onClick={(e) => {
            e.preventDefault();

            const keyPressEvent = new KeyboardEvent("keydown", {
              key: "k",
              metaKey: true,
            });

            document.dispatchEvent(keyPressEvent);
          }}
        >
          {metaKey} + K
        </Button>
      </div>

      {currentUser.isAdmin && (
        <div className="flex justify-center items-center space-x-4 px-6 py-4">
          <Button
            type="routeLink"
            to={pathWithQuery(null, { addUser: true })}
            variant="secondary"
            size="xs"
            Icon={UserPlusIcon}
          >
            Add user
          </Button>

          <Button
            type="routeLink"
            to={pathWithQuery(null, { addOrganization: true })}
            variant="secondary"
            size="xs"
            Icon={BuildingOfficeIcon}
          >
            Add organization
          </Button>

          <Button
            type="routeLink"
            to={pathWithQuery(null, { addCohort: true })}
            variant="secondary"
            size="xs"
            Icon={UserGroupIcon}
          >
            Add cohort
          </Button>

          <Button
            type="routeLink"
            to={pathWithQuery(null, { manageLicenses: true })}
            variant="secondary"
            size="xs"
            Icon={DocumentCheckIcon}
          >
            Manage licenses
          </Button>

          <Button
            type="routeLink"
            to={pathWithQuery(null, { emailUsers: users.map((u) => u.id) })}
            variant="secondary"
            size="xs"
            Icon={EnvelopeIcon}
            disabled={isLoading}
          >
            Email users
          </Button>
        </div>
      )}

      {isLoading ? null : (
        <div className="grid grid-cols-2 gap-x-4 gap-y-8 px-6 py-4">
          <div>
            <Label className="font-semibold mb-1 px-3 flex justify-between">
              <span>Certified</span>

              <div>
                {userData.certified.length === 1 ? (
                  <UserIcon className="h-4 w-4 inline" />
                ) : (
                  <UsersIcon className="h-4 w-4 inline" />
                )}{" "}
                {userData.certified.length}
                <span className="text-gray-600 font-normal text-xs italic ml-1">
                  (
                  {percentage(userData.certified.length, users.length, {
                    precision: 1,
                  })}
                  %)
                </span>
              </div>
            </Label>
            <Table
              className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
              headers={headers}
            >
              {userData.certified.map((user) => (
                <Table.Row
                  className="h-[69px]"
                  key={user.id}
                  columns={columns(user)}
                />
              ))}
            </Table>
          </div>

          <div>
            <Label className="font-semibold mb-1 px-3 flex justify-between">
              <span>Active</span>

              <div>
                {userData.active.length === 1 ? (
                  <UserIcon className="h-4 w-4 inline" />
                ) : (
                  <UsersIcon className="h-4 w-4 inline" />
                )}{" "}
                {userData.active.length}
                <span className="text-gray-600 font-normal text-xs italic ml-1">
                  (
                  {percentage(userData.active.length, users.length, {
                    precision: 1,
                  })}
                  %)
                </span>
              </div>
            </Label>
            <Table
              className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
              headers={headers}
            >
              {userData.active.map((user) => (
                <Table.Row
                  className="h-[69px]"
                  key={user.id}
                  columns={columns(user)}
                />
              ))}
            </Table>
          </div>

          <div>
            <Label className="font-semibold mb-1 px-3 flex justify-between">
              <span>Haven't started</span>

              <div>
                {userData.notStarted.length === 1 ? (
                  <UserIcon className="h-4 w-4 inline" />
                ) : (
                  <UsersIcon className="h-4 w-4 inline" />
                )}{" "}
                {userData.notStarted.length}
                <span className="text-gray-600 font-normal text-xs italic ml-1">
                  (
                  {percentage(userData.notStarted.length, users.length, {
                    precision: 1,
                  })}
                  %)
                </span>
              </div>
            </Label>
            <Table
              className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
              headers={headers}
            >
              {userData.notStarted.map((user) => (
                <Table.Row
                  className="h-[69px]"
                  key={user.id}
                  columns={columns(user)}
                />
              ))}
            </Table>
          </div>

          <div>
            <Label className="font-semibold mb-1 px-3 flex justify-between">
              <span>Disengaged</span>

              <div>
                {userData.rest.length === 1 ? (
                  <UserIcon className="h-4 w-4 inline" />
                ) : (
                  <UsersIcon className="h-4 w-4 inline" />
                )}{" "}
                {userData.rest.length}
                <span className="text-gray-600 font-normal text-xs italic ml-1">
                  (
                  {percentage(userData.rest.length, users.length, {
                    precision: 1,
                  })}
                  %)
                </span>
              </div>
            </Label>
            <Table
              className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
              headers={headers}
            >
              {userData.rest.map((user) => (
                <Table.Row
                  className="h-[69px]"
                  key={user.id}
                  columns={columns(user)}
                />
              ))}
            </Table>
          </div>
        </div>
      )}

      <UserPanel onChange={() => fetchUsers({ showLoading: false })} />

      {currentUser.isAdmin && <DestroyUserModal onChange={fetchUsers} />}

      {(currentUser.isAdmin || currentUser.isTrainer) && (
        <UserMailModal onChange={() => fetchUsers({ showLoading: false })} />
      )}

      {currentUser.isAdmin && <AddUserModal onChange={fetchUsers} />}
      {currentUser.isAdmin && <AddOrganizationModal />}
      {currentUser.isAdmin && <AddCohortModal />}
      {currentUser.isAdmin && <ManageLicensesModal />}
      {currentUser.isAdmin && <EmailUsersModal />}

      <CommandBar
        itemType="user"
        path="/users"
        comparisonProperty="id"
        groupFunction={(user) => user.lastName?.[0]}
        labelFunction={(user) =>
          `${user.name} ${user.email}${
            user.organization ? ` ${user.organization.name}` : ""
          }`
        }
        ItemComponent={UserInCommandBar}
        onSelectItem={(user) =>
          history.push(pathWithQuery(null, { user: user.id }))
        }
      />
    </div>
  );
};

export default Manage;
