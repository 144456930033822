import React from "react";
import { Modal } from "components/shared";
import { FolderIcon } from "@heroicons/react/24/solid";
import { useQuery } from "hooks";
import AddStageFromLibrary from "components/design/AddStageFromLibrary";

const AddStageFromLibraryModal = ({ activity, ...props }) => {
  const { watchQuery, deleteQuery } = useQuery();

  const open = watchQuery("addStageFromLibrary");
  const onClose = () => {
    deleteQuery("addStageFromLibrary");
    if (props.onChange) props.onChange();
  };

  return (
    <Modal
      size="lg"
      dismissible
      open={!!open}
      onClose={onClose}
      Icon={FolderIcon}
      title="Add stage from library"
    >
      <AddStageFromLibrary
        activity={activity}
        onClose={onClose}
        onAddStage={props.onAddStage}
      />
    </Modal>
  );
};

export default AddStageFromLibraryModal;
