import React, { useState, useEffect, useRef, useContext } from "react";
import AppContext from "contexts/AppContext";
import Chat from "components/bloom/Chat";
import Coaching from "components/bloom/Coaching";

const PersonalityChat = ({ stage, personality, attachments, ...props }) => {
  const { currentUser, streamingApiUrl } = useContext(AppContext);
  const [messages, setMessages] = useState([]);
  const [isRespondingToUser, setIsRespondingToUser] = useState(true);

  useEffect(() => {
    if (personality) {
      setMessages([]);
      setFeedbacks([]);

      let i = 0;

      let timeout1, timeout2, timeout3;

      const firstMessage = personality.firstMessage;

      const loopText = () => {
        timeout1 = setTimeout(() => {
          if (i < firstMessage.length) {
            i++;

            const newMessage = {
              id: 1,
              from: personality?.name,
              text: firstMessage.slice(0, i),
            };

            setMessages([newMessage]);

            loopText();
          } else {
            setIsRespondingToUser(false);
          }
        }, 20);
      };

      timeout2 = setTimeout(() => {
        setMessages([
          {
            id: 1,
            from: personality?.name,
            text: "...",
          },
        ]);
      }, 300);

      timeout3 = setTimeout(() => {
        loopText();
      }, 1000);

      return () => {
        clearTimeout(timeout1);
        clearTimeout(timeout2);
        clearTimeout(timeout3);
      };
    }
  }, [personality]);

  const [feedbacks, setFeedbacks] = useState([]);
  const [text, setText] = useState("");

  const inputRef = useRef(null);

  const lastMessageWasPersonality =
    messages[messages.length - 1]?.from === personality?.name;
  const canSubmit = lastMessageWasPersonality && !isRespondingToUser;

  const generateNextMessage = async (newMessages) => {
    const response = await fetch(`${streamingApiUrl}/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        messages: newMessages,
        personality: personality,
        user: currentUser.bio ? currentUser.bio : "a technical recruiter",
      }),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    // This data is a ReadableStream
    const data = response.body;
    if (!data) {
      return;
    }

    const reader = data.getReader();
    const decoder = new TextDecoder();

    let done = false;
    const messageId = Math.max(...newMessages.map((message) => message.id)) + 1;

    let responseText = "";

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);

      setMessages([
        ...newMessages,
        {
          id: messageId,
          from: personality.name,
          text: (responseText += chunkValue),
        },
      ]);

      if (done) {
        setIsRespondingToUser(false);
      }
    }
  };

  const generateFeedback = async (newMessages) => {
    const feedbackId =
      feedbacks.length > 0
        ? Math.max(...feedbacks.map((feedback) => feedback.id)) + 1
        : 1;

    setFeedbacks([
      ...feedbacks,
      {
        id: feedbackId,
        text: "...",
      },
    ]);

    const response = await fetch(`${streamingApiUrl}/coach`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        messages: newMessages,
        personality: personality,
        user: currentUser.bio ? currentUser.bio : "a technical recruiter",
      }),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    // This data is a ReadableStream
    const data = response.body;
    if (!data) {
      return;
    }

    const reader = data.getReader();
    const decoder = new TextDecoder();
    let done = false;

    let responseText = "";

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);

      setFeedbacks([
        ...feedbacks,
        {
          id: feedbackId,
          text: (responseText += chunkValue),
        },
      ]);

      document
        .getElementById(`feedback-${feedbackId}`)
        ?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  const handleSubmit = async (text) => {
    setIsRespondingToUser(true);

    const messageId = Math.max(...messages.map((message) => message.id)) + 1;

    const newMessages = [
      ...messages,
      {
        id: messageId,
        from: "you",
        text: text,
        attachments: attachments,
      },
    ];

    setMessages(newMessages);
    props.onSubmit(text);

    if (stage.conversationCoachEnabled) {
      generateFeedback(newMessages);
    }

    setTimeout(() => {
      setMessages([
        ...newMessages,
        {
          id: messageId + 1,
          from: personality.name,
          text: "...",
        },
      ]);

      generateNextMessage(newMessages);
    }, 300);
  };

  return (
    <div className="flex grow">
      <div className="h-full overflow-hidden grow flex flex-col justify-end">
        <Chat
          ref={inputRef}
          onSubmit={handleSubmit}
          onStartEdit={props.onStartEdit}
          messages={messages}
          attachments={attachments}
          onUnattach={props.onUnattach}
          canSubmit={canSubmit}
          isRespondingToUser={isRespondingToUser}
          user={{ name: personality?.name }}
        />
      </div>
      {stage.conversationCoachEnabled && (
        <div className="h-full overflow-hidden shrink w-96">
          <Coaching feedbacks={feedbacks} />
        </div>
      )}
    </div>
  );
};

export default PersonalityChat;
