import React, { useEffect, useContext, useRef, useState } from 'react'
import { Button, Overlay, Tooltip, Spinner } from 'react-bootstrap'
import AppContext from 'contexts/AppContext'
import { post } from 'api'
import { Alert } from 'components/shared'

const SetupProject = ({ activity, onClick, onComplete }) => {
  const { apiUrl, formToken, currentUser } = useContext(AppContext)
  const target = useRef(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const toggleShowTooltip = () => setShowTooltip(!showTooltip)
  const [data, setData] = useState({ isLoading: false, errorMessage: null })

  const handleSuccess = (response) => {
    setData({ ...data, isLoading: false })
    onComplete()
  }

  const handleError = (error, response) => setData({ ...data, errorMessage: error, isLoading: false })

  const duplicateRepo = (e) => {
    e.preventDefault()

    setData({ ...data, isLoading: true })

    onClick()
  }

  return(
    <>
      { data.errorMessage && <Alert type="danger" message={data.errorMessage} /> }
      <Overlay target={target.current} show={showTooltip} placement="left">
        <Tooltip>
          Sync <strong>{ currentUser.githubUsername }/{ activity.duplicateRepoTitle }</strong> with Github.
        </Tooltip>
      </Overlay>
      <Button ref={target} onMouseOver={toggleShowTooltip} onMouseLeave={toggleShowTooltip} onClick={duplicateRepo}>
        { data.isLoading && <Spinner animation="border" size="sm" className="mr05" /> }
        Sync project
      </Button>
    </>
  )
}

export default SetupProject
