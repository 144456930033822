import React, { useState, useEffect, useContext } from "react";
import AppContext from "contexts/AppContext";
import { motion } from "framer-motion";
import PersonalityForm from "components/bloom/PersonalityForm";
import Options from "components/bloom/Options";
import PersonalityChat from "components/bloom/PersonalityChat";
import ViewOptionModal from "components/bloom/ViewOptionModal";
import { Hint } from "components/typography";

export default function BloomActivity({ user, stage, ...props }) {
  const { editorMode } = useContext(AppContext);

  const [personality, setPersonality] = useState(stage.personalities[0]);
  const [isEditing, setIsEditing] = useState(
    editorMode && !stage.personalities[0],
  );

  useEffect(() => {
    setIsEditing(editorMode && !personality);
  }, [editorMode]);

  const handleUpdatePersonality = (response) => {
    setPersonality(response);
    setIsEditing(false);
    props.onUpdate();
  };

  const [attachments, setAttachments] = useState([]);

  const attach = (option) => {
    setAttachments([option]);
  };

  const unattach = (option) => {
    setAttachments([
      ...attachments.filter((attachment) => attachment.id !== option.id),
    ]);
  };

  const [options, setOptions] = useState([]);

  const OPTION_COLORS = [
    "gray",
    "red",
    "yellow",
    "green",
    "blue",
    "indigo",
    "purple",
    "pink",
  ];

  useEffect(() => {
    setOptions(
      personality?.options.map((option, index) => ({
        ...option,
        color: OPTION_COLORS[index % OPTION_COLORS.length],
      })),
    );
  }, [personality?.options, attachments]);

  if (isEditing) {
    return (
      <PersonalityForm
        personality={personality}
        stage={stage}
        onSubmit={handleUpdatePersonality}
      />
    );
  }

  if (!personality)
    return (
      <Hint center className="p-8">
        Couldn't load activity
        <br />
        No personality data, please contact support.
      </Hint>
    );

  return (
    <div className="h-full flex">
      <Options
        title={personality.optionsTitle}
        options={options}
        attachments={attachments}
        onAttach={attach}
        onUnattach={unattach}
      />
      <PersonalityChat
        stage={stage}
        personality={personality}
        attachments={attachments}
        onUnattach={unattach}
        onSubmit={() => setAttachments([])}
        onStartEdit={() => setIsEditing(true)}
      />

      <ViewOptionModal />
    </div>
  );
}
