import React, { Fragment, useContext, useState, useMemo } from "react";
import AppContext from "contexts/AppContext";
import { Tooltip, Button, Timestamp } from "components/shared";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
  ExclamationCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import Question from "components/questions/Question";
import QuestionTitle from "components/questions/QuestionTitle";
import { cn, toSentence, duration, fromDuration } from "utils";
import { useAPI } from "hooks";

const QuestionInAccordion = ({
  question,
  user,
  stage,
  currentQuestion,
  ...props
}) => {
  const { currentUser, editorMode } = useContext(AppContext);
  const { isLoading, updateQuestion, deleteActivityQuestion } = useAPI();
  const [localQuestion, setLocalQuestion] = useState(question);

  const isOpen = useMemo(
    () => editorMode || currentQuestion?.id === localQuestion.id,
    [currentQuestion, editorMode],
  );

  return (
    <div className="relative">
      {editorMode && (currentUser.isAdmin || currentUser.isCourseDesigner) && (
        <div className="flex items-center justify-between border-t border-gray-200 py-2 px-4">
          <Timestamp
            editable
            onChange={(timestamp) =>
              setLocalQuestion({ ...localQuestion, timestamp })
            }
          >
            {localQuestion.timestamp}
          </Timestamp>
          <div className="flex items-center space-x-2">
            {localQuestion.timestamp !== question.timestamp && (
              <Button
                type="pill"
                onClick={() => {
                  updateQuestion(
                    localQuestion.id,
                    {
                      timestamp: fromDuration(localQuestion.timestamp),
                    },
                    (question) => {
                      setLocalQuestion(question);
                      if (props.onUpdateTimestamp) props.onUpdateTimestamp();
                    },
                  );
                }}
                disabled={isLoading}
              >
                Update from {duration(question.timestamp - 1)} to{" "}
                {localQuestion.timestamp}
              </Button>
            )}
            {props.allowDelete && (
              <Button
                type="pill"
                Icon={TrashIcon}
                onClick={() =>
                  deleteActivityQuestion(localQuestion.id, stage.slug, props.onDelete)
                }
                disabled={isLoading}
              >
                Delete
              </Button>
            )}
          </div>
        </div>
      )}
      <div
        id={`question-${props.index}`}
        className={cn(
          "AccordionItemHeader w-full @container/accordion flex w-full justify-between items-start px-6 py-4 text-left text-sm text-gray-900 bg-gray-50 cursor-pointer hover:bg-gray-100 border-t border-b border-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75",
          isOpen && "bg-gray-100 hover:bg-gray-200",
          editorMode && "cursor-default",
        )}
        onClick={() => props.onClickHeader(localQuestion)}
      >
        <div className="flex items-start w-[90%]">
          {localQuestion.flaggers?.length > 0 ? (
            <Tooltip
              text={`${toSentence(
                localQuestion.flaggers.map((u) => u.firstName),
              )} ${
                localQuestion.flaggers.length > 1 ? "have" : "has"
              } requested you revise your answer.`}
            >
              <ExclamationCircleIcon
                className={cn(
                  "h-5 w-5 mr-2 flex-shrink-0 text-yellow-500",
                )}
              />
            </Tooltip>
          ) : localQuestion.userHasAnswered ? (
            <CheckCircleIcon
              className={cn(
                "h-5 w-5 mr-2 flex-shrink-0 text-green-500",
              )}
            />
          ) : (
            <QuestionMarkCircleIcon
              className={cn(
                "h-5 w-5 mr-2 flex-shrink-0 text-gray-400",
              )}
            />
          )}
          <QuestionTitle
            question={localQuestion}
            beforeChange={props.onStartUpdate}
            onChange={props.onUpdate}
            onError={props.onErrorUpdate}
          />
        </div>
        <ChevronUpIcon
          className={cn(
            "h-5 w-5 text-gray-400 flex-shrink-0",
            isOpen && "rotate-180 transform",
          )}
        />
      </div>

      {isOpen && (
        <div className="AccordionItemBody bg-gray-50 border-b border-gray-200 relative pb-4">
          <Question
            {...localQuestion}
            small
            showTitle={false}
            includeAiCoach={props.includeAiCoach}
            includeOtherAnswers={props.includeOtherAnswers}
            user={user}
            onAdvance={props.onAdvance}
            extraResponseParams={props.extraResponseParams}
            onStartUpdate={props.onStartUpdate}
            onUpdate={(question) => {
              setLocalQuestion(question);
              if (props.onUpdate) props.onUpdate(question);
            }}
            onErrorUpdate={props.onErrorUpdate}
            stage={localQuestion.stage ?? stage}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionInAccordion;
