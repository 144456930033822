import React, { useState, useContext } from 'react'
import { Button } from 'components/shared'
import { post } from 'api'
import AppContext from 'contexts/AppContext'

const CopyLearnerRepo = ({ activity, onSuccess }) => {
  const { apiUrl, formToken } = useContext(AppContext)
  const [data, setData] = useState({ isLoading: false, errorMessage: null })

  const handleSuccess = (response) => {
    setData({ ...data, isLoading: false })
    onSuccess(response)
  }

  const handleError = (error, response) => {
    setData({ ...data, errorMessage: error, isLoading: false })
  }

  const handleClick = (e) => {
    e.preventDefault()

    setData({ ...data, isLoading: true })

    post(`${ apiUrl }/activities/${ activity.id }/repos/actions/setup-learner-repo`, { formToken }, handleSuccess, handleError)
  }

  return(
    <>
    <Button size={'xs'} onClick={handleClick} disabled={data.isLoading}>
      Fix
    </Button>
    {
      data.errorMessage &&
      <>{data.errorMessage}</>
    }
    </>
  )
}

export default CopyLearnerRepo