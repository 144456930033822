import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react'
import { get } from 'api'

import AuthenticateAndUpdateUserFromGithub from 'components/github/Authentication'
import Fix from 'components/github/Fix'

import { Overlay, Tooltip } from "react-bootstrap"

import ProjectStatusIndicator from 'components/github/ProjectStatusIndicator'

import AppContext from 'contexts/AppContext'

const ProjectStatus = ({ active, url, children, order, current, onOutcome, fix }) => {
  const { STATUSES } = useContext(AppContext)
  const [response, setResponse] = useState(undefined)
  const [status, setStatus] = useState(STATUSES.waiting)

  const handleResponse = (response) => {
    setResponse(response)
    setStatus(STATUSES[response.status])

    if(onOutcome) return onOutcome(response)
  }

  const handleError = (response) => {
    setResponse(response)
    setStatus(STATUSES.error)

    if(onOutcome) return onOutcome({ status: STATUSES.error })
  }

  useEffect(() => {
    if(active && (order === current)) {
      setStatus(STATUSES.loading)
      get(url, handleResponse, handleError)
    }
  }, [order, current, active])

  return(
    <div className="ProjectStatus">
      <div className="ProjectStatusWrapper">
        <ProjectStatusIndicator
         status={status}
         error={response ? response.error : null}
         message={response ? response.message : null} />
        <small>{ children }</small>
      </div>

      { status === STATUSES.failure && fix }
    </div>
  )
}

const ProjectBar = React.forwardRef(({ active, activity, forceShow, style, onReady }, ref) => {
  const { currentUser, apiUrl, STATUSES } = useContext(AppContext)

  const { projectBarFull, projectBarHeader } = ref.current

  const [show, setShow] = useState(forceShow ? forceShow : false)
  const toggleShow = () => setShow(!show)

  useEffect(() => {
    if(forceShow) setShow(true)
  }, [forceShow])

  const [currentIndex, setCurrentIndex] = useState(0)
  const forceRerender = () => setCurrentIndex(-1)

  const STATUSES_TO_MESSAGES = {
    loading: 'Loading project details...',
    failure: 'Project not set up correctly. Click the chevron for more.',
    success: 'Project successfully set up.',
    error: 'Error setting up the project.'
  }

  const [status, setStatus] = useState(active ? STATUSES.loading : STATUSES.waiting)

  // uses all statuses to pipeline the actions
  const [pipeline, setPipeline] = useState([
    [STATUSES.waiting],
    [STATUSES.waiting],
    [STATUSES.waiting, STATUSES.waiting, STATUSES.waiting, STATUSES.waiting],
    [STATUSES.waiting]
  ])

  const reportOutcome = ({ status }) => {
    setPipeline((prevState) => {
      const stageLength = prevState[currentIndex].length
      prevState[currentIndex] = [...prevState[currentIndex], status].slice(-1 * stageLength)
      return [...prevState]
    })

    if(BAD_STATUSES.includes(status)) setShow(true)
  }

  useEffect(() => {
    if(pipeline[currentIndex].every(status => status === STATUSES.success))
      setCurrentIndex(currentIndex + 1)

    const overallStatus = active ? projectStatus(pipeline.flat()) : STATUSES.waiting

    setStatus(overallStatus)
  }, [pipeline, active])

  useEffect(() => {
    if(currentIndex < 0) setCurrentIndex(0)
  }, [currentIndex])

  const [barStyles, setBarStyles] = useState(style)

  useLayoutEffect(() => {
    const fullHeight = style.height ? style.height
                                    : projectBarFull.current ? projectBarFull.current.offsetHeight : 0
    const headerHeight = projectBarHeader.current ? projectBarHeader.current.offsetHeight : 0

    setBarStyles({
      ...style,
      transform: `translateY(${show ? 0 : (fullHeight - headerHeight)}px)`
    })
  }, [projectBarHeader, projectBarFull, show, style])

  const target = useRef(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const projectStatus = (statuses) => {
    if(statuses.every(status => status === STATUSES.success)) return STATUSES.success
    if(statuses.some(status => status === STATUSES.error)) return STATUSES.error
    if(statuses.some(status => status === STATUSES.failure)) return STATUSES.failure

    return STATUSES.loading
  }

  const BAD_STATUSES = [STATUSES.error, STATUSES.failure, STATUSES.waiting]

  const PROJECT_STATUS_TO_RESPONSES = {
    success: { message: 'Project is successfully set up.' },
    error: { error: 'project_error', message: 'There was an error setting up the project. Click the chevron for more information.' },
    failure: { error: 'project_failure', message: "Project has not been set up yet." },
    waiting: { error: 'project_not_set_up', message: "Click the 'setup' button to initialize this project." }
  }

  return(
    <div
     ref={projectBarFull}
     className={`ProjectBar ${ show ? 'ProjectBar--show' : '' }`}
     style={barStyles}>
      <h6 ref={projectBarHeader} className="ProjectBarHeader">
        <div className="ProjectBarHeader__Heading">
          <ProjectStatusIndicator
           status={status}
           error={BAD_STATUSES.includes(status) && PROJECT_STATUS_TO_RESPONSES[status].error}
           message={BAD_STATUSES.includes(status) && PROJECT_STATUS_TO_RESPONSES[status].message} />
          Project sync status
        </div>
        <div className="ProjectStatusHeader__Toggle">
          <div className="btn-options Button--light" onClick={toggleShow} type="button">
            <i className="material-icons">{ show ? 'expand_less' : 'expand_more' }</i>
          </div>
        </div>
      </h6>

      <ProjectStatus
       active={active}
       order={0}
       current={currentIndex}
       url={`${apiUrl}/activities/${activity.id}/repos/statuses/access-token-valid`}
       onOutcome={reportOutcome}
       fix={<AuthenticateAndUpdateUserFromGithub
             buttonText={'Fix'}
             onSuccess={forceRerender} />}>
        You have a valid access token.
      </ProjectStatus>

      <ProjectStatus
       active={active}
       order={1}
       current={currentIndex}
       url={`${apiUrl}/activities/${activity.id}/repos/statuses/learner-repo-exists`}
       onOutcome={(response) => { reportOutcome(response); onReady() }}
       fix={<Fix autofix
             url={`${ apiUrl }/activities/${ activity.id }/repos/actions/setup-learner-repo`}
             onSuccess={forceRerender} />}>
        Repo copied to { currentUser.githubUsername }/{ activity.duplicateRepoTitle }.
      </ProjectStatus>

      <ProjectStatus
       active={active}
       order={2}
       current={currentIndex}
       url={`${apiUrl}/activities/${activity.id}/repos/statuses/learner-repo-main-branch-protected`}
       onOutcome={reportOutcome}
       fix={<Fix autofix
             url={`${ apiUrl }/activities/${ activity.id }/repos/actions/protect-main-branch`}
             onSuccess={forceRerender} />}>
        <code>main</code> branch protected.
      </ProjectStatus>

      <ProjectStatus
       active={active}
       order={2}
       current={currentIndex}
       url={`${apiUrl}/activities/${activity.id}/repos/statuses/learner-hooks-synced`}
       onOutcome={reportOutcome}
       fix={<Fix autofix
             url={`${ apiUrl }/activities/${ activity.id }/repos/actions/setup-learner-hooks`}
             onSuccess={forceRerender} />}>
        Repo hooks synced.
      </ProjectStatus>

      <ProjectStatus
       active={active}
       order={2}
       current={currentIndex}
       url={`${apiUrl}/activities/${activity.id}/repos/statuses/collaborators-synced`}
       onOutcome={reportOutcome}
       fix={<Fix autofix
             url={`${ apiUrl }/activities/${ activity.id }/repos/actions/setup-collaborators`}
             onSuccess={forceRerender} />}>
        Collaborators synced.
      </ProjectStatus>


      <ProjectStatus
       active={active}
       order={2}
       current={currentIndex}
       url={`${apiUrl}/activities/${activity.id}/repos/statuses/learner-project-exists`}
       onOutcome={reportOutcome}
       fix={<Fix autofix
             url={`${ apiUrl }/activities/${ activity.id }/repos/actions/setup-learner-project`}
             onSuccess={forceRerender} />}>
        Project exists.
      </ProjectStatus>

      <ProjectStatus
       active={active}
       order={3}
       current={currentIndex}
       url={`${apiUrl}/activities/${activity.id}/repos/statuses/learner-project-synced`}
       onOutcome={reportOutcome}
       fix={<Fix autofix
             url={`${ apiUrl }/activities/${ activity.id }/repos/actions/setup-learner-project`}
             onSuccess={forceRerender} />}>
        Project issues synced.
      </ProjectStatus>
    </div>
  )
})

ProjectBar.defaultProps = {
  style: {}
}

export default ProjectBar
