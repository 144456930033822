import React, { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'

const ActivityControls = (props) => {
  const location = useLocation()

  const isOverview = !location.pathname.includes('/issues/')
  const pathParts = location.pathname.split("/")
  const issueSlug = pathParts[pathParts.length - 1]
  const currentIssue = props.activity.issues.find(({ slug }) => slug === issueSlug)

  const handleBackToGraph = (e) => {
    e.preventDefault()

    props.onHidePanel('/')
  }

  return(
    <div className="flex items-center justify-between w-full text-sm font-medium">
      <div className="pl-6">
        <span className="text-gray-400 hover:text-gray-800 cursor-pointer" onClick={handleBackToGraph}>
          Graph
        </span>
        <span className="inline-block mr-1 ml-1 text-gray-200">/</span>
        {
          isOverview
          ? <span className="text-gray-800">
              { props.activity.title }
            </span>
          : <>
              <Link className="text-gray-400 hover:text-gray-800 cursor-pointer" to={props.activityRootUrl}>
                { props.activity.title }
              </Link>
              <span className="inline-block mr-1 ml-1 text-gray-200">/</span>
              <span className="text-gray-800">
                { currentIssue?.title || "No issues" }
              </span>
            </>
        }
      </div>
    </div>
  )
}

export default ActivityControls