import React, { useContext, useState } from 'react'
import AppContext from 'contexts/AppContext'
import { Hint, Indicator, Avatar } from 'components/shared'
import moment from 'moment'
import { classNames } from 'utils'

const UserNote = ({ id, text, createdAt, updatedAt, from, about, viewers, ...props }) => {
  const { API, currentUser } = useContext(AppContext)

  const [viewed, setViewed] = useState(viewers.map(viewer => viewer.id).includes(currentUser.id))

  const viewNote = () => {
    const body = {
      user_note_id: id,
      user_id: currentUser.id
    }

    if(!viewed) {
      API.post(`/note_views`, body)

      setViewed(true)

      props.onView && props.onView(id)
    }
  }

  return(
    <div className={classNames(props.className)}
     onMouseEnter={viewNote}>
      <div className='flex space-x-1 items-center mb-1 relative'>
        {
          viewed || (
            <Indicator className='absolute left-0 top-0 border border-white' type='notification' />
          )
        }
        <Avatar size='miniscule' className='mr-0.5' {...from} />
        <div className='flex items-baseline space-x-1'>
          <div className='text-sm text-gray-900 font-medium'>
            {from.firstName}
          </div>
          <Hint small>{moment(createdAt).fromNow()}</Hint>
        </div>
      </div>

      <div className="text-sm text-gray-600 whitespace-pre-wrap pl-[22px]">
        { text }
      </div>
    </div>
  )
}

export default UserNote
