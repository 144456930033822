import React, { useContext, Fragment } from "react";
import AppContext from 'contexts/AppContext';
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import {
  FolderIcon,
} from "@heroicons/react/24/outline";
import { useQuery } from "hooks";
import { classNames } from "utils";

const NewStageMenu = React.forwardRef((props, ref) => {
  const { stageMetadata, AVAILABLE_STAGE_TYPES } = useContext(AppContext);
  const { pathWithQuery } = useQuery();

  const menu = AVAILABLE_STAGE_TYPES.map(stageMetadata);

  return (
    <Menu as="div" ref={ref} {...props} className="">
      <Menu.Items
        static
        className="w-60 rounded-md bg-slate-700 focus:outline-none"
      >
        <div className="grid grid-cols-2 gap-4 px-4 py-2">
          {menu.map((item, index) => (
            <Menu.Item as="div" className="col-span-1" key={index}>
              {({ active }) => (
                <div
                  onClick={() => props.onClick(item.type)}
                  className={classNames(
                    "px-4 py-2 cursor-pointer flex flex-col items-center space-y-1 rounded-md",
                    active && "bg-slate-800",
                  )}
                >
                  <item.Icon className="h-6 w-6 text-white/80" />
                  <span className="text-xs text-white">{item.name}</span>
                </div>
              )}
            </Menu.Item>
          ))}
        </div>
        <Menu.Item key={"library"}>
          {({ active }) => (
            <Link
              to={pathWithQuery(null, { addStageFromLibrary: true })}
              onClick={() => props.onOpenLibrary}
              className={classNames(
                "text-white block px-4 py-2 text-sm cursor-pointer text-center",
                active && "bg-slate-800",
                "rounded-b-md",
              )}
            >
              <FolderIcon className="h-4 w-4 inline mr-1" />
              Add from library
            </Link>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
});

export default NewStageMenu;
