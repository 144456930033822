import React, { Fragment, useContext, useState, useRef } from 'react'
import AppContext from 'contexts/AppContext'
import { classNames } from 'utils'
import { usePost, useMetaEnter } from 'hooks'
import AiCoachTrigger from 'components/coaching/AiCoachTrigger'
import TextareaAutosize from 'react-textarea-autosize'

const CommentForm = ({ className, question, user, response, discussion, ...props }) => {
  const { currentUser } = useContext(AppContext)
  const [text, setText] = useState('')
  const [isFocussed, setIsFocussed] = useState(false)

  const textarea = useRef()

  const onSubmit = (comment) => {
    setText('')
    if(props.onSubmit) props.onSubmit(comment)
  }

  const { post, data } = usePost(onSubmit)

  const canPost = text.length > 0 && !data.isLoading

  const handleSubmit = () => {
    if(!canPost) return

    const body = {
      comment: {
        text: text.trim(),
        user_id: currentUser.id
      }
    }

    post(`discussions/${discussion.id}/responses/${response.id}/comments`, body)
  }

  const { handleMetaEnter, metaKey } = useMetaEnter(handleSubmit)

  const handleAiCoachResponse = (text) => {
    setText(text)
    textarea.current.focus()
  }

  return (
    <div className={classNames("flex items-start space-x-4", className)}>
      {
        currentUser.isTrainer && question && response &&
        <AiCoachTrigger
         className="@xs:-ml-10 @sm:ml-0 mt-1"
         question={question.text}
         answer={response.text}
         user={user}
         onResponse={handleAiCoachResponse} />
      }
      <div className="min-w-0 flex-1">
        <div className="border-b border-gray-200 focus-within:border-indigo-600 pt-px flex space-x-2">
          <label htmlFor="comment" className="sr-only">
            Add a comment
          </label>
          <TextareaAutosize
            ref={textarea}
            minRows={1}
            maxRows={10}
            name="comment"
            id="comment"
            className="block w-full resize-none border-0 border-b bg-transparent border-transparent p-0 pb-2 focus:border-indigo-600 focus:ring-0 text-sm mt-1 placeholder:text-gray-400"
            placeholder="Add a comment..."
            autoFocus={isFocussed}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={handleMetaEnter}
            value={text}
          />
        </div>
      </div>
      <div className="min-w-0 flex-shrink-0 self-end">
        <button
          type="button"
          disabled={!canPost}
          onClick={handleSubmit}
          className="inline-flex items-center rounded-md border border-transparent bg-white px-3 py-1.5 text-sm font-medium leading-4 text-gray-600 shadow-sm disabled:shadow-none hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:hover:bg-white">
          { metaKey } +
          <svg className="ml-1" width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23 23V5H43V43H5V23H23Z" fill="none" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><path d="M33 13V33H13" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><path d="M17 29L13 33L17 37" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/></svg>
        </button>
      </div>
    </div>
  )
}

CommentForm.defaultProps = {
  className: ''
}

export default CommentForm