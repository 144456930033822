import React, { useState, useContext, useRef, useLayoutEffect } from 'react'
import { Button } from 'components/shared'
import { post } from 'api'
import AppContext from 'contexts/AppContext'

const FixProjectIssues = ({ activity, onSuccess }) => {
  const { apiUrl, formToken } = useContext(AppContext)
  const [data, setData] = useState({ isLoading: false, errorMessage: null })

  const button = useRef(null)

  const handleSuccess = (response) => {
    setData({ ...data, isLoading: false })
    onSuccess(response)
  }

  const handleError = (error, response) => {
    setData({ ...data, errorMessage: error, isLoading: false })
  }

  const handleClick = (e) => {
    e.preventDefault()

    setData({ ...data, isLoading: true })

    post(`${ apiUrl }/activities/${ activity.id }/repos/actions/setup-learner-project`, { formToken }, handleSuccess, handleError)
  }

  useLayoutEffect(() => {
    if(button) button.click()
  }, [button])

  return(
    <Button ref={button} onClick={handleClick} loading={data.isLoading} error={data.errorMessage}>
      { data.isLoading ? 'Fixing...' : 'Fix' }
    </Button>
  )
}

export default FixProjectIssues