import React, { useState, useContext } from 'react'
import { put, post, destroy } from 'api'
import { useHistory } from 'react-router-dom'
import AppContext from 'contexts/AppContext'

const useGraphEditor = () => {
  const { apiUrl, formToken, currentUser } = useContext(AppContext)
  const history = useHistory()

  const [potentialActivityPosition, setPotentialActivityPosition] = useState({})

  const createActivity = ({ id, ...activity }, callback) => {
    const title = 'Untitled Activity'

    const body = { activity: { course_id: currentUser.courseId, ...activity, title } }
    post(`${apiUrl}/admin/activities`, { formToken, body }, callback)
  }

  const updateActivity = ({ id, slug, ...activity }, callback) => {
    const body = { activity }
    put(`${apiUrl}/admin/activities/${slug}`, { formToken, body }, (persistedActivity) => callback(id, persistedActivity))
  }

  const createEdge = (source, target, callback) => {
    const body = { graph_activity_link: { in_id: source.id(), out_id: target.id() } }

    post(`${apiUrl}/admin/edges`, { formToken, body }, callback)
  }

  const deleteEdge = ({ id }, callback) => {
    const idWithoutPrefix = id.match(/relation\-(\d+)/)[1]

    destroy(`${apiUrl}/admin/edges/${idWithoutPrefix}`, { formToken }, callback)
  }

  const onNodeDrag = (e) => {
    const node = e.target
    const id = node.id()
    const position = node.position()

    const body = { activity: { id, ...position } }
    put(`${apiUrl}/admin/activities/${id}`, { body, formToken })
  }

  return  [ createActivity,
            updateActivity,
            createEdge,
            deleteEdge,
            onNodeDrag ]
}

export default useGraphEditor
