import React, { Fragment } from 'react'
import { Popover } from '@headlessui/react'
import { RemoteSelectInput, RemoteCombobox, DotBadge, Checkbox } from 'components/shared'
import { FunnelIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { classNames } from 'utils'

const DirectoryFilters = ({ filters, ...props }) => {
  const filtersApplied = Object
                        .entries(filters)
                        .filter(([key, value]) => {
                          if(key === 'search') return false
                          if(key === 'labels') return value && value.length > 0
                          if(key === 'order')  return false
                          if(key === 'hide_current_user_responses') return false
                          if(key === 'limit') return false

                          return value
                        }).map(([key, value]) => key)

  return(
    <Popover>
      <Popover.Button className="relative inline-flex justify-center rounded-md border border-gray-300 bg-white px-3.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2">
        <span className="sr-only">Open filters</span>
        <FunnelIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        {
          filtersApplied.length > 0 &&
          <DotBadge value={filtersApplied.length} />
        }
      </Popover.Button>

      <Popover.Panel className="absolute mt-2 z-50 bg-white rounded-md shadow-sm border border-gray-200">
        <div className="bg-gray-50 border-b border-gray-200 px-4 py-2">
          <h4 className="text-xs text-gray-500 font-medium">Apply filters</h4>
        </div>

        <div className="px-4 py-4 space-y-2 flex flex-col items-end">
          {
            props.showHideViewed &&
            <div className="w-full">
              <div
               className="flex items-center justify-between px-2 py-2 hover:bg-gray-50 cursor-pointer rounded-md"
               onClick={() => props.onChange({ name: 'hideViewed', value: !filtersApplied.includes('hideViewed') })}>
                <span className="text-sm">Hide viewed?</span>
                <Checkbox
                 readOnly
               checked={filtersApplied.includes('hideViewed')} />
              </div>
            </div>
          }

          <div className="w-full">
            <div
             className="flex items-center justify-between px-2 py-2 hover:bg-gray-50 cursor-pointer rounded-md"
             onClick={() => props.onChange({ name: 'requestingCertification', value: !filtersApplied.includes('requestingCertification') })}>
              <span className="text-sm">Requesting certification</span>
              <Checkbox
               readOnly
             checked={filtersApplied.includes('requestingCertification')} />
            </div>
          </div>

          <div className="flex w-full items-center space-x-2">
            {
              filtersApplied.includes('cohort') &&
              <XMarkIcon
               className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer"
               onClick={() => props.onChange({ name: 'cohort', value: null })} />
            }

            <RemoteSelectInput
             title='Cohort'
             defaultOption='Select a cohort'
             fetchPath='cohorts'
             defaultValue={filters.cohort}
             optionValue={'id'}
             optionLabel={'title'}
             onChange={(e) => props.onChange({ name: 'cohort', value: e.target.value })} />
          </div>

          <div className="flex w-full items-center space-x-2">
            {
              filtersApplied.includes('organization') &&
              <XMarkIcon
               className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer"
               onClick={() => props.onChange({ name: 'organization', value: null })} />
            }
            <RemoteSelectInput
             title='Organization'
             defaultOption='Select an organization'
             fetchPath='organizations'
             defaultValue={filters.organization}
             optionValue={'id'}
             optionLabel={'name'}
             onChange={(e) => props.onChange({ name: 'organization', value: e.target.value })} />
          </div>

          <div className="flex w-full items-center space-x-2">
            {
              filtersApplied.includes('course') &&
              <XMarkIcon
               className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer"
               onClick={() => props.onChange({ name: 'course', value: null })} />
            }
            <RemoteSelectInput
             title='Course'
             defaultOption='Select a course'
             fetchPath='courses'
             defaultValue={filters.course}
             optionValue={'id'}
             optionLabel={'title'}
             onChange={(e) => props.onChange({ name: 'course', value: e.target.value })} />
          </div>

          <div className="flex w-full relative space-x-2 pt-1">
            <RemoteCombobox
             title='Labels'
             placeholder='Filter by label'
             defaultValue={filters.labels}
             fetchPath='labels'
             optionValue={'id'}
             optionLabel={'text'}
             onChange={(labels) => props.onChange({ name: 'labels', value: labels })} />
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  )
}

export default DirectoryFilters
