import React, { useState } from "react";
import { Modal, Loading } from "components/shared";
import { useFetch } from "hooks";
import Conversation from "components/users/Conversation";

const AdminConversationModal = ({ userId, open, onClose, ...props }) => {
  const [id, setId] = useState(null);

  const { data } = useFetch(
    `users/${userId}/conversations?admin=true`,
    (conversations) => setId(conversations[0]?.id),
    [userId],
    {
      onlyIf: userId,
    },
  );

  if (data.isLoading)
    return (
      <Modal size={"lg"} open={open} onClose={onClose} className="p-0">
        <Loading />
      </Modal>
    );
  if (!id) return null;

  return (
    <Modal size={"lg"} open={open} onClose={onClose} className="p-0">
      <Conversation id={id} />
    </Modal>
  );
};

export default AdminConversationModal;
