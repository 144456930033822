import React, { useState, useEffect } from 'react'

// prices is
// [
//   "countryCode": "GB",
//   "currencySymbol": "£",
//   "isoCode": "GBP",
//   "amount": 750
// ]
const useGeolocatedPrice = (prices) => {
  const [price, setPrice] = useState(null)

  useEffect(() => {
    const fetchPrice = async () => {
      fetch("https://ip2c.org/self")
        .then((response) => response.text())
        .then((data) => {
          const [status, country] = String(data).split(";")
          if (status !== "1") {
            throw new Error("Unable to fetch country")
          }
          const possiblePrice = prices.find(({ countryCode }) => countryCode === country)
          setPrice(possiblePrice ? possiblePrice : prices[0])
        })
        .catch((err) => {
          console.error(err)
        })
    }

    if(prices?.length > 0) fetchPrice()
  }, [prices])

  return price
}

export default useGeolocatedPrice