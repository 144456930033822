import React, { useContext } from "react";
import AppContext from "contexts/AppContext";
import {
  Form,
  EmailInput,
  TextInput,
  AvatarUploader,
  Modal,
  Button,
} from "components/shared";
import { P, A } from "components/typography";
import { useQuery, usePut, useFlash } from "hooks";

const OnboardingModal = ({ ...props }) => {
  const { currentUser, setCurrentUser } = useContext(AppContext);

  const { successFlash } = useFlash();
  const { watchQuery, addQuery } = useQuery();

  const open = watchQuery("onboardingStage", (value) => value === 1);

  const onComplete = () => addQuery("onboardingStage", 2);

  const { put, data: updateData } = usePut(async (user) => {
    await setCurrentUser(user);
    successFlash("Your data has been saved.");
    onComplete();
  });

  const handleSubmit = (data) => {
    const body = {
      user: {
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        avatar: data.avatar,
      },
    };

    put(`users/${currentUser.id}`, body);
  };

  return (
    <Modal
      size={"lg"}
      open={open}
      onClose={() => {}}
      title={`Let's get started`}
      titleClassName="text-3xl pb-4"
    >
      <P>
        TechMap is a community of real people. By joining them, you agree to the{" "}
        <A
          href="https://docs.google.com/document/d/1p8r3Bg_vqWbYqEcwgkUy1OQD5ih9KIfkURZETD96fcg/edit"
          target="_blank"
        >
          Terms and Conditions
        </A>
        .
      </P>

      <Form onSubmit={handleSubmit}>
        <div className="px-6 my-8 grid grid-cols-6 gap-x-6 gap-y-8 text-left">
          <div className="col-span-6">
            <EmailInput
              name="email"
              label="Email address"
              defaultValue={currentUser.email}
              autoComplete="email"
              className="mt-1"
              required={true}
            />
          </div>

          <div className="col-span-3">
            <TextInput
              name="firstName"
              label="First name"
              defaultValue={currentUser.firstName}
              autoComplete="given-name"
              className="mt-1"
              required={true}
            />
          </div>

          <div className="col-span-3">
            <TextInput
              name="lastName"
              label="Last name"
              defaultValue={currentUser.lastName}
              autoComplete="family-name"
              className="mt-1"
              required={true}
            />
          </div>

          <div className="col-span-6 flex justify-center mt-4">
            <AvatarUploader />
          </div>
        </div>

        <Button type="submit" size="lg" disabled={updateData.isLoading}>
          OK, let's go!
        </Button>
      </Form>
    </Modal>
  );
};

export default OnboardingModal;
