import React, { useContext } from "react";
import AppContext from "contexts/AppContext";
import { Page, ThreeColumn } from "components/shared";
import DiscussionsFeed from "components/coaching/DiscussionsFeed";
import Question from "components/questions/Question";
import Discussions from "components/coaching/Discussions";
import SelectedDiscussion from "components/coaching/SelectedDiscussion";
import SelectedDiscussionMeta from "components/coaching/SelectedDiscussionMeta";
import { useDiscussions, useFilters } from "hooks";

const DiscussionsPage = () => {
  const { currentUser } = useContext(AppContext);

  const { filters, handleFilterChange } = useFilters({
    order: "latest",
    limit: 200,
    user_id:
      currentUser.isAdmin || currentUser.isTrainer ? null : currentUser.id,
  });

  const {
    discussions,
    fetchSelectedDiscussion,
    selectedDiscussion,
    selectedDiscussionId,
    selectedResponseId,
    selectedCommentId,
    loadingDiscussions,
    loadingSelectedDiscussion,
    websocketConnected,
  } = useDiscussions(filters);

  if (loadingDiscussions)
    return (
      <Page fullscreen>
        <ThreeColumn>
          <div>{loadingDiscussions}</div>
          <div>{loadingDiscussions}</div>
          <div>{loadingDiscussions}</div>
        </ThreeColumn>
      </Page>
    );

  return (
    <Page fullscreen>
      <ThreeColumn>
        <DiscussionsFeed
          showFilters={currentUser.isAdmin || currentUser.isTrainer}
          {...{
            discussions,
            filters,
            handleFilterChange,
            selectedDiscussionId,
            websocketConnected,
          }}
        />

        {currentUser.isAdmin || currentUser.isTrainer ? (
          <SelectedDiscussion
            selectedResponseId={selectedResponseId}
            selectedCommentId={selectedCommentId}
            {...selectedDiscussion}
          />
        ) : (
          selectedDiscussion?.question && (
            <Question
              includeAiCoach
              user={currentUser}
              selectedResponseId={selectedResponseId}
              selectedCommentId={selectedCommentId}
              {...selectedDiscussion.question}
            />
          )
        )}

        {currentUser.isAdmin || currentUser.isTrainer ? (
          <SelectedDiscussionMeta
            onChange={fetchSelectedDiscussion}
            selectedResponseId={selectedResponseId}
            {...selectedDiscussion}
          />
        ) : (
          selectedDiscussion?.question && (
            <Discussions
              locked={false}
              omitCurrentUser
              omitActivity
              omitLastName
              question={{ id: selectedDiscussion.question.id }}
            />
          )
        )}
      </ThreeColumn>
    </Page>
  );
};

export default DiscussionsPage;
