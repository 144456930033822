import React, { Fragment } from "react";
import { Badge, ContentEditable } from "components/shared";
import { ClockIcon } from "@heroicons/react/24/outline";
import { cn } from "utils";

const TimeEstimate = ({ time, ...props }) => {
  return (
    <Badge className={cn("text-gray-400", props.className)}>
      <ClockIcon className="h-4 w-4 mr-0.5" />
      {props.editable ? (
        <>
          <ContentEditable
            className="mt-px"
            html={String(time)}
            onChange={props.onChange}
          />
          <span className='mt-px'>m</span>
        </>
      ) : (
        <span className="mt-px">{time}m</span>
      )}
    </Badge>
  );
};

export default TimeEstimate;
