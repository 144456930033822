import React, { useState, useEffect } from "react";
import { ContentEditable } from "components/shared";
import { duration, cn } from "utils";

const Timestamp = ({ children, ...props }) => {
  const initialTimestamp = duration(children);

  const [timestamp, setTimestamp] = useState(initialTimestamp);
  const [minutes, setMinutes] = useState(initialTimestamp.split(":")[0]);
  const [seconds, setSeconds] = useState(initialTimestamp.split(":")[1]);

  useEffect(() => {
    setTimestamp(`${minutes}:${seconds}`);
  }, [minutes, seconds]);

  useEffect(() => {
    if (timestamp !== initialTimestamp && props.onChange)
      props.onChange(timestamp);
  }, [timestamp]);

  if (props.editable)
    return (
      <div className="flex">
        <ContentEditable
          interactive
          className="text-xs"
          html={minutes}
          onChange={(e) => {
            setMinutes(e.target.value);
          }}
        />
        <span className="text-xs">:</span>
        <ContentEditable
          interactive
          className="text-xs"
          html={seconds}
          onChange={(e) => {
            setSeconds(e.target.value);
          }}
        />
      </div>
    );
  return (
    <div className={cn("text-xs text-gray-400", props.className)}>
      {timestamp}
    </div>
  );
};

export default Timestamp;
