import React, { useState } from 'react'
import Orderer from 'components/questions/questionTypes/Orderer'
import { classNames } from 'utils'

const OrdererQuestion = (props) => {
  return(
    <div className={classNames(props.className)}>
      <Orderer items={props.ordererItems} />
    </div>
  )
}

export default OrdererQuestion