export const generateSubject = (emailText) => `
Given the following email from a coach at TechMap, generate a subject line that would be appropriate for the email.
Return only the subject line. Do not return anything else.
Keep the subject line friendly, light, and short.

Here are some examples:

Touching base
Nice job on TechMap!
Just reaching out

EMAIL TEXT:
${emailText}
`.trim()

export const welcome = (to, from) => `
We run a training course called "TechMap". It's designed to help non-technical people who work with technical people understand the world of technology.
Generate the body of a short email to welcome this user to TechMap. Use the user's name and be direct.
Don't refer to TechMap as "our platform", instead refer to "the TechMap community".
Don't refer to anyone as "starring" or "liking" discussions. Try not to invent functionality unless you know TechMap has that functionality.
Keep the tone friendly and light. Be terse.
The learner's name is "${to.firstName}" and the email is from "${from.firstName}".
Try to match the style and tone of the following examples:

Example 1:
Hi ${to.firstName},

I’m ${from.lastName}, one of the co-founders of TechMap (and the voice on the videos). I just wanted to say 🎉 welcome to TechMap! We're excited to have you on board.

Feel free to reach out to me any time. We're here to help you succeed – if you're finding something tricky to understand, you can reach out to us any time.

Yours,

${from.firstName}

Example 2:
Hi ${to.firstName},

I’m ${from.firstName}, a coach on TechMap. This is just a note to say hi, and welcome to our community!

The TechMap community is made up of hundreds of other learners just like you, and we're here to help you every step of the way. If there's anything we can do to help, please just reach out to me.

Yours,

${from.firstName}`.trim();

export const comeBack = (to, from) => `
We run a training course called "TechMap". It's designed to help non-technical people who work with technical people understand the world of technology.
Generate the body of a short email to attract this user back to TechMap. Use the user's name and be direct. Reference at least one of the answers they've given.
Don't refer to TechMap as "our platform", instead refer to "the TechMap community".
Don't refer to anyone as "starring" or "liking" discussions. Try not to invent functionality unless you know TechMap has that functionality.
Do not explain what TechMap is: the learner already knows this information.
Keep the tone friendly and light. Be terse.
The learner's name is "${to.firstName}" and the email is from "${from.firstName}".
Try to match the style and tone of the following examples:

Example 1:
Hi ${to.firstName},

I’m ${from.lastName}, one of the co-founders of TechMap (and the voice on the videos). I noticed it’s been about ten days since you completed anything on TechMap. You were giving some great answers to the Cloud and infrastructure activities, and Robin loved your answers, but then you stopped.

Just wondering if there's I can do to help speed your TechMap journey along?

Yours,

${from.firstName}

Example 2:
Hi ${to.firstName},

I’m ${from.firstName}, a coach on TechMap. I noticed it’s been a couple of weeks since you completed anything on TechMap. You signed up and then completed a ton of stuff on the right branch, but haven’t come back since.

Just wondering if there's I can do to help speed your TechMap journey along?

Yours,

${from.firstName}`.trim();

export const encouragement = (to, from) => `
We run a training course called "TechMap". It's designed to help non-technical people who work with technical people understand the world of technology.
Generate the body of a short email to congratulate the user on their work so far, and encourage the user to keep going. Use the user's name and be direct. Reference at least one of the answers they've given.
Don't refer to TechMap as "our platform", instead refer to "the TechMap community".
Don't refer to anyone as "starring" or "liking" discussions. Try not to invent functionality unless you know TechMap has that functionality.
Keep the tone friendly and light. Be terse.
The learner's name is "${to.firstName}" and the email is from "${from.firstName}".
Try to match the style and tone of the following examples:

Example 1:
Hi ${to.firstName},

I’m ${from.lastName}, one of the co-founders of TechMap (and the voice on the videos). I just wanted to say that we're really impressed with the answers you've been giving on TechMap. The one about infrastructure being "the nuts and bolts of the whole system" made us laugh, and was inspired.

Keep it up!

Yours,

${from.firstName}

Example 2:
Hi ${to.firstName},

I’m ${from.firstName}, a coach on TechMap. Just a note to say that we're loving the answers you've been giving on TechMap. You really seem like you're getting it.

Let us know if there's anything else we can do!

Yours,

${from.firstName}`.trim();

export const helpStarting = (to, from) => `
We run a training course called "TechMap". It's designed to help non-technical people who work with technical people understand the world of technology.
Generate the body of a short email to help the user get started. The user you are emailing has not completed any activities yet, and is stuck in onboarding.

Use the user's name and be direct.
Don't refer to TechMap as "our platform", instead refer to "the TechMap community".
Don't refer to anyone as "starring" or "liking" discussions. Try not to invent functionality unless you know TechMap has that functionality.
Keep the tone friendly and light. Be terse.
The learner's name is "${to.firstName}" and the email is from "${from.firstName}".
Try to match the style and tone of the following examples:

Example 1:
Hi ${to.firstName},

I’m ${from.lastName}, one of the co-founders of TechMap (and the voice on the videos). I noticed that it's been a little while since you signed up but you haven't gotten started yet.

Is there anything I can do to help?

Yours,

${from.firstName}

Example 2:
Hi ${to.firstName},

I’m ${from.firstName}, a coach on TechMap. You signed up a while back but haven't completed any activities yet.

Just wondering if I can help out?

Yours,

${from.firstName}`.trim();
