import React from "react";
import { Feed, Indicator } from "components/shared";
import moment from "moment";
import { stripTags } from "utils";

const NotificationInFeed = React.forwardRef(
  ({ notification, ...props }, ref) => {
    const header = (notification) => {
      if (notification.type === "comment")
        return `Comment from ${notification.from.firstName}`;
      if (notification.type === "certification")
        return "Certification request processed";
      if (notification.type === 'email_received')
        return `Email from ${notification.from.firstName}`;
      if (notification.type === "unclassified") return "New notification";
    };

    const text = (notification) => {
      if (notification.type === "comment") return notification.about.text;
      if (notification.type === "certification")
        return props.preview ? stripTags(notification.html) : notification.html;
      if (notification.type === 'email_received')
        return notification.about.text;
      if (notification.type === "unclassified")
        return "You have a new notification. Click here to view it.";
    };

    return (
      <Feed.Item
        to={`/notifications/${notification.id}`}
        ref={ref}
        onClick={props.onClick}
        onMouseOver={props.onMouseOver}
        {...props}
      >
        <Feed.Item.Header>
          <Feed.Item.Header.Title>
            <div className="flex items-center space-x-2">
              {notification.status === "unread" && <Indicator />}
              <span className="truncate">{header(notification)}</span>
            </div>
          </Feed.Item.Header.Title>

          <Feed.Item.Header.Meta>
            <time dateTime={notification.createdAt}>
              {moment(notification.createdAt).fromNow()}
            </time>
          </Feed.Item.Header.Meta>
        </Feed.Item.Header>

        <Feed.Item.Content>
          <Feed.Item.Content.Content>
            {text(notification)}
          </Feed.Item.Content.Content>
        </Feed.Item.Content>
      </Feed.Item>
    );
  },
);

export default NotificationInFeed;
