import React from "react";
import { motion } from "framer-motion";
import { Transition } from "@headlessui/react";

const GraphSidebar = ({ show, ...props }) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <Transition
      as="aside"
      show={show}
      enter="transform transition ease-in-out duration-500 sm:duration-500"
      enterFrom="translate-y-full"
      enterTo="translate-y-0"
      leave="transform transition ease-in-out duration-500 sm:duration-500"
      leaveFrom="translate-y-0"
      leaveTo="translate-y-full"
      className="absolute bottom-0 left-[50%] w-[320px] -ml-[160px] py-2 rounded-t-md shadow-md bg-white px-4"
    >
      <div
        className="cursor-grab px-4 py-2 text-center rounded-md border bg-white border-gray-300 shadow-sm hover:shadow-md active:shadow-lg"
        onDragStart={(event) => onDragStart(event, "activity")}
        draggable
      >
        Activity
      </div>
    </Transition>
  );
};

export default GraphSidebar;
