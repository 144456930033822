import React, { useState, useEffect, useContext } from "react";
import {
  Switch,
  Route,
  Link,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import ProjectIndex from "./ProjectIndex";
import Issue from "./Issue";
import { useFetch } from "hooks";
import AppContext from "contexts/AppContext";
import GithubAuthenticationAlert from "components/github/GithubAuthenticationAlert";

const Project = (props) => {
  const { currentUser } = useContext(AppContext);
  const location = useLocation();
  const { slug, issueSlug } = useParams();
  const { path, url } = useRouteMatch();
  const [project, setProject] = useState(null);

  const {
    fetch: fetchProject,
    data,
    loadingOrError,
  } = useFetch(`activities/${slug}/project`, setProject, [slug]);

  return (
    <div className="">
      {!(currentUser.githubUsername && currentUser.githubAccessToken) && (
        <GithubAuthenticationAlert />
      )}
      <Switch>
        <Route path={`${path}/issues/:issueSlug`}>
          {loadingOrError ||
            (project && (
              <Issue
                project={project}
                learnerRepo={project?.learnerRepo}
                onChangeRepo={fetchProject}
              />
            ))}
        </Route>

        <Route path={path}>
          {loadingOrError ||
            (project && (
              <ProjectIndex
                {...project}
                activity={props.activity}
                attempt={props.attempt}
                learnerRepo={project?.learnerRepo}
                onChangeRepo={fetchProject}
              />
            ))}
        </Route>
      </Switch>
    </div>
  );
};

export default Project;
