import React, { useState, useEffect } from 'react'
import { Pagination as BasePagination } from 'components/shared'

const usePagination = (objects, { perPage = 10 } = {}) => {
  const [page, setPage] = useState([])
  const [pageIndex, setPageIndex] = useState(0)

  useEffect(() => {
    const start = pageIndex * perPage
    const end = start + perPage

    setPage(objects.slice(start, end))
  }, [pageIndex, objects])

  useEffect(() => {
    setPageIndex(0)
  }, [])

  const Pagination = ({ small = false, top = false, className = '' }) => (
    <BasePagination
     pageIndex={pageIndex}
     pageControls
     pageCount
     pageOptions={ { length: Math.ceil(objects.length / perPage) } }
     canPreviousPage={ pageIndex !== 0 }
     previousPage={() => setPageIndex(pageIndex - 1)}
     canNextPage={ (pageIndex * perPage + perPage) < objects.length }
     nextPage={() => setPageIndex(pageIndex + 1)}
     gotoPage={setPageIndex}
     small={small}
     top={top}
     className={className} />
  )

  return { page, setPageIndex, Pagination }
}

export default usePagination
