import React from "react";
import { Button, Label } from "components/shared";
import { Hint } from "components/typography";

import { cn } from "utils";

const SelectInput = React.forwardRef(
  (
    {
      hint,
      button,
      buttonText,
      name,
      label,
      value,
      defaultValue,
      onChange,
      onSubmit,
      required,
      disabled,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={cn(props.wrapperClassName)}>
        {label && (
          <Label
            className={cn("mb-1 ml-1", props.labelClassName)}
            htmlFor={name}
          >
            {label}
          </Label>
        )}

        <div className="flex items-center justify-between w-full">
          <select
            className={cn(
              "relative min-h-[36px] w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 border-none",
              props.size === "sm" && "sm:text-xs",
              props.className,
            )}
            id={name}
            ref={ref}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            required={required}
            name={name}
            aria-label={label}
            disabled={disabled}
            multiple={props.multiple}
          >
            {children}
          </select>

          {button && <Button onClick={onSubmit}>{buttonText}</Button>}
        </div>

        {hint && <Hint>{hint}</Hint>}
      </div>
    );
  },
);

export default SelectInput;
