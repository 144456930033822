import React from "react";
import { Alert } from "components/shared";
import { cn } from "utils";

const AiJudgement = ({ judgement, ...props }) => {
  if (!judgement) return null;

  const emojiJudgement = judgement.trim().split(/\s+/)[0];
  const text = judgement.replace(emojiJudgement, "").trim();

  const EMOJI_TO_ALERT_TYPES = {
    "🔴": "alert",
    "🟡": "warning",
    "🟢": "success",
    "🚀": "notice",
  };

  const alertType = EMOJI_TO_ALERT_TYPES[emojiJudgement] || "notice";

  return (
    <Alert className={cn(props.className)} type={alertType}>
      {text}
    </Alert>
  );
};

export default AiJudgement;
