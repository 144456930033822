import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  FlagIcon,
  StarIcon,
} from "@heroicons/react/20/solid";
import { Indicator } from "components/shared";
import { cn } from "utils";

const DiscussionMenu = (props) => {
  return (
    <Menu as="div" className="absolute top-0 right-0 z-10">
      <div>
        <Menu.Button className="flex items-center rounded-full text-gray-300 hover:text-gray-500 focus:outline-none">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="">
            <Menu.Item>
              {({ active }) => (
                <a
                  className={cn(
                    "block text-gray-700 px-4 py-2 text-sm rounded-t-md flex items-center cursor-pointer",
                    active && "bg-gray-100 text-gray-900",
                  )}
                  onClick={props.onMarkUnviewed}
                >
                  <Indicator className="mr-3" />
                  Mark as unviewed
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  className={cn(
                    "block text-gray-700 px-4 py-2 text-sm rounded-t-md flex items-center cursor-pointer",
                    active && "bg-gray-100 text-gray-900",
                  )}
                  onClick={
                    props.isExemplar ? props.onUnexemplar : props.onExemplar
                  }
                >
                  {props.isExemplar ? (
                    <>
                      <StarIcon className="h-3 w-3 mr-2 text-gray-300" />
                      Unset as exemplar
                    </>
                  ) : (
                    <>
                      <StarIcon className="h-3 w-3 mr-2 text-orange-500" />
                      Set as exemplar
                    </>
                  )}
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  className={cn(
                    "block text-gray-700 px-4 py-2 text-sm rounded-t-md flex items-center cursor-pointer",
                    active && "bg-gray-100 text-gray-900",
                  )}
                  onClick={props.isFlagged ? props.onUnflag : props.onFlag}
                >
                  {props.isFlagged ? (
                    <>
                      <FlagIcon className="h-3 w-3 mr-2 text-gray-300" />
                      Remove flag
                    </>
                  ) : (
                    <>
                      <FlagIcon className="h-3 w-3 mr-2 text-orange-500" />
                      Flag
                    </>
                  )}
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DiscussionMenu;
