import React, { Fragment, useMemo, useCallback } from "react";
import {
  BaseEdge,
  getBezierPath,
  useStore,
  EdgeLabelRenderer,
  useReactFlow,
} from "reactflow";
import { getEdgeParams } from "./utils.js";
import { MinusIcon } from "@heroicons/react/24/outline";
import { cn } from "utils";

const ActivityEdge = ({ id, source, target, data, markerEnd, ...props }) => {
  const nodes = useStore(
    useCallback((store) => store.getNodes(), [source, target]),
  );
  const selectedNode = useMemo(
    () => nodes.find((node) => node.selected),
    [nodes],
  );
  const sourceNode = useMemo(
    () => nodes.find((node) => node.id === source),
    [nodes, source],
  );
  const targetNode = useMemo(
    () => nodes.find((node) => node.id === target),
    [nodes, target],
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { setEdges } = useReactFlow();

  const deleteEdge = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
  };

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode,
  );

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  const isInToSelected = useMemo(() => {
    if (selectedNode) {
      return selectedNode.id === target;
    }
    return false;
  }, [selectedNode, target]);

  const isOutOfSelected = useMemo(() => {
    if (selectedNode) {
      return selectedNode.id === source;
    }
    return false;
  }, [selectedNode, source]);

  const isInOrOutOfSelected = isInToSelected || isOutOfSelected;

  const opacity = () => {
    if (selectedNode) {
      if (isInOrOutOfSelected) {
        if (targetNode?.data?.unlocked) return 1;
        return 0.4;
      }
      return 0.2;
    } else {
      if (targetNode?.data?.unlocked) return 1;
      return 0.4;
    }
  };

  const style = {
    opacity: opacity(),
    transition: "opacity 600ms cubic-bezier(0.4, 0, 0.2, 1)",
    stroke: "rgb(209 213 219)", // gray-300
  };

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        style={style}
      />

      {isInToSelected && selectedNode?.data?.unlocked && (
        <circle
          style={{ filter: `drop-shadow(3px 3px 5px rgb(209 213 219)` }} // gray-300
          r="2"
          fill="rgb(209 213 219)" // gray-300
          className="circle"
        >
          <animateMotion dur="6s" repeatCount="indefinite" path={edgePath} />
        </circle>
      )}

      {isOutOfSelected &&
        selectedNode?.data?.unlocked &&
        selectedNode?.data?.completedAttempt &&
        targetNode?.data?.unlocked && (
          <circle
            style={{ filter: `drop-shadow(3px 3px 5px rgb(209 213 219)` }} // gray-300
            r="2"
            fill="rgb(209 213 219)" // gray-300
            className="circle"
          >
            <animateMotion dur="6s" repeatCount="indefinite" path={edgePath} />
          </circle>
        )}

      {
        data.editable && (
          <EdgeLabelRenderer>
            <div
              className="flex items-center justify-center"
              style={{
                position: "absolute",
                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                pointerEvents: "all",
                opacity: opacity(),
              }}
              className="nodrag nopan"
            >
              <button
                onClick={deleteEdge}
                className="p-0.5 rounded-full bg-white border border-gray-300 text-gray-400 hover:shadow-sm hover:bg-gray-50"
              >
                <MinusIcon className="h-3 w-3" onClick={deleteEdge} />
              </button>
            </div>
          </EdgeLabelRenderer>
        )
      }
    </>
  );
};

export default ActivityEdge;
