import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import { Switch } from "@headlessui/react";
import {
  Detail,
  RemoteSelectInput,
  TextInput,
  EmailInput,
  PasswordInput,
  Button,
  Form,
  Alert,
} from "components/shared";
import UserRoles from "components/admin/UserRoles";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import { useFlash } from "hooks";
import { cn } from "utils";

const AddUser = ({ ...props }) => {
  const { API } = useContext(AppContext);
  const { successFlash, errorFlash } = useFlash();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLicensed, setIsLicensed] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [cohortId, setCohortId] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [licenseCode, setLicenseCode] = useState(null);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (formData) => {
    const errors = [];

    if (formData.password.length <= 6)
      errors.push("Password must be at least 6 characters");

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const body = {
      user: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        password: formData.password,
        learner: formData.learner,
        manager: formData.manager,
        trainer: formData.trainer,
        admin: formData.admin,
        ...(courseId ? { course_id: courseId } : {}),
        ...(organizationId ? { organization_id: organizationId } : {}),
        ...(cohortId ? { cohort_id: cohortId } : {}),
      },
      ...(isLicensed ? { licensed: true } : {}),
      ...(isConfirmed ? { confirm: true } : {}),
    };

    setIsLoading(true);
    const response = await API.post(`/users`, body, {
      onError: errorFlash,
    });
    setIsLoading(false);

    if (response) {
      successFlash(`User created successfully.`);
      props.onClose();
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="text-left pt-4 space-y-4">
      {errors.length > 0 && (
        <Alert type="alert" className="mb-2">
          Couldn't create user: {errors.join(", ")}.
        </Alert>
      )}
      <TextInput
       required
       label="First name"
       name="firstName" />
      <TextInput
       require
       label="Last name"
       name="lastName" />
      <EmailInput
        required
        label="Email address"
        name="email"
        autoComplete="off"
      />
      <PasswordInput
        required
        label="Password"
        hint="Minimum 6 characters"
        name="password"
        autoComplete="off"
      />
      <Detail
        className="w-full"
        key="course"
        default="No course"
        title="Course"
      >
        <RemoteSelectInput
          className="w-full"
          selectClassName="w-full"
          title="Course"
          defaultOption="Select a course"
          fetchPath="courses"
          optionValue={"id"}
          optionLabel={"title"}
          onChange={(e) => setCourseId(e.target.value)}
        />
      </Detail>
      <Detail
        className="w-full"
        title="Organization"
        defaultValue="No organization given"
      >
        <RemoteSelectInput
          className="w-full"
          selectClassName="w-full"
          title="Organization"
          defaultOption="Select an organization"
          fetchPath="organizations"
          optionValue={"id"}
          optionLabel={"name"}
          onChange={(e) => setOrganizationId(e.target.value)}
        />
      </Detail>
      <Detail
        className="w-full"
        key="cohort"
        defaultValue="No cohort"
        title="Cohort"
      >
        <RemoteSelectInput
          className="w-full"
          selectClassName="w-full"
          title="Cohort"
          defaultOption="Select a cohort"
          fetchPath="cohorts"
          optionValue={"id"}
          optionLabel={"title"}
          onChange={(e) => setCohortId(e.target.value)}
        />
      </Detail>

      <ul role="list" className="mt-2 divide-y divide-gray-200">
        <Switch.Group
          as="li"
          className="flex items-center justify-between py-4"
        >
          <div className="flex flex-col">
            <Switch.Label
              as="p"
              className="text-sm font-medium text-gray-900 mb-1"
              passive
            >
              Confirmed
            </Switch.Label>
            <Switch.Description className="text-sm text-gray-500">
              If enabled, we won't send a confirmation email to the user.
            </Switch.Description>
          </div>
          <Switch
            checked={isConfirmed}
            onChange={setIsConfirmed}
            className={cn(
              isConfirmed ? "bg-teal-500" : "bg-gray-200",
              "relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2",
            )}
          >
            <span
              aria-hidden="true"
              className={cn(
                isConfirmed ? "translate-x-5" : "translate-x-0",
                "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
        </Switch.Group>
        <Switch.Group
          as="li"
          className="flex items-center justify-between py-4"
        >
          <div className="flex flex-col">
            <Switch.Label
              as="p"
              className="text-sm font-medium text-gray-900 mb-1"
              passive
            >
              Licensed
            </Switch.Label>
            <Switch.Description className="text-sm text-gray-500">
              Assign a license to this user for this course, organization, and
              cohort.
            </Switch.Description>
          </div>

          <Switch
            checked={isLicensed}
            onChange={setIsLicensed}
            className={cn(
              isLicensed ? "bg-teal-500" : "bg-gray-200",
              "relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2",
            )}
          >
            <span
              aria-hidden="true"
              className={cn(
                isLicensed ? "translate-x-5" : "translate-x-0",
                "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              )}
            />
          </Switch>
        </Switch.Group>
      </ul>

      <div>
        <h2 className="text-lg font-medium leading-6 text-gray-900 mb-4">
          Roles
        </h2>
        <p className="-mt-2 text-sm text-gray-500">
          Manage this user's permissions.
        </p>
      </div>

      <UserRoles />

      <div className="mt-5 sm:mt-6">
        <Button className="w-full block" type="submit" disabled={isLoading}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default AddUser;
