import React from 'react'
import { classNames } from 'utils'

const Label = ({ children, htmlFor, ...props }) => {
  return(
    <label
     htmlFor={htmlFor}
     className={classNames(
       "block text-sm font-medium text-gray-700",
       props.small && 'text-xs',
       props.className
     )}>
      { children }
    </label>
  )
}

Label.defaultProps = {
  htmlFor: null,
}

export default Label

