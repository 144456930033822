import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { Hint, Modal, Button } from "components/shared";
import { useFetch } from "hooks";
import { pure } from "recompose";
import IssueAttemptPreview from "components/github/IssueAttemptPreview";

import AppContext from "contexts/AppContext";

const CompareSolutions = (props) => {
  const { currentUser } = useContext(AppContext);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [issueAttempts, setIssueAttempts] = useState([]);

  const setIssueAttemptsWithoutUserIssueAttempt = (issueAttempts) => {
    setIssueAttempts(
      issueAttempts.filter((attempt) => attempt.user.id !== currentUser.id),
    );
  };

  if (props.attempt && !props.attempt.completed) history.goBack();

  const { loadingOrError } = useFetch(
    `issues/${props.issue.slug}/issue_attempts`,
    setIssueAttemptsWithoutUserIssueAttempt,
    [],
  );

  const hide = () => {
    setShow(false);

    setTimeout(() => {
      props.onHide();
    }, 300);
  };

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Modal size={"lg"} show={show} onHide={hide} className="CompareSolutions">
      <Modal.Header closeButton>
        <Modal.Title>Solutions for {props.issue.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <FlushList bordered rounded>
          { loadingOrError
            || issueAttempts.length > 0
               ? issueAttempts
                 .map((attempt, index) => <IssueAttemptPreview
                                           key={index}
                                           {...attempt} />)
               : <Hint center>
                   Nobody else has completed { props.issue.title } yet!
                 </Hint> 
          }
          </FlushList>*/}
      </Modal.Body>
    </Modal>
  );
};

export default pure(CompareSolutions);
