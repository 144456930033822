import React, { useContext } from "react";
import AppContext from "contexts/AppContext";
import { LabelHeader } from "components/shared";
import Editable from "components/design/Editable";
import { cn } from "utils";

const QuestionTitle = ({ question, ...props }) => {
  const { editorMode } = useContext(AppContext);

  const DEFAULT_TITLE = "Untitled Question";

  const onChange = (title) => {
    if (props.onChange) {
      props.onChange({ ...question, title });
    }
  };

  const questionLede = props.warmup
    ? "Warm-up Question"
    : question.isKey
    ? "Key Question"
    : "Question";

  if (editorMode)
    return (
      <LabelHeader className={cn(props.className)}>
        {`${questionLede}: `}
        <Editable
          path={`/questions/${question.id}`}
          transform={(title) => ({ question: { title } })}
          beforeChange={props.beforeChange}
          onChange={onChange}
          onError={props.onError}
        >
          {question.title ?? DEFAULT_TITLE}
        </Editable>
      </LabelHeader>
    );

  return (
    <LabelHeader className={cn(props.className)}>
      {questionLede}
    </LabelHeader>
  );
};

export default QuestionTitle;
