import React, { Fragment } from "react";
import { DateTime } from "components/shared";
import { cn } from "utils";

const DateTimeRange = ({ start, end, timeZone, size = "md", ...props }) => {
  const SIZE_TO_CLASSES = {
    sm: "text-xs",
    md: "text-sm",
    lg: "text-base",
  };

  return (
    <span className={cn(props.className, SIZE_TO_CLASSES[size])}>
      <DateTime
        datetime={start}
        month="long"
        showTimeZone={false}
        size={size}
      />{" "}
      –{"  "}
      <DateTime
        datetime={end}
        showWeekday={false}
        showDay={false}
        showMonth={false}
        showYear={false}
        size={size}
      />
    </span>
  );
};

export default DateTimeRange;
