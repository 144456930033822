import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import AppContext from "contexts/AppContext";
import { Hint, Button } from "components/shared";

import InteractiveVideoActivity from "components/activities/InteractiveVideoActivity";
import QuestionActivity from "components/activities/QuestionActivity";
import PollActivity from "components/activities/PollActivity";
import InternalActivity from "components/activities/InternalActivity";
import NotionActivity from "components/activities/NotionActivity";
import BloomActivity from "components/activities/BloomActivity";
import ArticleActivity from "components/activities/ArticleActivity";
import CertificationActivity from "components/activities/CertificationActivity";
import IntroActivity from "components/activities/IntroActivity";
import ActivityStageIntro from "components/activities/ActivityStageIntro";
import EditMetadataBar from "components/design/EditMetadataBar";

import { useParams } from "react-router-dom";
import { useFetch, useFlash, useClientRect } from "hooks";
import { classNames } from "utils";

const ActivityStage = ({ stages, attempt, ...props }) => {
  const { currentUser, editorMode, API } = useContext(AppContext);
  let { stageSlug } = useParams();
  const { errorFlash } = useFlash();

  const [stage, setStage] = useState(null);

  const [showIntro, setShowIntro] = useState(false);
  const [activityStyle, setActivityStyle] = useState({});
  const [containerRect, containerRef] = useClientRect([stageSlug, editorMode]);
  const [metadataRect, metadataRef] = useClientRect([stageSlug, editorMode]);

  const [isLoadingMeta, setIsLoadingMeta] = useState(false);
  const [metaError, setMetaError] = useState(null);

  const calculateActivityStyles = () => {
    if (!containerRect) return;
    let height = containerRect.height;

    if (metadataRect && editorMode) height -= metadataRect.height;

    setActivityStyle({ height });
  };

  useEffect(() => {
    if (stage?.introBlocks?.length > 0) setShowIntro(true);
  }, [stage]);

  useLayoutEffect(calculateActivityStyles, [
    containerRect,
    metadataRect,
    editorMode,
    stageSlug,
  ]);

  const { fetch: fetchStage, loadingOrError } = useFetch(
    `activity_stages/${stageSlug}`,
    setStage,
    [stageSlug],
    {
      onlyIf: stageSlug !== "no-stages",
    },
  );

  const handleUpdate = () => {
    fetchStage();
    if (props.onUpdate) props.onUpdate();
  };

  if (loadingOrError || !stage) return loadingOrError;

  const type = stage.type.replace(" ", "-");

  const ACTIVITIES = {
    "interactive-video": (
      <InteractiveVideoActivity
        user={currentUser}
        extraResponseParams={{ activity_attempt_id: attempt.id }}
        stage={stage}
        onStartUpdate={() => setIsLoadingMeta(true)}
        onFinishUpdate={() => setIsLoadingMeta(false)}
        onErrorUpdate={(err) => setMetaError(err)}
        onChange={fetchStage}
      />
    ),
    "open-ended-question": (
      <QuestionActivity
        user={currentUser}
        extraResponseParams={{ activity_attempt_id: attempt.id }}
        stage={stage}
        onStartUpdate={() => setIsLoadingMeta(true)}
        onFinishUpdate={() => setIsLoadingMeta(false)}
        onErrorUpdate={(err) => setMetaError(err)}
        onChange={fetchStage}
      />
    ),
    allocator: (
      <PollActivity
        user={currentUser}
        extraResponseParams={{ activity_attempt_id: attempt.id }}
        stage={stage}
      />
    ),
    notion: <NotionActivity user={currentUser} stage={stage} />,
    article: (
      <ArticleActivity
        user={currentUser}
        onStartUpdate={() => setIsLoadingMeta(true)}
        onFinishUpdate={() => setIsLoadingMeta(false)}
        onErrorUpdate={(err) => setMetaError(err)}
        stage={stage}
      />
    ),
    internal: <InternalActivity user={currentUser} stage={stage} />,
    certification: <CertificationActivity user={currentUser} stage={stage} />,
    intro: (
      <IntroActivity
        extraResponseParams={{ activity_attempt_id: attempt.id }}
        user={currentUser}
        stage={stage}
      />
    ),
    bloom: (
      <BloomActivity user={currentUser} stage={stage} onUpdate={fetchStage} />
    ),
  };

  return (
    <div
      ref={containerRef}
      className="@container/activity absolute inset-0 flex-col max-h-screen"
    >
      {editorMode && (
        <EditMetadataBar
          allowManageIntro
          allowPublish
          ref={metadataRef}
          stage={stage}
          isLoading={isLoadingMeta}
          setIsLoading={setIsLoadingMeta}
          error={metaError}
          onUpdate={handleUpdate}
          onAddIntro={() => {
            setStage({ ...stage, introBlocks: [] });
            setShowIntro(true);
          }}
          onEditIntro={() => setShowIntro(true)}
        />
      )}

      <ActivityStageIntro
        open={showIntro}
        stage={stage}
        onRemoveIntro={() => {
          setShowIntro(false);
          fetchStage();
        }}
        onClose={() => setShowIntro(false)}
        onStartUpdate={() => setIsLoadingMeta(true)}
        onUpdate={(blocks) => setIsLoadingMeta(false)}
        onErrorUpdate={(err) => setMetaError({ message: err })}
      />

      <div className="overflow-hidden" style={activityStyle}>
        {ACTIVITIES[type] || (
          <Hint center>
            Activity type "{type}" not recognised. Please contact support.
          </Hint>
        )}
      </div>
    </div>
  );
};

export default ActivityStage;
