import React, { Fragment, useEffect, useState } from "react";
import { Spinner } from "components/shared";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useFetch, usePut, useFlash } from "hooks";
import { cn } from "utils";

const RemoteSelectInput = ({
  title,
  fetchPath,
  updatePath,
  updateTransform,
  defaultValue,
  optionValue,
  optionLabel,
  defaultOption,
  ...props
}) => {
  const wrappedDefaultOption = {
    [optionValue]: 0,
    [optionLabel]: defaultOption,
  };

  const [options, setOptions] = useState(
    [wrappedDefaultOption].filter((option) => option),
  );
  const [selected, setSelected] = useState(0);
  const { successFlash } = useFlash();

  const handleReceiveOptions = (options) => {
    const newOptions = props.sortBy
      ? options.sort((a, b) => b[props.sortBy] - a[props.sortBy])
      : options;

    setOptions([wrappedDefaultOption, ...newOptions]);
  };

  const { data: fetchingData } = useFetch(fetchPath, handleReceiveOptions, [
    fetchPath,
  ]);
  useFetch(fetchPath, handleReceiveOptions, [defaultValue]);

  const { put, data } = usePut(() => {
    successFlash(`Successfully updated${title ? ` ${title}.` : "."}`);
    if (props.onSubmit) props.onSubmit();
  });

  useEffect(() => {
    if (options && options.length > 0 && defaultValue) {
      setSelected(defaultValue);
    } else {
      setSelected(0);
    }
  }, [options, defaultValue]);

  const handleChange = (e) => {
    setSelected(e.target.value);
    if (props.onChange) props.onChange(e);
    if (updatePath) put(updatePath, updateTransform(e));
  };

  return (
    <div className={cn("flex space-x-2", props.className)}>
      <Listbox
        as="div"
        value={selected}
        onChange={(value) => handleChange({ target: { value } })}
        className={cn("w-64", props.selectClassName)}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">{title}</Listbox.Label>
            <div className="relative">
              <Listbox.Button
                className={cn(
                  "relative min-h-[36px] w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6",
                  props.size === "sm" && "sm:text-xs",
                )}
              >
                <span className="block truncate">
                  {fetchingData.isLoading ? (
                    <Spinner className="text-gray-900" />
                  ) : (
                    options.find(
                      (option) => option[optionValue] === selected,
                    )?.[optionLabel]
                  )}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={cn(
                    "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                    props.size === "sm" && "sm:text-xs",
                  )}
                >
                  {options.map((option) => (
                    <Listbox.Option
                      key={option[optionValue]}
                      disabled={
                        props.disableDefaultOption &&
                        option[optionValue] ===
                          wrappedDefaultOption[optionValue]
                      }
                      className={({ active }) =>
                        cn(
                          active ? "bg-indigo-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-8 pr-4",
                          props.disableDefaultOption &&
                            option[optionValue] ===
                              wrappedDefaultOption[optionValue] &&
                            "bg-gray-100 cursor-not-allowed text-gray-900",
                          props.size === "sm" && "pl-2 pr-2",
                        )
                      }
                      value={option[optionValue]}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={cn(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate",
                            )}
                          >
                            {props.optionPrelabel && (
                              <span className="text-gray-300 font-semibold mr-2">
                                {option[props.optionPrelabel]}
                              </span>
                            )}
                            {option[optionLabel]}
                          </span>

                          {selected ? (
                            <span
                              className={cn(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                props.size === "sm" && "hidden",
                              )}
                            >
                              <CheckIcon
                                className={cn("h-5 w-5")}
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {data.isLoading && <Spinner />}
    </div>
  );
};

export default RemoteSelectInput;
