import React, { useContext } from 'react'
import AppContext from 'contexts/AppContext'
import { Modal, Button } from 'components/shared'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { KeyIcon, BellIcon } from '@heroicons/react/24/outline'
import { useQuery } from 'hooks'

const IntroModal = ({ ...props }) => {
  const { currentUser } = useContext(AppContext)
  const { watchQuery, mutateQuery } = useQuery()

  const open = watchQuery('onboardingStage', (value) => value === 3)
  const onClose = () => mutateQuery({ onboardingStage: undefined, completeOnboarding: true });

  return(
    <Modal
     size={'prose'}
     open={open}
     onClose={onClose}
     title={`Welcome to TechMap, ${currentUser.firstName}!`}
     titleClassName='text-3xl pb-4'
     button={
      <Button
       className='block mx-auto'
       size='lg'
       onClick={onClose}>
       OK, let's go!
      </Button>}>
       <ul className='py-6 px-4 text-left space-y-8 text-gray-800 leading-8'>
        <li className='flex items-start space-x-4'>
          <div className='h-8 w-8 rounded-full border border-2 border-gray-600 flex-shrink-0' />
          <p>
            TechMap is made up of <strong className='font-semibold'>activities</strong>. Click on an activity to start.
          </p>
        </li>
        <li className='flex items-start space-x-4'>
          <div className='h-8 w-8 rounded-full border border-2 border-gray-500 flex-shrink-0 flex items-center justify-center'>
            <LockClosedIcon className='h-5 w-5 text-gray-600' />
          </div>
          <p>
            Some activities have to be <strong>unlocked</strong> before you can do them.
          </p>
        </li>
        <li className='flex items-start space-x-4'>
          <div className='h-8 w-8 flex-shrink-0 flex items-center justify-center'>
            <KeyIcon className='h-6 w-6 text-gray-600' />
          </div>
          <p>
            Some activities contain <strong>key questions</strong>. To get certified, answer all key questions.
          </p>
        </li>
        <li className='flex items-start space-x-4'>
          <div className='h-8 w-8 flex-shrink-0 flex items-center justify-center'>
            <BellIcon className='h-6 w-6 text-gray-600' />
          </div>
          <p>
            Coaches will give you feedback on your answers. <strong>Revise</strong> your answers when coaches give you feedback.
          </p>
        </li>
       </ul>
    </Modal>
  )
}

export default IntroModal