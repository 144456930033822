import React, { useContext, useState, useEffect } from "react";
import AppContext from "contexts/AppContext";
import { Switch } from "@headlessui/react";
import { Button, Form, Alert, TextArea } from "components/shared";
import { useFlash, useConfirm } from "hooks";
import { cn, possessive } from "utils";

const CreateUserCertification = ({ user, ...props }) => {
  const { API, currentUser } = useContext(AppContext);
  const { successFlash, errorFlash } = useFlash();
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ConfirmationModal, confirmDeny] = useConfirm(
    `Deny certification?`,
    `Are you sure you want to deny ${possessive(
      user.firstName,
    )} request for certification? They will have to re-submit a certification request at a later date.`,
  );
  const [notify, setNotify] = useState(true);

  const handleSubmit = async (formData) => {
    const errors = [];

    if (formData.comment.length === 0)
      errors.push("You must add a certification comment.");

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const body = {
      certification: {
        user_id: user.id,
        trainer_id: currentUser.id,
        comment: formData.comment,
      },
      notify: notify,
    };

    setLoading(true);

    const response = await API.post(`/certifications`, body, {
      onError: errorFlash,
    });

    setLoading(false);

    if (response) {
      successFlash(`User certified successfully.`);
      if (props.onUpdate) props.onUpdate();
    }
  };

  const denyCertification = async () => {
    const isConfirmed = await confirmDeny();

    if (isConfirmed) {
      setLoading(true);

      const body = {
        certification: {
          user_id: user.id,
          trainer_id: currentUser.id,
        },
        notify: notify,
      };

      const response = API.post(`/certifications/deny`, body, {
        onError: errorFlash,
      });

      if (response) {
        successFlash(`${user.firstName} was denied certification.`);
        if (props.onUpdate) props.onUpdate();
      }
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} className="text-left pt-4 space-y-4">
        {errors.length > 0 && (
          <Alert type="alert" className="mb-2">
            Couldn't certify user: {errors.join(", ")}.
          </Alert>
        )}
        <TextArea
          required
          name="comment"
          label="Certification Comment"
          placeholder="e.g. You've really shown a good understanding of..."
          hint="This is shown to the user. Only required when certifying."
        />

        <ul role="list" className="mt-2 divide-y divide-gray-200">
          <Switch.Group
            as="li"
            className="flex items-center justify-between py-4"
          >
            <div className="flex flex-col">
              <Switch.Label
                as="p"
                className="text-sm font-medium text-gray-900 mb-1"
                passive
              >
                Notify user?
              </Switch.Label>
              <Switch.Description className="text-sm text-gray-500">
                If enabled, we'll send a certification email and notifications
                to the user.
              </Switch.Description>
            </div>
            <Switch
              checked={notify}
              onChange={setNotify}
              className={cn(
                notify ? "bg-teal-500" : "bg-gray-200",
                "relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2",
              )}
            >
              <span
                aria-hidden="true"
                className={cn(
                  notify ? "translate-x-5" : "translate-x-0",
                  "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                )}
              />
            </Switch>
          </Switch.Group>
        </ul>

        <div className="pt-6 flex items-center justify-center space-x-2">
          <Button type="submit" disabled={loading}>
            Certify {user.firstName}
          </Button>
          {
            user.certificationRequested && (
              <Button variant="secondary" onClick={denyCertification}>
                Deny certification
              </Button>
            )
          }
        </div>
      </Form>

      <ConfirmationModal />
    </div>
  );
};

export default CreateUserCertification;
