import React, { Fragment, useContext, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { classNames, toSentence } from "utils";
import AppContext from "contexts/AppContext";
import { usePost, useFlash, useMetaEnter } from "hooks";
import { Button, Spinner, Avatar, Tooltip } from "components/shared";
import { MarkdownIcon } from "components/icons";

const ResponseForm = ({
  question,
  discussion,
  constraints,
  previousResponse,
  ...props
}) => {
  const { currentUser } = useContext(AppContext);
  const [text, setText] = useState(props.defaultValue);

  const onChange = (e) => {
    setText(e.target.value);
  };

  useEffect(() => {
    const passedConstraints = constraints.filter((constraint) =>
      text.toLowerCase().includes(constraint.title.toLowerCase()),
    );
    setCheckedConstraints(passedConstraints);
  }, [text]);

  const onSubmit = (response) => {
    setText("");

    if (props.onSubmit) props.onSubmit(response);
  };

  const { post, data } = usePost(onSubmit);

  const { warningFlash } = useFlash();

  const [checkedConstraints, setCheckedConstraints] = useState([]);

  const SUBMISSION_CHECKS = [
    {
      condition: () => text.length === 0,
      message: () => "You cannot submit an empty response.",
    },
    {
      condition: () => previousResponse && text === previousResponse.text,
      message: () =>
        "You cannot submit a revision identical to the previous one.",
    },
    {
      condition: () => checkedConstraints.length !== constraints.length,
      message: () =>
        `Please use the following terms in your answer: ${toSentence(
          constraints
            .filter((c) => !checkedConstraints.includes(c))
            .map(({ title }) => title),
        )}.`,
    },
  ];

  const handleSubmit = () => {
    let canSubmit = true;

    SUBMISSION_CHECKS.forEach(({ condition, message }) => {
      if (condition()) {
        warningFlash(message());
        canSubmit = false;
      }
    });

    if (!canSubmit) return false;

    const body = {
      response: {
        text,
        discussion_id: discussion.id,
        ...props.extraResponseParams,
      },
    };

    post(`discussions/${discussion.id}/responses`, body);
  };

  const { metaKey, handleMetaEnter } = useMetaEnter(handleSubmit);

  return (
    <div
      className={classNames(
        previousResponse && props.expandRevisions && "mt-6",
      )}
    >
      {constraints.length > 0 && (
        <div className="mb-2 ml-14 text-xs text-gray-400">
          Use the following terms in your answer:{" "}
          {constraints.map((constraint, index) => (
            <span key={`constraint-${constraint.id}`} className="inline">
              <span
                className={classNames(
                  "inline",
                  text.toLowerCase().includes(constraint.title) &&
                    "text-green-500",
                )}
              >
                {constraint.title}
              </span>
              {index === constraints.length - 1 ? "" : ", "}
            </span>
          ))}
        </div>
      )}

      <div
        className={classNames("flex items-start space-x-4", props.className)}
      >
        <div className="flex-shrink-0">
          <Avatar
            className="flex @xs:h-8 @xs:w-8 @sm:h-10 @sm:w-10 h-10 w-10"
            {...currentUser}
          />
        </div>
        <div className="min-w-0 flex-1">
          <div action="#" className="relative">
            <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
              <label htmlFor="response" className="sr-only">
                {previousResponse ? "Revise your answer" : "Add your answer"}
              </label>
              <textarea
                rows={10}
                name="response"
                id="response"
                className="block w-full resize-none border-0 py-3 focus:ring-0 sm:text-sm"
                placeholder={
                  props.placeholder
                    ? props.placeholder
                    : previousResponse
                    ? "Revise your answer"
                    : "Add your answer"
                }
                value={text}
                onChange={onChange}
                onKeyDown={handleMetaEnter}
              />

              <div className="pt-1 pb-2" aria-hidden="true">
                <div className="py-0.5">
                  <div className="h-9" />
                </div>
              </div>
            </div>

            <div className="absolute inset-x-px bottom-px flex justify-between items-center py-2 pl-3 pr-2 border-t border-gray-200 bg-gray-50 rounded-br-lg rounded-bl-lg">
              <div className="flex-shrink-0">
                <Tooltip text="Markdown is supported. Click to find out more.">
                  <a
                    className="opacity-25 hover:opacity-50"
                    href="https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax"
                    target="_blank"
                  >
                    <MarkdownIcon className="w-6 text-gray-400" />
                  </a>
                </Tooltip>
              </div>

              <div className="flex-shrink-0 flex space-x-2">
                <Button
                  id="responseSubmit"
                  size="sm"
                  disabled={data.isLoading}
                  onClick={(e) => {
                    e.preventDefault;
                    e.stopPropagation;
                    handleSubmit();
                  }}
                >
                  {data.isLoading ? (
                    <>Submitting...</>
                  ) : (
                    <>
                      {previousResponse ? (
                        <>
                          Revise
                          <span className="text-xs ml-1">
                            ({metaKey} + Enter)
                          </span>
                        </>
                      ) : (
                        <>
                          Answer
                          <span className="text-xs ml-1">
                            ({metaKey} + Enter)
                          </span>
                        </>
                      )}
                    </>
                  )}
                </Button>
                {previousResponse && (
                  <Button
                    variant="secondary"
                    onClick={props.onClose}
                    disabled={data.isLoading}
                  >
                    Cancel
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResponseForm.defaultProps = {
  defaultValue: "",
  extraResponseParams: {},
  constraints: [],
};

export default ResponseForm;
