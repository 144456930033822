import React, { useContext } from 'react'
import UserDetails from 'components/users/UserDetails'
import { Link } from 'react-router-dom'
import { Checkbox } from 'components/shared'
import { classNames } from 'utils'

const UserInList = React.forwardRef(({ user, isCurrent, linkFn, ...props }, ref) => {
  if(!user) return null

  const to = linkFn ? linkFn(user.id) : null
  const Wrapper = to ? Link : 'div'

  return(
    <li>
      <Wrapper
       to={to}
       ref={ref}
       className={classNames(
        "relative flex items-center space-x-3 px-6 py-5 hover:bg-gray-50 cursor-pointer text-left",
        isCurrent && "bg-yellow-50"
       )}>
        <UserDetails
         user={ user }>
          {
            props.onCheck && props.checkable &&
            <Checkbox
             checked={props.checked}
             readOnly={true}
             onClick={() => props.onCheck(user)} />
          }
        </UserDetails>
      </Wrapper>
    </li>
  )
})

export default UserInList
