import React, { useMemo } from 'react'
import { cn } from 'utils'

const AttachmentBadge = ({ color, children, ...props }) => {
  const COLOR_TO_CLASSES = {
    gray: {
      wrapper: 'bg-gray-50 text-gray-600 ring-gray-500/10',
      dismiss: {
        wrapper: 'hover:bg-gray-500/20',
        svg: 'stroke-gray-600/50 group-hover:stroke-gray-600/75',
      },
    },
    red: {
      wrapper: 'bg-red-50 text-red-700 ring-red-600/10',
      dismiss: {
        wrapper: 'hover:bg-red-600/20',
        svg: 'stroke-red-600/50 group-hover:stroke-red-600/75',
      },
    },
    yellow: {
      wrapper: 'bg-yellow-50 text-yellow-800 ring-yellow-600/20',
      dismiss: {
        wrapper: 'hover:bg-yellow-600/20',
        svg: 'stroke-yellow-700/50 group-hover:stroke-yellow-700/75',
      },
    },
    green: {
      wrapper: 'bg-green-50 text-green-700 ring-green-600/20',
      dismiss: {
        wrapper: 'hover:bg-green-600/20',
        svg: 'stroke-green-700/50 group-hover:stroke-green-700/75',
      },
    },
    blue: {
      wrapper: 'bg-blue-50 text-blue-700 ring-blue-700/10',
      dismiss: {
        wrapper: 'hover:bg-blue-600/20',
        svg: 'stroke-blue-700/50 group-hover:stroke-blue-700/75',
      },
    },
    indigo: {
      wrapper: 'bg-indigo-50 text-indigo-700 ring-indigo-700/10',
      dismiss: {
        wrapper: 'hover:bg-indigo-600/20',
        svg: 'stroke-indigo-600/50 group-hover:stroke-indigo-600/75',
      },
    },
    purple: {
      wrapper: 'bg-purple-50 text-purple-700 ring-purple-700/10',
      dismiss: {
        wrapper: 'hover:bg-purple-600/20',
        svg: 'stroke-violet-600/50 group-hover:stroke-violet-600/75',
      },
    },
    pink: {
      wrapper: 'bg-pink-50 text-pink-700 ring-pink-700/10',
      dismiss: {
        wrapper: 'hover:bg-pink-600/20',
        svg: 'stroke-pink-700/50 group-hover:stroke-pink-700/75',
      },
    }
  }

  const classNames = useMemo(() => {
    if(color === 'random') return Object.values(COLOR_TO_CLASSES)[Math.floor(Math.random() * Object.keys(COLOR_TO_CLASSES).length)]

    return COLOR_TO_CLASSES[color] || COLOR_TO_CLASSES.gray
  }, [color])

  return (
    <span className={cn('inline-flex items-center gap-x-0.5 rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset whitespace-pre', classNames.wrapper, props.className)}>
      { children }
      {
        props.onDismiss && (
          <button
           type="button"
           className={cn('group relative -mr-1 h-3.5 w-3.5 rounded-sm', classNames.dismiss.wrapper)}
           onClick={props.onDismiss}>
            <span className="sr-only">Remove</span>
            <svg viewBox="0 0 14 14" className={cn('h-3.5 w-3.5 ', classNames.dismiss.svg)}>
              <path d="M4 4l6 6m0-6l-6 6" />
            </svg>
            <span className="absolute -inset-1" />
          </button>
        )
      }
    </span>
  )
}

export default AttachmentBadge