import React, { Fragment, useState, useEffect } from "react";
import QuestionInAccordion from "components/questions/QuestionInAccordion";
import { classNames } from "utils";

const Questions = ({ questions, user, stage, ...props }) => {
  if (!questions || questions.length === 0) return null;

  const [currentQuestion, setCurrentQuestion] = useState(null);

  useEffect(() => {
    setCurrentQuestion(props.currentQuestion);
  }, [props.currentQuestion]);

  const handleClickHeader = (question) => {
    const nextQuestion = currentQuestion?.id === question?.id ? null : question;

    setCurrentQuestion(nextQuestion);
    if (props.onClick) props.onClick(nextQuestion);
  };

  return (
    <div
      className={classNames(
        "mx-auto w-full rounded-2xl bg-white pt-2",
        props.className,
      )}
    >
      {questions.map((question, index) => (
        <QuestionInAccordion
          key={`accordion-question-${question.id}`}
          allowDelete={props.allowDeleteQuestion}
          question={question}
          user={user}
          currentQuestion={currentQuestion}
          onAdvance={props.onAdvance}
          onClickHeader={handleClickHeader}
          includeAiCoach={props.includeAiCoach}
          includeOtherAnswers={props.includeOtherAnswers}
          extraResponseParams={props.extraResponseParams}
          index={index}
          small={props.small}
          onStartUpdate={props.onStartUpdate}
          onUpdate={props.onUpdate}
          onUpdateTimestamp={props.onUpdateTimestamp}
          onDelete={props.onDelete}
          onErrorUpdate={props.onErrorUpdate}
          stage={question.stage ?? stage}
        />
      ))}
    </div>
  );
};

export default Questions;
