import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import AppContext from "contexts/AppContext";
import ReactFlow, {
  useReactFlow,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  MarkerType,
  useNodesState,
  useEdgesState,
} from "reactflow";
import ActivityNode from "components/graph/ActivityNode";
import FloatingConnectionLine from "components/graph/FloatingConnectionLine";
import DemoNode from "components/graph/DemoNode";
import ActivityEdge from "components/graph/ActivityEdge";
import GraphSidebar from "components/graph/GraphSidebar";
import { Loading } from "components/shared";
import { cn } from "utils";

const FlowGraph = ({ graph, focusSlug, interactive = true, ...props }) => {
  const edgeFromParams = ({ id, source, target }) => ({
    id: `edge-${id}`,
    type: "activity",
    source: source,
    target: target,
    data: { editable: props.editable },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#e0e0e0", // gray-300
    },
  });

  const nodesFromGraph = (graph) => {
    return graph.activities?.map((activity) => ({
      id: `${activity.id}`,
      type: interactive ? "activity" : "demo",
      position: { x: activity.flowX, y: activity.flowY },
      data: { label: activity.title, editable: props.editable, ...activity },
      selected: activity.slug === focusSlug,
    }));
  };

  const edgesFromGraph = (graph) => {
    if (!graph.relations) return [];
    return graph.relations.map(({ id, in_id, out_id }) =>
      edgeFromParams({ id, source: String(in_id), target: String(out_id) }),
    );
  };

  const initialNodes = useMemo(
    () =>
      graph.activities && graph.relations && graph.activities.length > 0
        ? nodesFromGraph(graph)
        : [],
    [graph, props.editable],
  );

  const initialEdges = useMemo(
    () =>
      graph.activities && graph.relations && graph.activities.length > 0
        ? edgesFromGraph(graph)
        : [],
    [graph, props.editable],
  );

  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [graph, props.editable]);

  const nodeTypes = useMemo(
    () => ({ activity: ActivityNode, demo: DemoNode }),
    [],
  );
  const edgeTypes = useMemo(() => ({ activity: ActivityEdge }), []);
  const reactFlow = useReactFlow();

  const handleFocusNodeChange = () => {
    if (focusSlug) {
      const selectedNode = nodes.filter(
        ({ data }) => data.slug === focusSlug,
      )[0];

      if(!selectedNode) return;

      const previous = selectedNode.data.previous
        ? nodes.filter(({ id }) =>
            selectedNode.data.previous.map(({ id }) => String(id)).includes(id),
          )
        : [];
      const next = selectedNode.data.next
        ? nodes.filter(({ id }) =>
            selectedNode.data.next.map(({ id }) => String(id)).includes(id),
          )
        : [];

      const xPositions = [
        selectedNode.position.x,
        ...previous.map(({ position }) => position.x),
        ...next.map(({ position }) => position.x),
      ];

      const yPositions = [
        selectedNode.position.y,
        ...previous.map(({ position }) => position.y),
        ...next.map(({ position }) => position.y),
      ];

      const bounds = {
        x:
          selectedNode.position.x +
          props.zoomedOffset +
          (selectedNode.width ? selectedNode.width / 4 : 0),
        y:
          selectedNode.position.y -
          (selectedNode.height ? selectedNode.height / 4 : 0),
        height: 100,
        width: 100,
      };

      const options = { duration: 600 };

      reactFlow.fitBounds(bounds, options);
    } else {
      reactFlow.fitView({ duration: 600 });
      setNodes(
        nodes.map((node) => ({
          ...node,
          selected: false,
        })),
      );
    }
  };

  useEffect(() => {
    if (nodes.length > 1) handleFocusNodeChange();
  }, [focusSlug, nodes.length]);

  let positionX = undefined;
  let positionY = undefined;

  const onNodesChange = useCallback(
    (changes) => {
      const resetNodes = [];

      changes.forEach((change) => {
        if (change.type === "reset") {
          resetNodes.push(change.item.id);
        }

        if (props.onUpdateActivity) {
          if (change.type === "position" && change.dragging) {
            positionX = change.position.x;
            positionY = change.position.y;
          }

          if (
            change.type === "position" &&
            !change.dragging &&
            positionX &&
            positionY
          ) {
            props.onUpdateActivity({
              id: change.id,
              flowX: positionX,
              flowY: positionY,
            });
          }
        }
      });

      if (props.onArchiveActivity && resetNodes.length > 0) {
        const deletedNode = nodes.find((node) => !resetNodes.includes(node.id));

        if (deletedNode)
          props.onArchiveActivity({
            id: parseInt(deletedNode.id),
            label: deletedNode.data.label,
          });
      }

      setNodes((nodes) => applyNodeChanges(changes, nodes));
    },
    [setNodes, props.editable],
  );

  const onEdgesChange = useCallback(
    (changes) => {
      const resetNodes = [];

      changes.forEach((change) => {
        if (change.type === "reset") {
          resetNodes.push(change.item.id);
        }
      });

      if (props.onDeleteRelation && resetNodes.length > 0) {
        const deletedRelation = edges.find(
          (edge) => !resetNodes.includes(edge.id),
        );

        if (deletedRelation)
          props.onDeleteRelation({
            id: parseInt(deletedRelation.id.split("-")[1]),
            in_id: parseInt(deletedRelation.source),
            out_id: parseInt(deletedRelation.target),
          });
      }

      setEdges((edges) => applyEdgeChanges(changes, edges));
    },
    [edges, setEdges, props.editable],
  );

  const reactFlowWrapper = useRef(null);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlow.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: "0",
        type,
        position,
        data: { label: `Untitled activity` },
        selected: false,
      };

      setNodes((nodes) => nodes.concat(newNode));

      if (props.onAddActivity)
        props.onAddActivity({
          title: newNode.data.label,
          flow_x: newNode.position.x,
          flow_y: newNode.position.y,
        });
    },
    [reactFlow, nodes],
  );

  const handleConnect = useCallback((params) => {
    const newEdge = edgeFromParams({ id: "0", ...params });
    setEdges([...edges, newEdge]);

    if (props.onAddRelation)
      props.onAddRelation({
        in_id: parseInt(newEdge.source),
        out_id: parseInt(newEdge.target),
      });
  }, []);

  return (
    <>
      <div ref={reactFlowWrapper} className="w-full h-full">
        <ReactFlow
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          fitView={!focusSlug}
          nodesDraggable={props.editable}
          nodesConnectable={props.editable}
          edgesFocusable={props.editable}
          onDrop={props.editable ? onDrop : undefined}
          onDragOver={props.editable ? onDragOver : undefined}
          onConnect={handleConnect}
          connectOnClick={true}
          proOptions={{ hideAttribution: true }}
          className={cn("group", !focusSlug && "nothing-selected")}
          connectionLineComponent={FloatingConnectionLine}
        >
          <Background
            variant="dots"
            gap={12}
            size={1}
            className={cn(
              "transition-opacity duration-[600ms]",
              focusSlug ? "opacity-20" : "opacity-60",
            )}
          />
        </ReactFlow>
      </div>
      <GraphSidebar show={props.editable} />
    </>
  );
};

export default FlowGraph;
