import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import { classNames } from "utils";
import moment from "moment";
import { Link } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Badge, Avatar } from "components/shared";
import { useDestroy, useQuery } from "hooks";

const Comment = ({
  response,
  discussion,
  id,
  user,
  updatedAt,
  text,
  isLast,
  isHighlighted,
  ...props
}) => {
  const { currentUser } = useContext(AppContext);
  const [showDelete, setShowDelete] = useState(false);

  const { pathWithQuery } = useQuery();

  const { destroy, data } = useDestroy(() => props.onDelete({ id }));

  const onDelete = (e) => {
    e.preventDefault();
    destroy(
      `discussions/${discussion.id}/responses/${response.id}/comments/${id}`,
    );
  };

  if (!user) return null;

  return (
    <div
      className={classNames(props.className, data.isLoading && "opacity-50")}
    >
      <div className={classNames("relative", !isLast && "pb-8")}>
        {!isLast && (
          <span
            className="absolute top-5 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        )}

        <div className="relative flex items-start space-x-3">
          <div className="relative">
            <Avatar size="small" className="ring-4 ring-white" {...user} />
          </div>
          <div
            className={classNames(
              "min-w-0 flex-1",
              isHighlighted && "bg-yellow-50",
            )}
            onMouseOver={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
          >
            <div>
              <div>
                <div className="text-sm">
                  <Link
                    to={pathWithQuery(null, { user: user.id })}
                    className="font-medium text-gray-900 mb-0"
                  >
                    {user.id === currentUser.id ? "You" : user.name}
                  </Link>

                  {user.isTrainer && (
                    <Badge className="ml-1" color="indigo">
                      Trainer
                    </Badge>
                  )}
                  <div className="inline ml-1 text-xs text-gray-500">
                    commented {moment(updatedAt).fromNow()}
                    {props.creationMethod === "email" && " via email"}
                  </div>

                  {(currentUser.id === user.id || currentUser.isAdmin) &&
                    showDelete && (
                      <TrashIcon
                        className="h-4 w-4 ml-1 text-gray-400 hover:text-gray-600 cursor-pointer inline"
                        onClick={onDelete}
                      />
                    )}
                </div>
              </div>
              <div className="text-sm text-gray-700">
                <p className="mb-0 whitespace-pre-line">{text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Comment.defaultProps = {
  className: "",
};

export default Comment;
