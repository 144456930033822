import React, { Fragment, useState, useContext } from "react";
import AppContext from "contexts/AppContext";
import { Tooltip } from "components/shared";
import NewStageMenu from "components/design/NewStageMenu";
import EditStageMenu from "components/design/EditStageMenu";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useConfirm } from "hooks";
import { cn } from "utils";

const Stage = ({ stage, index, isCurrent, isFirst, isLast, ...props }) => {
  const { currentUser, editorMode, stageMetadata } = useContext(AppContext);

  const [ConfirmModal, confirmArchive] = useConfirm(
    "Archive Stage?",
    `Are you sure you want to archive this ${stageMetadata(stage.type).name}?`,
  );

  const handleMoveLeft = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (props.onChange) props.onChange([{ type: "moveLeft" }]);
  };

  const handleMoveRight = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (props.onChange) props.onChange([{ type: "moveRight" }]);
  };

  const handleArchive = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const isConfirmed = await confirmArchive();
    if (isConfirmed) {
      if (props.onArchive) props.onArchive();
    }
  };

  return (
    <>
      <li className={cn("relative md:flex md:flex-1 group")}>
        <Link
          to={stage.slug}
          className={cn(
            "flex items-center pl-6 pr-11 py-2 text-sm font-medium",
            isLast && !editorMode && "pr-6",
          )}
          aria-current={isCurrent ? "stage" : undefined}
        >
          <span
            className={cn(
              "flex items-center justify-between text-sm font-medium text-gray-500 group-hover:text-gray-700",
            )}
          >
            <span
              className={cn(
                isCurrent && "text-indigo-600 group-hover:text-indigo-600",
                stage.isDraft && "bg-yellow-100",
              )}
            >
              {stageMetadata(stage.type).name}
            </span>
          </span>
        </Link>

        {editorMode && (
          <EditStageMenu
            canMoveLeft={!isFirst}
            canMoveRight={!isLast}
            canArchive={currentUser.isAdmin || currentUser.isCourseDesigner}
            onMoveLeft={handleMoveLeft}
            onMoveRight={handleMoveRight}
            onArchive={handleArchive}
          />
        )}

        {!(isLast && !editorMode) ? (
          <>
            {/* Arrow separator for lg screens and up */}
            <div
              className="absolute top-0 right-0 hidden h-full w-5 md:block"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-gray-200"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vectorEffect="non-scaling-stroke"
                  stroke="currentcolor"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </>
        ) : null}
      </li>
      <ConfirmModal />
    </>
  );
};

const StageTracker = ({ stages, currentStageSlug, ...props }) => {
  const { currentUser, editorMode } = useContext(AppContext);

  if ((!stages || stages.length === 0) && !editorMode) return null;

  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="divide-y divide-gray-200 rounded-md border border-gray-200 md:flex md:divide-y-0 mb-0"
      >
        {stages.map((stage, index) => (
          <Stage
            stage={stage}
            key={`stage-${stage.slug}`}
            index={index}
            isCurrent={currentStageSlug === stage.slug}
            isFirst={index === 0}
            isLast={index === stages.length - 1}
            onChange={(changes) => props.onChangeStage(stage.slug, changes)}
            onArchive={() => props.onArchiveStage(stage.slug)}
          />
        ))}
        {editorMode && (
          <Tooltip
            on="click"
            placement="bottom"
            delay={200}
            panel={<NewStageMenu onClick={props.onAddStage} />}
          >
            <li className="flex items-center pl-6 pr-6 py-2 text-sm font-medium group cursor-pointer">
              <PlusIcon
                className="h-5 w-5 text-gray-500 group-hover:text-gray-700"
                aria-hidden="true"
              />
            </li>
          </Tooltip>
        )}
      </ol>
    </nav>
  );
};

export default StageTracker;
