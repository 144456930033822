import React, { useState, useRef, useContext } from 'react'
import AppContext from 'contexts/AppContext'
import { Overlay, Tooltip } from "react-bootstrap"
import StatusIndicator from 'react-status-indicator'

const ProjectStatusIndicator = ({ status = 'loading', error, message, forceText }) => {
  const { STATUSES } = useContext(AppContext)

  const ERRORS_TO_HEADINGS = {
    project_error: 'Project setup error',
    no_learner_repo: "Repo not created" ,
    project_not_set_up: "No project yet"
  }

  const STATUSES_TO_HEADINGS = {
    error: error && (ERRORS_TO_HEADINGS[error] || "Error"),
    waiting: 'Queued',
    loading: 'Loading...',
    success: 'Success!'
  }

  const [showTooltip, setShowTooltip] = useState(false)
  const target = useRef(null)

  return(
    <div className="ProjectStatusIndicator">
      <Overlay target={target.current} show={showTooltip} placement="top">
        <Tooltip>
          { forceText ? forceText
                      : <>
                          <strong>
                            { error ? (ERRORS_TO_HEADINGS[error] || "Error")
                                    : STATUSES_TO_HEADINGS[status] }
                          </strong>
                          { (STATUSES_TO_HEADINGS[status] || error)
                             && message
                             && <br /> }
                          { message }
                        </> }
        </Tooltip>
      </Overlay>
      <div 
       className="ProjectStatusIndicatorWrapper"
       ref={target}
       onMouseOver={() => setShowTooltip(true)}
       onMouseLeave={() => setShowTooltip(false)}>
        <StatusIndicator
         Pulse={status === STATUSES.loading}
         Positive={status === STATUSES.success}
         Intermediary={status === STATUSES.failure}
         Negative={status === STATUSES.error} 
         Active={status === STATUSES.loading} />
      </div>
    </div>
  )
}

export default ProjectStatusIndicator
