import React, { useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import AppContext from 'contexts/AppContext'
import { Badge, SmallHeader, FlushList, Tabs } from 'components/shared'
import TimeEstimate from 'components/activityPreviews/TimeEstimate'
import LearnerRepoStatus from 'components/github/actions/LearnerRepoStatus'
import IssuePreview from './IssuePreview'
import { humanize, titleize, classNames } from 'utils'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { LockClosedIcon } from '@heroicons/react/20/solid'

const ProjectIndex = (props) => {
  const { currentUser } = useContext(AppContext)
  const { url } = useRouteMatch()

  const descriptionWithoutTitle = props.description.replace(/^\#\s+(.+)/, '')

  const authenticated = currentUser.githubUsername && currentUser.githubAccessToken
  const repoSynced = authenticated && props.learnerRepo

  return(
    <div className="h-screen -mt-16 pt-16 flex flex-1 overflow-hidden space-x-4">
      <div className="px-6 py-8 flex-1">
          <Badge
           className="mb-1"
           small
           type={ props.attempt.status }>
            { titleize(humanize(props.attempt.status)) }
          </Badge>
          <div className="flex">
            <ReactMarkdown className="MarkdownHeader mb-2">
              { props.title }
            </ReactMarkdown>
            <TimeEstimate className="ml-1" time={props.activity.timeEstimate} />
          </div>

          {
            (currentUser.githubUsername && currentUser.githubAccessToken)
            && <LearnerRepoStatus
               { ...props }
               className="mb-4"
               buttonText={ `Copy repo to ${ currentUser.githubUsername }/${ props.defaultRepoSlug }` }
               onSuccess={props.onChangeRepo} />
          }

        <ReactMarkdown
         className="prose"
         remarkPlugins={[remarkGfm]}>
          { descriptionWithoutTitle }
        </ReactMarkdown>
      </div>

      <aside className="@container relative w-[32rem] flex-shrink-0 overflow-y-auto border-l border-gray-200 flex flex-col">
        <Tabs.Header>
          { !repoSynced && <LockClosedIcon className="h-3 w-3 inline mr-1 -translate-y-px" /> }
          Challenges

          <div className="ml-1 text-gray-400 pb-2 text-xs mb-0 inline-block">
            { repoSynced
              ? null
              : '(Sync repo to unlock)' }
          </div>
        </Tabs.Header>
        <FlushList
         bordered
         className={classNames("w-100", !repoSynced && 'blur-sm pointer-events-none')}>
        { props
          .issues
          .map((issue, index) => <IssuePreview key={index} {...issue} />) }
        </FlushList>
      </aside>
    </div>
  )
}

export default ProjectIndex