import React, { useContext } from "react";
import AppContext from "contexts/AppContext";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { StageTracker, Button } from "components/shared";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const StageControls = ({ stages, attempts, ...props }) => {
  const { API, editorMode } = useContext(AppContext);
  const { slug, stageSlug } = useParams();

  const visibleStages = editorMode ? stages : stages.filter((stage) =>!stage.isDraft);

  const isFirstStage =
    visibleStages.length === 0 || visibleStages.length === 1 || stageSlug === visibleStages[0].slug;
  const isLastStage =
    visibleStages.length === 0 ||
    (visibleStages.length > 0 && stageSlug === visibleStages[visibleStages.length - 1].slug);
  const nextStage =
    visibleStages[visibleStages.map(({ slug }) => slug).indexOf(stageSlug) + 1];
  const previousStage =
    visibleStages[visibleStages.map(({ slug }) => slug).indexOf(stageSlug) - 1];

  const completeStage = async () => {
    API.post("/activity_stage_attempts", {
      attempt: { stage: { slug: stageSlug } },
    });
  };

  const completeLastStage = async () => {
    completeStage();
    if (props.onCompleteLastStage) props.onCompleteLastStage();
  };

  return (
    <div className="flex items-center justify-between w-full">
      <div className="pl-2 w-60">
        {isFirstStage ? (
          <a
            className="flex items-center text-sm text-gray-400 hover:text-gray-600 cursor-pointer"
            onClick={props.onBackToGraph}
          >
            <ChevronLeftIcon className="h-5 w-5 mr-0.5" />
            <span>Back to Graph</span>
          </a>
        ) : (
          previousStage && (
            <Link
              to={previousStage.slug}
              className="flex items-center text-sm text-gray-400 hover:text-gray-600 cursor-pointer"
            >
              <ChevronLeftIcon className="h-5 w-5 mr-0.5" />
              <span>Previous stage</span>
            </Link>
          )
        )}
      </div>

      <StageTracker
        stages={visibleStages}
        currentStageSlug={stageSlug}
        onChangeStage={props.onChangeStage}
        onAddStage={props.onAddStage}
        onArchiveStage={props.onArchiveStage}
      />

      <div className="pr-2 w-60 flex justify-end">
        {isLastStage ? (
          <Button id="completeActivityButton" onClick={completeLastStage}>
            Complete Activity
          </Button>
        ) : (
          nextStage && (
            <Link
              id="nextStageLink"
              to={nextStage.slug}
              onClick={completeStage}
              className="flex items-center text-sm text-gray-400 hover:text-gray-600 cursor-pointer"
            >
              Next stage
              <ChevronRightIcon className="h-5 w-5 ml-0.5" />
            </Link>
          )
        )}
      </div>
    </div>
  );
};

export default StageControls;
