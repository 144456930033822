import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";
import {
  Modal,
  Button,
  Loading,
  ScrollingImage,
  Form,
  TextInput,
} from "components/shared";
import { A } from "components/typography";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { KeyIcon, BellIcon } from "@heroicons/react/24/outline";
import LicenseInput from "components/users/LicenseInput";
import { useFetch, usePut, useFlash, useQuery } from "hooks";
import { pluralize, toSentence } from "utils";
import testimonialsImage from "images/testimonials.png";
import Logo from "images/techmap-logo.png";

const UpgradeModal = ({ ...props }) => {
  const { graphUrl, currentUser, setCurrentUser } = useContext(AppContext);
  const { watchQuery, deleteQuery, addQuery } = useQuery();

  const open = !!watchQuery("upgrade");
  const onClose = () => deleteQuery("upgrade");

  const [attempts, setAttempts] = useState([]);
  const [loadingPayment, setLoadingPayment] = useState(false);

  const { data } = useFetch(
    `attempts?user_id=${currentUser.id}`,
    setAttempts,
    [],
  );

  const STARTER_ACTIVITIES = ["Intro to TechMap", "Onboarding"];

  const completedAttempts = attempts
    .filter((attempt) => attempt.status === "complete")
    .filter((attempt) => !STARTER_ACTIVITIES.includes(attempt.activity.title));

  const ENTICING_ACTIVITIES = ["Cloud", "DevOps", "Data"];

  const referral = () => {
    if (
      typeof Rewardful !== "undefined" &&
      Rewardful &&
      Rewardful.referral &&
      Rewardful.referral.length > 0
    )
      return `&referral=${Rewardful.referral}`;
    return "";
  };

  const buyLicenseLink = `${graphUrl}/stripe/create-checkout-session?payment_point=end-of-trial${referral()}`;

  const descriptionHtml = `
    ${
      completedAttempts.length > 0
        ? `In the brief time you've been with us, you've completed ${pluralize(
            completedAttempts.length,
            "activity",
          )}${" "}
         on ${toSentence(
           completedAttempts.map(
             (attempt) =>
               `<span class='font-semibold'>${attempt.activity.title}</span>`,
           ),
         )}. But there's`
        : `There's`
    } so much more to learn:
      hundreds of technical recruiters have covered topics in ${toSentence(
        ENTICING_ACTIVITIES.map(
          (title) => `<span class='font-semibold'>${title}</span>`,
        ),
      )}.
      Join them by upgrading today.
  `;

  return (
    <Modal
      size={"6xl"}
      open={open}
      onClose={onClose}
      title={
        <>
          <img src={Logo} className="mx-auto mb-4 h-12 w-12" />
          Want to keep going?
        </>
      }
      titleClassName="text-3xl pb-4"
    >
      {data.isLoading ? (
        <Loading />
      ) : (
        <div className="mx-auto space-y-8 mb-4">
          <div className="prose mb-8 mx-auto">
            <p dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
          </div>
          <ScrollingImage className="h-72" src={testimonialsImage} />
          <div className="text-center space-y-4">
            <Button
              type="link"
              href={buyLicenseLink}
              disabled={loadingPayment}
              onClick={() => setLoadingPayment(true)}
              size="lg"
            >
              Upgrade now
            </Button>

            <LicenseInput
              onClose={() => addQuery("payment-point", "end-of-trial")}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default UpgradeModal;
