import React, { useContext, useState, useEffect, useMemo } from "react";
import AppContext from "contexts/AppContext";
import { Hint } from "components/typography";
import { Command } from "cmdk";
import { classNames, pluralize, titleize } from "utils";

const CommandBar = ({
  itemType,
  path,
  labelFunction,
  ItemComponent,
  ...props
}) => {
  const { API } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState(null)

  // Toggle the menu when ctrl-K or ⌘-K is pressed
  useEffect(() => {
    const down = (e) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  useEffect(() => {
    async function getItems() {
      setLoading(true);
      const res = await API.get(path);
      setItems(res);
      setLoading(false);
    }

    getItems();
  }, []);

  const onSelectItem = (item) => {
    setOpen(false);
    props.onSelectItem && props.onSelectItem(item);
  };

  const onSearchChange = (search) => {
    setSearch(search)
  }

  const filteredItems = useMemo(() => {
    return items.filter(item => {
      return labelFunction(item).toLowerCase().includes(search.toLowerCase())
    })
  }, [search])

  return (
    <Command.Dialog
      open={open}
      onOpenChange={setOpen}
      label={`${titleize(pluralize(2, itemType, false))} menu`}
      shouldFilter={false}
    >
      <Command.Input
        placeholder={`Search for a ${itemType}`}
        className="w-full py-4 px-6 ring-none text-gray-900 border-t-0 border-l-0 border-r-0 border-b border-gray-200 rounded-none focus:ring-0 focus:border-gray-200"
        value={search}
        onValueChange={onSearchChange}
      />
      <Command.List className="min-h-[330px] max-h-[400px] overflow-y-auto overscroll-contain transition-height duration-100 ease-in-out">
        {loading && (
          <Command.Loading className="w-full py-4 px-6">
            <Hint>Fetching {pluralize(2, itemType, false)}</Hint>
          </Command.Loading>
        )}

        <Command.Empty className="w-full py-4 px-6">
          <Hint>No results found.</Hint>
        </Command.Empty>

        <Command.Group className="py-4 px-4">
          {filteredItems.map((item) => {
            return (
              <Command.Item
                key={`${itemType}-${item.id}`}
                value={labelFunction(item)}
                onSelect={() => onSelectItem(item)}
                className={classNames(
                  "cursor-pointer h-12 text-sm flex items-center px-2 mb-2 py-6 text-gray-900 transition-all ease-in-out group data-[selected]:bg-gray-200 rounded-md data-[disabled]:cursor-not-allowed data-[disabled]:text-gray-400 transition-background active:bg-gray-300",
                )}
                onClick={props.onClickItem}
              >
                {ItemComponent ? (
                  <ItemComponent onClick={() => setOpen(false)} {...item} />
                ) : (
                  labelFunction(item)
                )}
              </Command.Item>
            );
          })}
        </Command.Group>
      </Command.List>
    </Command.Dialog>
  );
};

export default CommandBar;
