import React, { useState, useCallback } from 'react'

const useClientRect = (dependencies = []) => {
  const [rect, setRect] = useState(null);
  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, dependencies)
  return [rect, ref]
}

export default useClientRect
