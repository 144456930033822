import React from "react";
import { Modal } from "components/shared";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useQuery } from "hooks";
import AddCourse from "components/courses/AddCourse";

const AddCourseModal = ({ ...props }) => {
  const { watchQuery, deleteQuery } = useQuery();

  const open = watchQuery("addCourse");
  const onClose = () => {
    deleteQuery("addCourse");
    if (props.onChange) props.onChange();
  };

  return (
    <Modal
      size="sm"
      dismissible
      open={!!open}
      onClose={onClose}
      Icon={PencilSquareIcon}
      title="Add a new course"
    >
      <AddCourse onClose={onClose} />
    </Modal>
  );
};

export default AddCourseModal;
