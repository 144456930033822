import React, { useContext, useState } from "react";
import AppContext from "contexts/AppContext";

const useStageList = () => {
  const { API } = useContext(AppContext);

  class StageList extends Array {
    async addStage(stage) {
      this.push(stage);
      await this.updateOrders();

      return this;
    }

    async archiveStage(stage) {
      this.splice(this.indexOf(this.find((s) => s.slug === stage.slug)), 1);
      await this.updateOrders();

      return this;
    }

    async moveLeft(stage) {
      const index = this.indexOf(stage);
      if (index > 0) {
        this.swap(index, index - 1);
        await this.updateOrders();
      }

      return this;
    }

    async moveRight(stage) {
      const index = this.indexOf(stage);
      if (index < this.length - 1) {
        this.swap(index, index + 1);
        await this.updateOrders();
      }

      return this;
    }

    swap(index1, index2) {
      const memo = this[index1];
      this[index1] = this[index2];
      this[index2] = memo;
    }

    // updates all orders on the server with the local index
    // returns true if all stages were updated
    async updateOrders() {
      const updates = this.map(async (stage) => {
        const body = {
          activity_stage: {
            order: this.indexOf(stage),
          },
        };

        const response = API.put(`/activity_stages/${stage.slug}`, body);
        return response;
      });

      const responses = await Promise.all(updates);
      return responses.every((response) => !!response);
    }
  }

  const [stages, setInternalStages] = useState(new StageList());

  const setStages = (newStages) =>
    setInternalStages(new StageList(...newStages));

  return [stages, setStages];
};

export default useStageList;
