import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import AppContext from "contexts/AppContext";
import { Button, Tooltip, Loading } from "components/shared";
import { KeyIcon } from "@heroicons/react/24/outline";
import Questions from "components/questions/Questions";
import { pluralize } from "utils";
import confetti from "canvas-confetti";

const GetCertified = ({ ...props }) => {
  const { currentUser, setCurrentUser, API } = useContext(AppContext);
  const [keyQuestions, setKeyQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchKeyQuestions = async () => {
    setLoading(true);
    const response = await API.get(`/questions`, { params: { key: true } });

    if (response) {
      setKeyQuestions(response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKeyQuestions();
  }, []);

  const canRequestCertification = useMemo(
    () =>
      keyQuestions.every((q) => q.userHasAnswered && q.flaggers?.length === 0),
    [keyQuestions],
  );

  const requestCertification = async (e) => {
    e.preventDefault();

    const body = {
      user_id: currentUser.id,
    };

    const response = await API.post(`/certifications/start`, body);

    if (response) {
      setCurrentUser(response);
      confetti({ particleCount: 100 });
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <div className="prose mx-auto px-6 py-12 pb-6">
        <h1>
          Let's get you{" "}
          <span className="text-indigo-600">TechMap certified</span>
        </h1>
        <p>
          To qualify for TechMap certification, you must{" "}
          <strong>
            answer all{" "}
            <span className="text-yellow-600">
              <KeyIcon className="h-5 w-5 inline" /> key questions
            </span>
          </strong>{" "}
          to the level required.
        </p>
        <p>
          This includes{" "}
          <strong className="text-green-600">revising your answers</strong> in
          response to coach feedback.
        </p>
      </div>

      <div className="max-w-prose mx-auto px-6 relative">
        <Questions
          className="border-l border-r border-gray-200 rounded-none p-0"
          questions={keyQuestions}
          user={currentUser}
          onAdvance={fetchKeyQuestions}
        />
      </div>

      <div className="prose mx-auto px-6 py-12">
        {currentUser.certificationRequested ? (
          <p className="text-sm text-gray-500 text-right">
            🎉 Your request to be certified is being processed!
          </p>
        ) : canRequestCertification ? (
          <div className="text-right">
            <p className="text-sm text-gray-500 inline">
              All done? You're ready to
            </p>
            <Button
              id="requestCertificationButton"
              onClick={requestCertification}
              size="sm"
              className="ml-2"
            >
              Request certification
            </Button>
          </div>
        ) : (
          <div className="text-right">
            <Tooltip
              className="inline"
              text={
                keyQuestions.every((q) => q.userHasAnswered)
                  ? `To request certification, get approval on ${pluralize(
                      keyQuestions.filter((k) => k.flaggers.length > 0).length,
                      "key question",
                    )}.`
                  : `To request certification, answer the remaining ${pluralize(
                      keyQuestions.filter((k) => !k.userHasAnswered).length,
                      "key question",
                    )}.`
              }
            >
              <Button id="requestCertificationButton" size="sm" disabled={true}>
                Request certification
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
};

export default GetCertified;
