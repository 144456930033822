import React, { Fragment } from 'react'
import { Embed, Hint } from 'components/shared'

const PollHeader = ({ title, description, url }) => {
  return(
    <div className="px-6 py-4 border-b border-gray-200 @lg:pl-12">
      {
        description &&
        <>
          <div className="text-gray-400 font-semibold text-sm @xs:hidden @md:block mb-2">
            To which categories does the following belong?
          </div>
          <div
           className="prose"
           dangerouslySetInnerHTML={{ __html: description }} />
        </>
      }

      {
        url &&
        <Embed className="" { ...{url} } />
      }
    </div>
  )
}

export default PollHeader