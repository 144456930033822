import React from "react";
import { useRouteMatch, Link } from "react-router-dom";
import {
  ArrowRightOnRectangleIcon,
  CheckIcon,
  ArrowPathIcon,
  PlusCircleIcon,
  XCircleIcon,
  ChatBubbleLeftIcon,
  ChatBubbleLeftRightIcon,
  RocketLaunchIcon,
  QuestionMarkCircleIcon,
  ClockIcon,
  CheckBadgeIcon,
  UserPlusIcon,
  InboxArrowDownIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";
import { useQuery } from 'hooks';
import moment from "moment";
import { classNames, interpolate, excerpt, stripTags } from "utils";

const FormattedEvent = ({
  event,
  isHighlighted,
  isShort,
  isCompact,
  isTiny,
  ...props
}) => {
  const { path } = useRouteMatch();

  const { pathWithQuery } = useQuery();

  const questionText =
    event.question?.text && !isShort
      ? `"${interpolate(
          excerpt(stripTags(event.question.text), 9),
          event.user?.organization?.name,
        )}"`
      : "a question";

  const attemptActivityTitle = event.attempt?.activity?.title
    ? event.attempt.activity.title
    : "an activity";

  let parsedExtra = event.extra ? JSON.parse(event.extra) : undefined;
  const branchTitle = parsedExtra?.ref || "unknown branch";
  const prTitle = parsedExtra?.pull_request?.title || "Unknown title";
  const reviewerHandle = parsedExtra?.review?.user?.login || "a trainer";

  const emailFromName = event.email?.from?.firstName || "a trainer";
  const emailToName = event.email?.to?.firstName || "us";

  const content = (event) => {
    switch (event.type) {
      default:
        return "Unknown event type";
      case "unclassified":
        return "Unclassified event type";
      case "attempt_started":
        return "Started";
      case "attempt_completed":
        return "Completed";
      case "first_response_created":
        return "Answered";
      case "subsequent_response_created":
        return "Revised";
      case "feedback_given":
        return "Gave feedback to";
      case "branch_started":
        return "Opened";
      case "pull_request_closed":
        return "Closed";
      case "pull_request_opened":
        return "Opened";
      case "pull_request_approved":
        return "Was approved for";
      case "pull_request_merged":
        return "Merged";
      case "login":
        return "Logged in";
      case "sign_up":
        return "Signed up";
      case "requested_certification":
        return "Requested certification";
      case "certified":
        return "Certified";
      case "certification_denied":
        return "Denied certification";
      case "confirmed_email":
        return "Confirmed email";
      case "sent_email":
        return "Emailed by";
      case "responded_to_email":
        return "Emailed";
      case "feedback_received":
        return "Comment from";
      case "created_by_admin":
        return "Created by admin";
    }
  };

  const target = (event) => {
    switch (event.type) {
      default:
        return null;
      case "unclassified":
        return null;
      case "attempt_started":
        return attemptActivityTitle;
      case "attempt_completed":
        return attemptActivityTitle;
      case "first_response_created":
        return questionText;
      case "subsequent_response_created":
        return questionText;
      case "feedback_given":
        return event.response?.discussion?.user?.firstName
          ? event.response.discussion.user.firstName
          : "someone";
      case "branch_started":
        return branchTitle;
      case "pull_request_closed":
        return "an existing PR";
      case "pull_request_opened":
        return prTitle;
      case "pull_request_approved":
        return prTitle;
      case "pull_request_merged":
        return prTitle;
      case "login":
        return null;
      case "sign_up":
        return null;
      case "requested_certification":
        return null;
      case "certified":
        return null;
      case "certification_denied":
        return null;
      case "confirmed_email":
        return null;
      case "sent_email":
        return emailFromName;
      case "responded_to_email":
        return emailToName;
      case "feedback_received":
        return event.comment?.user?.firstName
          ? event.comment.user.firstName
          : "someone";
      case "created_by_admin":
        return null;
    }
  };

  const icon = (event) => {
    switch (event.type) {
      default:
        return QuestionMarkCircleIcon;
      case "unclassified":
        return QuestionMarkCircleIcon;
      case "attempt_started":
        return RocketLaunchIcon;
      case "attempt_completed":
        return CheckIcon;
      case "first_response_created":
        return ChatBubbleLeftIcon;
      case "subsequent_response_created":
        return ArrowPathIcon;
      case "feedback_given":
        return ChatBubbleLeftRightIcon;
      case "branch_started":
        return PlusCircleIcon;
      case "pull_request_closed":
        return XCircleIcon;
      case "pull_request_opened":
        return PlusCircleIcon;
      case "pull_request_approved":
        return CheckIcon;
      case "pull_request_merged":
        return CheckIcon;
      case "login":
        return ArrowRightOnRectangleIcon;
      case "sign_up":
        return UserPlusIcon;
      case "requested_certification":
        return ClockIcon;
      case "certified":
        return CheckBadgeIcon;
      case "certification_denied":
        return XCircleIcon;
      case "confirmed_email":
        return CheckIcon;
      case "sent_email":
        return InboxArrowDownIcon;
      case "responded_to_email":
        return EnvelopeIcon;
      case "feedback_received":
        return ChatBubbleLeftRightIcon;
      case "created_by_admin":
        return UserPlusIcon;
    }
  };

  const color = (event) => {
    switch (event.type) {
      default:
        return "bg-gray-400";
      case "unclassified":
        return "bg-gray-400";
      case "attempt_started":
        return "bg-blue-500";
      case "attempt_completed":
        return "bg-blue-500";
      case "first_response_created":
        return "bg-gray-400";
      case "subsequent_response_created":
        return "bg-gray-400";
      case "feedback_given":
        return "bg-gray-400";
      case "branch_started":
        return "bg-blue-500";
      case "pull_request_closed":
        return "bg-yellow-400";
      case "pull_request_opened":
        return "bg-gray-400";
      case "pull_request_approved":
        return "bg-blue-500";
      case "pull_request_merged":
        return "bg-gray-400";
      case "login":
        return "bg-gray-400";
      case "sign_up":
        return "bg-gray-400";
      case "requested_certification":
        return "bg-gray-400";
      case "certified":
        return "bg-yellow-300";
      case "certification_denied":
        return "bg-gray-400";
      case "confirmed_email":
        return "bg-gray-400";
      case "sent_email":
        return "bg-indigo-600";
      case "responded_to_email":
        return "bg-indigo-600";
      case "feedback_received":
        return "bg-indigo-600";
      case "created_by_admin":
        return "bg-indigo-600";
    }
  };

  const link = (event) => {
    switch (event.type) {
      default:
        return null;
      case "unclassified":
        return null;
      case "attempt_started":
        return null;
      case "attempt_completed":
        return null;
      case "first_response_created":
        return `discussions/${event.response?.discussion?.id}/responses/${event.response?.id}`;
      case "subsequent_response_created":
        return `discussions/${event.response?.discussion?.id}/responses/${event.response?.id}`;
      case "feedback_given":
        return `discussions/${event.response?.discussion?.id}/responses/${event.response?.id}/comments/${event.comment?.id}`;
      case "branch_started":
        return null;
      case "pull_request_closed":
        return null;
      case "pull_request_opened":
        return null;
      case "pull_request_approved":
        return null;
      case "pull_request_merged":
        return null;
      case "login":
        return null;
      case "sign_up":
        return null;
      case "requested_certification":
        return null;
      case "certified":
        return null;
      case "certification_denied":
        return null;
      case "confirmed_email":
        return null;
      case "sent_email":
        return pathWithQuery(path, { userMail: event.email?.to?.id });
      case "responded_to_email":
        return pathWithQuery(path, { userMail: event.email?.from?.id });
      case "feedback_received":
        return `discussions/${event.response?.discussion?.id}/responses/${event.response?.id}/comments/${event.comment?.id}`;
      case "created_by_admin":
        return null;
    }
  };

  const formattedEvent = {
    id: event.id,
    content: content(event),
    target: target(event),
    link: link(event),
    date: moment(event.createdAt).format("MMM DD"),
    datetime: moment(event.createdAt).format(),
    icon: icon(event),
    iconBackground: color(event),
  };

  const MaybeLink = formattedEvent.link ? Link : "div";

  return (
    <MaybeLink
      to={formattedEvent.link}
      className={classNames(
        "relative flex",
        isCompact ? "space-x-2" : "space-x-3",
        formattedEvent.link && "hover:bg-gray-100 cursor-pointer",
      )}
    >
      {isCompact ? null : (
        <div>
          <span
            className={classNames(
              formattedEvent.iconBackground,
              isCompact ? "h-5 w-5" : "h-8 w-8",
              "rounded-full flex items-center justify-center",
            )}
          >
            <formattedEvent.icon
              className={classNames(
                isCompact ? "h-3 w-3" : "h-5 w-5",
                "text-white",
              )}
              aria-hidden="true"
            />
          </span>
        </div>
      )}
      <div
        className={classNames(
          "flex min-w-0 flex-1 justify-between items-center space-x-4",
          isHighlighted ? "bg-yellow-50" : null,
        )}
      >
        <div className="w-full max-w-[240px] flex-shrink-0">
          {isCompact && (
            <div className="text-gray-400 text-xs">
              {moment(formattedEvent.datetime).fromNow()}
            </div>
          )}
          <p className={classNames(
            "text-gray-500 truncate",
            isTiny ? "text-xs" : "text-sm"
           )}>
            {formattedEvent.content}{" "}
            <span className="font-medium text-gray-900">
              {formattedEvent.target}
            </span>
          </p>
        </div>
        {isCompact ? null : (
          <div className="whitespace-nowrap text-right text-sm text-gray-500">
            <time dateTime={formattedEvent.datetime}>
              {formattedEvent.date}
            </time>
          </div>
        )}
      </div>
    </MaybeLink>
  );
};

export default FormattedEvent;
