import React from "react";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import { cn } from "utils";

const TimeZonePicker = ({ ...props }) => {
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle: "original",
    timezones: allTimezones,
  });

  return (
    <select
      className={cn(
        "bg-right pr-6 pl-1 bg-transparent text-xs appearance-none outline-none border-none",
        props.className,
      )}
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={(e) =>
        props.onChange(parseTimezone(e.currentTarget.value).value)
      }
    >
      {options.map((option, index) => (
        <option key={`tz-${index}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default TimeZonePicker;
